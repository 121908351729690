import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';

import { CommonTabProps } from './CommonTab.types';
import { CommonInfoPrivatePerson } from '../PartnerFormPrivatePerson.types';
import { Input } from 'ui/Input';
import { Select } from 'ui/Select';
import { DateIcon, MailIcon, PhoneIcon } from 'components/icons';
import { InputDate } from 'ui/InputDate';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPreSavePrivatePerson } from 'store/entities/partners/partnersSlice';

import styles from '../PartnerFormPrivatePerson.module.scss';

export const CommonTab: FC<CommonTabProps> = ({ commonInfo, check, setErrors, markDirty, setErrorList }) => {
  const { preSavePrivatePerson, isResetPreSavePrivatePerson } = useAppSelector((state) => state.partners);
  const dispatch = useAppDispatch();
  const [blur, setBlur] = useState(false);
  const [dirty, setDirty] = useState(false);

  const genders: string[] = ['Не выбран', 'Мужской', 'Женский'];
  const [gender, setGender] = useState<string>(preSavePrivatePerson?.commonInfo.gender || commonInfo?.gender || '');

  const initialValues: CommonInfoPrivatePerson = {
    lastName: preSavePrivatePerson?.commonInfo.lastName || commonInfo?.lastName || '',
    firstName: preSavePrivatePerson?.commonInfo.firstName || commonInfo?.firstName || '',
    patronymic: preSavePrivatePerson?.commonInfo.patronymic || commonInfo?.patronymic || '',
    gender: gender,
    birthDate: preSavePrivatePerson?.commonInfo.birthDate || commonInfo?.birthDate || null,
    phone: preSavePrivatePerson?.commonInfo.phone || commonInfo?.phone || '',
    email: preSavePrivatePerson?.commonInfo.email || commonInfo?.email || '',
    comments: preSavePrivatePerson?.commonInfo.comments || commonInfo?.comments || '',
  };

  const setMarkedDirty = () => {
    setDirty(true);
  };

  const validate = {
    firstName: (value: string) => (value.length == 0 ? 'Имя обязательно для заполнения' : null),
    phone: (value: string) => (value.length == 0 ? 'Телефон обязателен для заполнения' : null),
  };

  const formCommonInfo = useForm({
    initialValues,
    validate,
    validateInputOnChange: true,
  });
  //если форму начали заполнять и в ней нет ошибок, данные уходят в предсохранении и оттуда на сервер
  useEffect(() => {
    if (JSON.stringify(formCommonInfo.errors) === '{}') {
      dispatch(
        setPreSavePrivatePerson({
          ...preSavePrivatePerson,
          commonInfo: formCommonInfo.values,
        })
      );
    } else {
      if (setErrors) {
        setErrors(formCommonInfo.errors);
      }
    }
  }, [formCommonInfo.values, formCommonInfo.errors]);

  useEffect(() => {
    setErrorList(Object.keys(formCommonInfo.errors));
  }, [formCommonInfo.errors]);

  useEffect(() => {
    if (blur) {
      console.log('www');
      markDirty();
    }
  }, [blur]);

  useEffect(() => {
    if (dirty) {
      setDirty(false);
      markDirty();
    }
  }, [dirty]);

  const updGender = (s: string) => {
    formCommonInfo.setFieldValue('gender', s);
    setGender(s);
  };

  useEffect(() => {
    if (isResetPreSavePrivatePerson) {
      formCommonInfo.reset();
      formCommonInfo.setFieldValue('lastName', initialValues.lastName);
      formCommonInfo.setFieldValue('firstName', initialValues.firstName);
      formCommonInfo.setFieldValue('patronymic', initialValues.patronymic);
      formCommonInfo.setFieldValue('gender', initialValues.gender);
      formCommonInfo.setFieldValue('birthDate', initialValues.birthDate);
      formCommonInfo.setFieldValue('phone', initialValues.phone);
      formCommonInfo.setFieldValue('email', initialValues.email);
      formCommonInfo.setFieldValue('comments', initialValues.comments);
    }
  }, [isResetPreSavePrivatePerson]);

  //по нажатию кнопки "сохранить" в родительском компоненте меняется статус check. если true - запускаем валидацию и создаем объект с ошибками
  useEffect(() => {
    if (check) {
      formCommonInfo.validate();
    }
  }, [check]);

  useEffect(() => {
    if (setErrors) {
      setErrors(formCommonInfo.errors);
    }
  }, [formCommonInfo.errors]);

  return (
    <form>
      <div className={styles.group}>
        <Input
          label="Фамилия"
          {...formCommonInfo.getInputProps('lastName')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
            formCommonInfo.validate();
          }}
          error={!!formCommonInfo.errors.lastName}
        />
        <Input
          label="Имя*"
          {...formCommonInfo.getInputProps('firstName')}
          className={styles.input}
          error={!!formCommonInfo.errors.firstName}
          onBlur={() => {
            setBlur(true);
            formCommonInfo.validate();
          }}
        />
        <Input
          label="Отчество"
          {...formCommonInfo.getInputProps('patronymic')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
            formCommonInfo.validate();
          }}
        />
      </div>

      <div className={styles.info}>
        <Select
          selected={gender}
          data={genders}
          setSelect={updGender}
          onDirty={() => {
            formCommonInfo.validate();
            setMarkedDirty();
          }}
          label="Пол"
          className={styles.gender}
          error={!!formCommonInfo.errors.gender}
          isWithReset={false}
        />
        <InputDate
          label="Дата рождения"
          className={styles.birthDate}
          placeholder="_ _._ _._ _ _ _"
          icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formCommonInfo.errors.birthDate}
          {...formCommonInfo.getInputProps('birthDate')}
          setDate={(a) => formCommonInfo.setFieldValue('birthDate', a)}
          onBlur={setMarkedDirty}
        />
      </div>

      <div className={styles.info}>
        <Input
          type="text"
          label="Телефон*"
          placeholder="Введите номер телефона"
          {...formCommonInfo.getInputProps('phone')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.match(/^\d+$/)) {
              formCommonInfo.setFieldValue('phone', e.target.value);
            } else {
              formCommonInfo.setFieldValue(
                'phone',
                e.target.value === '' ? e.target.value : formCommonInfo.values.phone
              );
            }
          }}
          className={clsx(styles.phone, styles.number)}
          iconLeft={<PhoneIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formCommonInfo.errors.phone}
          onBlur={setBlur}
          onWheel={(e: any) => e.target.blur()}
        />
        <Input
          label="Почта"
          placeholder="Введите почту"
          {...formCommonInfo.getInputProps('email')}
          className={styles.email}
          iconLeft={<MailIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formCommonInfo.errors.email}
          onBlur={setBlur}
        />
      </div>

      <div className={styles.textarea}>
        <label className={styles.textareaLabel}>Примечание</label>
        <textarea
          placeholder="Введите примечание"
          {...formCommonInfo.getInputProps('comments')}
          className={styles.textareaText}
          onBlur={setBlur}
        />
      </div>
    </form>
  );
};
