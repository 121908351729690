import React, { FC, useEffect, useState } from 'react';
import styles from './Dropdown.module.scss';
import { Button } from 'ui/Button';
import clsx from 'clsx';

interface DropdownProps {
  data: string[];
  title: string;
  setFiltersData: (f: string) => void;
  setOpenedFilter: (o: boolean) => void;
}

export const Dropdown: FC<DropdownProps> = ({ data, title, setFiltersData, setOpenedFilter }) => {
  const [opened, setOpened] = useState(true);
  const [filters, setFilters] = useState(data);
  const [filter, setFilter] = useState('');
  const [query, setQuery] = useState('');

  const addingFilter = (item: string) => {
    const getElementByValue = data.filter((elem) => elem === item)[0];
    setFilter(getElementByValue);
    setOpened(false);
    setQuery('');
  };

  useEffect(() => {
    if (query === '') {
      setFilters(data);
    } else {
      const filterByQuery = filters?.filter((item) => item.toLowerCase().includes(query));
      setFilters(filterByQuery);
    }
  }, [query]);

  const handleBtnApply = () => {
    setFiltersData(filter);
    setOpenedFilter(false);
  };

  return (
    <div className={styles.choice}>
      <div className={clsx(styles.input, opened && styles.inputOpened)} onClick={() => setOpened((prev) => !prev)}>
        {filter === '' ? title : filter}
      </div>
      {opened && (
        <ul className={styles.list}>
          <li>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Поиск"
              className={styles.search}
            />
          </li>
          {filters.map((item) => (
            <li key={item} onClick={() => addingFilter(item)} className={styles.item}>
              {item}
            </li>
          ))}
        </ul>
      )}
      <Button bgColor="#F7A860" onClick={handleBtnApply} label="Применить" className={styles.btnApply} />
    </div>
  );
};
