import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const Client2AIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41615 10.0474C11.5566 10.0474 13.2918 8.31217 13.2918 6.17172C13.2918 4.03126 11.5566 2.29608 9.41615 2.29608C7.2757 2.29608 5.54052 4.03126 5.54052 6.17172C5.54052 8.31217 7.2757 10.0474 9.41615 10.0474ZM14.9502 6.17172C14.9502 8.23832 13.8174 10.0403 12.1389 10.9907C13.5597 11.4079 14.8668 12.1492 15.9377 13.1665C16.8602 14.043 17.5672 15.086 18.0261 16.2212H22.3143C22.0596 15.226 21.52 14.3004 20.7334 13.5531C19.6031 12.4793 18.0561 11.8649 16.4297 11.8649C16.3504 11.8649 16.2713 11.8663 16.1924 11.8692V10.0867C16.2708 10.0927 16.3501 10.0957 16.43 10.0957C18.1424 10.0957 19.5306 8.70756 19.5306 6.9952C19.5306 5.28284 18.1424 3.89469 16.43 3.89469C16.3501 3.89469 16.2708 3.89772 16.1924 3.90366V2.63763H17.2165C19.2864 3.00873 20.8573 4.81852 20.8573 6.9952C20.8573 8.64859 19.9509 10.0903 18.608 10.8506C19.7447 11.1843 20.7905 11.7774 21.6472 12.5913C22.6971 13.5887 23.398 14.8558 23.675 16.2212C23.7631 16.6553 23.8084 17.0994 23.8084 17.5479H22.4817H18.4392C18.4508 17.5998 18.4619 17.6518 18.4725 17.704C18.5826 18.2466 18.6391 18.8017 18.6391 19.3624H16.9807H9.41575H1.85076H0.192383C0.192383 18.8017 0.248938 18.2466 0.359048 17.704C0.705357 15.9972 1.58142 14.4134 2.89385 13.1665C3.96485 12.1491 5.27213 11.4077 6.69317 10.9906C5.0148 10.0401 3.88213 8.23821 3.88213 6.17172C3.88213 3.11536 6.3598 0.637695 9.41615 0.637695C12.4725 0.637695 14.9502 3.11536 14.9502 6.17172ZM16.7715 17.704C16.4532 16.4599 15.7787 15.3029 14.7954 14.3689C13.3825 13.0266 11.4488 12.2585 9.41575 12.2585C7.38273 12.2585 5.44901 13.0266 4.03605 14.3689C3.05285 15.3029 2.37829 16.4599 2.05999 17.704L9.41575 17.704H16.7715Z"
        fill={fill}
      />
    </svg>
  );
};
