import { TableColumn } from './CreatedDocumentsHistory.types';

export const toursColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
  },
  {
    id: 'title',
    name: 'Название',
  },
  {
    id: 'documentType',
    name: 'Тип документа',
  },

  {
    id: 'date',
    name: 'Дата создания',
  },
  {
    id: 'lk',
    name: 'Синхронизация с ЛК',
  },
  {
    id: 'delete',
    name: '',
  },
];
