import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const Client1A2CIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8289 6.17172C13.8289 8.31217 12.0937 10.0474 9.95326 10.0474C7.81281 10.0474 6.07763 8.31217 6.07763 6.17172C6.07763 4.03126 7.81281 2.29608 9.95326 2.29608C12.0937 2.29608 13.8289 4.03126 13.8289 6.17172ZM12.676 10.9907C14.3545 10.0403 15.4873 8.23832 15.4873 6.17172C15.4873 3.11536 13.0096 0.637695 9.95326 0.637695C6.89691 0.637695 4.41924 3.11536 4.41924 6.17172C4.41924 8.23821 5.55191 10.0401 7.23028 10.9906C5.80924 11.4077 4.50196 12.1491 3.43095 13.1665C2.11853 14.4134 1.24247 15.9972 0.896157 17.704C0.786048 18.2466 0.729492 18.8017 0.729492 19.3624H2.38787L9.95286 19.3624H17.5178H19.1762C19.1762 18.8017 19.1197 18.2466 19.0096 17.704C18.6633 15.9972 17.7872 14.4134 16.4748 13.1665C15.4039 12.1492 14.0968 11.4079 12.676 10.9907ZM15.3326 14.3689C16.3158 15.3029 16.9903 16.4599 17.3086 17.704H9.95286L2.5971 17.704C2.9154 16.4599 3.58996 15.3029 4.57316 14.3689C5.98611 13.0266 7.91984 12.2585 9.95286 12.2585C11.9859 12.2585 13.9196 13.0266 15.3326 14.3689Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3344 6.41712C28.3344 7.5957 27.379 8.55113 26.2004 8.55113C25.0218 8.55113 24.0664 7.5957 24.0664 6.41712C24.0664 5.23853 25.0218 4.28311 26.2004 4.28311C27.379 4.28311 28.3344 5.23853 28.3344 6.41712ZM29.9344 6.41712C29.9344 8.47935 28.2626 10.1511 26.2004 10.1511C24.1382 10.1511 22.4664 8.47935 22.4664 6.41712C22.4664 4.35488 24.1382 2.68311 26.2004 2.68311C28.2626 2.68311 29.9344 4.35488 29.9344 6.41712ZM26.2001 10.6048C25.3544 10.6048 24.3984 10.6474 23.4864 10.8466C22.5754 11.0456 21.6526 11.4126 20.9258 12.1031C20.2017 12.7909 19.7706 13.7063 19.5183 14.6135C19.2648 15.5253 19.1768 16.4821 19.1768 17.317H20.7768C20.7768 16.5838 20.8552 15.7781 21.0598 15.0422C21.2658 14.3016 21.5847 13.684 22.0278 13.2631C22.468 12.8449 23.0816 12.5727 23.8279 12.4097C24.5732 12.247 25.3953 12.2048 26.2001 12.2048C27.0049 12.2048 27.8415 12.2469 28.6016 12.4103C29.3647 12.5743 29.9885 12.8477 30.4258 13.2631C30.8636 13.679 31.17 14.2903 31.3639 15.0305C31.5566 15.7664 31.6235 16.5745 31.6235 17.317H33.2235C33.2235 16.4915 33.1505 15.5369 32.9117 14.6251C32.674 13.7176 32.257 12.7959 31.5278 12.1031C30.798 11.4098 29.8586 11.044 28.9378 10.846C28.014 10.6474 27.0459 10.6048 26.2001 10.6048Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.3813 6.41712C42.3813 7.5957 41.4258 8.55113 40.2473 8.55113C39.0687 8.55113 38.1133 7.5957 38.1133 6.41712C38.1133 5.23853 39.0687 4.28311 40.2473 4.28311C41.4258 4.28311 42.3813 5.23853 42.3813 6.41712ZM43.9813 6.41712C43.9813 8.47935 42.3095 10.1511 40.2473 10.1511C38.185 10.1511 36.5133 8.47935 36.5133 6.41712C36.5133 4.35488 38.185 2.68311 40.2473 2.68311C42.3095 2.68311 43.9813 4.35488 43.9813 6.41712ZM40.247 10.6048C39.4013 10.6048 38.4453 10.6474 37.5333 10.8466C36.6223 11.0456 35.6995 11.4126 34.9726 12.1031C34.2486 12.7909 33.8175 13.7063 33.5652 14.6135C33.3116 15.5253 33.2236 16.4821 33.2236 17.317H34.8236C34.8236 16.5838 34.9021 15.7781 35.1067 15.0422C35.3127 14.3016 35.6316 13.684 36.0746 13.2631C36.5149 12.8449 37.1285 12.5727 37.8747 12.4097C38.62 12.247 39.4421 12.2048 40.247 12.2048C41.0517 12.2048 41.8884 12.2469 42.6485 12.4103C43.4116 12.5743 44.0353 12.8477 44.4726 13.2631C44.9104 13.679 45.2169 14.2903 45.4108 15.0305C45.6035 15.7664 45.6703 16.5745 45.6703 17.317H47.2703C47.2703 16.4915 47.1974 15.5369 46.9585 14.6251C46.7209 13.7176 46.3039 12.7959 45.5746 12.1031C44.8448 11.4098 43.9055 11.044 42.9847 10.846C42.0609 10.6474 41.0928 10.6048 40.247 10.6048Z"
        fill={fill}
      />
    </svg>
  );
};
