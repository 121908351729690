import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const OrdersIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_11)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.74293 11.3037L8.47098 11.9924L6.78101 16.2723L6.70201 16.4724L6.43379 17.1517L6.32803 17.4195C6.22076 17.6912 6.50475 17.9522 6.76643 17.8224L7.02442 17.6945L7.67868 17.37L7.87139 17.2745L11.9938 15.2301L12.6572 14.9011L13.1248 14.3922L22.7299 3.94117C23.1272 3.50881 23.0989 2.8362 22.6665 2.43884L20.3179 0.280406C19.8856 -0.11695 19.213 -0.0885767 18.8156 0.34378L9.21059 10.7948L8.74293 11.3037ZM10.9034 14.2279L8.62895 15.3559L9.56136 12.9945L10.9034 14.2279ZM12.1071 13.4569L10.2283 11.7302L19.6175 1.51396L21.4963 3.2407L12.1071 13.4569ZM3.36 5.09673H8.77778V3.2831H3.36C1.50432 3.2831 0 4.78742 0 6.6431V20.9231C0 22.7788 1.50432 24.2831 3.36 24.2831H17.64C19.4957 24.2831 21 22.7788 21 20.9231V13.2831H19.1864V20.9231C19.1864 21.7771 18.494 22.4695 17.64 22.4695H3.36C2.50597 22.4695 1.81364 21.7771 1.81364 20.9231V6.6431C1.81364 5.78906 2.50597 5.09673 3.36 5.09673Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_11">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
