export const getSales = (condition: string) => {
  switch (condition) {
    case 'не выбрано':
      return { years_from: 0, years_to: 0, sex: '' };
    case 'школьникам  7 лет - 13 лет':
      return { years_from: 7, years_to: 13, sex: '' };
    case 'школьникам старше 14 лет - 18 лет':
      return { years_from: 14, years_to: 18, sex: '' };
    case 'школьникам до 16 лет':
      return { years_from: 7, years_to: 16, sex: '' };
    case 'студентам':
      return { years_from: 0, years_to: 0, sex: 'student' };
    case 'школьникам до 14 лет на основном месте':
      return { years_from: 7, years_to: 14, sex: '' };
    case 'детям старше 16 лет - 18 лет':
      return { years_from: 16, years_to: 18, sex: '' };
    case 'школьникам до 12 лет':
      return { years_from: 7, years_to: 12, sex: '' };
    case 'мужчинам от 65 лет':
      return { years_from: 65, years_to: 0, sex: 'man' };
    case 'женщинам от 60 лет':
      return { years_from: 60, years_to: 0, sex: 'woman' };
    default:
      return { years_from: 0, years_to: 0, sex: '' };
  }
};

export const getConditionFromSales = (years_from: number, years_to: number, sex: string) => {
  if (years_from === 0 && years_to === 0 && sex === '') return 'не выбрано';
  if (years_from === 7 && years_to === 13 && sex === '') return 'школьникам  7 лет - 13 лет';
  if (years_from === 14 && years_to === 18 && sex === '') return 'школьникам старше 14 лет - 18 лет';
  if (years_from === 7 && years_to === 16 && sex === '') return 'школьникам до 16 лет';
  if (years_from === 0 && years_to === 0 && sex === 'student') return 'студентам';
  if (years_from === 7 && years_to === 14 && sex === '') return 'школьникам до 14 лет на основном месте';
  if (years_from === 16 && years_to === 18 && sex === '') return 'детям старше 16 лет - 18 лет';
  if (years_from === 7 && years_to === 12 && sex === '') return 'школьникам до 12 лет';
  if (years_from === 65 && years_to === 0 && sex === 'man') return 'мужчинам от 65 лет';
  if (years_from === 60 && years_to === 0 && sex === 'woman') return 'женщинам от 60 лет';
  return '';
};
