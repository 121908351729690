import { TableColumn } from './OrderTable.types';

export const ordersColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
    sorted: 'desc',
  },
  {
    id: 'partner',
    name: 'Контрагент',
    sorted: 'desc',
  },
  {
    id: 'bookingDate',
    name: 'Дата брони',
    sorted: 'desc',
  },
  {
    id: 'price',
    name: 'Стоимость',
    sorted: 'desc',
  },
  {
    id: 'tourTitle',
    name: 'Услуга',
  },
  {
    id: 'tourDate',
    name: 'Даты',
  },
  {
    id: 'status',
    name: 'Статус',
    sorted: 'desc',
  },
  {
    id: 'manager',
    name: 'Менеджер',
  },
];
