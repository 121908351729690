import React, { FC } from 'react';

import { GalleryProps } from '..';
import { GalleryTab } from 'core/constants/enums';
import { AllPhotoTable } from './AllPhotoTable';
import { AllAlbumTable } from './AllAlbumTable';

export const GalleryTable: FC<GalleryProps> = ({ tab }) => {
  return <>{tab === GalleryTab.ALL_PHOTO ? <AllPhotoTable /> : <AllAlbumTable />}</>;
};
