import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';

export const ActualPaymentColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
  },
  {
    id: 'type',
    name: 'Способ оплаты',
  },
  {
    id: 'sum',
    name: 'Сумма',
  },
  {
    id: 'rate',
    name: 'Курс',
  },
  {
    id: 'rub',
    name: 'Руб.',
  },
  {
    id: 'date',
    name: 'Дата получения',
  },
];

export const paymentTypes = [
  'Наличные',
  'Безналичная оплата (юр. лица)',
  'Оплата по терминалу',
  'Оплата по ссылке',
  'Оплата по QR-коду',
];
