import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './AlbumList.module.scss';
import ModalContext from 'core/context/ModalContext';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Button } from 'ui/Button';
import { getAlbumTags, getAlbums } from 'store/action-creators/gallery';
import { Loader } from '@mantine/core';
import { Album, PhotoData } from 'services/gallery/galleryApi.service';
import {
  setAlbumParamsSortByDate,
  setAlbumParamsSortById,
  setAlbumParamsDateFrom,
  setAlbumParamsDateTo,
  setAlbumParamsPage,
  setAlbumParamsSearch,
  setAlbumParamsTags,
} from 'store/entities/gallery/gallerySlice';
import clsx from 'clsx';
import { Search } from 'components/Search';
import { InputDatePicker } from 'ui/InputDatePicker';
import { FilterNew } from 'ui/FilterNew/FilterNew';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { getTimezonelessDate } from 'core/utils/getDate';
import { columns } from '../GalleryTable/data';
import { CellWithSorting } from 'components/CellWithSorting';
import { AlbumListItem } from './AlbumItem';

export const AlbumList: FC<{ setAlbum: (a: Album) => void; selectedAlbum: number }> = ({ setAlbum, selectedAlbum }) => {
  const { albums, album_tags, albumCount, paramsAlbum, isLoading, isSuccess } = useAppSelector(
    (state) => state.gallerySlice
  );
  const { setActive } = useContext(ModalContext);
  const [albumData, setAlbumData] = useState<PhotoData | null>(null);
  const [photoList, setPhotoList] = useState<Album[]>([]);
  const dispatch = useAppDispatch();
  const [selectedId, setSelectedId] = useState<number>(0);
  // при изменении параметров для запроса, отправляем запрос на получение заказов
  useEffect(() => {
    dispatch(getAlbumTags());
  }, []);
  useEffect(() => {
    if (albumData) {
      dispatch(getAlbums(albumData));
    }
  }, [albumData]);
  useEffect(() => {
    if (paramsAlbum.currentPage === 1) {
      setPhotoList(albums);
    } else {
      setPhotoList((pre: any) => {
        const combinedArray = pre.concat(albums);
        const uniqueArray = combinedArray.filter(
          (item: any, index: number, arr: any[]) => arr.findIndex((el) => el.id === item.id) === index
        );
        return uniqueArray;
      });
    }
  }, [albums]);

  //при изменении значений фильтров, меняем параметры для запроса на сервер
  useEffect(() => {
    setAlbumData({
      ...albumData,
      page: paramsAlbum.currentPage,
      search: paramsAlbum.search === '' ? null : paramsAlbum.search,
      dateFrom: paramsAlbum['created_at[after]'] ? paramsAlbum['created_at[after]'] : null,
      dateTo: paramsAlbum['created_at[before]'] ? paramsAlbum['created_at[before]'] : null,
      itemsPerPage: paramsAlbum.itemsPerPage,
      photo_tags: paramsAlbum.photo_tags.length > 0 ? paramsAlbum.photo_tags : null,
      sortById: paramsAlbum.sortById ? paramsAlbum.sortById : null,
      sortByDate: paramsAlbum.sortByDate,
    });
  }, [paramsAlbum]);

  const getNextPage = () => {
    dispatch(setAlbumParamsPage(paramsAlbum.currentPage + 1));
  };
  const myRef = useRef<any>(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  useEffect(() => {
    if (isSuccess === 'GET_GALLERY_ALBUMS' && paramsAlbum.currentPage > 1) {
      executeScroll();
    }
  }, [isSuccess]);

  const handleSave = () => {
    const album = photoList.find((el) => Number(el.id) === selectedId);
    if (album) {
      setAlbum(album);
      setActive(false);
    }
  };

  const toggleActiveItem = (index: number) => {
    if (selectedId === index) {
      setSelectedId(0);
    } else {
      setSelectedId(index);
    }
  };

  const handleSearchClick = (query: string) => {
    dispatch(setAlbumParamsSearch(query));

    dispatch(setAlbumParamsPage(1));
  };
  const handleTags = (query: SelectMultiItemType) => {
    if (query) {
      dispatch(setAlbumParamsTags([query['@id']!]));

      dispatch(setAlbumParamsPage(1));
    }
    if (query === null) {
      dispatch(setAlbumParamsTags([]));

      dispatch(setAlbumParamsPage(1));
    }
  };
  const handleDataPicker = (value: [Date | null, Date | null]) => {
    console.log(value);
    if (value[0] != null) {
      dispatch(setAlbumParamsDateFrom(getTimezonelessDate(new Date(value[0]!))));
      dispatch(
        setAlbumParamsDateTo(
          new Date(value[0]).getTime() === new Date(value[1]!).getTime()
            ? null
            : getTimezonelessDate(new Date(value[1]!))
        )
      );
    } else {
      dispatch(setAlbumParamsDateFrom(null));
      dispatch(setAlbumParamsDateTo(null));
    }
    dispatch(setAlbumParamsPage(1));
  };

  const handleSortByColumn = (sort: 'asc' | 'desc', column: string) => {
    if (column === 'id') {
      dispatch(setAlbumParamsSortById(sort));
      dispatch(setAlbumParamsSortByDate(null));
      dispatch(setAlbumParamsPage(1));
    }
    if (column === 'edit') {
      dispatch(setAlbumParamsSortById(null));
      dispatch(setAlbumParamsSortByDate(sort));
      dispatch(setAlbumParamsPage(1));
    }
  };
  return (
    <section className={styles.edit}>
      <div className={styles.top}>
        <h2 className={styles.title}>Выбор фотоальбома</h2>
        <Button onClick={handleSave}>Сохранить выбор</Button>
      </div>
      <div className={styles.content}>
        <div className={styles.filters}>
          <Search placeholder="Поиск" setQuery={handleSearchClick} />
          <InputDatePicker placeholder={'Дата загрузки'} setDates={handleDataPicker} />
          <FilterNew
            filter={{
              title: 'Теги',
              data: album_tags,
            }}
            setFilter={(a) => handleTags(a!)}
          />
        </div>
        <div className={styles.table}>
          <div className={styles.rows}>
            {columns.map((column) => (
              <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
                {column.id === 'id' || column.id === 'edit' ? (
                  <CellWithSorting column={column} setColumnSort={handleSortByColumn} sorting={column.sorted!} />
                ) : (
                  <span>{column.name}</span>
                )}
              </div>
            ))}
          </div>
          {isLoading && isLoading === 'GET_GALLERY_ALBUMS' && (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
          )}
          <div>
            {isLoading !== 'GET_GALLERY_ALBUMS' &&
              albums &&
              albums.length > 0 &&
              photoList.map((album) => (
                <AlbumListItem
                  album={album}
                  key={album.id}
                  handleClickRow={(id) => toggleActiveItem(id)}
                  selected={selectedId === Number(album.id)}
                />
              ))}
            <div className={styles.loadMore}>
              <Button onClick={() => getNextPage()}>Показать ещё</Button>
              <span ref={myRef} />
            </div>
            {isSuccess === 'GET_GALLERY_ALBUMS' && photoList.length === 0 && (
              <span className={styles.notFound}>Ничего не найдено</span>
            )}
          </div>

          {isLoading !== 'GET_GALLERY_ALBUMS' && isSuccess === 'GET_GALLERY_ALBUMS' && albums && albums.length > 0 && (
            <div className={styles.count}>Всего записей — {albumCount}</div>
          )}
        </div>
      </div>
    </section>
  );
};
