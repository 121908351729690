import { createAsyncThunk } from '@reduxjs/toolkit';
import { addAttachmentAPI, addOrderActionAPI, orderActionsAPI } from 'services/orderActions/orderActionsApi.service';
import { AddOrderActionData, OrderActionsData } from '../../services/orderActions/OrderActinResponse.model';

export const addOrderAction = createAsyncThunk('orderActions/addOrderAction', async (arg: AddOrderActionData, thunkAPI) => {
  try {
    const attachmentsPromise = arg.attachments.map((file) => {
      return addAttachmentAPI({ file: file as File });
    });
    return await Promise.all(attachmentsPromise).then(async (data) => {

      const response = await addOrderActionAPI({ ...arg, attachments: data.map(({ data }) => data.path) });
      return response.data;
    });
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getOrderActions = createAsyncThunk('orderActions/getOrderAction', async (arg: OrderActionsData, thunkAPI) => {
  try {
    const response = await orderActionsAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const addAttachment = createAsyncThunk('orderActions/addAttachment', async (arg: { file: File }, thunkAPI) => {
  try {
    const response = await addAttachmentAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

