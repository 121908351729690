import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddOrderLink,
  PlannedPayment,
  RealPayment,
  addOrderPaymentLinkAPI,
  addPlannedPayment,
  addRealPayment,
  deletePlannedPayment,
  deleteRealPayment,
  getPaymentLinks,
  getPaymentLinksCancel,
  getPaymentLinksFinish,
} from 'services/orderPaymentLink/orderPaymentApi.service';
import {
  getOrderAdditionalNight,
  getOrderDocument,
  getOrderNotifications,
  getOrderPlacements,
  getOrderTransfer,
  getPlannedPayments,
  getRealPayments,
} from 'services/orders/orderApi.service';

// создание ссылки
export const addOrderPaymentLink = createAsyncThunk(
  'orders/addOrderPaymentLink',
  async (arg: AddOrderLink, thunkAPI) => {
    try {
      const response = await addOrderPaymentLinkAPI(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

export const addOrderPaymentLinkFinish = createAsyncThunk(
  'orders/addOrderPaymentLinkFinish',
  async (arg: string, thunkAPI) => {
    try {
      const response = await getPaymentLinksFinish(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

export const addOrderPaymentLinkCancel = createAsyncThunk(
  'orders/addOrderPaymentLinkCancel',
  async (arg: string, thunkAPI) => {
    try {
      const response = await getPaymentLinksCancel(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
// добавление плановой оплаты
export const addOrderPlannedPayment = createAsyncThunk(
  'orders/addPlannedPayment',
  async (arg: PlannedPayment, thunkAPI) => {
    try {
      const response = await addPlannedPayment(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
// добавление фактической оплаты
export const addOrderRealPayment = createAsyncThunk('orders/addRealPayment', async (arg: RealPayment, thunkAPI) => {
  try {
    const response = await addRealPayment(arg);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
// удаление фактической оплаты
export const deleteOrderRealPayment = createAsyncThunk(
  'orders/deleteOrderRealPayment',
  async (arg: string, thunkAPI) => {
    try {
      const response = await deleteRealPayment(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
// удаление плановой оплаты
export const deleteOrderPlannedPayment = createAsyncThunk(
  'orders/deleteOrderPlannedPayment',
  async (arg: string, thunkAPI) => {
    try {
      const response = await deletePlannedPayment(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
// вывод фактической оплаты
export const getRealPayment = createAsyncThunk('orders/getRealPayment', async (arg: { id: number }, thunkAPI) => {
  try {
    const response = await getRealPayments(arg.id);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
// вывод плановой оплаты
export const getPlannedPayment = createAsyncThunk('orders/getPlannedPayment', async (arg: { id: number }, thunkAPI) => {
  try {
    const response = await getPlannedPayments(arg.id);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// вывод доп.ночей
export const getAdditionalNight = createAsyncThunk(
  'orders/getAdditionalNight',
  async (arg: { id: number }, thunkAPI) => {
    try {
      const response = await getOrderAdditionalNight(arg.id);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

// вывод напоминаний
export const getNotifications = createAsyncThunk('orders/getNotifications', async (arg: { id: number }, thunkAPI) => {
  try {
    const response = await getOrderNotifications(arg.id);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// вывод размещения
export const getPlacements = createAsyncThunk('orders/getPlacements', async (arg: { id: number }, thunkAPI) => {
  try {
    const response = await getOrderPlacements(arg.id);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// вывод трансфера
export const getTransfer = createAsyncThunk('orders/getTransfer', async (arg: { id: number }, thunkAPI) => {
  try {
    const response = await getOrderTransfer(arg.id);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// вывод документов
export const getDocument = createAsyncThunk('orders/getDocument', async (arg: { id: number }, thunkAPI) => {
  try {
    const response = await getOrderDocument(arg.id);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// вывод ссылок на оплату
export const getOrderPaymentLinks = createAsyncThunk(
  'orders/getPaymentLinks',
  async (arg: { id: number }, thunkAPI) => {
    try {
      const response = await getPaymentLinks(arg.id);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
