import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useForm } from '@mantine/form';

import { PlannedPaymentsType, PlannedPaymentsProps } from './PlannedPayment.type';
import { Button } from 'ui/Button';
import { paymentTypes, PlannedPaymentColumns } from './data';
import { Select } from 'ui/Select';
import { InputDate } from 'ui/InputDate';
import { RemoveIcon } from 'components/icons';
import { Input } from 'ui/Input';
import { getDate } from 'core/utils/getDate';
import { setPreSaveOrder } from 'store/entities/orders/ordersSlice';
import styles from './PlannedPayment.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addOrderPlannedPayment, deleteOrderPlannedPayment } from 'store/action-creators/orderPaymentLink';
import { getPaymentTypeRuToEn } from 'core/utils/getPaymentMethod';
import { listOfPaymentStatuses } from 'core/constants/lists';
import { InputRate } from 'ui/InputRate';
import { getCurrencyIconToUI } from 'core/utils/getCurrency';

export const PlannedPayments: FC<PlannedPaymentsProps> = ({
  selectedActualPayments,
  setselectedActualPayments,
  orderId,
  isCanEdit,
  allSum,
  rate,
}) => {
  const [viewNewRow, setViewNewRow] = useState(true);
  const [newRow, setNewRow] = useState<PlannedPaymentsType | null>(null);
  const dispatch = useAppDispatch();
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const [type, setType] = useState<string>('');
  const [lastIndex, setlastIndex] = useState(0);
  const [errors, setErrors] = useState<{
    date: boolean;
    sum: boolean;
  }>({
    date: false,
    sum: false,
  });
  const initialValues = {
    id: selectedActualPayments.length + 1,
    type: type,
    plan_date: '',
    summ: '',
    rate: '1',
    sum_rub: 0,
  };

  const formNewRow = useForm({
    initialValues,
  });

  useEffect(() => {
    if (selectedActualPayments.length > 0 && lastIndex === 0) {
      const lastEl = selectedActualPayments.at(-1);
      setlastIndex(lastEl!.id);
    }
  }, [selectedActualPayments]);

  useEffect(() => {
    if (selectedActualPayments.length > 0 && preSaveOrder.documentsAndPayment?.real_payments.length === 0) {
      dispatch(
        setPreSaveOrder({
          ...preSaveOrder,
          basicInfo: {
            ...preSaveOrder.basicInfo,
            payed_status: listOfPaymentStatuses[1],
          },
        })
      );
    }
  }, [selectedActualPayments]);

  const addNewRow = () => {
    if (!newRow?.plan_date) {
      setErrors((prev) => {
        return { ...prev, date: true };
      });
      return;
    }
    setErrors({ date: false, sum: false });
    setViewNewRow(true);
    if (newRow) {
      newRow.id = selectedActualPayments.length > 0 ? lastIndex + 1 : selectedActualPayments.length + 1;
      if (orderId) {
        dispatch(
          addOrderPlannedPayment({
            tour_order: orderId,
            type: getPaymentTypeRuToEn(newRow.type),
            summ: newRow.summ,
            plan_date: newRow.plan_date.toString(),
            summ_rub: (Number(newRow.summ) * Number(newRow.rate)).toString(),
            exchange_rate: newRow.rate,
          })
        );
      }
      setlastIndex((prev) => prev + 1);
      setselectedActualPayments([...selectedActualPayments, newRow]);
      formNewRow.setValues(initialValues);
      if (preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment?.real_payments.length === 0) {
        dispatch(
          setPreSaveOrder({
            ...preSaveOrder,
            basicInfo: {
              ...preSaveOrder.basicInfo,
              payed_status: listOfPaymentStatuses[1],
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    setNewRow({
      id: 0,
      type: formNewRow.values.type,
      plan_date: formNewRow.values.plan_date ? getDate(new Date(formNewRow.values.plan_date)) : '',
      summ: formNewRow.values.summ,
      rate: formNewRow.values.rate,
    });
  }, [formNewRow.values]);

  useEffect(() => {
    setNewRow({
      id: 0,
      type: formNewRow.values.type,
      plan_date: formNewRow.values.plan_date ? getDate(new Date(formNewRow.values.plan_date)) : '',
      summ: formNewRow.values.summ,
      rate: formNewRow.values.rate,
    });
  }, [formNewRow.values.plan_date, type, formNewRow.values.plan_date]);

  const handleSettingsDelete = (id: number | string | undefined) => {
    if (!isCanEdit) {
      return;
    }
    const updateRows = selectedActualPayments?.filter((item) => item.id !== id);
    if (preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment?.real_payments.length > 0) {
      dispatch(
        setPreSaveOrder({
          ...preSaveOrder,
          basicInfo: {
            ...preSaveOrder.basicInfo,
            payed_status: listOfPaymentStatuses[2],
          },
        })
      );
    } else {
      dispatch(
        setPreSaveOrder({
          ...preSaveOrder,
          basicInfo: {
            ...preSaveOrder.basicInfo,
            payed_status: listOfPaymentStatuses[1],
          },
        })
      );
    }
    if (id) {
      dispatch(deleteOrderPlannedPayment(id?.toString()));
    }
    if (updateRows) {
      setselectedActualPayments(updateRows);
    }
  };

  const actualPaymentSum = useMemo(() => {
    return selectedActualPayments.reduce((accumulator, object) => {
      return accumulator + Number(object.summ);
    }, 0);
  }, [selectedActualPayments]);

  useEffect(() => {
    if (Number(formNewRow.values.rate) === 1) return;
    if (Number(formNewRow.values.sum_rub) === Number(formNewRow.values.summ) * Number(formNewRow.values.rate)) return;
    formNewRow.setFieldValue('sum_rub', Number(formNewRow.values.summ) * Number(formNewRow.values.rate));
  }, [formNewRow.values]);
  return (
    <div className={styles.actulPayments}>
      <h2 className={styles.title}>Плановая оплата</h2>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {PlannedPaymentColumns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div>
            {selectedActualPayments.length > 0 &&
              selectedActualPayments.map((item, index) => (
                <div key={index} className={clsx(styles.row, styles.rowView)}>
                  <div className={clsx(styles.cell, styles.id)}>{index + 1}</div>
                  <div className={clsx(styles.cell, styles.type)}>{item.type}</div>
                  <div className={clsx(styles.cell, styles.sum, styles.sumView)}>
                    <div>{getCurrencyIconToUI(String(rate))}</div>
                    {item.summ}
                  </div>
                  <div className={clsx(styles.cell, styles.sum, styles.sumView)}>
                    <div>{getCurrencyIconToUI(String(rate))}</div>
                    {item.rate}
                  </div>
                  <div className={clsx(styles.cell, styles.sum, styles.sumView)}>
                    <div>{getCurrencyIconToUI(String(rate))}</div>
                    {Number(item.summ) * Number(item.rate)}
                  </div>
                  <div className={clsx(styles.cell, styles.date, styles.dateView)}>{item.plan_date.toString()}</div>
                  <span className={styles.delete} onClick={() => handleSettingsDelete(item.id)}>
                    <RemoveIcon width={15} height={15} />
                  </span>
                </div>
              ))}
          </div>
          {viewNewRow && (
            <form className={styles.row}>
              <span className={clsx(styles.cell, styles.number, styles.newItem, styles.id)}>
                {selectedActualPayments.length + 1}
              </span>
              <Select
                selected={type}
                data={paymentTypes.length ? paymentTypes?.map((item) => item) : []}
                setSelect={(a) => {
                  setType(a);
                  formNewRow.setFieldValue('type', a);
                }}
                className={styles.type}
                disabled={!isCanEdit}
              />
              <Input
                type="number"
                {...formNewRow.getInputProps('summ')}
                className={styles.sum}
                error={!!formNewRow.errors.number}
                background="#F5F6FA"
                disabled={!isCanEdit}
              />
              <InputRate
                type="number"
                {...formNewRow.getInputProps('rate')}
                className={styles.sum}
                error={!!formNewRow.errors.number}
                background="#F5F6FA"
                disabled={!isCanEdit}
                rate={rate}
              />{' '}
              <InputRate
                type="number"
                {...formNewRow.getInputProps('sum_rub')}
                className={styles.sum}
                error={!!formNewRow.errors.number}
                background="#F5F6FA"
                rate={rate}
                disabled
              />
              <InputDate
                className={styles.date}
                {...formNewRow.getInputProps('plan_date')}
                error={errors.date}
                disabled={!isCanEdit}
              />
              <div style={{ width: '16px' }}></div>
            </form>
          )}
        </div>
      </div>

      <Button
        transparent={true}
        label="+ Сохранить строку"
        className={styles.addRow}
        onClick={addNewRow}
        disabled={!isCanEdit}
      />

      <div className={styles.resultWrapper}>
        <div className={styles.resultItem}>
          <div>{getCurrencyIconToUI(String(rate))}</div>
          <span>Сумма</span>
          <span className={styles.sumNumber}>{actualPaymentSum}</span>
        </div>
        <div className={styles.resultItem}>
          <div>{getCurrencyIconToUI(String(rate))}</div>
          <span>Остаток</span>
          <span className={styles.sumNumber}>{+allSum - actualPaymentSum}</span>
        </div>
      </div>
    </div>
  );
};
