import React, { FC } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import styles from './ReportsTable.module.scss';
import { CellWithSorting } from 'components/CellWithSorting';
import { reportsColumns } from './data';
import { User } from 'components/User';
import { PdfIcon } from 'components/icons/PdfIcon';
import { Loader } from '@mantine/core';
import { useAppSelector } from 'store/hooks';

const ReportsTable: FC = () => {
  const { isSuccess, isLoading, reports } = useAppSelector(({ reports }) => reports);

  if (isLoading === 'GET_REPORTS')
    return <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />;

  if (!reports && isSuccess === 'GET_REPORTS') return <span className={styles.notFound}>Ничего не найдено</span>;

  return (
    <>
      {!!reports?.length && (
        <div className={styles.tableWrapper}>
          <div className={styles.table}>
            <div className={styles.rows}>
              {reportsColumns.map((column) => (
                <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
                  {column.id === 'partner' ? (
                    <CellWithSorting column={column} sorting="asc" />
                  ) : (
                    <span>{column.name}</span>
                  )}
                </div>
              ))}
            </div>
            <div>
              {reports.map((report) => (
                <div key={report.id} className={styles.row}>
                  <a
                    href={`${process.env.REACT_APP_BASE_API_URL}${report.link}`}
                    className={clsx(styles.cell, styles.column_name, styles.name)}
                  >
                    <PdfIcon />
                    {report.title}
                  </a>
                  <div className={clsx(styles.cell, styles.column_type)}>{report.type}</div>
                  <div className={clsx(styles.cell, styles.column_date)}>
                    {moment(report.created_at).format('DD.MM.YY')}
                  </div>
                  <div className={clsx(styles.cell, styles.column_author, styles.author)}>
                    <User id={report.id} name={report.authorName as string} viewName={true} className={styles.user} />
                  </div>
                </div>
              ))}
            </div>
            {reports.length > 0 && <div className={styles.count}>Всего записей — {reports.length}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default ReportsTable;
