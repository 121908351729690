export const getCurrencyToServer = (string: string) => {
  switch (string) {
    case 'Рубль':
      return 'RUB';
    case 'Доллар':
      return 'USD';
    case 'Евро':
      return 'EUR';
  }
};
export const getCurrencyToUI = (string: string) => {
  switch (string) {
    case 'RUB':
      return 'Рубль';
    case 'USD':
      return 'Доллар';
    case 'EUR':
      return 'Евро';
    default:
      return 'Рубль';
  }
};

export const getCurrencyIconToUI = (string: string) => {
  switch (string) {
    case 'RUB':
      return '₽';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '₽';
  }
};
