import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GalleryState } from './GalleryTypes';
import {
  addAlbum,
  addAlbumTag,
  addPhotoTag,
  deleteAlbum,
  deleteAlbumWithPhoto,
  deletePhoto,
  getAlbum,
  getAlbumTags,
  getAlbums,
  getPhoto,
  getPhotos,
  getTags,
  savePhoto,
  updateAlbum,
  updatePhoto,
} from 'store/action-creators/gallery';
import { Album, PhotoRes } from 'services/gallery/galleryApi.service';
import { GalleryTab } from 'core/constants/enums';

const initialState: GalleryState = {
  isLoading: null,
  isSuccess: null,
  isError: null,
  params: {
    currentPage: 1,
    search: null,
    'created_at[before]': null,
    'created_at[after]': null,
    itemsPerPage: 50,
    photo_tags: [],
  },
  paramsAlbum: {
    currentPage: 1,
    search: null,
    'created_at[before]': null,
    'created_at[after]': null,
    itemsPerPage: 50,
    photo_tags: [],
    sortById: 'desc',
    sortByDate: null,
  },
  tags: [],
  album_tags: [],
  photos: [],
  albums: [],
  photoCount: 0,
  albumCount: 0,
  isResetPreSave: false,
  isNeedToReload: false,
  isOpenPhotoList: false,
  preSaveAlbum: {
    id: '',
    name: '',
    created_at: '',
    updated_at: '',
    photos: [],
    album_tags: [],
    used_count: 0,
    photos_count: 0,
    description: '',
    used_tours: [],
  },
  preSave: {
    id: '',
    base_name: '',
    name: '',
    thumbnail_name: '',
    title: '',
    alt: '',
    photo_tags: [],
    is_license: false,
    used_count: 0,
    albums_count: 0,
    updated_at: '',
    created_at: '',
    used_tours: [],
  },
  galleryTab: GalleryTab.ALL_PHOTO,
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState: initialState,
  reducers: {
    resetPreSave: (state) => {
      state.preSave = initialState.preSave;
      state.isResetPreSave = true;
    },
    setPreSave(state, action: PayloadAction<PhotoRes>) {
      state.preSave = action.payload;
    },
    resetPreSaveAlbum: (state) => {
      state.preSaveAlbum = initialState.preSaveAlbum;
      state.isResetPreSave = true;
    },
    setPreSaveAlbum(state, action: PayloadAction<Album>) {
      state.preSaveAlbum = action.payload;
    },
    setParamsPage(state, action: PayloadAction<number>) {
      state.params.currentPage = action.payload;
    },
    setParamsSearch(state, action: PayloadAction<string>) {
      state.params.search = action.payload;
    },
    setGalleryTab(state, action: PayloadAction<string>) {
      state.galleryTab = action.payload;
    },
    setParamsDateFrom(state, action: PayloadAction<Date | null>) {
      state.params['created_at[before]'] = action.payload;
    },
    setParamsDateTo(state, action: PayloadAction<Date | null>) {
      state.params['created_at[after]'] = action.payload;
    },
    setParamsTags(state, action: PayloadAction<string[]>) {
      state.params.photo_tags = action.payload;
    },
    setAlbumParamsPage(state, action: PayloadAction<number>) {
      state.paramsAlbum.currentPage = action.payload;
    },
    setAlbumParamsSearch(state, action: PayloadAction<string>) {
      state.paramsAlbum.search = action.payload;
    },
    setAlbumParamsDateFrom(state, action: PayloadAction<Date | null>) {
      state.paramsAlbum['created_at[before]'] = action.payload;
    },
    setAlbumParamsDateTo(state, action: PayloadAction<Date | null>) {
      state.paramsAlbum['created_at[after]'] = action.payload;
    },
    setAlbumParamsTags(state, action: PayloadAction<string[]>) {
      state.paramsAlbum.photo_tags = action.payload;
    },
    setIsNeedReload(state, action: PayloadAction<boolean>) {
      state.isNeedToReload = action.payload;
    },
    setIsOpenPhotoList(state, action: PayloadAction<boolean>) {
      state.isOpenPhotoList = action.payload;
    },
    setAlbumParamsSortById(state, action: PayloadAction<'asc' | 'desc' | null>) {
      state.paramsAlbum.sortById = action.payload;
    },
    setAlbumParamsSortByDate(state, action: PayloadAction<'asc' | 'desc' | null>) {
      state.paramsAlbum.sortByDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    //получение списка тегов тура
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_GALLERY_TAGS';
      state.isError = null;
      state.tags = action.payload['hydra:member'];
    });
    builder.addCase(getTags.pending, (state) => {
      state.isLoading = 'GET_GALLERY_TAGS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTags.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_GALLERY_TAGS';
    });
    builder.addCase(getAlbumTags.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_GALLERY_TAGS';
      state.isError = null;
      state.album_tags = action.payload['hydra:member'];
    });
    builder.addCase(getAlbumTags.pending, (state) => {
      state.isLoading = 'GET_GALLERY_TAGS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getAlbumTags.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_GALLERY_TAGS';
    });
    builder.addCase(getPhotos.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_GALLERY_PHOTOS';
      state.isError = null;
      state.photos = action.payload['hydra:member'];
      state.photoCount = action.payload['hydra:totalItems'];
    });
    builder.addCase(getPhotos.pending, (state) => {
      state.isLoading = 'GET_GALLERY_PHOTOS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getPhotos.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_GALLERY_PHOTOS';
    });
    builder.addCase(getAlbums.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_GALLERY_ALBUMS';
      state.isError = null;
      state.albums = action.payload['hydra:member'];
      state.albumCount = action.payload['hydra:totalItems'];
    });
    builder.addCase(getAlbums.pending, (state) => {
      state.isLoading = 'GET_GALLERY_ALBUMS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getAlbums.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_GALLERY_ALBUMS';
    });
    builder.addCase(getPhoto.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_PHOTO';
      state.isError = null;
      state.preSave = action.payload;
    });
    builder.addCase(getPhoto.pending, (state) => {
      state.isLoading = 'GET_PHOTO';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getPhoto.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_PHOTO';
    });

    builder.addCase(updatePhoto.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'UPDATE_PHOTO';
      // state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(updatePhoto.pending, (state) => {
      state.isLoading = 'UPDATE_PHOTO';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(updatePhoto.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'UPDATE_PHOTO';
    });
    builder.addCase(addPhotoTag.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_PHOTO_TAG';
      state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(addAlbumTag.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_ALBUM_TAG';
      state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(getAlbum.fulfilled, (state, action: PayloadAction<Album>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_ALBUM';
      state.isError = null;
      state.preSaveAlbum = action.payload;
    });
    builder.addCase(getAlbum.pending, (state) => {
      state.isLoading = 'GET_ALBUM';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getAlbum.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_ALBUM';
    });
    builder.addCase(savePhoto.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_PHOTO';
      state.isError = null;
    });
    builder.addCase(savePhoto.pending, (state) => {
      state.isLoading = 'ADD_PHOTO';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(savePhoto.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_PHOTO';
    });
    builder.addCase(deletePhoto.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'DELETE_PHOTO';
      state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(deletePhoto.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'DELETE_PHOTO';
    });
    builder.addCase(deleteAlbum.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'DELETE_ALBUM';
      state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(deleteAlbum.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'DELETE_ALBUM';
    });
    builder.addCase(deleteAlbumWithPhoto.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'DELETE_ALBUM';
      state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(deleteAlbumWithPhoto.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'DELETE_ALBUM';
    });
    builder.addCase(addAlbum.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_ALBUM';
      state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(addAlbum.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_ALBUM';
    });
    builder.addCase(updateAlbum.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'UPDATE_ALBUM';
      state.isNeedToReload = true;
      state.isError = null;
    });
    builder.addCase(updateAlbum.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'UPDATE_ALBUM';
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetPreSave,
  setPreSave,
  setParamsPage,
  setParamsSearch,
  setParamsTags,
  setParamsDateFrom,
  setParamsDateTo,
  setIsNeedReload,
  setAlbumParamsPage,
  setAlbumParamsDateFrom,
  setAlbumParamsDateTo,
  setAlbumParamsTags,
  setAlbumParamsSearch,
  setAlbumParamsSortById,
  resetPreSaveAlbum,
  setPreSaveAlbum,
  setIsOpenPhotoList,
  setAlbumParamsSortByDate,
  setGalleryTab,
} = gallerySlice.actions;

export default gallerySlice.reducer;
