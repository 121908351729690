import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'ui/Button';
import { InputDatePicker } from 'ui/InputDatePicker';
import { Search } from 'components/Search';
import { TabsPanel } from 'ui/TabsPanel/TabsPanel';
import { TourTabs } from 'core/constants/enums';
import { RouteNames } from 'core/constants/routeNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setParamsDateFrom,
  setParamsDateTo,
  setParamsDirection,
  setParamsManager,
  setParamsPage,
  setParamsSearch,
  setParamsStatus,
} from 'store/entities/tours/toursSlice';
import { getUsers } from 'store/action-creators/users';
import { FilterNew } from 'ui/FilterNew/FilterNew';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';

import styles from './Tours.module.scss';
import { getDirections } from 'store/action-creators/tours';
import { downloadAllToursAPI, downloadLastMonthAPI } from 'services/tours/toursApi.service';
import fileDownload from 'js-file-download';
import axios from 'axios';

export const ToursTop = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state.users);
  const { params, directions } = useAppSelector((state) => state.tours);
  const [managers, setManagers] = useState<SelectMultiItemType[]>([]);
  const [directionsList, setdirectionsList] = useState<SelectMultiItemType[]>([]);
  //получение справочника с менеджерами с сервера
  useEffect(() => {
    dispatch(getUsers({ page: 1 }));
    dispatch(getDirections());
  }, []);

  //загрузка менеджеров в фильтр
  useEffect(() => {
    if (users.length > 0) {
      setManagers(users);
    }
  }, [users]);

  //загрузка направлений в фильтр
  useEffect(() => {
    if (directions.length > 0) {
      setdirectionsList(directions);
    }
  }, [directions]);

  const handleSearchClick = (query: string) => {
    dispatch(setParamsSearch(query));
    dispatch(setParamsPage(1));
  };

  const handleDataPicker = (value: [Date | null, Date | null]) => {
    dispatch(setParamsDateFrom(value[0]));
    dispatch(setParamsDateTo(value[1]));
    dispatch(setParamsPage(1));
  };

  const handleManagersClick = (manager: SelectMultiItemType | null) => {
    if (manager) {
      dispatch(setParamsManager(manager['@id'] as string));
      dispatch(setParamsPage(1));
    }
    //если удалили всех менеджеров из фильтра
    if (manager === null) {
      dispatch(setParamsManager(''));
      dispatch(setParamsPage(1));
    }
  };
  const handleDestinationsClick = (destination: SelectMultiItemType | null) => {
    if (destination) {
      console.log(destination);
      dispatch(setParamsDirection(destination['@id'] as string));
      dispatch(setParamsPage(1));
    }
    //если удалили всех менеджеров из фильтра
    if (destination === null) {
      dispatch(setParamsDirection(''));
      dispatch(setParamsPage(1));
    }
  };
  console.log(params.status);
  const handleTabClick = (tab: string) => {
    dispatch(setParamsPage(params.currentPage));
    switch (tab) {
      case TourTabs.ACTIVE_TOURS:
        dispatch(setParamsStatus(TourTabs.ACTIVE_TOURS));
        break;
      case TourTabs.CLOSE_TOURS:
        dispatch(setParamsStatus(TourTabs.CLOSE_TOURS));
        break;
      case TourTabs.ALL:
        dispatch(setParamsStatus(TourTabs.ALL));
        break;
      case TourTabs.VISIBLE_TOUR:
        dispatch(setParamsStatus(TourTabs.VISIBLE_TOUR));
        break;
      case TourTabs.NO_VISIBLE_TOUR:
        dispatch(setParamsStatus(TourTabs.NO_VISIBLE_TOUR));
        break;
      default:
        dispatch(setParamsStatus(params.status));
        break;
    }
  };
  const handleCreateTour = () => {
    navigate(RouteNames.TOURS + '/new');
  };

  const handleDownloadAllTours = async () => {
    await downloadAllToursAPI().then((res) => {
      const a = document.createElement('a');
      a.href = process.env.REACT_APP_BASE_API_URL + res.data.file;
      a.download = process.env.REACT_APP_BASE_API_URL + res.data.file.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  const handleDownloadLastMonth = async () => {
    await downloadLastMonthAPI().then((res) => {
      const a = document.createElement('a');
      a.href = process.env.REACT_APP_BASE_API_URL + res.data.file;
      a.download = process.env.REACT_APP_BASE_API_URL + res.data.file.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };
  return (
    <>
      <div className={styles.top}>
        <div className={styles.tours}>
          <h2 className={styles.title}>Туры</h2>
          <div className={styles.btnGroup}>
            <Button onClick={handleDownloadAllTours} width="200px">
              Выгрузка всех туров
            </Button>
            <Button onClick={handleDownloadLastMonth} width="230px">
              Выгрузка - последний мес.
            </Button>
            <Button onClick={handleCreateTour} width="200px">
              + Новый тур
            </Button>
          </div>
        </div>

        <TabsPanel
          tabs={[
            TourTabs.ALL,
            TourTabs.VISIBLE_TOUR,
            TourTabs.NO_VISIBLE_TOUR,
            TourTabs.ACTIVE_TOURS,
            TourTabs.CLOSE_TOURS,
          ]}
          tab={String(params.status)}
          setActive={handleTabClick}
          className={styles.tabs}
        />

        <div className={styles.search}>
          <Search placeholder="Поиск" setQuery={handleSearchClick} query={params.search ? params.search : ''} />

          {params.status !== TourTabs.ALL && <InputDatePicker placeholder="Дата брони" setDates={handleDataPicker} />}

          <FilterNew
            defaultVal={managers.find((el) => el['@id'] === params.manager)?.name}
            filter={{
              title: 'Менеджер',
              data: managers,
            }}
            setFilter={handleManagersClick}
            isZeroing={false}
          />
          <FilterNew
            defaultVal={directionsList.find((el) => el['@id'] === params.tour_directions)?.name}
            filter={{
              title: 'Направления',
              data: directionsList,
            }}
            setFilter={handleDestinationsClick}
            isZeroing={false}
          />
        </div>
      </div>
    </>
  );
};
