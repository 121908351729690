import React, { FC, useEffect, useState } from 'react';
import styles from './AlbumTable.module.scss';
import { Modal } from 'components/Modal';
import { Pagination } from '@mantine/core';
import { albumData, columns } from './data';
import clsx from 'clsx';
import { CellWithSorting } from 'components/CellWithSorting';
import { AlbumItem } from './AlbumItem';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Album, PhotoData } from 'services/gallery/galleryApi.service';
import { getAlbum, getAlbumTags, getAlbums, getTags } from 'store/action-creators/gallery';
import { Loader } from '@mantine/core';
import {
  resetPreSaveAlbum,
  setAlbumParamsPage,
  setAlbumParamsSortByDate,
  setAlbumParamsSortById,
  setIsNeedReload,
} from 'store/entities/gallery/gallerySlice';
import { AlbumEdit } from '../AlbumEdit';
import { notifications } from '@mantine/notifications';

export const AllAlbumTable: FC = () => {
  const { albums, paramsAlbum, albumCount, isLoading, isSuccess, isError, isNeedToReload, preSaveAlbum } =
    useAppSelector((state) => state.gallerySlice);
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [photoData, setPhotoData] = useState<PhotoData | null>(null);
  const [photoList, setPhotoList] = useState<Album[]>([]);
  const [countPages, setCountPages] = useState<number>(1);

  useEffect(() => {
    if (isSuccess === 'DELETE_ALBUM') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Альбом удалён', id: 'DELETE_ALBUM' });
    }
    if (isError === 'DELETE_ALBUM') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'DELETE_ALBUM_ERROR',
      });
    }
  }, [isSuccess, isError]);

  const handleClickRow = (item: number) => {
    setSelectedId(item);
    setModal(true);
  };

  useEffect(() => {
    // if (paramsAlbum.currentPage === 1) {
    setPhotoList(albums);
    // } else {
    //   setPhotoList((pre: any) => {
    //     const combinedArray = pre.concat(albums);
    //     const uniqueArray = combinedArray.filter(
    //       (item: any, index: number, arr: any[]) => arr.findIndex((el) => el.id === item.id) === index
    //     );
    //     return uniqueArray;
    //   });
    // }
  }, [albums]);

  //при изменении значений фильтров, меняем параметры для запроса на сервер
  useEffect(() => {
    setPhotoData({
      ...photoData,
      page: paramsAlbum.currentPage,
      search: paramsAlbum.search === '' ? null : paramsAlbum.search,
      dateFrom: paramsAlbum['created_at[after]'] ? paramsAlbum['created_at[after]'] : null,
      dateTo: paramsAlbum['created_at[before]'] ? paramsAlbum['created_at[before]'] : null,
      itemsPerPage: paramsAlbum.itemsPerPage,
      photo_tags: paramsAlbum.photo_tags.length > 0 ? paramsAlbum.photo_tags : null,
      sortById: paramsAlbum.sortById ? paramsAlbum.sortById : null,
      sortByDate: paramsAlbum.sortByDate,
    });
  }, [paramsAlbum]);

  // при изменении параметров для запроса, отправляем запрос на получение заказов
  useEffect(() => {
    if (photoData) {
      dispatch(getAlbums(photoData));
    }
  }, [photoData]);

  useEffect(() => {
    if (isNeedToReload) {
      dispatch(getAlbums(photoData!));
      dispatch(getTags());
      dispatch(getAlbumTags());
      dispatch(setIsNeedReload(false));
    }
  }, [photoData, isNeedToReload]);
  useEffect(() => {
    if (selectedId) {
      dispatch(getAlbum(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    if (!modal) {
      setSelectedId(null);
      dispatch(resetPreSaveAlbum());
    }
  }, [modal]);

  // высчитываем кол-во страниц в зависимости от кол-ва элементов и числа элементов на странице
  useEffect(() => {
    setCountPages(Math.ceil(albumCount / paramsAlbum.itemsPerPage));
  }, [albumCount, paramsAlbum.itemsPerPage]);

  const handleSortByColumn = (sort: 'asc' | 'desc', column: string) => {
    if (column === 'id') {
      dispatch(setAlbumParamsSortById(sort));
      dispatch(setAlbumParamsSortByDate(null));
      dispatch(setAlbumParamsPage(1));
    }
    if (column === 'edit') {
      dispatch(setAlbumParamsSortById(null));
      dispatch(setAlbumParamsSortByDate(sort));
      dispatch(setAlbumParamsPage(1));
    }
  };

  // // следим за скроллом. если достигаем конца страницы устанавливаем его в true
  // useEffect(() => {
  //   const handleScroll = (e: any) => {
  //     const scrollHeight = e.target.documentElement.scrollHeight;
  //     const currentHeight = e.target.documentElement.scrollTop + window.innerHeight;
  //     setScroll(false);
  //     if (currentHeight + 10 >= scrollHeight && paramsAlbum.currentPage <= countPages) {
  //       setScroll(true);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [paramsAlbum.currentPage]);

  // // когда достигли конца страницы, увеличиваем счетчик страниц на 1
  // useEffect(() => {
  //   if (scroll && paramsAlbum.currentPage < countPages) {
  //     dispatch(setAlbumParamsPage(paramsAlbum.currentPage + 1));
  //   }
  // }, [scroll]);

  return (
    <>
      <div className={styles.table}>
        <div className={styles.rows}>
          {columns.map((column) => (
            <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
              {column.id === 'id' || column.id === 'edit' ? (
                <CellWithSorting column={column} setColumnSort={handleSortByColumn} sorting={column.sorted!} />
              ) : (
                <span>{column.name}</span>
              )}
            </div>
          ))}
        </div>
        {isLoading && isLoading === 'GET_GALLERY_ALBUMS' && (
          <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
        )}
        <div>
          {isLoading !== 'GET_GALLERY_ALBUMS' &&
            albums &&
            albums.length > 0 &&
            photoList.map((album) => (
              <AlbumItem album={album} key={album.id} handleClickRow={(id) => handleClickRow(id)} />
            ))}

          {isSuccess === 'GET_GALLERY_ALBUMS' && photoList.length === 0 && (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
        </div>

        {isLoading !== 'GET_GALLERY_ALBUMS' && isSuccess === 'GET_GALLERY_ALBUMS' && albums && albums.length > 0 && (
          <div className={styles.tableFooter}>
            <div className={styles.count}>Всего записей — {albumCount}</div>
            <Pagination
              total={countPages}
              value={paramsAlbum.currentPage}
              onChange={(val) => dispatch(setAlbumParamsPage(val))}
            />
          </div>
        )}
      </div>
      {modal && (
        <Modal opened={modal} setOpened={setModal}>
          {preSaveAlbum.id && <AlbumEdit album={preSaveAlbum} />}
        </Modal>
      )}
    </>
  );
};
