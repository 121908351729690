export const filterByProp = (arr: any[], prop: string) => {
  const seen: any = {};
  const result = arr.filter((item) => {
    if (seen[item[prop]]) {
      return false;
    } else {
      seen[item[prop]] = true;
      return true;
    }
  });
  return result;
};

export const filterByProps = (arr: any[], props: string[]) => {
  const seen: any = {};
  const result = arr.filter((item) => {
    const propValues = props.map((prop) => item[prop]);
    const key = propValues.join('|');
    if (seen[key]) {
      return false;
    } else {
      seen[key] = true;
      return true;
    }
  });
  return result;
};