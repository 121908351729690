import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useForm } from '@mantine/form';
import { Select, TextInput } from '@mantine/core';
import { BinIcon } from 'components/icons/BinIcon';
import { SalesProps } from './Sales.type';
import { Button } from 'ui/Button';
import { salesColumns } from './data';
import styles from './Sales.module.scss';
import { useAppSelector } from 'store/hooks';

export const Sales: FC<SalesProps> = ({ tour, isCanEdit, selectedSales, setSelectedSales, setDirty }) => {
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const salesData =
    tour?.sales_array && tour?.sales_array.length
      ? preSaveOrder.tourInfo?.sales && preSaveOrder.tourInfo?.sales.length > 0
        ? tour.sales_array
            .map((item, index) => ({
              id: index,
              name: item.name,
              sale: item.sale_value,
              quantity: '',
            }))
            .concat(
              preSaveOrder.tourInfo.sales
                .filter((el) => !tour.sales_array.find((item) => item.title === el.name))
                .map((item, index) => ({
                  id: index,
                  name: item.name,
                  sale: item.sale,
                  quantity: item.quantity,
                }))
            )
        : selectedSales.length > 0
        ? tour.sales_array
            .map((item, index) => ({
              id: index,
              name: item.name,
              sale: item.sale_value,
              quantity: '',
            }))
            .concat(
              selectedSales
                .filter((el) => !tour.sales_array.find((item) => item.title === el.name))
                .map((item, index) => ({
                  id: index,
                  name: item.name,
                  sale: item.sale,
                  quantity: item.quantity,
                }))
            )
        : tour.sales_array.map((item, index) => ({
            id: index,
            name: item.name,
            sale: item.sale_value,
            quantity: '',
          }))
      : preSaveOrder.tourInfo?.sales && preSaveOrder.tourInfo?.sales.length > 0
      ? preSaveOrder.tourInfo.sales.map((item, index) => ({
          id: index,
          name: item.name,
          sale: item.sale,
          quantity: item.quantity,
        }))
      : selectedSales.length > 0
      ? selectedSales.map((item, index) => ({
          id: index,
          name: item.name,
          sale: item.sale,
          quantity: item.quantity,
        }))
      : [];

  //создаем массив цен с привязкой по именам
  const initialPrice =
    tour?.sales_array && tour?.sales_array.length
      ? tour.sales_array
          .map((item) => ({
            ind: item.name,
            value: item.sale_value,
          }))
          .concat(
            selectedSales
              .map((item) => ({
                ind: item.name,
                value: item.sale,
              }))
              .filter((el) => !tour.sales_array.find((item) => Number(item.sale_value) === Number(el.value)))
          )
      : [];

  const [data, setData] = useState<{ value: string; label: string }[]>(
    salesData.map((el) => ({ value: el.name, label: el.name })) || []
  );
  const [price, setPrice] = useState<{ ind: string; value: string }[]>(initialPrice);

  // Задаем изначальные скидки
  useEffect(() => {
    setData(salesData.map((item) => ({ value: item.name, label: item.name })));
  }, [salesData.length]);

  // Задаем изначальные размеры скидок с привязкой к скидке
  useEffect(() => {
    setPrice(
      salesData.map((item) => ({
        ind: item.name,
        value: item.sale,
      }))
    );
  }, [salesData.length]);

  const form = useForm({
    initialValues: {
      sales:
        preSaveOrder.tourInfo?.sales && preSaveOrder.tourInfo?.sales.length > 0
          ? preSaveOrder.tourInfo?.sales
          : selectedSales.map((el) => ({ name: el.name, sale: el.sale, key: 1, quantity: el.quantity.toString() })) || [
              { name: '', sale: '', key: 1, quantity: '1' },
            ],
    },
  });

  useEffect(() => {
    setSelectedSales(form.values.sales);
  }, [form.values.sales]);
  const fields = form.values.sales ? (
    form.values.sales.map((item, index) => (
      <div key={index} className={styles.row}>
        <div className={clsx(styles.cell, styles.title)}>
          <Select
            data={data}
            disabled={!isCanEdit}
            placeholder="Выбрать"
            {...form.getInputProps(`sales.${index}.name`)}
            searchable
            creatable
            getCreateLabel={(query) => `+ Создать ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setData((current) => [...current, item]);
              return item;
            }}
            //при смене скидки обнуляет стоимость
            onChange={(value: string) => {
              form.setFieldValue(`sales.${index}.sale`, '');
              form.setFieldValue(`sales.${index}.name`, value);
            }}
            onBlur={setDirty}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <div className={clsx(styles.cell, styles.price)}>
          <Select
            disabled={!isCanEdit}
            data={
              price.length
                ? price
                    .filter((item) => item.ind === form.values.sales[index].name)
                    .map((item) => item.value.toString())
                : []
            }
            placeholder="Выбрать"
            {...form.getInputProps(`sales.${index}.sale`)}
            onBlur={setDirty}
            searchable
            creatable
            getCreateLabel={(query) => `+ Создать ${query}`}
            onCreate={(query) => {
              const item2 = { value: query, label: query };
              setPrice((current) => [...current, { ind: item.name, value: query }]);
              return item2;
            }}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <div className={clsx(styles.cell, styles.quantity)}>
          <TextInput
            disabled={!isCanEdit}
            variant="unstyled"
            defaultValue={+form.values.sales[index].quantity}
            {...form.getInputProps(`sales.${index}.quantity`)}
            onBlur={setDirty}
            styles={{
              rightSection: { pointerEvents: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
            {...form.getInputProps(`sales.${index}.quantity`)}
          />
        </div>
        <span
          className={styles.delete}
          onClick={() => {
            if (!isCanEdit) {
              return;
            }
            const currentItem = price.find((el) => Number(el.value) === Number(item.sale));
            setPrice(price.filter((el) => el.value != currentItem?.value));
            form.removeListItem('sales', index);
            setDirty();
          }}
        >
          <BinIcon width={10} height={11} fill="#5B6572" />
        </span>
      </div>
    ))
  ) : (
    <></>
  );

  return (
    <div className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {salesColumns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div>{fields}</div>
        </div>
      </div>

      <Button
        transparent={true}
        disabled={!isCanEdit}
        label="+ Добавить строку"
        className={styles.addRow}
        onClick={() =>
          form.insertListItem('sales', { name: '', key: form.values.sales.length + 1, sale: '', quantity: 1 })
        }
      />
    </div>
  );
};
