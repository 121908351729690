import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const ReminderIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6253 8.1125V5.3125C11.6253 3.28285 9.97991 1.6375 7.95027 1.6375C5.92062 1.6375 4.27527 3.28285 4.27527 5.3125V8.1125H11.6253ZM7.95027 0.5C5.2924 0.5 3.13777 2.65463 3.13777 5.3125V8.1125V8.44649L3.11246 8.48192L1.02379 11.4061C0.713534 11.8404 1.02402 12.4438 1.5578 12.4438H5.76658C5.83446 13.5908 6.78615 14.5 7.95021 14.5C9.11427 14.5 10.066 13.5908 10.1338 12.4438H14.3427C14.8765 12.4438 15.187 11.8404 14.8768 11.4061L12.7881 8.48192L12.7628 8.44649V8.1125V5.3125C12.7628 2.65463 10.6081 0.5 7.95027 0.5ZM8.99209 12.4438H6.90833C6.97292 12.9617 7.41476 13.3625 7.95021 13.3625C8.48566 13.3625 8.9275 12.9617 8.99209 12.4438ZM3.86796 9.38125L2.49296 11.3062H13.4076L12.0326 9.38125H3.86796Z"
        fill={fill}
      />
    </svg>
  );
};
