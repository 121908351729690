import clsx from 'clsx';
import { ModalType } from 'components/Modal/Modal.types';
import { CloseIcon } from 'components/icons';
import { useRefCloseOut } from 'hooks/useRefCloseOut';
import React, { FC, useRef } from 'react';

const ModalCentred: FC<ModalType> = ({ opened, setOpened, children }) => {
  const refModal = useRef() as React.MutableRefObject<HTMLDivElement>;
  const refCloseBtn = useRef() as React.MutableRefObject<HTMLButtonElement>;

  const close = () => {
    setOpened(false);
  };

  useRefCloseOut(refModal, setOpened, refCloseBtn);
  return (
    <section className={clsx('center', { ['modal']: opened, ['modal_close']: !opened })} id="modal">
      <div className="content_reports" ref={refModal}>
        {children}
        <button className={'close_reports'} onClick={close} ref={refCloseBtn}>
          <CloseIcon width={12} height={12} fill={'rgba(91, 101, 114, 0.75)'} className="close" />
        </button>
      </div>
    </section>
  );
};

export default ModalCentred;
