import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderActionState } from './OrderActions.types';
import { addOrderAction, getOrderActions } from '../../action-creators/orderActions';
import { OrderActionsResponse } from '../../../services/orderActions/OrderActinResponse.model';
import { getAction } from 'services/orders/orders.adapter';

const initialState: OrderActionState = {
  isLoading: null,
  isSuccess: null,
  isError: null,

  orderActions: [],

  params: {
    isSystemActions: false,
    hiddenAllActions: false,
  },
};

export const orderActionsSlice = createSlice({
  name: 'orderActions',
  initialState: initialState,
  reducers: {
    setParamsIsSystemActions(state, action: PayloadAction<boolean | null>) {
      state.params.isSystemActions = action.payload;
    },
    resetParamsIsSystemActions(state) {
      state.params.isSystemActions = null;
    },
    setParamsHiddenAllActions(state, action: PayloadAction<boolean>) {
      state.params.hiddenAllActions = action.payload;
    },
    resetOrderActions(state) {
      state.orderActions = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addOrderAction.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'ADD_ORDER_ACTION';
    });
    builder.addCase(addOrderAction.pending, (state) => {
      state.isLoading = 'ADD_ORDER_ACTION';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addOrderAction.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_ORDER_ACTION';
    });

    builder.addCase(getOrderActions.fulfilled, (state, action: PayloadAction<OrderActionsResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_ORDER_ACTIONS';
      state.isError = null;
      state.orderActions = action.payload['hydra:member'].map(getAction);
    });
    builder.addCase(getOrderActions.pending, (state) => {
      state.isLoading = 'GET_ORDER_ACTIONS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getOrderActions.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_ORDER_ACTIONS';
    });
  },
});

export const { setParamsIsSystemActions, resetParamsIsSystemActions, resetOrderActions, setParamsHiddenAllActions } =
  orderActionsSlice.actions;
export default orderActionsSlice.reducer;
