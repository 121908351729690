import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { AddServiceProps, DescProps, TableDescColumn } from './AddService.type';
import { Button } from 'ui/Button';
import { generateId } from 'core/utils/generateId';
import { RemoveIcon } from '../icons';

import styles from './AddService.module.scss';

export const AddDescription: FC<AddServiceProps> = ({
  columns,
  items = [],
  title,
  titleDescription,
  setTitleDescription,
  setAddService,
  valueChanged,
  setColumns,
}) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<DescProps[]>(items);
  const [rowNames, setRowNames] = useState<TableDescColumn[]>(columns);
  const [titleDesc, setTitleDesc] = useState(titleDescription);
  const [canDo, setCanDo] = useState<boolean>(false);
  useEffect(() => {
    if (canDo) {
      setCanDo(false);
    }
  }, [canDo]);

  const addNewRow = () => {
    const newRow: DescProps = {} as DescProps;
    // newRow.id = generateId();
    newRow.hotel_string = '';
    newRow.description = '';
    newRow.price1 = '';
    newRow.price2 = '';
    newRow.price3 = '';
    newRow.price4 = '';
    newRow.price5 = '';
    setRows([...rows, newRow]);
    setCanDo(true);
  };

  const editExistRow = (
    key: number,
    value: Partial<{
      hotel_string: string;
      description: string;
      price1: string;
      price2: string;
      price3: string;
      price4: string;
      price5: string;
    }>
  ) => {
    const data = [...rows];
    if (value.price1) {
      value.price1 = value.price1.replace(/\D/g, '');
    }
    if (value.price2) {
      value.price2 = value.price2.replace(/\D/g, '');
    }
    if (value.price3) {
      value.price3 = value.price3.replace(/\D/g, '');
    }
    if (value.price4) {
      value.price4 = value.price4.replace(/\D/g, '');
    }
    if (value.price5) {
      value.price5 = value.price5.replace(/\D/g, '');
    }
    if (data[key]) {
      data[key] = { ...data[key], ...value };
    }
    setRows(data);
    setCanDo(true);
  };

  const editExistRowName = (key: number, value: Partial<{ name: string }>) => {
    const data = [...rowNames];
    if (data[key]) {
      data[key] = { ...data[key], ...value };
    }
    setRowNames(data);
    setCanDo(true);
  };

  const removeExistRow = (key: number) => {
    const data = [...rows];
    if (data[key]) {
      delete data[key];
    }
    setRows([...data].filter((i) => i));
    setCanDo(true);
  };

  useEffect(() => {
    if (setAddService && rows) {
      setAddService(rows);
    }
  }, [rows]);

  useEffect(() => {
    if (setTitleDescription && titleDesc) {
      setTitleDescription(titleDesc);
    }
  }, [titleDesc]);

  useEffect(() => {
    if (setColumns && rowNames) {
      setColumns(rowNames);
    }
  }, [rowNames]);

  const prevRows = useRef<DescProps[]>();
  useEffect(() => {
    if (
      valueChanged &&
      rows &&
      prevRows.current &&
      JSON.stringify(prevRows.current) !== JSON.stringify(rows) &&
      canDo
    ) {
      valueChanged();
    }
    prevRows.current = rows;
  }, [rows, canDo]);

  useEffect(() => {
    setRows(items);
  }, [items]);

  useEffect(() => {
    setTitleDesc(titleDescription);
  }, [titleDescription]);

  useEffect(() => {
    setRowNames(columns);
  }, [columns]);

  useEffect(() => {
    if (rows.length > 0 || rowNames.length > 0) {
      setOpen(true);
    }
  }, [rows]);

  return (
    <article className={styles.addService}>
      <h3 className={clsx(styles.top, open && styles.topUp)} onClick={() => setOpen((prev) => !prev)}>
        {title}
      </h3>
      {open && (
        <>
          <div className={styles.tableWrapper}>
            <div className={styles.titleDescBlock}>
              <label>Описание условий</label>
              <input type="text" defaultValue={titleDesc} onBlur={(e) => setTitleDesc(e.target.value)} />
            </div>
            <div className={styles.table}>
              <div className={styles.rows}>
                {rowNames.map((column, key) => (
                  <div key={key} className={clsx(styles.column)}>
                    {/* <span>{column.name}</span> */}
                    <input
                      type="text"
                      defaultValue={column.name}
                      onBlur={(e) => editExistRowName(key, { name: e.target.value })}
                      className={styles.columnInput}
                    />
                  </div>
                ))}
              </div>
              <div>
                {rows.length > 0 &&
                  rows.map((item, key) => (
                    <Fragment key={key + item.hotel_string + item.price1}>
                      <div className={styles.row}>
                        <textarea
                          defaultValue={item.hotel_string}
                          onBlur={(e) => editExistRow(key, { hotel_string: e.target.value })}
                          className={clsx(styles.hotel_string, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.description}
                          onBlur={(e) => editExistRow(key, { description: e.target.value })}
                          className={clsx(styles.description, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.price1}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price1: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.price2}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price2: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.price3}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price3: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.price4}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price4: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.price5}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price5: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        />
                      </div>
                      <div className={styles.removeWrapper}>
                        <button className={styles.remove} onClick={() => removeExistRow(key)}>
                          <RemoveIcon width={15} height={15} />
                        </button>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
          <Button transparent={true} label="+ Добавить строку" className={styles.button} onClick={addNewRow} />
        </>
      )}
    </article>
  );
};
