import { createAsyncThunk } from '@reduxjs/toolkit';
import { tourDatesFactoryIn, tourHotelFactoryIn } from 'services/tours/tours.factory';

import {
  addTourAPI,
  AddTourData,
  allToursAPI,
  AllToursData,
  CityData,
  deleteDatesAPI,
  deleteHotelAPI,
  deleteHotelPlacementAPI,
  deleteHotelsAPI,
  deleteNightAPI,
  deletePlacementAPI,
  DeletePlacementData,
  deleteTripAPI,
  DeleteTripData,
  editTourAPI,
  EditTourData,
  getCitiesAPI,
  getCountriesAPI,
  getDirectionsAPI,
  getGuidesAPI,
  getHotelTypesAPI,
  getTagsAPI,
  getTourAPI,
  GetTourData,
  getTourDateAPI,
  getTourDatesAPI,
  getTourDatesPlacementAPI,
  getTourHotelAPI,
  getTourHotelPlacementAPI,
  getTourHotelsAPI,
  getTourHotelsMinAPI,
  getTourNightsAPI,
  getTourOperatorsAPI,
  getTourParamsAPI,
  getTourPeriodsAPI,
  getTourProgramAPI,
  getTourTypesAPI,
  getTransportsAPI,
  realToursAPI,
  toursAPI,
  ToursData,
} from 'services/tours/toursApi.service';

// получение списка туров (поездок)
export const getTours = createAsyncThunk('tours/getTours', async (arg: ToursData, thunkAPI) => {
  try {
    const response = await toursAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка туров (реальные туры)
export const getRealTours = createAsyncThunk('tours/getRealTours', async (arg: ToursData, thunkAPI) => {
  try {
    const response = await realToursAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка туров
export const getAllTours = createAsyncThunk('tours/getAllTours', async (arg: AllToursData, thunkAPI) => {
  try {
    const response = await allToursAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка туров
export const getAllToursForSearch = createAsyncThunk(
  'tours/getAllToursForSearch',
  async (arg: AllToursData, thunkAPI) => {
    try {
      const response = await allToursAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

// получение тура по id
export const getTour = createAsyncThunk('tours/getTour', async (arg: GetTourData, thunkAPI) => {
  try {
    const response = await getTourAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение дат тура для заказа
export const getTourDatesList = createAsyncThunk('tours/getTourDatesList', async (arg: GetTourData, thunkAPI) => {
  try {
    const response = await getTourDatesAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// добавление тура
export const addTour = createAsyncThunk('tours/addTour', async (arg: AddTourData, thunkAPI) => {
  try {
    const response = await addTourAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// добавление тура
export const getTourProgram = createAsyncThunk('tours/getTourProgram', async (arg: { tourId: number }, thunkAPI) => {
  try {
    const response = await getTourProgramAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка туроператоров
export const getTourOperators = createAsyncThunk('tours/getTourOperators', async (_, thunkAPI) => {
  try {
    const response = await getTourOperatorsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка стран
export const getCountries = createAsyncThunk('tours/getCountries', async (_, thunkAPI) => {
  try {
    const response = await getCountriesAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка гидов
export const getGuides = createAsyncThunk('tours/getGuides', async (_, thunkAPI) => {
  try {
    const response = await getGuidesAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка городов
export const getCities = createAsyncThunk('tours/getCities', async (arg: CityData, thunkAPI) => {
  try {
    const response = await getCitiesAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getDatesDelete = createAsyncThunk('tours/getDatesDelete', async (arg: { id: number[] }, thunkAPI) => {
  try {
    const response = await deleteDatesAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getHotelsDelete = createAsyncThunk('tours/getHotelsDelete', async (arg: { id: number[] }, thunkAPI) => {
  try {
    const response = await deleteHotelsAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка транспорта
export const getTransports = createAsyncThunk('tours/getTransports', async (_, thunkAPI) => {
  try {
    const response = await getTransportsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка видов туров
export const getTourTypes = createAsyncThunk('tours/getTourTypes', async (_, thunkAPI) => {
  try {
    const response = await getTourTypesAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка времени путешествия
export const getTourPeriods = createAsyncThunk('tours/getTourPeriods', async (_, thunkAPI) => {
  try {
    const response = await getTourPeriodsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка направлений тура
export const getDirections = createAsyncThunk('tours/getDirections', async (_, thunkAPI) => {
  try {
    const response = await getDirectionsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
// список типов номеров
export const getHotelRoomTypes = createAsyncThunk('tours/getHotelRoomTypes', async (_, thunkAPI) => {
  try {
    const response = await getHotelTypesAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка тегов тура
export const getTags = createAsyncThunk('tours/getTagsAPI', async (_, thunkAPI) => {
  try {
    const response = await getTagsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

//получение отдельного тура по id с конвертацией данных для интерфейса
export const getTourFactory = createAsyncThunk('tours/getTourFactory', async (arg: GetTourData, thunkAPI) => {
  try {
    return await Promise.all([getTourAPI(arg), getTourParamsAPI(arg)]).then((data) => {
      console.log(data);
      const order = { ...data[0].data, ...data[1].data };

      return order;
    });
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getTourParams = createAsyncThunk('tours/getTourParams', async (arg: GetTourData, thunkAPI) => {
  try {
    const response = await getTourParamsAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// редактирование тура по ID
export const editTour = createAsyncThunk('tours/editTour', async (arg: EditTourData, thunkAPI) => {
  try {
    const response = await editTourAPI(arg);
    if (response.data) {
      const params = await getTourParamsAPI({ id: String(arg.id) });
      const order = { ...response.data, ...params.data };
      return order;
    } else {
      return response.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// удаление гостиницы по ID
export const deletePlacement = createAsyncThunk('tours/deletePlacement', async (arg: DeletePlacementData, thunkAPI) => {
  try {
    const response = await deletePlacementAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
export const deleteHotelPlacement = createAsyncThunk(
  'tours/deleteHotelPlacement',
  async (arg: DeletePlacementData, thunkAPI) => {
    try {
      const response = await deleteHotelPlacementAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
export const deleteNight = createAsyncThunk('tours/deleteNight', async (arg: DeletePlacementData, thunkAPI) => {
  try {
    const response = await deleteNightAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// удаление поездки по ID
export const deleteTrip = createAsyncThunk('tours/deleteTrip', async (arg: DeleteTripData, thunkAPI) => {
  try {
    const response = await deleteTripAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// удаление поездки по ID
export const deleteHotel = createAsyncThunk('tours/deleteHotel', async (arg: DeleteTripData, thunkAPI) => {
  try {
    const response = await deleteHotelAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение гостиниц
export const getTourHotels = createAsyncThunk(
  'tours/getTourhotels',
  async (
    arg: {
      page: number;
      dateFrom?: string;
      dateTo?: string;
      itemsPerPage: number;
      tour: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await getTourHotelsAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

// получение сокращенных дат
export const getTourDates = createAsyncThunk('tours/getTourDates', async (arg: GetTourData, thunkAPI) => {
  try {
    const response = await getTourDatesAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

//получение отдельной даты тура по id с конвертацией данных для интерфейса
export const getTourDate = createAsyncThunk('tours/getTourDate', async (arg: GetTourData, thunkAPI) => {
  try {
    return await Promise.all([
      getTourDateAPI(arg),
      thunkAPI.dispatch(getTourDatesPlacements({ tour_date: `/api/v1/tour-dates/${arg.id}` })),
      thunkAPI.dispatch(getTourNights({ tour_date: `/api/v1/tour-dates/${arg.id}` })),
    ]).then((data) => {
      console.log(data);
      const tour_date = { ...data[0].data };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tour_date.placements = data[1].payload['hydra:member'];
      tour_date.tour_additional_nights = data[2].payload['hydra:member'];
      console.log(tourDatesFactoryIn(tour_date));
      return tourDatesFactoryIn(tour_date);
    });
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getTourDatesPlacements = createAsyncThunk(
  'tours/getTourDatesPlacements',
  async (
    arg: {
      tour_date: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await getTourDatesPlacementAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

export const getTourNights = createAsyncThunk(
  'tours/getTourNights',
  async (
    arg: {
      tour_date: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await getTourNightsAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

export const getTourHotelsPlacements = createAsyncThunk(
  'tours/getTourHotelsPlacements',
  async (
    arg: {
      page?: number;
      dateFrom?: string;
      dateTo?: string;
      itemsPerPage?: number;
      tour: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await getTourHotelPlacementAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

// получение сокращенных гостиниц
export const getTourHotelsMin = createAsyncThunk('tours/getTourHotelsMin', async (arg: GetTourData, thunkAPI) => {
  try {
    const response = await getTourHotelsMinAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

//получение отдельной гостиницы по id с конвертацией данных для интерфейса
export const getTourHotel = createAsyncThunk('tours/getTourHotel', async (arg: GetTourData, thunkAPI) => {
  try {
    return await Promise.all([
      getTourHotelAPI(arg),
      thunkAPI.dispatch(getTourHotelsPlacements({ tour: `/api/v1/tour-hotels/${arg.id}` })),
    ]).then((data) => {
      console.log(data);
      const tour_hotel = { ...data[0].data };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tour_hotel.tour_hotel_placements = data[1].payload['hydra:member'];
      return tourHotelFactoryIn(tour_hotel);
    });
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
