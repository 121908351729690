import { TableColumn } from 'components/AddService/AddService.type';
import { PlacementResponse } from 'services/tours/ToursResponse.model';

export const accomodationColumns: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Гостиница',
  },
  {
    id: 'roomtype',
    name: 'Тип номера',
  },
  {
    id: 'category',
    name: 'Категория',
  },
  {
    id: 'accomodationType',
    name: 'Вид размещения',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'tourist',
    name: 'ФИО туриста',
  },
];

export const accomodationIndColumns: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Гостиница',
  },
  {
    id: 'roomtype',
    name: 'Тип номера',
  },
  {
    id: 'category',
    name: 'Категория',
  },
  {
    id: 'food_type',
    name: 'Питание',
  },
  {
    id: 'accomodationType',
    name: 'Вид размещения',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'tourist',
    name: 'ФИО туриста',
  },
];

export const accomodationOneColumns: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Гостиница',
  },
  {
    id: 'accomodationType',
    name: 'Вид размещения',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'tourist',
    name: 'ФИО туриста',
  },
];

export const accomOneInd: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Гостиница',
  },
  {
    id: 'food_type',
    name: 'Питание',
  },
  {
    id: 'accomodationType',
    name: 'Вид размещения',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'tourist',
    name: 'ФИО туриста',
  },
];
export const accomodationEveryDayColumns: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Гостиница',
  },
  {
    id: 'period',
    name: 'Период',
  },
  {
    id: 'nightCount',
    name: 'Кол-во ночей',
  },
  {
    id: 'roomtype',
    name: 'Тип номера',
  },
  {
    id: 'category',
    name: 'Категория',
  },

  {
    id: 'accomodationType',
    name: 'Вид размещения',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'tourist',
    name: 'ФИО туриста',
  },
];

export const hotelsTransferColumns: TableColumn[] = [
  {
    id: 'period',
    name: 'Период',
  },

  {
    id: 'hotel_trans',
    name: 'Гостиница',
  },

  {
    id: 'adress',
    name: 'Адрес',
  },
  {
    id: 'roomtype_trans',
    name: 'Тип номера',
  },
  {
    id: 'category_trans',
    name: 'Категория',
  },
  {
    id: 'food_type',
    name: 'Питание',
  },
  {
    id: 'accomodationType_trans',
    name: 'Вид',
  },
  {
    id: 'price_trans',
    name: 'Цена',
  },
  {
    id: 'tourist_trans',
    name: 'ФИО туриста',
  },
];

export const cruizeColumns: TableColumn[] = [
  {
    id: 'period',
    name: 'Дата круиза',
  },

  {
    id: 'boat',
    name: 'Название теплохода',
  },
  {
    id: 'roomtype_boat',
    name: 'Тип номера',
  },
  {
    id: 'category_trans',
    name: 'Категория',
  },
  {
    id: 'deck',
    name: 'Палуба',
  },
  {
    id: 'number',
    name: 'Номер каюты',
  },
  {
    id: 'accomodationType_trans',
    name: 'Вид',
  },
  {
    id: 'price_trans',
    name: 'Цена',
  },
  {
    id: 'tourist_trans',
    name: 'ФИО туриста',
  },
];

export const getPriceForNight = (hotel: PlacementResponse, nightCount: string) => {
  switch (nightCount) {
    case 'price1':
      return hotel.price1;
    case 'price2':
      return hotel.price2;
    case 'price3':
      return hotel.price3;
    case 'price4':
      return hotel.price4;
    case 'price5':
      return hotel.price5;
    case 'price6':
      return hotel.price6;
    case 'price7':
      return hotel.price7;

    default:
      return '0';
  }
};

export const passColumns: TableColumn[] = [
  {
    id: 'period',
    name: 'Период',
  },
  {
    id: 'hotel',
    name: 'Страна',
  },
  {
    id: 'roomtype',
    name: 'Населенный пункт',
  },
  {
    id: 'category',
    name: 'Субъект РФ',
  },
  {
    id: 'food_type',
    name: 'Наименование средства размещения',
  },
  {
    id: 'accomodationType',
    name: 'Тип расселения',
  },
  {
    id: 'tourist',
    name: 'ФИО туриста',
  },
];
