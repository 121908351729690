import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';
import { notifications } from '@mantine/notifications';

import { HotelInfo, TripInfo } from 'pages/Tours/Tours.types';
import { Input } from 'ui/Input';
import { InputDate } from 'ui/InputDate';
import { CloseIcon, DateIcon } from 'components/icons';
import { Select } from 'ui/Select';
import { listOfTourType } from 'core/constants/lists';
import { Button } from 'ui/Button';
import { Hotels } from './Hotels';
import { addDaysToDate, getDate } from 'core/utils/getDate';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setErrorTripDate, setErrorTripStatus, setIsEditedTour } from 'store/entities/tours/toursSlice';

import styles from './Trip.module.scss';
import { ToursTemplates } from 'core/constants/enums';
import { setPreSaveTripsInfo } from 'store/entities/tours/preSaveTourSlice';
import { Calendar } from '@mantine/dates';
import dayjs from 'dayjs';

const statuses: string[] = listOfTourType;

export type TripInfoProps = {
  trip?: TripInfo | null;
  setTrip: (x: TripInfo) => void;
  setCopy: Dispatch<SetStateAction<boolean>>;
};

export const Trip: FC<TripInfoProps> = ({ trip, setTrip, setCopy }) => {
  const dispatch = useAppDispatch();
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const { template } = preSaveTour2;
  const { errorsValidateTour } = useAppSelector((state) => state.tours);
  const [status, setStatus] = useState<string>();
  const [hotels, setHotels] = useState<HotelInfo[]>([]);
  const [addNights, setAddNights] = useState<HotelInfo[]>([]);
  const [showDublicate, setShowDublicate] = useState(false);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  //начальные значения для стандартных полей формы
  const initialValues: TripInfo = {
    id: trip?.id || null,
    '@id': trip?.['@id'] || null,
    date: trip?.date || null,
    dateStart: trip?.dateStart || null,
    priceOneDay: trip?.priceOneDay === null || trip?.priceOneDay === undefined ? 0 : trip?.priceOneDay,
    freePlaces: trip?.freePlaces === null || trip?.freePlaces === undefined ? 0 : trip?.freePlaces,
    discount_percent:
      trip?.discount_percent === null || trip?.discount_percent === undefined ? 0 : trip?.discount_percent,
    discount: trip?.discount === null || trip?.discount === undefined ? 0 : trip?.discount,
    agents_sale: trip?.agents_sale === null || trip?.agents_sale === undefined ? 0 : trip?.agents_sale,
    agents_sale_percent:
      trip?.agents_sale_percent === null || trip?.agents_sale_percent === undefined ? 0 : trip?.agents_sale_percent,
    status: status,
  };

  const formTrip = useForm({
    initialValues,
  });

  //если поездка меняется (изменяют извне), то переписываются заполненные значения в форме
  useEffect(() => {
    formTrip.setValues(trip || initialValues);
    formTrip.setFieldValue('status', status);
    setStatus(trip?.status || '');
    setHotels(trip?.hotels || []);
    setAddNights(trip?.tour_additional_nights || []);
  }, [trip]);

  //добавление/изменение отдельной поездки
  const handleAddTrip = (e: React.MouseEvent) => {
    e.preventDefault();
    let error = false;

    if (formTrip.values.dateStart === null) {
      dispatch(setErrorTripDate(true));
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Заполните поле с датой тура!' });
      error = true;
    }

    if (formTrip.values.status === '') {
      dispatch(setErrorTripStatus(true));
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Заполните поле со статусом!' });
      error = true;
    }
    if (!error) dispatch(setIsEditedTour(true));

    const hotelsOut = hotels.map((hotel) => {
      if (hotel['@id']) {
        return hotel;
      } else {
        return {
          hotel: hotel.hotel,
          typeRoom: hotel.typeRoom,
          category: hotel.category,
          food_type_string: hotel.food_type_string,
          places_count: hotel.places_count,
          show_no_places: hotel.show_no_places,
          price1A: hotel.price1A,
          price2A: hotel.price2A,
          price3A: hotel.price3A,
          price1A1C: hotel.price1A1C,
          price1A2C: hotel.price1A2C,
          price2A1C: hotel.price2A1C,
          price2A2C: hotel.price2A2C,
        };
      }
    });

    const addNightOut = addNights.map((hotel) => {
      if (hotel['@id']) {
        return hotel;
      } else {
        return {
          hotel: hotel.hotel,
          typeRoom: hotel.typeRoom,
          category: hotel.category,
          food_type_string: hotel.food_type_string,
          places_count: hotel.places_count,
          price1A: hotel.price1A,
          price2A: hotel.price2A,
          price3A: hotel.price3A,
          price1A1C: hotel.price1A1C,
          price1A2C: hotel.price1A2C,
          price2A1C: hotel.price2A1C,
          price2A2C: hotel.price2A2C,
        };
      }
    });

    if (formTrip.values.dateStart !== null && formTrip.values.status !== '') {
      if (formTrip.values['@id'] !== '') {
        setTrip({
          ...formTrip.values,
          priceOneDay: formTrip.values.priceOneDay === 0 ? null : Number(formTrip.values.priceOneDay),
          freePlaces: formTrip.values.freePlaces === 0 ? null : Number(formTrip.values.freePlaces),
          discount_percent: Number(formTrip.values.discount_percent),
          discount: Number(formTrip.values.discount),
          agents_sale_percent: Number(formTrip.values.agents_sale_percent),
          agents_sale: Number(formTrip.values.agents_sale),
          tour_additional_nights: addNightOut,
          hotels: hotelsOut,
          dateEnd: addDaysToDate(formTrip.values.dateStart || null, Number(preSaveTour2.descriptionInfo.duration)),
        });
      } else {
        setTrip({
          dateStart: formTrip.values.dateStart,
          dateEnd: addDaysToDate(formTrip.values.dateStart || null, Number(preSaveTour2.descriptionInfo.duration)),
          priceOneDay: formTrip.values.priceOneDay === 0 ? null : Number(formTrip.values.priceOneDay),
          freePlaces: formTrip.values.freePlaces === 0 ? null : Number(formTrip.values.freePlaces),
          discount_percent: Number(formTrip.values.discount_percent),
          discount: Number(formTrip.values.discount),
          agents_sale_percent: Number(formTrip.values.agents_sale_percent),
          agents_sale: Number(formTrip.values.agents_sale),
          status: status,
          tour_additional_nights: addNightOut,
          hotels: hotelsOut,
        });
      }
    }
  };

  // если поле с датой заполнено, снимаем ошибку
  useEffect(() => {
    if (formTrip.values.dateStart !== null) {
      dispatch(setErrorTripDate(false));
    }
  }, [formTrip.values.dateStart]);

  const handleDublicate = (date: string) => {
    const obj = {
      '@id': undefined,
      dateStart: new Date(date),
      dateEnd: addDaysToDate(new Date(date), Number(preSaveTour2.descriptionInfo.duration)),
      priceOneDay: trip?.priceOneDay,
      freePlaces: trip?.freePlaces,
      discount_percent: trip?.discount_percent,
      discount: trip?.discount,
      agents_sale_percent: trip?.agents_sale_percent,
      agents_sale: trip?.agents_sale,
      status: trip?.status,
      hotels: hotels?.map((hotel) => ({ ...hotel, '@id': undefined, id: undefined })),
      tour_additional_nights: addNights?.map((hotel) => ({
        ...hotel,
        '@id': undefined,
        id: undefined,
      })),
    };
    dispatch(setPreSaveTripsInfo([...(preSaveTour2.tripsInfo || []), obj]));
  };

  const handleDelete = (date: string) => {
    setSelectedDates((prev) => prev.filter((item) => item != date));
    const arr = preSaveTour2.tripsInfo?.filter((el) => String(el.dateStart) !== String(date));
    dispatch(setPreSaveTripsInfo(arr));
  };

  return (
    <>
      <form className={styles.form}>
        {!showDublicate ? (
          <div className={styles.groupParams}>
            <section className={styles.parameters}>
              <InputDate
                label="Дата тура"
                className={clsx(styles.calendar, errorsValidateTour.tripDate && styles.calendarError)}
                placeholder="_ _._ _._ _ _ _"
                icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
                {...formTrip.getInputProps('dateStart')}
              />
              {template === ToursTemplates.ONEDAY && (
                <>
                  <Input
                    type="number"
                    label="Стоимость однодневного тура"
                    className={clsx(styles.input, styles.priceOneDay, styles.number)}
                    {...formTrip.getInputProps('priceOneDay')}
                  />
                  <Input
                    type="number"
                    label="Свободные места"
                    {...formTrip.getInputProps('freePlaces')}
                    className={clsx(styles.input, styles.number)}
                  />
                </>
              )}
              <Input
                type="text"
                label="Скидка %"
                {...formTrip.getInputProps('discount_percent')}
                onChange={(e: any) => {
                  if (formTrip.values.discount != 0) {
                    formTrip.setFieldValue('discount', 0);
                  }
                  if (e.target.value.match(/^\d+$/)) {
                    formTrip.setFieldValue('discount_percent', e.target.value);
                  } else {
                    formTrip.setFieldValue(
                      'discount_percent',
                      e.target.value === '' ? e.target.value : formTrip.values.discount_percent
                    );
                  }
                }}
                className={clsx(styles.input, styles.number, styles.sales)}
              />
              <Input
                type="text"
                label="Скидка руб."
                {...formTrip.getInputProps('discount')}
                onChange={(e: any) => {
                  if (formTrip.values.discount_percent != 0) {
                    formTrip.setFieldValue('discount_percent', 0);
                  }
                  if (e.target.value.match(/^\d+$/)) {
                    formTrip.setFieldValue('discount', e.target.value);
                  } else {
                    formTrip.setFieldValue(
                      'discount',
                      e.target.value === '' ? e.target.value : formTrip.values.discount
                    );
                  }
                }}
                className={clsx(styles.input, styles.number, styles.sales)}
              />
              <Select
                selected={status}
                isFirstEl
                data={statuses}
                setSelect={(a) => {
                  setStatus(a);
                  if (a != '') {
                    dispatch(setErrorTripStatus(false));
                  }
                }}
                label="Статус"
                className={clsx(styles.selector, errorsValidateTour.tripStatus && styles.selectorError)}
              />
              {trip?.['@id'] && (
                <Button className={styles.addRow} transparent={true} onClick={() => setShowDublicate((prev) => !prev)}>
                  Дублировать
                </Button>
              )}
            </section>
            <section className={styles.parameters}>
              <Input
                type="text"
                label="Скидка % (для агентств)"
                {...formTrip.getInputProps('agents_sale_percent')}
                onChange={(e: any) => {
                  if (formTrip.values.agents_sale != 0) {
                    formTrip.setFieldValue('agents_sale', 0);
                  }
                  if (e.target.value.match(/^\d+$/)) {
                    formTrip.setFieldValue('agents_sale_percent', e.target.value);
                  } else {
                    formTrip.setFieldValue(
                      'agents_sale_percent',
                      e.target.value === '' ? e.target.value : formTrip.values.agents_sale_percent
                    );
                  }
                }}
                className={clsx(styles.input, styles.number, styles.agent_sales)}
              />
              <Input
                type="text"
                label="Скидка руб.  (для агентств)"
                {...formTrip.getInputProps('agents_sale')}
                onChange={(e: any) => {
                  if (formTrip.values.agents_sale_percent != 0) {
                    formTrip.setFieldValue('agents_sale_percent', 0);
                  }
                  if (e.target.value.match(/^\d+$/)) {
                    formTrip.setFieldValue('agents_sale', e.target.value);
                  } else {
                    formTrip.setFieldValue(
                      'agents_sale',
                      e.target.value === '' ? e.target.value : formTrip.values.agents_sale
                    );
                  }
                }}
                className={clsx(styles.input, styles.number, styles.agent_sales)}
              />
            </section>
          </div>
        ) : (
          <section className={styles.dublicateBlock}>
            <div className={styles.headerBlock}>
              <Calendar
                getDayProps={(date) => ({
                  selected: selectedDates.some((s) => dayjs(date).isSame(s, 'date')),
                  onClick: () => {
                    if (!date) return;
                    if (selectedDates.includes(String(date))) {
                      setSelectedDates((prev) => prev.filter((el) => el !== String(date)));
                      const arr = preSaveTour2.tripsInfo?.filter((el) => String(el.dateStart) !== String(date));
                      dispatch(setPreSaveTripsInfo(arr));
                      return;
                    }
                    setSelectedDates((prev) => [String(date), ...prev]);
                    handleDublicate(String(date));
                  },
                })}
              />
              <Button
                className={styles.addRow}
                transparent={true}
                onClick={() => {
                  setShowDublicate((prev) => !prev);

                  setSelectedDates([]);
                }}
              >
                Отменить
              </Button>
            </div>
            <div className={styles.dateBlock}>
              {selectedDates.map((el) => (
                <div key={el}>
                  {getDate(new Date(el))}{' '}
                  <span onClick={() => handleDelete(el)}>
                    <CloseIcon width={7} height={7} fill="#5B6572" />
                  </span>
                </div>
              ))}
            </div>
          </section>
        )}
      </form>

      <Hotels hotels={hotels} setHotels={setHotels} addNights={addNights} setNights={setAddNights} />
      <div className={styles.fixedContainer}>
        <Button onClick={handleAddTrip} className={styles.addTrip}>
          {trip?.id === undefined || trip?.id === null ? 'Добавить поездку' : 'Изменить поездку'}
        </Button>
      </div>
    </>
  );
};
