import React, { FC, forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { AccommodationProps } from './Accomodation.type';
import { Button } from 'ui/Button';
import { useForm } from '@mantine/form';

import { Select, TextInput, MultiSelect } from '@mantine/core';
import { BinIcon } from 'components/icons/BinIcon';
import { useAppSelector } from 'store/hooks';

import styles from './Accomodation.module.scss';
import {
  Client1A1CIcon,
  Client1A2CIcon,
  Client1AIcon,
  Client2A1CIcon,
  Client2A2CIcon,
  Client2AIcon,
  Client3AIcon,
  ClientChildIcon,
  DateIcon,
} from 'components/icons';
import { ToursTemplates } from 'core/constants/enums';
import { getTemplateToUI } from 'core/utils/getTemplate';
import { DatePickerInput, DatesProvider } from '@mantine/dates';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  ind: string;
  type: string;
  category: string;
  value: string;
}

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ ind, type, category, value, ...others }: ItemProps, ref) => {
    const icon = () => {
      const tourById = useAppSelector((state) => state.tours.selectedTour);
      switch (value) {
        case '1взр':
          return <Client1AIcon width={18} height={18} fill="#263B53" />;
        case '2взр':
          return getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY ? (
            <ClientChildIcon width={23} height={18} fill="#263B53" />
          ) : (
            <Client2AIcon width={23} height={18} fill="#263B53" />
          );
        case 'Реб.':
          return <ClientChildIcon width={23} height={18} fill="#263B53" />;
        case '3взр':
          return <Client3AIcon width={29} height={18} fill="#263B53" />;
        case '1взр+1реб':
          return <Client1A1CIcon width={33} height={18} fill="#263B53" />;
        case '1взр+2реб':
          return <Client1A2CIcon width={46} height={18} fill="#263B53" />;
        case '2взр+1реб':
          return <Client2A1CIcon width={39} height={24} fill="#263B53" />;
        case '2взр+2реб':
          return <Client2A2CIcon width={53} height={24} fill="#263B53" />;
      }
    };
    return (
      <div ref={ref} {...others}>
        <div>{icon()}</div>
      </div>
    );
  }
);
SelectItem.displayName = 'SelectItem';

export const PassPlace: FC<AccommodationProps> = ({
  selectedAccomodation,
  setSelectedAccomodation,
  selectedTourists,
  setDirty,
  isCanEdit,
  columns,
}) => {
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const individualTypes = ['1взр', '2взр', '3взр', '1взр+1реб', '1взр+2реб', '2взр+1реб', '2взр+2реб'];
  const formAccommodation = useForm({
    initialValues: {
      accommodations: selectedAccomodation ||
        preSaveOrder.tourInfo?.pass_placements_array || [
          {
            period: [null, null],
            country: '',
            city: '',
            region: '',
            hotel: '',
            place_type: '',
            tourists: [],
          },
        ],
    },
  });

  useEffect(() => {
    if (!preSaveOrder.id) {
      setSelectedAccomodation(formAccommodation.values.accommodations);
    }
  }, [formAccommodation.values.accommodations]);

  const fields = formAccommodation.values.accommodations ? (
    formAccommodation.values.accommodations.map((item, index) => (
      <div key={index} className={styles.row}>
        <div className={clsx(styles.cell, styles.period)}>
          <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
            <DatePickerInput
              type="range"
              allowSingleDateInRange={true}
              {...formAccommodation.getInputProps(`accommodations.${index}.period`)}
              valueFormat="DD.MM.YYYY "
              placeholder="Выбрать"
              disabled={!isCanEdit}
              mx="auto"
              maw={400}
              icon={<DateIcon width={12} height={12} fill={'rgba(91, 101, 114, 0.75)'} className={'close'} />}
              className={styles.select}
              styles={{
                rightSection: { pointerEvents: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </DatesProvider>
        </div>
        <TextInput
          {...formAccommodation.getInputProps(`accommodations.${index}.country`)}
          disabled={!isCanEdit}
          styles={{
            input: {
              border: 'none',
              fontFamily: 'Museo Sans Cyrl',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '19px',
              color: '#263B53',
            },
          }}
        />
        <TextInput
          {...formAccommodation.getInputProps(`accommodations.${index}.city`)}
          disabled={!isCanEdit}
          styles={{
            input: {
              border: 'none',
              fontFamily: 'Museo Sans Cyrl',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '19px',
              color: '#263B53',
            },
          }}
        />
        <TextInput
          {...formAccommodation.getInputProps(`accommodations.${index}.region`)}
          disabled={!isCanEdit}
          styles={{
            input: {
              border: 'none',
              fontFamily: 'Museo Sans Cyrl',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '19px',
              color: '#263B53',
            },
          }}
        />
        <TextInput
          {...formAccommodation.getInputProps(`accommodations.${index}.hotel`)}
          disabled={!isCanEdit}
          styles={{
            input: {
              border: 'none',
              fontFamily: 'Museo Sans Cyrl',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '19px',
              color: '#263B53',
            },
          }}
        />
        <Select
          data={individualTypes}
          defaultValue={selectedAccomodation[index]?.place_type ? selectedAccomodation[index].place_type : ''}
          value={selectedAccomodation[index]?.place_type ? selectedAccomodation[index].place_type : ''}
          placeholder="Выбрать"
          itemComponent={SelectItem}
          disabled={!isCanEdit}
          {...formAccommodation.getInputProps(`accommodations.${index}.place_type`)}
          searchable
          creatable
          getCreateLabel={(query) => `+ Создать ${query}`}
          onChange={(value: string) => {
            formAccommodation.setFieldValue(`accommodations.${index}.place_type`, value);
          }}
          className={styles.select}
          rightSectionWidth={30}
          styles={{
            rightSection: { pointerEvents: 'none', display: 'none' },
            input: {
              border: 'none',
              fontFamily: 'Museo Sans Cyrl',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '19px',
              color: '#263B53',
            },
            item: {
              fontFamily: 'Museo Sans Cyrl',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '19px',
              color: '#263B53',
            },
          }}
        />
        <div className={clsx(styles.cell, styles.tourist)}>
          <MultiSelect
            data={selectedTourists.map((item) => item.name)}
            disabled={!isCanEdit}
            placeholder="Выбрать"
            {...formAccommodation.getInputProps(`accommodations.${index}.tourists`)}
            searchable
            creatable
            getCreateLabel={(query) => `+ Создать ${query}`}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <span
          className={styles.delete}
          onClick={() => {
            if (!isCanEdit) {
              return;
            }
            formAccommodation.removeListItem('accommodations', index);
          }}
        >
          <BinIcon width={10} height={11} fill="#5B6572" />
        </span>
      </div>
    ))
  ) : (
    <></>
  );

  return (
    <div className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {columns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div></div>
          <div>{fields}</div>
        </div>
      </div>
      <div className={styles.btnRow}>
        {preSaveOrder.id && (
          <Button
            transparent={true}
            label="Сохранить все размещение"
            className={styles.saveRow}
            disabled={!isCanEdit}
            onClick={() => {
              setSelectedAccomodation(formAccommodation.values.accommodations);
              setDirty();
            }}
          />
        )}

        <Button
          transparent={true}
          label="+ Добавить строку"
          className={styles.addRow}
          disabled={!isCanEdit}
          onClick={() =>
            formAccommodation.insertListItem('accommodations', {
              period: [null, null],
              country: '',
              city: '',
              region: '',
              hotel: '',
              place_type: '',
              tourists: [],
            })
          }
        />
      </div>
    </div>
  );
};
