import React, { FC, useEffect } from 'react';
import { ButtonWithSelect } from 'ui/ButtonWithSelect';
import { OrderFormType } from '../components/OrderForm/OrderForm.types';

import styles from './OrderEdit.module.scss';
import { OrderForm } from '../components/OrderForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addOrderDocument } from 'store/action-creators/orderDocument';
import { notifications } from '@mantine/notifications';
import { documentsList } from './constants';
import { PaymentStatus, ToursTemplates } from 'core/constants/enums';
import { getTemplateToUI } from 'core/utils/getTemplate';
import { Button } from 'ui/Button';
import { getBookingCancel, getBookingStatus, getVoucher, updateOrder } from 'store/action-creators/orders';
import { Order, updateOrderAPI } from 'services/orders/orderApi.service';

export const OrderEdit: FC<OrderFormType> = ({ order }) => {
  const dispatch = useAppDispatch();
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const { currentUser = null } = useAppSelector((state) => state.users);
  const tourById = useAppSelector((state) => state.tours.selectedTour);
  const { tourOperatorsForSearch } = useAppSelector((state) => state.partners);
  const handleSelectedContract = async (docType: string, req?: number) => {
    if (order) {
      if (order.manager) {
        dispatch(
          addOrderDocument({
            document_type: docType,
            order: `/api/v1/orders/${order.id}`,
            contact_requisite: req
              ? tourOperatorsForSearch.length > 0
                ? String(req)
                : `/api/v1/contact-requisites/${req}`
              : undefined,
          })
        );
      } else {
        const response = await updateOrderAPI({
          id: order.id,
          manager: `/api/v1/users/${
            preSaveOrder.basicInfo?.manager ? preSaveOrder.basicInfo.manager.id : currentUser && currentUser.id
          }`,
        } as unknown as Partial<Order>).then((res) => {
          if (res.status === 200) {
            dispatch(
              addOrderDocument({
                document_type: docType,
                order: `/api/v1/orders/${order.id}`,
                contact_requisite: req
                  ? tourOperatorsForSearch.length > 0
                    ? String(req)
                    : `/api/v1/contact-requisites/${req}`
                  : undefined,
              })
            );
          }
        });
      }
    }
  };

  const { isSuccess, isError } = useAppSelector((state) => state.orders);

  //проверка успешности отправки запроса. если ок, закрываем окно, если нет - выводим сообщение об ошибке
  useEffect(() => {
    if (isSuccess === 'EDIT_ORDER') {
      notifications.show({
        title: 'Успех!',
        color: 'green',
        message: 'Заказ успешно отредактирован!',
      });
    } else if (isError === 'EDIT_ORDER') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка сохранения данных на сервер',
      });
    }
  }, [isSuccess, isError]);

  const handleGetDoc = () => {
    if (order) {
      dispatch(
        addOrderDocument({
          document_type: 'consultation',
          order: `/api/v1/orders/${order.id}`,
        })
      );
    }
  };

  const handleGetBookingStatus = () => {
    if (order) {
      dispatch(getBookingStatus(Number(order.id)));
    }
  };

  const handleGetBookingCancel = () => {
    if (order) {
      dispatch(getBookingCancel(Number(order.id)));
    }
  };

  const handleGetVaucher = () => {
    if (order) {
      dispatch(getVoucher(Number(order.id)));
    }
  };
  return (
    <>
      {order?.basicInfo?.partner && (
        <section className={styles.edit}>
          <div className={styles.top}>
            <h2 className={styles.title}>Заказ № {order?.id}</h2>
            <div className={styles.btnGroup}>
              {preSaveOrder.tourInfo?.isHotelTransfer &&
                preSaveOrder.basicInfo?.payed_status === PaymentStatus.AWAITING_PAYMENT && (
                  <Button onClick={handleGetBookingStatus}>Получить статус брони</Button>
                )}
              {preSaveOrder.tourInfo?.isHotelTransfer &&
                preSaveOrder.basicInfo?.payed_status === PaymentStatus.PAID && (
                  <>
                    <Button onClick={handleGetVaucher}>Отправить ваучер</Button>
                    <Button onClick={handleGetBookingCancel}>Отменить бронь</Button>
                  </>
                )}

              <Button onClick={handleGetDoc}>Договор консультации</Button>
              <ButtonWithSelect
                req={{
                  contact: order.basicInfo.requisites,
                  operator: order.tourInfo?.isCruize
                    ? tourOperatorsForSearch.length > 0
                      ? tourOperatorsForSearch.find((el) => el.id === order.tourInfo?.tour_operator_contact?.id)
                          ?.requisites
                      : order.tourInfo.tour_operator_contact != null
                      ? order?.tourInfo?.tour_operator_contact?.requisites
                      : undefined
                    : tourById.tour_operator_contact != null
                    ? tourById.tour_operator_contact.requisites
                    : undefined,
                }}
                data={
                  order.tourInfo?.isHotelTransfer
                    ? [
                        {
                          title: 'Услуга',
                          docType: 'service',
                          hasChild: false,
                        },
                        {
                          title: 'Договор Проживания',
                          docType: 'residence',
                          hasChild: false,
                        },
                      ]
                    : getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY
                    ? [
                        ...documentsList,
                        {
                          title: 'Договор Экскурсия',
                          docType: 'excursion',
                          hasChild: false,
                        },
                      ]
                    : documentsList
                }
                setSelect={handleSelectedContract}
              />
            </div>
          </div>
          <div className={styles.content}>
            <OrderForm order={order} />
          </div>
        </section>
      )}
    </>
  );
};
