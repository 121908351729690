import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const ReportsIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_45)">
        <path
          d="M21.5993 12.164H11.836V2.40075C11.836 2.28075 11.7378 2.18257 11.6178 2.18257H10.9087C9.47614 2.18023 8.05723 2.46117 6.73362 3.00924C5.41002 3.55731 4.20785 4.36169 3.19627 5.3761C2.20006 6.36923 1.40629 7.54646 0.859078 8.84234C0.289657 10.1864 -0.00253417 11.6316 1.65571e-05 13.0913C-0.00232932 14.5239 0.278614 15.9428 0.826685 17.2664C1.37475 18.59 2.17913 19.7922 3.19354 20.8038C4.19442 21.8046 5.36165 22.5928 6.65979 23.1409C8.00384 23.7104 9.44903 24.0026 10.9087 24C12.3413 24.0024 13.7602 23.7214 15.0838 23.1733C16.4074 22.6253 17.6096 21.8209 18.6212 20.8065C19.6221 19.8056 20.4102 18.6384 20.9584 17.3402C21.5278 15.9962 21.82 14.551 21.8174 13.0913V12.3822C21.8174 12.2622 21.7193 12.164 21.5993 12.164ZM17.2821 19.522C16.4377 20.3599 15.4362 21.0229 14.335 21.4731C13.2339 21.9233 12.0547 22.1518 10.8651 22.1455C8.46245 22.1346 6.20435 21.1937 4.50532 19.4947C2.79537 17.7848 1.8545 15.5103 1.8545 13.0913C1.8545 10.6723 2.79537 8.39781 4.50532 6.68787C5.99436 5.19883 7.91156 4.29068 9.98149 4.08342V14.0185H19.9166C19.7066 16.0994 18.7903 18.0275 17.2821 19.522V19.522ZM23.9992 10.6477L23.9283 9.87867C23.6965 7.36694 22.5811 4.99702 20.7866 3.20799C18.9908 1.41568 16.6258 0.306451 14.0995 0.0717349L13.3277 0.000828318C13.1996 -0.0100804 13.0905 0.0880981 13.0905 0.216276V10.6914C13.0905 10.8114 13.1887 10.9095 13.3086 10.9095L23.781 10.8823C23.9092 10.8823 24.0101 10.7732 23.9992 10.6477ZM14.9395 9.06052V2.06258C16.6574 2.42215 18.234 3.27294 19.4775 4.51158C20.7238 5.75518 21.5775 7.33694 21.932 9.04143L14.9395 9.06052Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_45">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
