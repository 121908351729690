import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const RemoveIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.10415 1.94424H4.95645C5.03768 1.94424 5.10415 1.87778 5.10415 1.79654V1.94424H10.7168V1.79654C10.7168 1.87778 10.7833 1.94424 10.8645 1.94424H10.7168V3.27356H12.0461V1.79654C12.0461 1.14481 11.5162 0.614929 10.8645 0.614929H4.95645C4.30471 0.614929 3.77483 1.14481 3.77483 1.79654V3.27356H5.10415V1.94424ZM14.4093 3.27356H1.41161C1.08482 3.27356 0.820801 3.53757 0.820801 3.86436V4.45517C0.820801 4.53641 0.887267 4.60287 0.968502 4.60287H2.08365L2.53968 14.2589C2.56922 14.8884 3.08987 15.3851 3.71945 15.3851H12.1015C12.7329 15.3851 13.2517 14.8903 13.2813 14.2589L13.7373 4.60287H14.8525C14.9337 4.60287 15.0002 4.53641 15.0002 4.45517V3.86436C15.0002 3.53757 14.7361 3.27356 14.4093 3.27356ZM11.9593 14.0558H3.86161L3.41481 4.60287H12.4061L11.9593 14.0558Z"
        fill={fill}
      />
    </svg>
  );
};
