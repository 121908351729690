import React, { useState } from 'react';

import styles from './Gallery.module.scss';
import { GalleryTop } from './GalleryTop';
import { GalleryTab } from 'core/constants/enums';
import { GalleryTable } from './GalleryTable';

export type GalleryProps = {
  tab: string;
  setTab?: (tab: string) => void;
};
export const GalleryPage = () => {
  const [currentTab, setCurrentTab] = useState<string>(GalleryTab.ALL_PHOTO);
  return (
    <section className={styles.gallery}>
      <GalleryTop tab={currentTab} setTab={(a) => setCurrentTab(a)} />
      <GalleryTable tab={currentTab} />
    </section>
  );
};
