import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';

export const additionalServecesColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
  },
  {
    id: 'service',
    name: 'Услуга',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'quantity',
    name: 'Количество',
  },
  {
    id: 'sum',
    name: 'Сумма',
  },
  {
    id: 'description',
    name: 'Описание',
  },
];

export const services = ['уборка', 'клининг'];
export const prices = ['5000', '10000', '12000'];
export const quantities = ['1', '2', '3'];
export const sums = ['5000', '2000', '3000'];
export const descriptions = ['норм', 'хорошо', 'отлично'];


