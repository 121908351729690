import React, { FC, useEffect, useMemo, useState } from 'react';

import styles from './DocumentAndPaymentTab.module.scss';
import { Input } from 'ui/Input';
import { OneFieldResult } from 'ui/OneFieldResult';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { DocumentAndPaymentProps, PartnerType } from './DocumentAndPaymentTab.types';
import { Button } from 'ui/Button';
import { AccordionFeature } from 'ui/Accordion';
import { Loader } from '@mantine/core';
import { ActualPaymentType } from './ActualPayment/ActualPayment.type';
import { ActualPayments } from './ActualPayment';
import { PlannedPaymentsType } from './PlannedPayment/PlannedPayment.type';
import { PlannedPayments } from './PlannedPayment';
import { PaymentLinkHistory } from './PaymentLinkHistory';
import { CreatedDocumentsHistory } from './CreatedDocumentsHistory';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPartner, getPayersForSearch } from 'store/action-creators/partners';
import { SelectSearch } from 'ui/SelectSearch';
import { Modal } from 'components/Modal';
import { PartnerCreate } from 'pages/Partners/PartnerCreate';
import { PartnerEdit } from 'pages/Partners/PartnerEdit';
import { CloseIcon, DateIcon } from 'components/icons';
import { InputDate } from 'ui/InputDate';
import { Select } from 'ui/Select';
import { setPreSaveOrder } from 'store/entities/orders/ordersSlice';
import { addOrderPaymentLink } from 'store/action-creators/orderPaymentLink';
import clsx from 'clsx';
import { getPaymentTypeRuToEn } from 'core/utils/getPaymentMethod';
import { getOrder } from 'store/action-creators/orders';
import { setIsPartnerModal } from 'store/entities/partners/partnersSlice';
import { PrivatePerson } from 'pages/Partners/components/PartnerFormPrivatePerson/PartnerFormPrivatePerson.types';
import { LegalPerson } from 'pages/Partners/components/PartnerFormLegalPerson/PartnerFormLegalPerson.types';
import { InputRate } from 'ui/InputRate';
import { getCurrencyIconToUI } from 'core/utils/getCurrency';

export const DocumentAndPaymentTab: FC<DocumentAndPaymentProps> = ({ isCanEdit, documentsAndPayment, setDirty }) => {
  const dispatch = useAppDispatch();
  const tourById = useAppSelector((state) => state.tours.selectedTour);
  const [partner, setPartner] = useState<PartnerType>();
  const { preSaveOrder, isSuccess, isError } = useAppSelector((state) => state.orders);
  const isHotel = useAppSelector((state) => state.orders.preSaveOrder.tourInfo?.isHotelTransfer);
  const [selectedActualPayments, setselectedActualPayments] = useState<ActualPaymentType[]>(
    preSaveOrder.documentsAndPayment?.real_payments ? preSaveOrder.documentsAndPayment?.real_payments : []
  );
  const [selectedPlannedPayments, setselectedPlannedPayments] = useState<PlannedPaymentsType[]>(
    preSaveOrder.documentsAndPayment?.planned_payments ? preSaveOrder.documentsAndPayment?.planned_payments : []
  );
  const [openedPartnerEdit, setOpenedPartnerEdit] = useState<boolean>(false);
  const [openedPartnerCreate, setOpenedPartnerCreate] = useState<boolean>(false);
  const [openedLinkCreate, setOpenedLinkCreate] = useState<boolean>(false);
  const { payersForSearch, currentPrivatePersone, currentLegalPersone, isPartnerModal, isLoading } = useAppSelector(
    (state) => state.partners
  );
  const [allSum, setAllSum] = useState(0);
  const [allSumRub, setAllSumRub] = useState(0);
  const [documents, setDocuments] = useState<
    {
      id: number;
      title: string;
      type: string;
      created_at: Date | string;
      link: string;
      sent_by_email: string | null;
    }[]
  >(documentsAndPayment?.order_documents ? documentsAndPayment?.order_documents : []);
  const [searchVal, setSearchVal] = useState('');
  const partnerList = useMemo(
    () =>
      preSaveOrder?.documentsAndPayment?.payer?.id && searchVal === ''
        ? [
            {
              id: preSaveOrder?.documentsAndPayment?.payer.id,
              name: preSaveOrder?.documentsAndPayment?.payer.name
                ? preSaveOrder?.documentsAndPayment?.payer.name
                : 'no name',
            },
          ]
        : payersForSearch.length > 0 && searchVal != ''
        ? payersForSearch
            .filter((el) => el.name?.toLowerCase().includes(searchVal.toLowerCase()))
            .map((item) => {
              if (item.name?.trim()) {
                return { id: item.id, name: item.name };
              } else return { id: item.id, name: 'no name' };
            })
        : [],
    [payersForSearch]
  );
  // Формирование базовой суммы
  const baseSum = isHotel
    ? preSaveOrder.tourInfo &&
      preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
        return accumulator + Number(object.price);
      }, 0) +
        preSaveOrder.tourInfo?.transfers?.reduce((accumulator, object) => {
          return accumulator + Number(object.price);
        }, 0)
    : preSaveOrder.tourInfo && preSaveOrder.tourInfo?.hotels.length > 0
    ? preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
        return accumulator + Number(object.price);
      }, 0)
    : preSaveOrder.tourInfo?.base_price;

  // Форма итоговой суммы
  const firstSectionForm = useForm({
    initialValues: {
      rate: preSaveOrder.documentsAndPayment?.exchange_rate || '1',
      baseSum:
        preSaveOrder.documentsAndPayment?.changed_baseSum &&
        +preSaveOrder.documentsAndPayment?.changed_baseSum > 0 &&
        +preSaveOrder.documentsAndPayment?.changed_baseSum != Number(baseSum)
          ? preSaveOrder.documentsAndPayment?.changed_baseSum
          : (baseSum && baseSum.toString()) || '0',
      supplierPrice: preSaveOrder.documentsAndPayment?.supplierPrice
        ? +preSaveOrder.documentsAndPayment?.supplierPrice
        : 0,
      managerDiscont: preSaveOrder.documentsAndPayment?.manager_sale
        ? +preSaveOrder.documentsAndPayment?.manager_sale
        : 0,
      managerDiscontPercent: preSaveOrder.documentsAndPayment?.manager_sale_percent
        ? +preSaveOrder.documentsAndPayment?.manager_sale_percent
        : '0',
      agent_reward: preSaveOrder.documentsAndPayment?.agent_reward
        ? preSaveOrder.documentsAndPayment?.agent_reward
        : '0',
    },
  });
  // Формирование суммы доп.ночей
  const extraNightSum = preSaveOrder.tourInfo?.extraNights.reduce((accumulator, object) => {
    return accumulator + Number(object.price);
  }, 0);

  // Формирование суммы доп.услуг
  const extraServiceSum =
    preSaveOrder.tourInfo?.extraService.reduce((accumulator, object) => {
      return accumulator + Number(object.price) * object.quantity;
    }, 0) || 0;

  // Формирование суммы скидок
  const extraSalesSum =
    preSaveOrder.tourInfo?.sales.reduce((accumulator, object) => {
      return accumulator + Number(object.sale) * Number(object.quantity);
    }, 0) || 0;
  // формирование суммы услуг
  const total_amount = +firstSectionForm.values.baseSum + extraNightSum! + extraServiceSum;
  //  Формирование итоговой суммы
  useEffect(() => {
    if (total_amount) {
      if (firstSectionForm.values.managerDiscont != 0) {
        const all =
          total_amount -
          extraSalesSum! -
          firstSectionForm.values.managerDiscont -
          +firstSectionForm.values.agent_reward;
        setAllSum(all);
        setAllSumRub(all * Number(firstSectionForm.values.rate));
      } else {
        const allPrice = total_amount - extraSalesSum! - +firstSectionForm.values.agent_reward;
        const percent = (allPrice * +firstSectionForm.values.managerDiscontPercent) / 100;
        setAllSum(allPrice - percent);
        setAllSumRub((allPrice - percent) * Number(firstSectionForm.values.rate));
      }
    }
  }, [
    total_amount,
    extraSalesSum,
    firstSectionForm.values.rate,
    firstSectionForm.values.managerDiscont,
    firstSectionForm.values.managerDiscontPercent,
    firstSectionForm.values.agent_reward,
  ]);
  // Форма плательщика
  const secondSectionForm = useForm({
    initialValues: {
      email: preSaveOrder?.documentsAndPayment?.payer?.email ? preSaveOrder?.documentsAndPayment?.payer?.email : '',
      responsible: '',
      order: '',
    },
  });

  // Форма создания ссылки на оплату
  const thirdSectionForm = useForm({
    initialValues: {
      payment_type: '',
      amount: '',
      active_before: '',
      rate: '',
    },
  });

  //обработка клика на конкретного контрагента
  const handlePartnerClick = (partnerId: number) => {
    setOpenedPartnerEdit(true);
    setOpenedPartnerCreate(false);
    dispatch(setIsPartnerModal(true));
    dispatch(getPartner({ id: partnerId.toString() }));
  };

  //обработка клика на "создание контрагента"
  const handlePartnerCreateClick = () => {
    setOpenedPartnerEdit(false);
    setOpenedPartnerCreate(true);
    dispatch(setIsPartnerModal(true));
  };

  useEffect(() => {
    console.log(baseSum, firstSectionForm.values.baseSum);

    const _id = preSaveOrder.documentsAndPayment?.payer?.id;
    const _name = preSaveOrder.documentsAndPayment?.payer?.name;
    if (_id /*&& !payersForSearch.length*/) {
      dispatch(
        getPayersForSearch({
          search: _name,
          properties: ['id', 'full_name', 'company', 'email', 'phone'],
          itemsPerPage: 100,
        })
      );
    }
  }, []);

  // поиск информации контрагента по id
  useEffect(() => {
    if (!partner) {
      return;
    }
    if (partner.id != currentLegalPersone?.id) {
      secondSectionForm.setFieldValue('order', '');
      secondSectionForm.setFieldValue('responsible', '');
    }
    dispatch(getPartner({ id: partner.id.toString() }));
  }, [partner]);

  // поиск выбранного контрагента
  const selectedPartner:
    | {
        id: number;
        name: string;
        phone?: string;
        email?: string;
        contact?: string;
      }
    | undefined = useMemo(() => {
    if (partner?.id) {
      return payersForSearch.find((item) => item.id === partner?.id);
    }
    if (preSaveOrder.documentsAndPayment?.payer?.id) {
      return {
        id: preSaveOrder?.documentsAndPayment?.payer.id,
        name: preSaveOrder?.documentsAndPayment?.payer.name ? preSaveOrder?.documentsAndPayment?.payer.name : 'no name',
      };
    }
  }, [payersForSearch, partner]);
  // сбор данных формы плательщика
  useEffect(() => {
    if (selectedPartner?.email) {
      secondSectionForm.setFieldValue('email', selectedPartner?.email);
    } else if (
      preSaveOrder?.documentsAndPayment?.payer?.email &&
      preSaveOrder?.documentsAndPayment?.payer?.email != ''
    ) {
      secondSectionForm.setFieldValue('email', preSaveOrder?.documentsAndPayment?.payer?.email);
    } else {
      secondSectionForm.setFieldValue('email', '');
    }
  }, [selectedPartner?.email]);

  useEffect(() => {
    console.log('>>>>> orderForm DocumentAndPayments selectedPartner change', selectedPartner);
  }, [selectedPartner]);

  useEffect(() => {
    if (currentLegalPersone?.requisites.length && currentLegalPersone?.requisites[0].responsiblePerson.name) {
      secondSectionForm.setFieldValue('responsible', currentLegalPersone?.requisites[0].responsiblePerson.name);
    }
    if (currentLegalPersone?.requisites.length && currentLegalPersone?.requisites[0].responsiblePerson.permission) {
      secondSectionForm.setFieldValue('order', currentLegalPersone?.requisites[0].responsiblePerson.permission);
    }
  }, [currentLegalPersone?.requisites]);

  useEffect(() => {
    const allValues = {
      id: 0,
      base_summ: baseSum ? baseSum.toString() : '0',
      changed_baseSum:
        Number(firstSectionForm.values.baseSum) === Number(baseSum) ? '0' : firstSectionForm.values.baseSum.toString(),
      services_summ: extraServiceSum ? extraServiceSum.toString() : '0',
      additional_nights_summ: extraNightSum ? extraNightSum.toString() : '0',
      sales_summ: extraSalesSum ? extraSalesSum.toString() : '0',
      exchange_rate: firstSectionForm.values.rate,
      manager_sale:
        firstSectionForm.values.managerDiscont.toString() === ''
          ? '0'
          : firstSectionForm.values.managerDiscont.toString(),
      supplierPrice: firstSectionForm.values.supplierPrice.toString(),
      manager_sale_percent: firstSectionForm.values.managerDiscontPercent.toString(),
      total_amount: total_amount.toString(),
      agent_reward: firstSectionForm.values.agent_reward ? firstSectionForm.values.agent_reward : '0',
      total_summ: allSum.toString(),
      real_payments: selectedActualPayments.length > 0 ? selectedActualPayments : [],
      planned_payments: selectedPlannedPayments.length > 0 ? selectedPlannedPayments : [],
      order_documents: documents,
      payer: {
        id: selectedPartner ? selectedPartner.id : preSaveOrder.documentsAndPayment?.payer?.id,
        name: selectedPartner ? selectedPartner?.name : preSaveOrder.documentsAndPayment?.payer?.name,
        email: selectedPartner ? selectedPartner?.email : preSaveOrder.documentsAndPayment?.payer?.email,
        order: secondSectionForm.values.order,
        responsible: secondSectionForm.values.responsible,
      },
      payment_links: preSaveOrder.documentsAndPayment?.payment_links,
    };
    dispatch(setPreSaveOrder({ ...preSaveOrder, documentsAndPayment: allValues }));
  }, [
    selectedPartner,
    extraServiceSum,
    extraNightSum,
    extraSalesSum,
    firstSectionForm.values,
    secondSectionForm.values,
    allSum,
    selectedActualPayments,
    selectedPlannedPayments,
  ]);

  const handleSavePaymentLink = () => {
    dispatch(
      addOrderPaymentLink({
        amount:
          tourById.currency === 'RUB'
            ? Number(thirdSectionForm.values.amount)
            : Number(thirdSectionForm.values.amount) * Number(thirdSectionForm.values.rate),
        active_before: thirdSectionForm.values.active_before,
        email: secondSectionForm.values.email,
        payment_method: 'link',
        payment_type: getPaymentTypeRuToEn(thirdSectionForm.values.payment_type),
        contact: `/api/v1/contacts/${partner?.id}`,
        tour_order: preSaveOrder?.id ? `/api/v1/orders/${preSaveOrder?.id.toString()}` : '0',
      })
    );
    setOpenedLinkCreate(!openedLinkCreate);
  };

  useEffect(() => {
    if (isSuccess === 'ADD_ORDER_PAYMENT_LINK') {
      dispatch(getOrder({ id: Number(preSaveOrder?.id) }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess === 'FINISH_PAYMENT_LINK') {
      notifications.show({
        title: 'Успех!',
        color: 'green',
        message: 'Средства списаны!',
      });
      dispatch(getOrder({ id: Number(preSaveOrder?.id) }));
    } else if (isError === 'FINISH_PAYMENT_LINK') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess === 'CANCEL_PAYMENT_LINK') {
      notifications.show({
        title: 'Успех!',
        color: 'green',
        message: 'Средства везвращены!',
      });
      dispatch(getOrder({ id: Number(preSaveOrder?.id) }));
    } else if (isError === 'CANCEL_PAYMENT_LINK') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isSuccess === 'ADD_ORDER_DOCUMENT') {
      dispatch(getOrder({ id: Number(preSaveOrder?.id) }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (documentsAndPayment?.order_documents) {
      setDocuments(documentsAndPayment?.order_documents);
    }
  }, [documentsAndPayment?.order_documents]);

  useEffect(() => {
    console.log('>>>>> DocumentsAndPayments paymentLinks change: ', documentsAndPayment?.payment_links);
  }, [documentsAndPayment?.payment_links]);

  const handleKey = () => {
    if (searchVal != '') {
      dispatch(
        getPayersForSearch({
          search: searchVal,
          properties: ['id', 'full_name', 'company', 'email', 'phone'],
          itemsPerPage: 100,
        })
      );
      return;
    }
  };
  return (
    <>
      <div className={styles.form}>
        <section className={styles.firstSection}>
          <div className={styles.column}>
            <InputRate
              type="number"
              name="amount"
              label="Курс"
              {...firstSectionForm.getInputProps('rate')}
              onBlur={setDirty}
              className={styles.rate}
              rate={tourById.currency}
              disabled={!isCanEdit || tourById.currency === 'RUB'}
            />
            <Input
              label="Базовая сумма"
              {...firstSectionForm.getInputProps('baseSum')}
              onBlur={setDirty}
              className={clsx(styles.input, styles.suplierPrice)}
              disabled={!isCanEdit}
            />
            <OneFieldResult
              name="addServiceSum"
              label="Сумма доп. услуг"
              className={clsx(styles.oneResult, !isCanEdit && styles.disabled)}
              value={extraServiceSum}
              background={'#F5F6FA'}
            />
            {!preSaveOrder.tourInfo?.isCruize && !isHotel && (
              <OneFieldResult
                name="addNightsSum"
                label="Сумма доп. ночей"
                className={clsx(styles.oneResult, !isCanEdit && styles.disabled)}
                value={extraNightSum}
                background={'#F5F6FA'}
              />
            )}
            <OneFieldResult
              name="addNightsSum"
              label="Сумма услуг"
              className={clsx(styles.oneResult, !isCanEdit && styles.disabled)}
              value={total_amount}
              background={'#F5F6FA'}
            />
          </div>
          <div className={styles.column}>
            <OneFieldResult
              name="salesSum"
              label="Сумма скидок"
              className={clsx(styles.oneResult, !isCanEdit && styles.disabled)}
              value={extraSalesSum}
              background={'#F5F6FA'}
            />
            <Input
              label="Скидка,руб. "
              onBlur={setDirty}
              {...firstSectionForm.getInputProps('managerDiscont')}
              className={clsx(styles.input, styles.managerDiscont)}
              onChange={(e: any) => {
                if (firstSectionForm.values.managerDiscontPercent != '0') {
                  firstSectionForm.setFieldValue('managerDiscontPercent', '0');
                }
                firstSectionForm.setFieldValue('managerDiscont', e.target.value);
              }}
              disabled={!isCanEdit || tourById.currency !== 'RUB'}
            />
            <Input
              label="Скидка,%. "
              onBlur={setDirty}
              {...firstSectionForm.getInputProps('managerDiscontPercent')}
              className={clsx(styles.input, styles.managerDiscontPercent)}
              onChange={(e: any) => {
                if (firstSectionForm.values.managerDiscont != 0) {
                  firstSectionForm.setFieldValue('managerDiscont', 0);
                }
                firstSectionForm.setFieldValue('managerDiscontPercent', e.target.value);
              }}
              disabled={!isCanEdit}
            />
            <Input
              label="Цена поставщика"
              onBlur={setDirty}
              {...firstSectionForm.getInputProps('supplierPrice')}
              className={clsx(styles.input, styles.suplierPrice)}
              disabled={!isCanEdit}
            />
            <Input
              label="Агентское
              вознаграждение"
              onBlur={setDirty}
              {...firstSectionForm.getInputProps('agent_reward')}
              className={clsx(styles.input, styles.suplierPrice)}
              disabled={!isCanEdit}
            />
            <OneFieldResult
              name="finalResult"
              label="Итоговая сумма в ₽"
              className={clsx(styles.oneResult, !isCanEdit || (tourById.currency === 'RUB' && styles.disabled))}
              value={allSumRub}
              background={'#F5F6FA'}
            />
            <OneFieldResult
              name="finalResult"
              label="Итоговая сумма в y.e"
              className={clsx(styles.oneResult, !isCanEdit && styles.disabled)}
              value={allSum}
              background={'#F5F6FA'}
            />
          </div>
        </section>
        <section className={styles.secondSection}>
          <div className={styles.partnerWrapper}>
            <SelectSearch
              data={partnerList}
              label="Плательщик"
              value={selectedPartner}
              setValue={setPartner}
              onBlur={setDirty}
              setSearch={setSearchVal}
              handleKey={handleKey}
              getValueForModal={handlePartnerClick}
              create={handlePartnerCreateClick}
              disabled={!isCanEdit}
              className={styles.partner}
            />
            <Input
              type="email"
              name="email"
              label="Почта плательщика"
              onBlur={setDirty}
              className={styles.email}
              {...secondSectionForm.getInputProps('email')}
              background={'#F5F6FA'}
              disabled={!isCanEdit}
            />
            <Button
              type="submit"
              className={clsx(styles.button, !preSaveOrder.id && styles.disabledBtn)}
              onClick={() => {
                setOpenedLinkCreate(!openedLinkCreate);
              }}
              disabled={!isCanEdit || !preSaveOrder.id}
            >
              Создать ссылку на оплату
            </Button>
          </div>
          <div className={styles.partnerWrapper}>
            <Input
              type="text"
              disabled={!isCanEdit}
              name="responsible"
              label="ФИО ответственного по договору (в род. падеже)"
              onBlur={setDirty}
              {...secondSectionForm.getInputProps('responsible')}
              className={styles.responsible}
              background={'#F5F6FA'}
            />
            <Input
              type="text"
              name="onOrder"
              disabled={!isCanEdit}
              onBlur={setDirty}
              label="Действует на основании"
              className={styles.order}
              {...secondSectionForm.getInputProps('order')}
              background={'#F5F6FA'}
            />
          </div>
          {openedLinkCreate && (
            <div className={styles.modalForm}>
              <button className={styles.close} onClick={() => setOpenedLinkCreate(false)}>
                <CloseIcon width={9} height={9} fill={'rgba(91, 101, 114, 0.75)'} />
              </button>
              <Select
                selected={thirdSectionForm.values.payment_type}
                data={['Аванс', 'Доплата', 'Полная оплата']}
                setSelect={(a) => thirdSectionForm.setFieldValue('payment_type', a)}
                label="Вид платежа"
                className={styles.paymentType}
              />
              <div className={styles.inputGroup}>
                <InputRate
                  type="number"
                  name="amount"
                  label="Курс"
                  {...thirdSectionForm.getInputProps('rate')}
                  className={styles.amount}
                  rate={tourById.currency}
                  disabled={tourById.currency === 'RUB'}
                />
                <Input
                  type="text"
                  name="amount"
                  label="Сумма"
                  {...thirdSectionForm.getInputProps('amount')}
                  onChange={(e: any) => {
                    if (e.target.value.match(/^\d+$/)) {
                      thirdSectionForm.setFieldValue('amount', e.target.value);
                    } else {
                      thirdSectionForm.setFieldValue(
                        'amount',
                        e.target.value === '' ? e.target.value : thirdSectionForm.values.amount
                      );
                    }
                  }}
                  className={styles.amount}
                  background={'#FFFFFF'}
                />
              </div>

              <InputDate
                date={thirdSectionForm.values.active_before}
                label="Оплатить до"
                className={styles.calendar}
                placeholder="__.__.___-__.__.___"
                icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
                {...thirdSectionForm.getInputProps('active_before')}
              />
              <Button type="button" className={styles.formButton} onClick={() => handleSavePaymentLink()}>
                Отправить ссылку
              </Button>
            </div>
          )}
        </section>
        <section className={styles.secondSection}>
          <AccordionFeature
            title="История оплаты"
            is_opened={selectedActualPayments.length > 0 || selectedPlannedPayments.length > 0 ? true : false}
            item={
              <div className={styles.tableWrapperB}>
                <ActualPayments
                  orderId={preSaveOrder?.id ? `/api/v1/orders/${preSaveOrder?.id.toString()}` : undefined}
                  allSum={allSum}
                  selectedActualPayments={selectedActualPayments}
                  setselectedActualPayments={setselectedActualPayments}
                  isCanEdit={isCanEdit}
                  rate={tourById.currency}
                />
                <PlannedPayments
                  orderId={preSaveOrder?.id ? `/api/v1/orders/${preSaveOrder?.id.toString()}` : undefined}
                  allSum={allSum}
                  selectedActualPayments={selectedPlannedPayments}
                  setselectedActualPayments={setselectedPlannedPayments}
                  isCanEdit={isCanEdit}
                  rate={tourById.currency}
                />
              </div>
            }
          />
        </section>
        <section className={styles.secondSection}>
          <AccordionFeature
            title="История ссылок на оплату"
            is_opened={
              preSaveOrder.documentsAndPayment?.payment_links
                ? preSaveOrder.documentsAndPayment?.payment_links.length > 0
                  ? true
                  : false
                : false
            }
            item={
              <div className={styles.tableWrapperB}>
                <PaymentLinkHistory
                  paymentLinks={
                    preSaveOrder.documentsAndPayment?.payment_links
                      ? preSaveOrder.documentsAndPayment?.payment_links
                      : []
                  }
                />
              </div>
            }
          />
        </section>
        <section className={styles.secondSection}>
          <AccordionFeature
            title="История созданных документов"
            is_opened={documents.length > 0 ? true : false}
            item={
              <div className={styles.tableWrapperB}>
                <CreatedDocumentsHistory
                  orderId={preSaveOrder['@id']}
                  documents={documents}
                  setDocuments={setDocuments}
                />
              </div>
            }
          />
        </section>
      </div>
      {openedPartnerEdit && isPartnerModal && (
        <Modal opened={isPartnerModal} setOpened={setOpenedPartnerEdit}>
          {isLoading === 'GET_PARTNER' && (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loaderPartner} size={'lg'} />
          )}
          {currentPrivatePersone && <PartnerEdit partner={currentPrivatePersone as PrivatePerson} />}
          {currentLegalPersone && <PartnerEdit partner={currentLegalPersone as LegalPerson} />}
        </Modal>
      )}

      {openedPartnerCreate && isPartnerModal && (
        <Modal opened={openedPartnerCreate} setOpened={setOpenedPartnerCreate}>
          <PartnerCreate />
        </Modal>
      )}
    </>
  );
};
