import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { AddServiceProps, SalesProps } from './AddSales.type';
import { Button } from 'ui/Button';
import { generateId } from 'core/utils/generateId';
import { RemoveIcon } from '../icons';

import styles from './AddSales.module.scss';
import { Select } from 'ui/Select';
import { SalesCondition } from 'core/constants/lists';

export const AddSales: FC<AddServiceProps> = ({ columns, items = [], title, setAddService, valueChanged }) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<SalesProps[]>(items);
  const [canDo, setCanDo] = useState<boolean>(false);
  useEffect(() => {
    if (canDo) {
      setCanDo(false);
    }
  }, [canDo]);

  const addNewRow = () => {
    const newRow: SalesProps = {} as SalesProps;
    newRow.id = generateId();
    newRow.title = '';
    newRow.condition = '';
    newRow.price = '';
    newRow.price_percent = '';
    setRows([...rows, newRow]);
    setCanDo(true);
  };
  const editExistRow = (key: number, value: Partial<SalesProps>) => {
    const data = [...rows];
    if (Number(value.price_percent) > 0) {
      value.price = '';
      value.price_percent = String(value.price_percent).replace(/\D/g, '');
    }
    if (Number(value.price) > 0) {
      value.price_percent = '';
      value.price = String(value.price).replace(/\D/g, '');
    }
    if (data[key]) {
      data[key] = { ...data[key], ...value };
    }
    setRows(data);
    setCanDo(true);
  };

  const removeExistRow = (key: number) => {
    const data = [...rows];
    if (data[key]) {
      delete data[key];
    }
    setRows([...data].filter((i) => i));
    setCanDo(true);
  };

  useEffect(() => {
    if (setAddService && rows) {
      setAddService(rows);
    }
  }, [rows]);

  const prevRows = useRef<SalesProps[]>();
  useEffect(() => {
    if (
      valueChanged &&
      rows &&
      prevRows.current &&
      JSON.stringify(prevRows.current) !== JSON.stringify(rows) &&
      canDo
    ) {
      valueChanged();
    }
    prevRows.current = rows;
  }, [rows, canDo]);

  useEffect(() => {
    setRows(items);
  }, [items]);

  useEffect(() => {
    if (rows.length > 0) {
      setOpen(true);
    }
  }, [rows]);

  return (
    <article className={clsx(title != 'Скидка' ? styles.serviceContainer : styles.addService)}>
      <h3 className={clsx(styles.top, open && styles.topUp)} onClick={() => setOpen((prev) => !prev)}>
        {title}
      </h3>
      {open && (
        <>
          <div className={styles.tableWrapper}>
            <div className={styles.table}>
              <div className={styles.rows}>
                {columns.map((column) => (
                  <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
                    <span>{column.name}</span>
                  </div>
                ))}
              </div>
              <div>
                {rows.length > 0 &&
                  rows.map((item, key) => (
                    <Fragment key={key + item.title + item.price}>
                      <div className={styles.row}>
                        <input
                          type="text"
                          defaultValue={item.title}
                          onBlur={(e) => editExistRow(key, { title: e.target.value })}
                          className={clsx(styles.service, styles.newItem)}
                        />
                        <Select
                          data={SalesCondition}
                          selected={item.condition}
                          setSelect={(e) => editExistRow(key, { condition: e })}
                          className={clsx(styles.sex, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.price}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        />
                        {/* <input
                          type="text"
                          defaultValue={item.price_percent}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price_percent: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        /> */}
                      </div>
                      <div className={styles.removeWrapper}>
                        <button className={styles.remove} onClick={() => removeExistRow(key)}>
                          <RemoveIcon width={15} height={15} />
                        </button>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
          <Button transparent={true} label="+ Добавить строку" className={styles.button} onClick={addNewRow} />
        </>
      )}
    </article>
  );
};
