import React, { FC, forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { AccommodationProps } from './Accomodation.type';
import { Button } from 'ui/Button';
import { useForm } from '@mantine/form';

import { Select, NumberInput, TextInput, MultiSelect } from '@mantine/core';
import { BinIcon } from 'components/icons/BinIcon';
import { useAppSelector } from 'store/hooks';

import styles from './Accomodation.module.scss';
import { filterByProps } from 'core/utils/getUnique';
import {
  Client1A1CIcon,
  Client1A2CIcon,
  Client1AIcon,
  Client2A1CIcon,
  Client2A2CIcon,
  Client2AIcon,
  Client3AIcon,
  ClientChildIcon,
  DateIcon,
} from 'components/icons';
import { DatesProvider, DatePickerInput } from '@mantine/dates';
import { ToursTemplates } from 'core/constants/enums';
import { getTemplateToUI } from 'core/utils/getTemplate';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  ind: string;
  type: string;
  category: string;
  value: string;
}

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ ind, type, category, value, ...others }: ItemProps, ref) => {
    const icon = () => {
      const tourById = useAppSelector((state) => state.tours.selectedTour);
      switch (value) {
        case '1взр':
          return <Client1AIcon width={18} height={18} fill="#263B53" />;
        case '2взр':
          return getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY ? (
            <ClientChildIcon width={23} height={18} fill="#263B53" />
          ) : (
            <Client2AIcon width={23} height={18} fill="#263B53" />
          );
        case 'Реб.':
          return <ClientChildIcon width={23} height={18} fill="#263B53" />;
        case '3взр':
          return <Client3AIcon width={29} height={18} fill="#263B53" />;
        case '1взр+1реб':
          return <Client1A1CIcon width={33} height={18} fill="#263B53" />;
        case '1взр+2реб':
          return <Client1A2CIcon width={46} height={18} fill="#263B53" />;
        case '2взр+1реб':
          return <Client2A1CIcon width={39} height={24} fill="#263B53" />;
        case '2взр+2реб':
          return <Client2A2CIcon width={53} height={24} fill="#263B53" />;
      }
    };
    return (
      <div ref={ref} {...others}>
        <div>{icon()}</div>
      </div>
    );
  }
);
SelectItem.displayName = 'SelectItem';

export const Accommodation: FC<AccommodationProps> = ({
  tour,
  selectedAccomodation,
  setSelectedAccomodation,
  selectedTourists,
  setDirty,
  isCanEdit,
  columns,
}) => {
  const isIndividual = useAppSelector((state) => state.orders.preSaveOrder.tourInfo?.individual);
  const isHotel = useAppSelector((state) => state.orders.preSaveOrder.tourInfo?.isHotelTransfer);
  const isCruize = useAppSelector((state) => state.orders.preSaveOrder.tourInfo?.isCruize);
  const tourById = useAppSelector((state) => state.tours.selectedTour);
  const placements = useAppSelector((state) => state.tours.tourDatePlacements);
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const individualTypes = ['1взр', '2взр', '3взр', '1взр+1реб', '1взр+2реб', '2взр+1реб', '2взр+2реб'];
  const onedayTypes = ['1взр', 'Реб.'];
  const formAccommodation = useForm({
    initialValues: {
      accommodations: selectedAccomodation ||
        preSaveOrder.tourInfo?.hotels || [
          {
            id: null,
            period: [null, null],
            boat_string: '',
            room_level_string: '',
            room_number: '',
            address: '',
            hotel: '',
            type: '',
            category: '',
            accommodation: '',
            food_type: '',
            price: 0,
            tourist: [],
          },
        ],
    },
  });

  // список гостиниц в туре в конкретной поезде - dateIndex
  const hotelsData =
    placements?.length > 0
      ? placements?.map((item, index) => ({
          id: item.id,
          name: item.hotel_string,
          type: item.room_type_string,
          category: item.room_category_string,
          food_type: item.food_type_string,
          accommodation: [
            { type: '1взр', price: parseInt(item.price1) },
            { type: '2взр', price: parseInt(item.price2) },
            { type: '3взр', price: parseInt(item.price3) },
            { type: '1взр+1реб', price: parseInt(item.price4) },
            { type: '1взр+2реб', price: parseInt(item.price5) },
            { type: '2взр+1реб', price: parseInt(item.price6) },
            { type: '2взр+2реб', price: parseInt(item.price7) },
          ],
        }))
      : [];

  // список типов комнат в зависимости от отеля
  const initialType =
    placements?.length > 0
      ? placements?.map((item) => ({
          ind: item.hotel_string,
          value: item.room_type_string,
        }))
      : [];

  // список категорий комнат в зависимости от отеля и типа комнаты
  const initialCategory =
    placements?.length > 0
      ? placements?.map((item) => ({
          ind: item.hotel_string,
          type: item.room_type_string,
          value: item.room_category_string,
        }))
      : [];

  const [hotels, setHotels] = useState<string[]>([]);
  const [categories, setCategories] = useState<{ ind: string; type: string; value: string }[]>(initialCategory);
  const [types, setTypes] = useState<{ ind: string; value: string }[]>(initialType);

  const [accommodations, setAccommodations] = useState<
    { ind: string; type: string; category: string; value: string }[]
  >([]);
  const [prices, setPrices] = useState<
    { ind: string; type: string; category: string; accommodation: string; value: string }[]
  >([]);

  useEffect(() => {
    if (!preSaveOrder.id) {
      setSelectedAccomodation(formAccommodation.values.accommodations);
    }
  }, [formAccommodation.values.accommodations]);

  // Задаем изначальные отели
  useEffect(() => {
    const data = selectedAccomodation.map((el) => el.hotel);
    const hdata = hotelsData.map((item) => {
      return item.name;
    });
    const aggdata = data.concat(hdata);
    setHotels(aggdata.filter((el, index, self) => self.indexOf(el) === index));
  }, [hotelsData.length, selectedAccomodation]);

  // Задаем изначальные типы
  useEffect(() => {
    const newArr = hotelsData
      .map((item) => ({
        ind: item.name,
        value: item.type,
      }))
      .concat(selectedAccomodation.map((el) => ({ ind: el.hotel, value: el.type })));
    setTypes(filterByProps(newArr, ['ind', 'value']));
  }, [hotelsData.length, selectedAccomodation]);

  // Задаем изначальные категории
  useEffect(() => {
    const newArr = hotelsData
      .map((item) => ({
        ind: item.name,
        type: item.type,
        value: item.category,
      }))
      .concat(selectedAccomodation.map((el) => ({ ind: el.hotel, value: el.category, type: el.type })));
    setCategories(newArr);
  }, [hotelsData.length]);

  // Задаем изначальные виды размещений
  useEffect(() => {
    const accomms: any = [];
    hotelsData.forEach((item2) =>
      item2.accommodation
        .filter((item) => item.price > 0)
        .forEach((item) =>
          accomms.push({
            ind: item2.name,
            type: item2.type,
            category: item2.category,
            value:
              getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY && item.type === '2взр' ? 'Реб.' : item.type,
          })
        )
    );
    const newAccomms = accomms.concat(
      selectedAccomodation.map((el) => ({
        ind: el.hotel,
        type: el.type,
        category: el.category,
        value:
          getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY && el.accommodation === '2взр'
            ? 'Реб.'
            : el.accommodation,
      }))
    );
    setAccommodations(newAccomms);
  }, [hotelsData.length]);

  //задаем начальные виды цен
  useEffect(() => {
    const prcs: any = [];
    hotelsData.forEach((item2) =>
      item2.accommodation.forEach((item) =>
        prcs.push({
          ind: item2.name,
          type: item2.type,
          category: item2.category,
          accommodation:
            getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY && item.type === '2взр' ? 'Реб.' : item.type,
          value: item.price,
        })
      )
    );

    setPrices(prcs);
  }, [hotelsData.length]);

  const fields = formAccommodation.values.accommodations ? (
    formAccommodation.values.accommodations.map((item, index) => (
      <div key={index} className={styles.row}>
        {(isHotel || isCruize) && (
          <div className={clsx(styles.cell, styles.period)}>
            <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
              <DatePickerInput
                type="range"
                allowSingleDateInRange={true}
                {...formAccommodation.getInputProps(`accommodations.${index}.period`)}
                valueFormat="DD.MM.YYYY "
                placeholder="Выбрать"
                disabled={!isCanEdit}
                mx="auto"
                maw={400}
                icon={<DateIcon width={12} height={12} fill={'rgba(91, 101, 114, 0.75)'} className={'close'} />}
                className={styles.select}
                styles={{
                  rightSection: { pointerEvents: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            </DatesProvider>
          </div>
        )}
        {!isCruize && (
          <div className={clsx(styles.cell, isHotel ? styles.hotel_trans : styles.hotel)}>
            {isIndividual || isHotel || (tourById?.name != '' && tour?.length === 0) ? (
              <TextInput
                {...formAccommodation.getInputProps(`accommodations.${index}.hotel`)}
                disabled={!isCanEdit}
                styles={{
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <Select
                data={hotels}
                placeholder="Выбрать"
                {...formAccommodation.getInputProps(`accommodations.${index}.hotel`)}
                searchable
                creatable
                disabled={!isCanEdit}
                getCreateLabel={(query) => `+ Создать ${query}`}
                onCreate={(query) => {
                  const item = { value: query, label: query };
                  setHotels((current) => [...current, query]);
                  return item;
                }}
                //при смене гостиницы обнуляет поля
                onChange={(value: string) => {
                  // console.log('>>> OrderForm, Accomodation Select ON CHANGE event');
                  formAccommodation.setFieldValue(`accommodations.${index}.type`, '');
                  formAccommodation.setFieldValue(`accommodations.${index}.category`, '');
                  formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
                  formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
                  formAccommodation.setFieldValue(`accommodations.${index}.hotel`, value);
                }}
                className={styles.select}
                rightSectionWidth={30}
                styles={{
                  rightSection: { pointerEvents: 'none', display: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                  item: {
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            )}
          </div>
        )}
        {isCruize && (
          <div className={clsx(styles.cell, styles.boat)}>
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.boat_string`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        {isHotel && (
          <div className={clsx(styles.cell, styles.adress)}>
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.address`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        {getTemplateToUI(tourById.template) !== ToursTemplates.ONEDAY && (
          <>
            <div
              className={clsx(
                styles.cell,
                isHotel ? styles.roomtype_trans : isCruize ? styles.roomtype_boat : styles.roomtype
              )}
            >
              {isIndividual || isHotel || isCruize || (tourById?.name != '' && tour?.length === 0) ? (
                <TextInput
                  {...formAccommodation.getInputProps(`accommodations.${index}.type`)}
                  disabled={!isCanEdit}
                  styles={{
                    input: {
                      border: 'none',
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                  }}
                />
              ) : (
                <Select
                  data={types
                    .filter((item) => item.ind === formAccommodation.values.accommodations[index].hotel)
                    .map((item) => item.value.toString())
                    .filter((el, index, self) => self.indexOf(el) === index)}
                  placeholder="Выбрать"
                  disabled={!isCanEdit}
                  {...formAccommodation.getInputProps(`accommodations.${index}.type`)}
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Создать ${query}`}
                  onCreate={(query) => {
                    const item2 = { value: query, label: query };
                    setTypes((current) => [...current, { ind: item.hotel, value: query }]);
                    return item2;
                  }}
                  //при смене типа обнуляет поля
                  onChange={(value: string) => {
                    formAccommodation.setFieldValue(`accommodations.${index}.category`, '');
                    formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
                    formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
                    formAccommodation.setFieldValue(`accommodations.${index}.type`, value);
                  }}
                  className={styles.select}
                  rightSectionWidth={30}
                  styles={{
                    rightSection: { pointerEvents: 'none', display: 'none' },
                    input: {
                      border: 'none',
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                    item: {
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                  }}
                />
              )}
            </div>
            <div className={clsx(styles.cell, isHotel || isCruize ? styles.category_trans : styles.category)}>
              {isIndividual || isHotel || isCruize || (tourById?.name != '' && tour?.length === 0) ? (
                <TextInput
                  {...formAccommodation.getInputProps(`accommodations.${index}.category`)}
                  disabled={!isCanEdit}
                  styles={{
                    input: {
                      border: 'none',
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                  }}
                />
              ) : (
                <Select
                  data={categories
                    .filter((item) => item.ind === formAccommodation.values.accommodations[index].hotel)
                    .filter((item) => item.type === formAccommodation.values.accommodations[index].type)
                    .map((item) => item.value.toString())
                    .filter((el, index, self) => self.indexOf(el) === index)}
                  defaultValue={selectedAccomodation[index]?.category ? selectedAccomodation[index].category : ''}
                  placeholder="Выбрать"
                  {...formAccommodation.getInputProps(`accommodations.${index}.category`)}
                  searchable
                  disabled={!isCanEdit}
                  creatable
                  getCreateLabel={(query) => `+ Создать ${query}`}
                  onCreate={(query) => {
                    const item2 = { value: query, label: query };
                    setCategories((current) => [...current, { ind: item.hotel, type: item.type, value: query }]);
                    return item2;
                  }}
                  //при смене категории обнуляет поля
                  onChange={(value: string) => {
                    formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
                    formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
                    formAccommodation.setFieldValue(`accommodations.${index}.category`, value);
                  }}
                  className={styles.select}
                  rightSectionWidth={30}
                  styles={{
                    rightSection: { pointerEvents: 'none', display: 'none' },
                    input: {
                      border: 'none',
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                    item: {
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                  }}
                />
              )}
            </div>
          </>
        )}
        {isCruize && (
          <div className={clsx(styles.cell, styles.room_level)}>
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.room_level_string`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        {isCruize && (
          <div className={clsx(styles.cell, styles.room_number)}>
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.room_number`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        {(isHotel || (tourById?.name != '' && tour?.length === 0) || isIndividual) && (
          <div className={clsx(styles.cell, styles.food_type)}>
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.food_type`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        {getTemplateToUI(tourById.template) !== ToursTemplates.ONEDAY && (
          <div
            className={clsx(styles.cell, isHotel || isCruize ? styles.accomodationType_trans : styles.accomodationType)}
          >
            {isIndividual || isHotel || isCruize || (tourById?.name != '' && tour?.length === 0) ? (
              <Select
                data={individualTypes}
                defaultValue={
                  selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                }
                value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                placeholder="Выбрать"
                itemComponent={SelectItem}
                disabled={!isCanEdit}
                {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                searchable
                creatable
                getCreateLabel={(query) => `+ Создать ${query}`}
                onChange={(value: string) => {
                  formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                }}
                className={styles.select}
                rightSectionWidth={30}
                styles={{
                  rightSection: { pointerEvents: 'none', display: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                  item: {
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <>
                {accommodations.filter(
                  (item) =>
                    item.ind === formAccommodation.values.accommodations[index].hotel &&
                    item.category === formAccommodation.values.accommodations[index].category &&
                    item.type === formAccommodation.values.accommodations[index].type
                ).length > 0 ? (
                  <Select
                    data={accommodations
                      .filter(
                        (item) =>
                          item.ind === formAccommodation.values.accommodations[index].hotel &&
                          item.category === formAccommodation.values.accommodations[index].category &&
                          item.type === formAccommodation.values.accommodations[index].type
                      )
                      .map((item) => item.value?.toString() ?? '')}
                    disabled={!isCanEdit}
                    defaultValue={
                      selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                    }
                    value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                    placeholder="Выбрать"
                    itemComponent={SelectItem}
                    {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onChange={(value: string) => {
                      const price = prices
                        .filter(
                          (item) =>
                            item.ind === formAccommodation.values.accommodations[index].hotel &&
                            item.category === formAccommodation.values.accommodations[index].category &&
                            item.type === formAccommodation.values.accommodations[index].type &&
                            item.accommodation === value
                        )
                        .map((item) => item.value.toString());
                      const foodType = hotelsData.filter(
                        (el) =>
                          el.name === formAccommodation.values.accommodations[index].hotel &&
                          el.category === formAccommodation.values.accommodations[index].category &&
                          el.type === formAccommodation.values.accommodations[index].type
                      );
                      console.log(
                        hotelsData.filter((el) => el.name === formAccommodation.values.accommodations[index].hotel)
                      );
                      formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                      formAccommodation.setFieldValue(`accommodations.${index}.price`, parseInt(price[0]));
                      formAccommodation.setFieldValue(`accommodations.${index}.food_type`, foodType[0].food_type);
                    }}
                    className={styles.select}
                    rightSectionWidth={30}
                    styles={{
                      rightSection: { pointerEvents: 'none', display: 'none' },
                      input: {
                        border: 'none',
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                      item: {
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                    }}
                  />
                ) : (
                  <Select
                    data={individualTypes}
                    defaultValue={
                      selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                    }
                    value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                    placeholder="Выбрать"
                    itemComponent={SelectItem}
                    disabled={!isCanEdit}
                    {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onChange={(value: string) => {
                      formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                    }}
                    className={styles.select}
                    rightSectionWidth={30}
                    styles={{
                      rightSection: { pointerEvents: 'none', display: 'none' },
                      input: {
                        border: 'none',
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                      item: {
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                    }}
                  />
                )}
              </>
            )}
          </div>
        )}

        {getTemplateToUI(tourById.template) === ToursTemplates.ONEDAY && (
          <div
            className={clsx(styles.cell, isHotel || isCruize ? styles.accomodationType_trans : styles.accomodationType)}
          >
            {isIndividual || isHotel || isCruize || (tourById?.name != '' && tour?.length === 0) ? (
              <Select
                data={onedayTypes}
                defaultValue={
                  selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                }
                value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                placeholder="Выбрать"
                itemComponent={SelectItem}
                disabled={!isCanEdit}
                {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                searchable
                creatable
                getCreateLabel={(query) => `+ Создать ${query}`}
                onChange={(value: string) => {
                  formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                }}
                className={styles.select}
                rightSectionWidth={30}
                styles={{
                  rightSection: { pointerEvents: 'none', display: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                  item: {
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <>
                {accommodations.filter((item) => item.ind === formAccommodation.values.accommodations[index].hotel)
                  .length > 0 ? (
                  <Select
                    data={accommodations
                      .filter((item) => item.ind === formAccommodation.values.accommodations[index].hotel)
                      .map((item) => item.value?.toString() ?? '')}
                    disabled={!isCanEdit}
                    defaultValue={
                      selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                    }
                    value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                    placeholder="Выбрать"
                    itemComponent={SelectItem}
                    {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onChange={(value: string) => {
                      const price = prices
                        .filter(
                          (item) =>
                            item.ind === formAccommodation.values.accommodations[index].hotel &&
                            item.accommodation === value
                        )
                        .map((item) => item.value.toString());
                      const foodType = hotelsData.filter(
                        (el) => el.name === formAccommodation.values.accommodations[index].hotel
                      );

                      formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                      formAccommodation.setFieldValue(`accommodations.${index}.price`, parseInt(price[0]));
                      formAccommodation.setFieldValue(`accommodations.${index}.food_type`, foodType[0].food_type);
                    }}
                    className={styles.select}
                    rightSectionWidth={30}
                    styles={{
                      rightSection: { pointerEvents: 'none', display: 'none' },
                      input: {
                        border: 'none',
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                      item: {
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                    }}
                  />
                ) : (
                  <Select
                    data={onedayTypes}
                    defaultValue={
                      selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                    }
                    value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                    placeholder="Выбрать"
                    itemComponent={SelectItem}
                    disabled={!isCanEdit}
                    {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onChange={(value: string) => {
                      formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                    }}
                    className={styles.select}
                    rightSectionWidth={30}
                    styles={{
                      rightSection: { pointerEvents: 'none', display: 'none' },
                      input: {
                        border: 'none',
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                      item: {
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '15px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                    }}
                  />
                )}
              </>
            )}
          </div>
        )}
        <div className={clsx(styles.cell, isHotel || isCruize ? styles.price_trans : styles.price)}>
          {isIndividual || isHotel || isCruize || (tourById?.name != '' && tour?.length === 0) ? (
            <NumberInput
              {...formAccommodation.getInputProps(`accommodations.${index}.price`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          ) : (
            <NumberInput
              hideControls
              defaultValue={formAccommodation.values.accommodations[index].price}
              disabled={!isCanEdit}
              value={formAccommodation.values.accommodations[index].price}
              {...formAccommodation.getInputProps(`accommodations.${index}.price`)}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          )}
        </div>
        <div className={clsx(styles.cell, isHotel || isCruize ? styles.tourist_trans : styles.tourist)}>
          <MultiSelect
            data={selectedTourists.map((item) => item.name)}
            disabled={!isCanEdit}
            placeholder="Выбрать"
            {...formAccommodation.getInputProps(`accommodations.${index}.tourist`)}
            searchable
            creatable
            getCreateLabel={(query) => `+ Создать ${query}`}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <span
          className={styles.delete}
          onClick={() => {
            if (!isCanEdit) {
              return;
            }
            formAccommodation.removeListItem('accommodations', index);
          }}
        >
          <BinIcon width={10} height={11} fill="#5B6572" />
        </span>
      </div>
    ))
  ) : (
    <></>
  );

  return (
    <div className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {columns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div></div>
          <div>{fields}</div>
        </div>
      </div>
      <div className={styles.btnRow}>
        {preSaveOrder.id && (
          <Button
            transparent={true}
            label="Сохранить все размещение"
            className={styles.saveRow}
            disabled={!isCanEdit}
            onClick={() => {
              setSelectedAccomodation(formAccommodation.values.accommodations);
              setDirty();
            }}
          />
        )}

        <Button
          transparent={true}
          label="+ Добавить строку"
          className={styles.addRow}
          disabled={!isCanEdit}
          onClick={() =>
            formAccommodation.insertListItem('accommodations', {
              id: null,
              period: [null, null],
              boat_string: '',
              room_level_string: '',
              period_string: '',
              nights_count: '',
              room_number: '',
              address: '',
              hotel: '',
              type: '',
              category: '',
              accommodation: '',
              food_type: '',
              price: 0,
              tourist: [],
            })
          }
        />
      </div>
    </div>
  );
};
