import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const DateIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.44 1.26H10.5V0.14C10.5 0.063 10.437 0 10.36 0H9.38C9.303 0 9.24 0.063 9.24 0.14V1.26H4.76V0.14C4.76 0.063 4.697 0 4.62 0H3.64C3.563 0 3.5 0.063 3.5 0.14V1.26H0.56C0.25025 1.26 0 1.51025 0 1.82V13.44C0 13.7498 0.25025 14 0.56 14H13.44C13.7498 14 14 13.7498 14 13.44V1.82C14 1.51025 13.7498 1.26 13.44 1.26ZM12.74 12.74H1.26V6.09H12.74V12.74ZM1.26 4.9V2.52H3.5V3.36C3.5 3.437 3.563 3.5 3.64 3.5H4.62C4.697 3.5 4.76 3.437 4.76 3.36V2.52H9.24V3.36C9.24 3.437 9.303 3.5 9.38 3.5H10.36C10.437 3.5 10.5 3.437 10.5 3.36V2.52H12.74V4.9H1.26Z"
        fill={fill}
      />
    </svg>
  );
};
