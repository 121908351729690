import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginData, checkAuthAPI, loginAPI } from 'services/auth/authApi.service';
import { setAuth } from 'store/entities/auth/authSlice';

export const login = createAsyncThunk('auth/login', async (arg: LoginData, thunkAPI) => {
  try {
    const response = await loginAPI(arg);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    thunkAPI.dispatch(setAuth(true));
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const checkAuth = createAsyncThunk('auth/checkAuth', async (_, thunkAPI) => {
  try {
    const response = await checkAuthAPI();
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    thunkAPI.dispatch(setAuth(true));
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
