import React from 'react';

import styles from '../Orders.module.scss';
import { OrdersTop } from '../OrdersTop';
import { OrdersTable } from '../OrdersTable';

export const OrdersIndex = () => {
  return (
    <section className={styles.orders}>
      <OrdersTop />
      <OrdersTable />
    </section>
  );
};
