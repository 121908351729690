import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const Client1A1CIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3523 6.17172C13.3523 8.31217 11.6172 10.0474 9.4767 10.0474C7.33625 10.0474 5.60106 8.31217 5.60106 6.17172C5.60106 4.03126 7.33625 2.29608 9.4767 2.29608C11.6172 2.29608 13.3523 4.03126 13.3523 6.17172ZM12.1994 10.9907C13.8779 10.0403 15.0107 8.23832 15.0107 6.17172C15.0107 3.11536 12.5331 0.637695 9.4767 0.637695C6.42035 0.637695 3.94268 3.11536 3.94268 6.17172C3.94268 8.23821 5.07535 10.0401 6.75372 10.9906C5.33268 11.4077 4.0254 12.1491 2.95439 13.1665C1.64196 14.4134 0.765904 15.9972 0.419595 17.704C0.309485 18.2466 0.25293 18.8017 0.25293 19.3624H1.91131L9.4763 19.3624H17.0413H18.6997C18.6997 18.8017 18.6431 18.2466 18.533 17.704C18.1867 15.9972 17.3106 14.4134 15.9982 13.1665C14.9273 12.1492 13.6202 11.4079 12.1994 10.9907ZM14.856 14.3689C15.8392 15.3029 16.5138 16.4599 16.8321 17.704H9.4763L2.12054 17.704C2.43884 16.4599 3.11339 15.3029 4.0966 14.3689C5.50955 13.0266 7.44327 12.2585 9.4763 12.2585C11.5093 12.2585 13.443 13.0266 14.856 14.3689Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8578 6.41712C28.8578 7.5957 27.9024 8.55113 26.7238 8.55113C25.5452 8.55113 24.5898 7.5957 24.5898 6.41712C24.5898 5.23853 25.5452 4.28311 26.7238 4.28311C27.9024 4.28311 28.8578 5.23853 28.8578 6.41712ZM30.4578 6.41712C30.4578 8.47935 28.7861 10.1511 26.7238 10.1511C24.6616 10.1511 22.9898 8.47935 22.9898 6.41712C22.9898 4.35488 24.6616 2.68311 26.7238 2.68311C28.7861 2.68311 30.4578 4.35488 30.4578 6.41712ZM26.7235 10.6048C25.8779 10.6048 24.9218 10.6474 24.0099 10.8466C23.0989 11.0456 22.176 11.4126 21.4492 12.1031C20.7252 12.7909 20.2941 13.7063 20.0418 14.6135C19.7882 15.5253 19.7002 16.4821 19.7002 17.317H21.3002C21.3002 16.5838 21.3786 15.7781 21.5833 15.0422C21.7892 14.3016 22.1081 13.684 22.5512 13.2631C22.9915 12.8449 23.605 12.5727 24.3513 12.4097C25.0966 12.247 25.9187 12.2048 26.7235 12.2048C27.5283 12.2048 28.3649 12.2469 29.125 12.4103C29.8882 12.5743 30.5119 12.8477 30.9492 13.2631C31.387 13.679 31.6934 14.2903 31.8873 15.0305C32.0801 15.7664 32.1469 16.5745 32.1469 17.317H33.7469C33.7469 16.4915 33.6739 15.5369 33.4351 14.6251C33.1974 13.7176 32.7805 12.7959 32.0512 12.1031C31.3214 11.4098 30.3821 11.044 29.4613 10.846C28.5375 10.6474 27.5693 10.6048 26.7235 10.6048Z"
        fill={fill}
      />
    </svg>
  );
};
