import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const BackIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2562 17.64C11.5347 17.8807 11.9457 17.6644 11.9457 17.275L11.9457 4.35883C11.9457 3.97219 11.5374 3.75588 11.2562 3.99382L3.76933 10.4533C3.55573 10.6398 3.55573 10.9967 3.76933 11.1806L11.2562 17.64ZM16.7829 17.8347L19 17.8347L19 3.80185L16.7829 3.80185L16.7829 17.8347Z"
        fill={fill}
      />
    </svg>
  );
};
