import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const DragIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 130 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1008 74.6209C19.0717 74.6047 19.0417 74.59 19.011 74.5768C8.41153 70.0171 1 59.5168 1 47.2979C1 30.8979 14.3528 17.5957 30.8333 17.5957C35.0013 17.5957 38.9671 18.4461 42.5674 19.9811C42.8262 20.0915 43.1197 20.0877 43.3756 19.9706C43.6315 19.8535 43.8262 19.634 43.912 19.366C47.3198 8.71605 57.3377 1 69.1667 1C83.8062 1 95.6667 12.8161 95.6667 27.383C95.6667 27.4232 95.6666 27.4633 95.6664 27.5035C95.6662 27.5505 95.6658 27.5976 95.6654 27.6446C95.6623 27.9627 95.8107 28.2633 96.0651 28.4542C96.3195 28.6452 96.6496 28.7037 96.9542 28.6118C99.2355 27.9236 101.657 27.5532 104.167 27.5532C117.886 27.5532 129 38.6263 129 52.2766C129 63.6817 121.241 73.2891 110.685 76.1399C110.679 76.1414 110.674 76.1429 110.668 76.1445C110.662 76.1463 110.656 76.1481 110.65 76.15C108.866 76.7022 106.969 77 105 77H104.167H30.8333H28.3333C24.9803 77 21.8331 76.1362 19.1008 74.6209Z"
        stroke="#7084CB"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M81.6 54.6C81.6 54.0477 81.1523 53.6 80.6 53.6C80.0477 53.6 79.6 54.0477 79.6 54.6H81.6ZM54.3 54.6C54.3 54.0477 53.8523 53.6 53.3 53.6C52.7477 53.6 52.3 54.0477 52.3 54.6H54.3ZM65.95 55.9C65.95 56.4523 66.3977 56.9 66.95 56.9C67.5023 56.9 67.95 56.4523 67.95 55.9H65.95ZM67.6571 35.0429C67.2666 34.6524 66.6334 34.6524 66.2429 35.0429L59.8789 41.4069C59.4884 41.7974 59.4884 42.4305 59.8789 42.8211C60.2694 43.2116 60.9026 43.2116 61.2931 42.8211L66.95 37.1642L72.6068 42.8211C72.9974 43.2116 73.6305 43.2116 74.0211 42.8211C74.4116 42.4305 74.4116 41.7974 74.0211 41.4069L67.6571 35.0429ZM79.6 54.6V59.7H81.6V54.6H79.6ZM76.6 62.7H57.3V64.7H76.6V62.7ZM54.3 59.7V54.6H52.3V59.7H54.3ZM67.95 55.9V35.75H65.95V55.9H67.95ZM57.3 62.7C55.6431 62.7 54.3 61.3569 54.3 59.7H52.3C52.3 62.4614 54.5386 64.7 57.3 64.7V62.7ZM79.6 59.7C79.6 61.3569 78.2568 62.7 76.6 62.7V64.7C79.3614 64.7 81.6 62.4614 81.6 59.7H79.6Z"
        fill="#7084CB"
      />
    </svg>
  );
};
