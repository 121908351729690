import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { TouristsProps, TouristType } from './Tourists.type';
import { Button } from 'ui/Button';
import { accomodationColumns } from './data';
import { useForm } from '@mantine/form';

import styles from './Tourist.module.scss';

import { Select } from '@mantine/core';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { BinIcon } from 'components/icons/BinIcon';
import { Modal } from 'components/Modal';
import { PartnerCreate } from 'pages/Partners/PartnerCreate';
import { getTouristsForSearch } from 'store/action-creators/partners';
import { resetTourists, setIsPartnerModal } from 'store/entities/partners/partnersSlice';
import { getDate } from 'core/utils/getDate';

export const Tourists: FC<TouristsProps> = ({
  tourists,
  isCanEdit,
  selectedTourists,
  setSelectedTourists,
  setDirty,
}) => {
  const dispatch = useAppDispatch();
  const { touristsForSearch, isPartnerModal } = useAppSelector((state) => state.partners);
  const { preSaveOrder } = useAppSelector((state) => state.orders);

  // храним данные о туристах локально здесь, этот же
  // массив потом передаем выше в заказ
  const [myTourists, setMyTourists] = useState<TouristType[]>([]);
  const [initState, setInitState] = useState<boolean>(true);

  const [openedPartnerCreate, setOpenedPartnerCreate] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      tourists: selectedTourists || preSaveOrder.tourInfo?.tourists || [{ name: '', id: 0 }],
    },
  });

  // "хак" для хранения данных из формы по текущей строке
  const [selectVal, setSelectVal] = useState('');

  function removeTourist(tourist: string): void {
    // console.log('надо удалить ' + tourist);
    // TODO: одинаковые имена - отфильтруются
    // проблема что у нас нет сопоставления
    // реального id туриста из базы (и в myTourists)
    // и строки в таблице которую он занимает
    // а сам источник проблемы - не тянутся
    // реальные id туристов в форме, а порядковые номера
    // строк в качестве id
    setInitState(false);

    setMyTourists(myTourists.filter((item) => item.name !== tourist));
  }

  // на входе формируем список туристов по данным формы (если есть)
  useEffect(() => {
    //console.log('tourists INIT. Current tourists form list: ');
    //console.log(form.values.tourists);
    //console.log('===================');
    setInitState(true); // на первом входе не будем дергать сохранение
    setMyTourists(form.values.tourists);
  }, []);
  // тут формируем список туристов - берем из формы значения
  // и сопоставляем с реальными id-шниками (по текущей строчке,
  // когда актуален контекст поиска еще)

  useEffect(() => {
    if (selectVal.length > 0) {
      // console.log('***** selectVal: *******', selectVal);

      // TODO: Если в текущей строке мы заменили одного
      // туриста на другого - первого надо удалять
      // если  myTourists.length !== form.values.tourists.length
      // это значит текущую строку заменили, ее надо достать
      // криво? возможно, но хитрее без переписывания самой формы
      // не придумал
      const found = selectVal.includes('—')
        ? touristsForSearch.find(
            (elem) =>
              elem.name.trim() === selectVal.split('—')[0].trim() && elem.birthday === selectVal.split('—')[1].trim()
          )?.id
        : touristsForSearch.find((elem) => elem.name.trim() === selectVal.trim())?.id;

      // для текущего туриста подставляем правильный id
      // и сохраняем в локальный список туристов
      if (found) {
        // console.log('>>>>>>>>>>>>>>>>>> надо заменить id у туриста ' + selectVal + ' на ' + found);

        if (initState) setInitState(false); // включаем отработки сохранений

        // если такой уже есть в списке - обновим ему id
        if (myTourists.find((elem) => elem.name === selectVal)) {
          setMyTourists(
            myTourists.map((obj: TouristType) => {
              if (obj.name === selectVal) {
                return { ...obj, id: found };
              }
              return obj;
            })
          );
        } else {
          // если нет - просто добавим его в список
          // console.log('>>>>>>>>>>>>>>>>>> ADDING TOURIST: ' + selectVal);
          setMyTourists(myTourists.concat({ id: found, name: selectVal }));
        }

        // console.log('MY TOURISTS: ', myTourists);
      }
    }
  }, [selectVal]);

  useEffect(() => {
    // по обновлению локального списка - передаем его
    // выше и помечаем как изменившийся
    // console.log('>>>>> myTourists change: ', myTourists);
    setSelectedTourists(myTourists);
    if (setDirty && !initState) {
      //console.log('^^^^^^^^^^^^^^^^ TOURISTS DIRTY ^^^^^^^^^^^^^^^^^');
      setDirty(true);
    }
  }, [myTourists]);

  // не опираемся на форму больше
  // useEffect(() => {
  // console.log('>>>>>>>++++ form.values.Tourists change: ', form.values.tourists);
  // console.log('=====================');
  //setSelectedTourists(form.values.tourists);
  // if (setDirty) {
  //   setDirty(true);
  //}
  //}, [form.values.tourists]);

  const handlePartnerCreateClick = () => {
    setOpenedPartnerCreate(true);
    dispatch(setIsPartnerModal(true));
  };
  const [searchVal, setSearchVal] = useState('');
  const touristList = useMemo(
    () =>
      touristsForSearch.length > 0 && searchVal != ''
        ? touristsForSearch
            .filter((el) => el.name?.toLowerCase().includes(searchVal.toLowerCase()))
            .map((item) => {
              if (item.name?.trim()) {
                return item.birthday
                  ? item.phone
                    ? item.code
                      ? `${item.name} — ${item.birthday} — ${item.phone} — ${item.code}`
                      : `${item.name} — ${item.birthday} — ${item.phone}`
                    : `${item.name} — ${item.birthday}`
                  : item.name;
              } else return 'no name';
              // по-хорошему надо брать id реальный и имя
              // а не только имя, но там надо ловить почему
              // белый экран получается
              // return {id: item.id, name: item.name};
              // } else return {id: -1, name: 'no name'};
            })
        : [],
    [touristsForSearch]
  );

  const handleKey = () => {
    if (searchVal != '') {
      dispatch(
        getTouristsForSearch({
          search: searchVal,
          properties: ['id', '@id', 'full_name', 'birthday', 'phone', 'contact_passport'],
          itemsPerPage: 100,
        })
      );
      return;
    }
  };
  return (
    <>
      <div className={styles.hotels}>
        <div className={styles.tableWrapper}>
          <div className={styles.table}>
            <div className={styles.headers}>
              {accomodationColumns.map((column) => (
                <div
                  key={column.id}
                  className={clsx(
                    styles.column,
                    styles[`column_${column.id}`],
                    column.id.includes('price') && styles[`column_price`]
                  )}
                >
                  <span>{column.icon ? column.icon : column.name}</span>
                </div>
              ))}
            </div>
            <div>
              {form.values.tourists ? (
                form.values.tourists.map((item, index) => {
                  return (
                    <div key={item.id + '_' + index} className={styles.row}>
                      <div className={clsx(styles.cell, styles.number)}>{index + 1}</div>
                      <div className={clsx(styles.cell, styles.name)}>
                        <Select
                          data={
                            selectedTourists.length > 0
                              ? touristList.concat(
                                  selectedTourists
                                    .map((el) => el.name)
                                    .filter((el) => el != '' && !touristList.includes(el))
                                )
                              : touristList

                            // ? touristList.concat(selectedTourists).filter((el) => el.name != '')
                            //   : touristList.filter((el) => el.name != '')
                          }
                          defaultValue={form.values.tourists[index].name}
                          placeholder="Выбрать"
                          {...form.getInputProps(`tourists.${index}.name`)}
                          searchable
                          onBlur={setDirty}
                          creatable
                          getCreateLabel={(query) => `+ Создать`}
                          onSearchChange={setSearchVal}
                          // по onSelect вызываем обработку строки
                          onSelect={(event) => setSelectVal(event.currentTarget.value)}
                          onCreate={(query) => {
                            handlePartnerCreateClick();
                          }}
                          onKeyDown={(e) => {
                            if (e.key == 'Enter') {
                              e.preventDefault();
                              handleKey();
                            }
                          }}
                          className={styles.select}
                          rightSectionWidth={30}
                          disabled={!isCanEdit}
                          styles={{
                            rightSection: { pointerEvents: 'none', display: 'none' },
                            input: {
                              border: 'none',
                              fontFamily: 'Museo Sans Cyrl',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: '16px',
                              lineHeight: '19px',
                              color: '#263B53',
                            },
                            item: {
                              fontFamily: 'Museo Sans Cyrl',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: '16px',
                              lineHeight: '19px',
                              color: '#263B53',
                            },
                          }}
                        />
                      </div>

                      <span
                        className={styles.delete}
                        onClick={() => {
                          if (!isCanEdit) {
                            return;
                          }
                          // локально тоже надо удалить туриста
                          const _n = form.values.tourists[index].name;
                          form.removeListItem('tourists', index);
                          form.values.tourists.forEach((it, ind) => {
                            if (ind < form.values.tourists.length - 1) {
                              form.setFieldValue(`tourists.${ind}.id`, ind + 1);
                            }
                          });
                          removeTourist(_n);
                        }}
                      >
                        <BinIcon width={10} height={11} fill="#5B6572" />
                      </span>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <Button
          transparent={true}
          label="+ Добавить строку"
          className={styles.addRow}
          disabled={!isCanEdit}
          onClick={() => {
            //form.reset();
            form.insertListItem('tourists', {
              id: form.values.tourists.length + 1,
              name: '',
            });
            dispatch(resetTourists());
          }}
        />
      </div>
      {openedPartnerCreate && isPartnerModal && (
        <Modal opened={isPartnerModal} setOpened={setOpenedPartnerCreate}>
          <PartnerCreate />
        </Modal>
      )}
    </>
  );
};
