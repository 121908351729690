import React, { FC, useEffect, useState } from 'react';
import { navigation } from './navigation';
import styles from './LayoutNavbar.module.scss';
import NavbarItem from './components/NavbarItem';
import NavbarButton from './components/NavbarButton';
import clsx from 'clsx';

type LayoutNavbarProps = {
  setNavbarOpened: (a: boolean) => void;
};

const LayoutNavbar: FC<LayoutNavbarProps> = ({ setNavbarOpened }) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setNavbarOpened(opened);
  }, [opened]);

  return (
    <div className={clsx(styles.navbar, opened && styles.navbarOpen)}>
      <div>
        {navigation.map((nav) => (
          <NavbarItem key={nav.title} item={nav} opened={opened} />
        ))}
      </div>
      <div>
        <NavbarButton opened={opened} setOpened={() => setOpened(!opened)} />
      </div>
    </div>
  );
};

export default LayoutNavbar;
