import React, { FC, useEffect, useState } from 'react';
import { MultiSelect } from '@mantine/core';

import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';

interface MultiSelectorProps {
  allItems: SelectMultiItemType[];
  defaultItems?: SelectMultiItemType[];
  className?: string;
  label?: string;
  getItems?: (value: SelectMultiItemType[]) => void;
  customValue?: FC<any>;
  readonly?: boolean;
  creatable?: boolean;
  onCreate?: (query: string) => string;
}

export const MultiSelector: FC<MultiSelectorProps> = ({
  allItems,
  className,
  label,
  defaultItems,
  getItems,
  customValue,
  creatable,
  onCreate,
}) => {
  const [data, setData] = useState<{ value: string; label: string }[]>([]);
  const [value, setValue] = useState<string[]>([]);
  const [searchVal, setSearchVal] = useState('');
  //   обрабатываем входящий список элементов и приводим к нужному для компонента формату
  useEffect(() => {
    const formatData = allItems.map((item) => ({ value: item.name, label: item.name }));
    setData(formatData);
  }, [allItems]);

  //  обрабатываем входящий список выбранных элементов и приводим к нужному для компонента формату
  useEffect(() => {
    if (defaultItems) {
      const defaultValue = defaultItems.map((item) => item.name);
      setValue(defaultValue);
    }
  }, [defaultItems]);

  //   при изменении в селекторе, следим за контролируемым компонентом, а также отдаем полученные данные в родительский компонент
  const onChange = (value: string[]) => {
    setValue(value);

    if (getItems) {
      const selectedItems = allItems.filter((item) => value.includes(item.name));
      getItems(selectedItems);
    }
  };

  return (
    <MultiSelect
      data={data}
      label={label}
      placeholder=""
      searchable
      clearButtonProps={{ 'aria-label': 'Clear selection' }}
      valueComponent={customValue && customValue}
      clearable
      nothingFound="Ничего не найдено"
      className={className}
      maxDropdownHeight={200}
      value={value}
      searchValue={searchVal}
      onDropdownClose={() => setSearchVal('')}
      onSearchChange={(query) => setSearchVal(query)}
      onChange={onChange}
      styles={{
        root: {
          width: '100%',
          borderRadius: '8px 8px 8px 8px',
          minHeight: '38px',
          padding: '0',
          color: '#263b53',
        },
        wrapper: {
          minHeight: '50px',
        },
        input: {
          background: '#f5f6fa',
          border: 'none',
          minHeight: '50px',
          padding: '8px 16px',
        },
        defaultValue: {
          background: '#ffffff',
          padding: '5px 10px',
          color: '#263b53',
          fontFamily: 'Museo Sans Cyrl',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19px',
          minHeight: '29px',
          marginRight: '12px',
        },
      }}
      //   если понадобится, то строки ниже подключат создание новых элемнетов.

      creatable={creatable}
      getCreateLabel={(query) => `+ Создать ${query}`}
      onCreate={(query) => onCreate && onCreate(query)}
    />
  );
};
