import React, { FC } from 'react';
import styles from '../Request.module.scss';
import { ISiteRequest } from 'services/orders/orderApi.service';
import { getFormType } from 'core/utils/getFormType';
import { getDate, getTime, getTimeTzNoLess } from 'core/utils/getDate';
export const Consultation: FC<{ request: ISiteRequest }> = ({ request }) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.requestType}>
          <div className={styles.requestType_title}>
            <p>Форма связи:</p>
            <span>{getFormType(request.form_type)}</span>
          </div>
          {request.tour_name && (
            <div className={styles.requestType_title}>
              <p>Название тура: </p>
              <span>{request.tour_name}</span>
            </div>
          )}

          <div className={styles.dateTimeBlock}>
            <div className={styles.requestType_title}>
              <p>Дата оформления заявки:</p>
              <span>{getDate(new Date(request.created_at))}</span>
            </div>
            <div className={styles.requestType_title}>
              <p>Время оформления заявки:</p>
              <span>{getTimeTzNoLess(new Date(request.created_at))}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
