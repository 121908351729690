export const getInitialsFromName = (name: string) => {
  const words = name.split(' ');
  let initials = '';
  words.forEach((word) => {
    initials += word[0] + '.';
  });

  return initials;
};

export const getNameSurname = (name: string) => {
  return name
    .split(/\s+/)
    .filter((el, idx) => idx != 2)
    .map((w, i) => (i ? `${w.substring(0, 1)?.toUpperCase()}.` : `${w[0]?.toUpperCase()}.`))
    .join(' ');
};
