import { PaymentStatus } from 'core/constants/enums';

export const getStatus = (status: string) => {
  switch (status) {
    case 'new':
      return 'В работе';
    case 'waiting_for_payment':
      return 'Ожидает оплаты';
    case 'prepayment_received':
      return 'Аванс получен';
    case 'payed':
      return 'Заказ оплачен';
    case 'declined':
      return 'Отказ';
    case 'cancelled':
      return 'Аннулирован';
    case 'finished':
      return 'Завершен';
    case 'waiting':
      return 'Ожидается оплата';
    case 'prepayment':
      return 'Аванс получен';
    case 'error':
      return 'Сбой при оплате с сайта';
    case 'reserved':
      return 'Разморозить деньги';
    case 'no_payed':
      return 'Заказ не оплачен';
    case 'consultation':
      return 'Консультация';
    case 'site_reserve':
      return 'Бронь с сайта';
    case 'payment_acceptance':
      return 'Приём расчёт';
    case 'reservation_acceptance':
      return 'Приём брони';
    default:
      return '';
  }
};
export const getStatusToServer = (status: string) => {
  switch (status) {
    case 'Новый':
      return 'new';
    case 'Ожидает оплаты':
      return 'waiting_for_payment';
    case 'Заказ оплачен':
      return 'payed';
    case 'Отказ':
      return 'declined';
    case 'Аннулирован':
      return 'cancelled';
    case 'Завершен':
      return 'finished';
    case 'Ожидается оплата':
      return 'waiting';
    case 'Аванс получен':
      return 'prepayment';
    case 'Сбой при оплате с сайта':
      return 'error';
    case 'Разморозить деньги':
      return 'reserved';
    case 'Заказ не оплачен':
      return 'no_payed';
    case 'Консультация':
      return 'consultation';
    case 'Бронь с сайта':
      return 'site_reserve';
  }
};
export const getStatusColor = (status: string) => {
  switch (status) {
    case PaymentStatus.AWAITING_PAYMENT:
      return '#CDB900';
    case PaymentStatus.ADVANCE_RECEIVED:
      return '#4F6AC9';
    case PaymentStatus.RESERVED:
      return '#1573BB';
    case PaymentStatus.FAILURE:
      return '#F77B60';
    case PaymentStatus.PAID:
      return '#70CBBB';
    case PaymentStatus.NO_PAID:
      return '#9C9C9C';
    case 'unregistered':
      return '#000';
    case 'registered':
      return '#8000FF';
    case 'waiting_for_approve':
      return '#0075FF';
    case 'approved':
      return '#1ADD16';
  }
};

export const getStatusLK = (status: string) => {
  switch (status) {
    case 'unregistered':
      return 'Нет ЛК';
    case 'registered':
      return 'Неполный ЛК';
    case 'waiting_for_approve':
      return 'Отправлен договор с ЛК';
    case 'approved':
      return 'Подтвержденный ЛК';
    default:
      return '';
  }
};

export const getStatusLKToServer = (status?: string) => {
  if (!status) return;
  switch (status) {
    case 'Нет ЛК':
      return 'unregistered';
    case 'Неполный ЛК':
      return 'registered';
    case 'Отправлен договор с ЛК':
      return 'waiting_for_approve';
    case 'Подтвержденный ЛК':
      return 'approved';
    default:
      return undefined;
  }
};
