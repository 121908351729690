import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { SelectItemType, SelectType } from './SelectNew.type';
import { useRefCloseOut } from 'hooks/useRefCloseOut';

import styles from './SelectNew.module.scss';

export const SelectNew: FC<SelectType> = ({
  label,
  data,
  setSelect,
  className,
  selected,
  error,
  onBlur = () => void 1,
  icon = null,
  valueChanged,
  disabled = false,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<SelectItemType>();
  const selectRef = useRef() as React.MutableRefObject<HTMLUListElement>;
  const btnSelectRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [dataList, setDataList] = useState<SelectItemType[]>([]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  const handleClickHeader = () => {
    !disabled && setOpened((prev) => !prev);
  };

  const handleClickItem = (item: SelectItemType) => {
    setValue(item);
    setOpened(false);
    onBlur();
    if (item && setSelect) {
      setSelect(item);
    }
    if (valueChanged) {
      valueChanged();
    }
  };

  useEffect(() => {
    if (value && setSelect) {
      setSelect(value);
    }
  }, []);

  useEffect(() => {
    if (selected) {
      setValue(selected);
    }
  }, [selected]);
  useRefCloseOut(selectRef, setOpened, btnSelectRef);

  return (
    <article className={clsx(styles.select, className)}>
      <label className={styles.label}>{label}</label>

      <div
        className={clsx(
          styles.header,
          opened && styles.headerOpened,
          error && styles.error,
          disabled && styles.disabled
        )}
        onClick={handleClickHeader}
        ref={btnSelectRef}
        onChange={() => console.log('dd')}
        {...props}
      >
        {icon ? <span className={styles.iconWrapper}>{icon}</span> : null}
        {value?.name}
      </div>
      {opened && (
        <ul className={styles.list} ref={selectRef}>
          {dataList.map((item) => (
            <li key={item.id} onClick={() => handleClickItem(item)} className={styles.item}>
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </article>
  );
};
