import { RouteNames } from 'core/constants/routeNames';
import { RoutesProps } from './routes.types';
import { PartnersPage } from 'pages/Partners';
import { ReportsPage } from 'pages/Reports';
import { ToursPage } from 'pages/Tours';
import { SignIn } from 'pages/SignIn';
import { GalleryPage } from 'pages/Gallery';
import { OrdersPage } from 'pages/Orders';

export const privateRoutes: RoutesProps[] = [
  {
    path: RouteNames.SIGN_IN,
    Component: SignIn,
  },
  {
    path: RouteNames.ORDERS + '/*',
    Component: OrdersPage,
  },
  {
    path: RouteNames.TOURS + '/*',
    Component: ToursPage,
  },
  {
    path: RouteNames.PARTNERS,
    Component: PartnersPage,
  },
  {
    path: RouteNames.GALLERY,
    Component: GalleryPage,
  },
  {
    path: RouteNames.REPORTS,
    Component: ReportsPage,
  },
];

export const publicRoutes: RoutesProps[] = [
  {
    path: RouteNames.SIGN_IN,
    Component: SignIn,
  },
];
