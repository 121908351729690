import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Loader } from '@mantine/core';
import { Pagination } from '@mantine/core';
import { RouteNames } from 'core/constants/routeNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetCurrentTour,
  resetPreSaveTour,
  setParamsPage,
  setParamsSortById,
  setParamsSortByName,
} from 'store/entities/tours/toursSlice';
import { resetPreSaveTour2 } from 'store/entities/tours/preSaveTourSlice';

import styles from './ToursWithTripsTable.module.scss';
import { CellWithSorting } from 'components/CellWithSorting';
import { AllTourShortInfo, TourShortInfo } from 'pages/Tours/Tours.types';
import { toursWithTripsColumns } from 'pages/Tours/ToursWithTripsTable/data';

export const ToursAll: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { allTours, countAllTours, isLoading, isSuccess, params } = useAppSelector((state) => state.tours);
  const [toursList, setToursList] = useState<AllTourShortInfo[]>([]);
  const [countPages, setCountPages] = useState<number>(1);

  // для первой страницы устанавливаем первую загрузку данных,
  // для остальных подтягиваем дополнительные, полученные при скролле
  // небольшая имитация первой загрузки страницы
  useEffect(() => {
    // if (params.currentPage === 1) {
    setToursList(allTours);
    // } else {
    //   setToursList((pre) => [...pre, ...allTours]);
    // }
  }, [allTours]);

  //когда загружаем страницу со списком туров, обнуляем данные в предсохраненном туре и туре с сервера.
  //тогда при клике на отдельный тур сразу подтягивается нужная информация, избегаем мелькания старой и новой.
  useEffect(() => {
    dispatch(resetCurrentTour());
    dispatch(resetPreSaveTour());
    dispatch(resetPreSaveTour2());
  }, []);

  // при клике на строку таблицы, переходим на страницу редактирования тура
  const handleClickRow = (item: TourShortInfo) => {
    navigate(`${RouteNames.TOURS}/${item.id}`);
  };

  // обработка клика на сортировку по столбцам
  const handleSortByColumn = (sort: 'asc' | 'desc', column: string) => {
    if (column === 'id') {
      dispatch(setParamsSortById(sort));
      dispatch(setParamsPage(1));
    }
    if (column === 'title') {
      dispatch(setParamsSortByName(sort));
      dispatch(setParamsPage(1));
    }
  };

  // высчитываем кол-во страниц в зависимости от кол-ва элементов и числа элементов на странице
  useEffect(() => {
    setCountPages(Math.ceil(countAllTours / params.itemsPerPage));
  }, [countAllTours, params.itemsPerPage]);
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.table}>
        <div className={styles.rows}>
          {toursWithTripsColumns.map((column) => (
            <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
              {column.sorted ? (
                <CellWithSorting
                  column={column}
                  setColumnSort={handleSortByColumn}
                  sorting={
                    column.id === 'id' && params.sortById
                      ? params.sortById
                      : column.id === 'title' && params.sortByName
                      ? params.sortByName
                      : column.sorted
                  }
                />
              ) : (
                <span>{column.name}</span>
              )}
            </div>
          ))}
        </div>
        {isLoading === 'GET_ALL_TOURS' && (
          <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
        )}
        <div>
          {isLoading !== 'GET_ALL_TOURS' &&
            toursList.length > 0 &&
            toursList.map((tour) => (
              <div key={tour.id} className={styles.row} onClick={() => handleClickRow(tour)}>
                <div className={clsx(styles.cell, styles.id)}>{tour.id}</div>
                <div className={clsx(styles.cell, styles.title)}>
                  {tour.system_name ? tour.system_name : '-'}
                  <span>{tour.title}</span>
                </div>
                <div className={clsx(styles.cell, styles.bookingDate)}>
                  {tour.tour_duration_dates ? tour.tour_duration_dates : '-'}
                </div>
                <div className={clsx(styles.cell, styles.tourOperator)}>{tour.tourOperator}</div>
                <div className={clsx(styles.cell, styles.route)}>{tour.route}</div>
              </div>
            ))}
          {isSuccess === 'GET_ALL_TOURS' && toursList.length === 0 && (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
        </div>
        {isLoading !== 'GET_ALL_TOURS' && isSuccess === 'GET_ALL_TOURS' && toursList.length > 0 && (
          <div className={styles.tableFooter}>
            <div className={styles.count}>Всего записей — {countAllTours}</div>
            <Pagination
              total={countPages}
              value={params.currentPage}
              onChange={(val) => dispatch(setParamsPage(val))}
            />
          </div>
        )}
      </div>
    </div>
  );
};
