import { TableColumn } from './PartnersTable.types';

export const partnersColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
    sortingId: 'id',
  },
  {
    id: 'partner',
    name: 'Контрагент',
    sortingId: 'full_name',
  },
  {
    id: 'phone',
    name: 'Телефон',
  },
  {
    id: 'email',
    name: 'Почта',
  },
  {
    id: 'comment',
    name: 'Примечание',
  },
];

export const partnersJuricalColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
    sortingId: 'id',
  },
  {
    id: 'partner',
    name: 'Контрагент',
    sortingId: 'full_name',
  },
  {
    id: 'phone',
    name: 'Телефон',
  },
  {
    id: 'email',
    name: 'Почта',
  },
  {
    id: 'status',
    name: 'Статус',
  },
  {
    id: 'comment',
    name: 'Примечание',
  },
];
