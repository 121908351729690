import axios from 'axios';

import axiosInstance, { BASE_URL } from '../axiosInstance';
import { AuthResponse } from 'services/auth/AuthResponse.model';

export interface LoginData {
  email: string;
  password: string;
}

export const loginAPI = async ({ email, password }: LoginData) => {
  const response = await axiosInstance.post<AuthResponse>('/api/v1/token', {
    email: email,
    password: password,
  });
  return response;
};

export const checkAuthAPI = async () => {
  const response = await axios.post<AuthResponse>(BASE_URL + '/api/v1/token/refresh', {
    refresh_token: localStorage.getItem('refresh_token'),
    withCredentials: true,
  });

  return response;
};
