import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const GymIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.205 7.40197L12.6235 3.71572L13.8528 2.85547L16.4335 6.54172L15.205 7.40197ZM13.792 9.30697L12.502 7.46272L6.35802 11.7647L7.64802 13.6075L6.41952 14.4685L2.97852 9.55297L4.20702 8.69272L5.49702 10.5362L11.641 6.23422L10.351 4.39072L11.5795 3.53047L15.0205 8.44522L13.792 9.30622V9.30697ZM15.5733 5.31247L16.8018 4.45222L15.9415 3.22372L14.713 4.08397L15.5733 5.31247ZM5.37552 14.2847L2.79402 10.5985L1.56552 11.4587L4.14627 15.145L5.37552 14.2847ZM3.28602 13.9165L2.05752 14.7767L1.19727 13.5482L2.42577 12.688L3.28602 13.9165Z"
        fill="#1886E9"
      />
    </svg>
  );
};
