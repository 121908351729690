import { TableColumn } from './ToursTable.types';

export const toursWithTripsColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
    sorted: 'asc'
  },
  {
    id: 'title',
    name: 'Название тура',
    sorted: 'desc'
  },
  {
    id: 'bookingDate',
    name: 'Даты',
  },
  {
    id: 'tourOperator',
    name: 'Туроператор',
  },
  {
    id: 'route',
    name: 'Маршрут',
  },
];
