import React, { FC, forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { AccommodationProps } from './Accomodation.type';
import { Button } from 'ui/Button';
import { useForm } from '@mantine/form';

import { Select, NumberInput, TextInput, MultiSelect } from '@mantine/core';
import { BinIcon } from 'components/icons/BinIcon';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import styles from './Accomodation.module.scss';
import { filterByProps } from 'core/utils/getUnique';
import {
  Client1A1CIcon,
  Client1A2CIcon,
  Client1AIcon,
  Client2A1CIcon,
  Client2A2CIcon,
  Client2AIcon,
  Client3AIcon,
  DateIcon,
} from 'components/icons';
import { getDate } from 'core/utils/getDate';
import { listOfIdsTypePlacements } from 'core/constants/lists';
import { getPriceForNight } from '../constants';
import { getTourHotelsPlacements } from 'store/action-creators/tours';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  ind: string;
  type: string;
  category: string;
  value: string;
}

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ ind, type, category, value, ...others }: ItemProps, ref) => {
    const icon = () => {
      switch (value) {
        case '1взр':
          return <Client1AIcon width={18} height={18} fill="#263B53" />;
        case '2взр':
          return <Client2AIcon width={23} height={18} fill="#263B53" />;
        case '3взр':
          return <Client3AIcon width={29} height={18} fill="#263B53" />;
        case '1взр+1реб':
          return <Client1A1CIcon width={33} height={18} fill="#263B53" />;
        case '1взр+2реб':
          return <Client1A2CIcon width={46} height={18} fill="#263B53" />;
        case '2взр+1реб':
          return <Client2A1CIcon width={39} height={24} fill="#263B53" />;
        case '2взр+2реб':
          return <Client2A2CIcon width={53} height={24} fill="#263B53" />;
      }
    };
    return (
      <div ref={ref} {...others}>
        <div>{icon()}</div>
      </div>
    );
  }
);
SelectItem.displayName = 'SelectItem';

export const AccommodationEveryDay: FC<AccommodationProps> = ({
  tour,
  selectedAccomodation,
  setSelectedAccomodation,
  selectedTourists,
  setDirty,
  isCanEdit,
  columns,
  dates,
}) => {
  const dispatch = useAppDispatch();
  const isIndividual = useAppSelector((state) => state.orders.preSaveOrder.tourInfo?.individual);
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const { searchedHotels, hotelsPlacements } = useAppSelector((state) => state.tours);

  const individualTypes = ['1взр', '2взр', '3взр', '1взр+1реб', '1взр+2реб', '2взр+1реб', '2взр+2реб'];

  const formAccommodation = useForm({
    initialValues: {
      accommodations: selectedAccomodation ||
        preSaveOrder.tourInfo?.hotels || [
          {
            id: null,
            period: [null, null],
            boat_string: '',
            room_level_string: '',
            period_string: '',
            nights_count: '',
            room_number: '',
            address: '',
            hotel: '',
            type: '',
            category: '',
            accommodation: '',
            food_type: '',
            price: 0,
            tourist: [],
          },
        ],
    },
  });

  const [hotels, setHotels] = useState<string[]>([]);
  const [categories, setCategories] = useState<{ ind: string; type: string; period: string; value: string }[]>([]);
  const [types, setTypes] = useState<{ ind: string; value: string; period: string }[]>([]);

  const [accommodations, setAccommodations] = useState<
    { ind: string; type: string; category: string; period: string; value: string }[]
  >([]);
  const [periods, setPeriods] = useState<
    {
      ind: string;
      type: string;
      category: string;
      value: string;
    }[]
  >([]);
  const nightCounts = [
    { ind: 'price1', value: '1 ночь' },
    { ind: 'price2', value: '2 ночи' },
    { ind: 'price3', value: '3 ночи' },
    { ind: 'price4', value: '4 ночи' },
    { ind: 'price5', value: '5 ночей' },
    { ind: 'price6', value: '6 ночей' },
    { ind: 'price7', value: '7 ночей' },
  ];
  useEffect(() => {
    if (!preSaveOrder.id) {
      setSelectedAccomodation(formAccommodation.values.accommodations);
    }
  }, [formAccommodation.values.accommodations]);

  useEffect(() => {
    console.log('>> OrderForm Accomodation, SELECTED ACCOMODATION CHANGE: ', selectedAccomodation);
  }, [selectedAccomodation]);

  // Задаем изначальные отели
  useEffect(() => {
    const data = selectedAccomodation.map((el) => el.hotel);
    const hdata = searchedHotels.map((item) => {
      return item.name;
    });
    const aggdata = data.concat(hdata);
    setHotels(aggdata.filter((el, index, self) => self.indexOf(el) === index && el != ''));
  }, [searchedHotels.length, selectedAccomodation]);

  //  изначальные периоды
  useEffect(() => {
    const newArr: any = [];
    searchedHotels.forEach((item2) =>
      hotelsPlacements
        .filter(
          (el) =>
            Math.max(new Date(el.start).getTime(), new Date(dates[0]).getTime()) <=
            Math.min(new Date(el.ending).getTime(), new Date(dates[1]).getTime())
        )
        .forEach((item) =>
          newArr.push({
            ind: item2.name,
            type: item.room_type_string,
            category: item.room_category_string,
            value: `${getDate(new Date(item.start))}-${getDate(new Date(item.ending))}`,
          })
        )
    );

    if (newArr) {
      setPeriods(
        filterByProps(
          newArr.concat(
            selectedAccomodation.map((el) => ({
              ind: el.hotel,
              value: el.period_string,
              type: el.type,
              category: el.category,
            }))
          ),
          ['ind', 'value']
        ).filter((el) => el.value)
      );
    }
  }, [searchedHotels.length, hotelsPlacements.length, selectedAccomodation, formAccommodation.values.accommodations]);

  // Задаем изначальные типы
  useEffect(() => {
    const newArr: any = [];
    searchedHotels.forEach((item2) =>
      hotelsPlacements.forEach((item) =>
        newArr.push({
          ind: item2.name,
          period: `${getDate(new Date(item.start))}-${getDate(new Date(item.ending))}`,
          value: item.room_type_string,
        })
      )
    );

    if (newArr) {
      setTypes(
        newArr.concat(selectedAccomodation.map((el) => ({ ind: el.hotel, period: el.period_string, value: el.type })))
      );
    }
  }, [searchedHotels.length, hotelsPlacements.length, selectedAccomodation, formAccommodation.values.accommodations]);

  // Задаем изначальные категории
  useEffect(() => {
    const newArr: any = [];
    searchedHotels.forEach((item2) =>
      hotelsPlacements.forEach((item) =>
        newArr.push({
          ind: item2.name,
          type: item.room_type_string,
          period: `${getDate(new Date(item.start))}-${getDate(new Date(item.ending))}`,
          value: item.room_category_string,
        })
      )
    );

    if (newArr) {
      setCategories(
        newArr.concat(
          selectedAccomodation.map((el) => ({
            ind: el.hotel,
            value: el.category,
            type: el.type,
            period: el.period_string,
          }))
        )
      );
    }
  }, [searchedHotels.length, hotelsPlacements.length, selectedAccomodation, formAccommodation.values.accommodations]);
  // Задаем изначальные виды размещений
  useEffect(() => {
    const newAccomms: any = [];
    searchedHotels.forEach((item2) =>
      hotelsPlacements.forEach((item) => {
        const type = listOfIdsTypePlacements.find((i) => i.id.toString() === item.placement_type_string);
        return newAccomms.push({
          ind: item2.name,
          type: item.room_type_string,
          category: item.room_category_string,
          period: `${getDate(new Date(item.start))}-${getDate(new Date(item.ending))}`,
          value: type ? String(type.value) : '',
        });
      })
    );

    if (newAccomms) {
      setAccommodations(newAccomms);
    }
  }, [searchedHotels.length, hotelsPlacements.length, formAccommodation.values.accommodations]);

  const fields = formAccommodation.values.accommodations ? (
    formAccommodation.values.accommodations.map((item, index) => (
      <div key={index} className={styles.row}>
        <div className={clsx(styles.cell, styles.hotel)}>
          {isIndividual || (tour?.name != '' && searchedHotels.length === 0) ? (
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.hotel`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          ) : (
            <Select
              data={hotels}
              placeholder="Выбрать"
              {...formAccommodation.getInputProps(`accommodations.${index}.hotel`)}
              searchable
              creatable
              disabled={!isCanEdit}
              getCreateLabel={(query) => `+ Создать ${query}`}
              onCreate={(query) => {
                const item = { value: query, label: query };
                setHotels((current) => [...current, query]);
                return item;
              }}
              //при смене гостиницы обнуляет поля
              onChange={(value: string) => {
                const searchedHotel = searchedHotels.find((el) => el.name === value);

                dispatch(getTourHotelsPlacements({ tour: String(searchedHotel?.['@id']) }));
                formAccommodation.setFieldValue(`accommodations.${index}.type`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.category`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.period_string`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.nights_count`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.hotel`, value);
              }}
              className={styles.select}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none', display: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
                item: {
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          )}
        </div>
        <div className={clsx(styles.cell, styles.period)}>
          <Select
            data={periods
              .filter((item) => item.ind === formAccommodation.values.accommodations[index].hotel)

              .map((item) => item.value.toString())
              .filter((el, index, self) => self.indexOf(el) === index)}
            defaultValue={selectedAccomodation[index]?.period_string ? selectedAccomodation[index].period_string : ''}
            placeholder="Выбрать"
            {...formAccommodation.getInputProps(`accommodations.${index}.period_string`)}
            searchable
            disabled={!isCanEdit}
            //при смене периода обнуляет поля
            onChange={(value: string) => {
              formAccommodation.setFieldValue(`accommodations.${index}.type`, '');
              formAccommodation.setFieldValue(`accommodations.${index}.category`, '');
              formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
              formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
              formAccommodation.setFieldValue(`accommodations.${index}.period_string`, value);
            }}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <div className={clsx(styles.cell, styles.nightCount)}>
          <Select
            data={nightCounts.map((item) => item.value.toString())}
            defaultValue={selectedAccomodation[index]?.nights_count ? selectedAccomodation[index].nights_count : ''}
            placeholder="Выбрать"
            {...formAccommodation.getInputProps(`accommodations.${index}.nights_count`)}
            searchable
            disabled={!isCanEdit}
            onChange={(value: string) => {
              formAccommodation.setFieldValue(`accommodations.${index}.nights_count`, value);
              formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
              formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
            }}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <div className={clsx(styles.cell, styles.roomtype)}>
          {isIndividual || (tour?.name != '' && searchedHotels.length === 0) ? (
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.type`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          ) : (
            <Select
              data={types
                .filter((item) => item.ind === formAccommodation.values.accommodations[index].hotel)
                .filter((item) => item.period === formAccommodation.values.accommodations[index].period_string)
                .map((item) => item.value.toString())
                .filter((el, index, self) => self.indexOf(el) === index)}
              placeholder="Выбрать"
              disabled={!isCanEdit}
              {...formAccommodation.getInputProps(`accommodations.${index}.type`)}
              searchable
              creatable
              getCreateLabel={(query) => `+ Создать ${query}`}
              onCreate={(query) => {
                const item2 = { value: query, label: query };
                setTypes((current) => [...current, { ind: item.hotel, period: item.period_string, value: query }]);
                return item2;
              }}
              //при смене типа обнуляет поля
              onChange={(value: string) => {
                formAccommodation.setFieldValue(`accommodations.${index}.category`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.type`, value);
              }}
              className={styles.select}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none', display: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
                item: {
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          )}
        </div>
        <div className={clsx(styles.cell, styles.category)}>
          {isIndividual || (tour?.name != '' && searchedHotels.length === 0) ? (
            <TextInput
              {...formAccommodation.getInputProps(`accommodations.${index}.category`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          ) : (
            <Select
              data={categories
                .filter((item) => item.ind === formAccommodation.values.accommodations[index].hotel)
                .filter((item) => item.period === formAccommodation.values.accommodations[index].period_string)
                .filter((item) => item.type === formAccommodation.values.accommodations[index].type)
                .map((item) => item.value.toString())
                .filter((el, index, self) => self.indexOf(el) === index)}
              defaultValue={selectedAccomodation[index]?.category ? selectedAccomodation[index].category : ''}
              placeholder="Выбрать"
              {...formAccommodation.getInputProps(`accommodations.${index}.category`)}
              searchable
              disabled={!isCanEdit}
              creatable
              getCreateLabel={(query) => `+ Создать ${query}`}
              onCreate={(query) => {
                const item2 = { value: query, label: query };
                setCategories((current) => [
                  ...current,
                  { ind: item.hotel, type: item.type, period: item.period_string, value: query },
                ]);
                return item2;
              }}
              //при смене категории обнуляет поля
              onChange={(value: string) => {
                formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.price`, '');
                formAccommodation.setFieldValue(`accommodations.${index}.category`, value);
              }}
              className={styles.select}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none', display: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
                item: {
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          )}
        </div>
        <div className={clsx(styles.cell, styles.accomodationType)}>
          {isIndividual || (tour?.name != '' && searchedHotels.length === 0) ? (
            <Select
              data={individualTypes}
              defaultValue={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
              value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
              placeholder="Выбрать"
              itemComponent={SelectItem}
              disabled={!isCanEdit}
              {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
              searchable
              creatable
              getCreateLabel={(query) => `+ Создать ${query}`}
              onChange={(value: string) => {
                formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
              }}
              className={styles.select}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none', display: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
                item: {
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          ) : (
            <>
              {accommodations.filter(
                (item) =>
                  item.ind === formAccommodation.values.accommodations[index].hotel &&
                  item.period === formAccommodation.values.accommodations[index].period_string &&
                  item.category === formAccommodation.values.accommodations[index].category &&
                  item.type === formAccommodation.values.accommodations[index].type
              ).length > 0 ? (
                <Select
                  data={accommodations
                    .filter(
                      (item) =>
                        item.ind === formAccommodation.values.accommodations[index].hotel &&
                        item.period === formAccommodation.values.accommodations[index].period_string &&
                        item.category === formAccommodation.values.accommodations[index].category &&
                        item.type === formAccommodation.values.accommodations[index].type
                    )
                    .map((item) => item.value?.toString() ?? '')}
                  disabled={!isCanEdit}
                  defaultValue={
                    selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                  }
                  value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                  placeholder="Выбрать"
                  itemComponent={SelectItem}
                  {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Создать ${query}`}
                  onChange={(value: string) => {
                    const currentHotel = searchedHotels?.find(
                      (el) => el.name === formAccommodation.values.accommodations[index].hotel
                    );
                    const currentPlacement = hotelsPlacements.find(
                      (el) =>
                        `${getDate(new Date(el.start))}-${getDate(new Date(el.ending))}` ===
                          formAccommodation.values.accommodations[index].period_string &&
                        el.room_type_string === formAccommodation.values.accommodations[index].type &&
                        el.room_category_string === formAccommodation.values.accommodations[index].category &&
                        listOfIdsTypePlacements.find((i) => i.id.toString() === el.placement_type_string)?.value ===
                          value
                    );
                    const nightCount = nightCounts.find(
                      (el) => el.value === formAccommodation.values.accommodations[index].nights_count.toString()
                    );
                    if (currentPlacement && nightCount) {
                      const price = getPriceForNight(currentPlacement, nightCount.ind);
                      formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                      formAccommodation.setFieldValue(`accommodations.${index}.price`, parseInt(price));
                      formAccommodation.setFieldValue(
                        `accommodations.${index}.food_type`,
                        currentPlacement.food_type_string
                      );
                    }
                  }}
                  className={styles.select}
                  rightSectionWidth={30}
                  styles={{
                    rightSection: { pointerEvents: 'none', display: 'none' },
                    input: {
                      border: 'none',
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                    item: {
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                  }}
                />
              ) : (
                <Select
                  data={individualTypes}
                  defaultValue={
                    selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''
                  }
                  value={selectedAccomodation[index]?.accommodation ? selectedAccomodation[index].accommodation : ''}
                  placeholder="Выбрать"
                  itemComponent={SelectItem}
                  disabled={!isCanEdit}
                  {...formAccommodation.getInputProps(`accommodations.${index}.accommodation`)}
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Создать ${query}`}
                  onChange={(value: string) => {
                    formAccommodation.setFieldValue(`accommodations.${index}.accommodation`, value);
                  }}
                  className={styles.select}
                  rightSectionWidth={30}
                  styles={{
                    rightSection: { pointerEvents: 'none', display: 'none' },
                    input: {
                      border: 'none',
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                    item: {
                      fontFamily: 'Museo Sans Cyrl',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '15px',
                      lineHeight: '19px',
                      color: '#263B53',
                    },
                  }}
                />
              )}
            </>
          )}
        </div>
        <div className={clsx(styles.cell, styles.price)}>
          {isIndividual || (tour?.name != '' && searchedHotels.length === 0) ? (
            <NumberInput
              hideControls
              {...formAccommodation.getInputProps(`accommodations.${index}.price`)}
              disabled={!isCanEdit}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          ) : (
            <NumberInput
              hideControls
              defaultValue={formAccommodation.values.accommodations[index].price}
              disabled={!isCanEdit}
              value={formAccommodation.values.accommodations[index].price}
              {...formAccommodation.getInputProps(`accommodations.${index}.price`)}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          )}
        </div>
        <div className={clsx(styles.cell, styles.tourist)}>
          <MultiSelect
            data={selectedTourists.map((item) => item.name)}
            disabled={!isCanEdit}
            placeholder="Выбрать"
            {...formAccommodation.getInputProps(`accommodations.${index}.tourist`)}
            searchable
            creatable
            getCreateLabel={(query) => `+ Создать ${query}`}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <span
          className={styles.delete}
          onClick={() => {
            if (!isCanEdit) {
              return;
            }
            formAccommodation.removeListItem('accommodations', index);
          }}
        >
          <BinIcon width={10} height={11} fill="#5B6572" />
        </span>
      </div>
    ))
  ) : (
    <></>
  );

  return (
    <div className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {columns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div></div>
          <div>{fields}</div>
        </div>
      </div>
      <div className={styles.btnRow}>
        {preSaveOrder.id && (
          <Button
            transparent={true}
            label="Сохранить все размещение"
            className={styles.saveRow}
            disabled={!isCanEdit}
            onClick={() => {
              setSelectedAccomodation(formAccommodation.values.accommodations);
              setDirty();
            }}
          />
        )}
        <Button
          transparent={true}
          label="+ Добавить строку"
          className={styles.addRow}
          disabled={!isCanEdit}
          onClick={() =>
            formAccommodation.insertListItem('accommodations', {
              id: null,
              period: [null, null],
              boat_string: '',
              room_level_string: '',
              period_string: '',
              nights_count: '',
              room_number: '',
              address: '',
              hotel: '',
              type: '',
              category: '',
              accommodation: '',
              food_type: '',
              price: 0,
              tourist: [],
            })
          }
        />
      </div>
    </div>
  );
};
