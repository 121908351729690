import React, { FC } from 'react';


export const DownloadIcon: FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={16}
      fill='none'
    >
      <path
        fill='#5D96C1'
        d='M9.529 10.739c.26.26.682.26.942 0l4.242-4.241a.667.667 0 0 0-.943-.943L10 9.325l-3.77-3.77a.666.666 0 0 0-.943.943l4.242 4.24ZM9.334.232v10.036h1.332V.232H9.334Z'
      />
      <path
        stroke='#5D96C1'
        strokeWidth={2.372}
        d='M2 8.768v4.418c0 .874.708 1.582 1.581 1.582H16.42c.873 0 1.581-.708 1.581-1.582V8.768'
      />
    </svg>
  );
};
