import axiosInstance from 'services/axiosInstance';

export interface AddOrderLink {
  payment_type: string;
  payment_method: string;
  amount: number;
  active_before: string;
  tour_order: string;
  email: string;
  contact: string;
}

export interface PlannedPayment {
  tour_order: string;
  type: string;
  summ: string;
  plan_date: string;
  exchange_rate: string;
  summ_rub: string;
}
export interface RealPayment {
  tour_order: string;
  method: string;
  summ: string;
  date: string | null;
  exchange_rate: string;
  summ_rub: string;
}
export const addOrderPaymentLinkAPI = async (data?: AddOrderLink) => {
  const response = await axiosInstance.post<any>('/api/v1/payment-links/generate', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
export const getPaymentLinks = async (id: number) => {
  const response = await axiosInstance.get<any>(`/api/v1/payment-links`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: 1,
      itemsPerPage: 100,
      tour_order: id.toString(),
    },
  });
  return response;
};

export const getPaymentLinksFinish = async (id: string) => {
  const response = await axiosInstance.get<any>(`/api/v1/payment-links/${id}/finish`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const getPaymentLinksCancel = async (id: string) => {
  const response = await axiosInstance.get<any>(`/api/v1/payment-links/${id}/cancel`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const addPlannedPayment = async (data?: PlannedPayment) => {
  const response = await axiosInstance.post<any>('/api/v1/planned-payments', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
export const addRealPayment = async (data?: RealPayment) => {
  const response = await axiosInstance.post<any>('/api/v1/real-payments', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
export const deleteRealPayment = async (id: string) => {
  const response = await axiosInstance.delete<any>(`/api/v1/real-payments/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const deletePlannedPayment = async (id: string) => {
  const response = await axiosInstance.delete<any>(`/api/v1/planned-payments/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
