import React, { FC, useEffect, useRef, useState } from 'react';
import { Loader } from '@mantine/core';
import { Icon, SelectType } from './Select.type';
import styles from './Select.module.scss';
import clsx from 'clsx';
import { useRefCloseOut } from 'hooks/useRefCloseOut';

export const SelectForElements: FC<SelectType & { isWithReset?: boolean; onBlur?: () => void; disabled?: boolean }> = ({
  label,
  data,
  setSelect,
  onDirty,
  className,
  selected,
  onBlur = () => void 1,
  error,
  icon = null,
  isWithReset = true,
  uploaded = true,
  disabled = false,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<Icon | undefined>(selected);
  const selectRef = useRef() as React.MutableRefObject<HTMLUListElement>;
  const btnSelectRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [dirty, setDirty] = useState(false);

  const handleClickHeader = () => {
    !disabled && setOpened((prev) => !prev);
  };

  const handleClickItem = (item: Icon) => {
    const old_value = value;
    setValue(item);
    onBlur();

    if (old_value !== item) {
      setDirty(true); // помечаем измененным, если задано другое значение
    }
    setOpened(false);
  };

  useEffect(() => {
    if (dirty) {
      if (onDirty && value) onDirty(value?.value);
      setDirty(false);
    }
  }, [dirty]);

  useEffect(() => {
    if (value && setSelect) {
      setSelect(value.value);
    }
  }, [value]);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  useRefCloseOut(selectRef, setOpened, btnSelectRef, isWithReset);

  return (
    <article className={clsx(styles.select, className)}>
      {label && <label className={styles.label}>{label}</label>}

      <div
        className={clsx(
          styles.header,
          opened && styles.headerOpened,
          error && styles.error,
          disabled && styles.disabled
        )}
        onClick={handleClickHeader}
        ref={btnSelectRef}
        {...props}
      >
        {icon ? <span className={styles.iconWrapper}>{icon}</span> : null}
        {value?.icon}
      </div>
      {opened && (
        <ul className={styles.list} ref={selectRef}>
          {uploaded ? (
            data.map((item, key) => (
              <li key={key} onClick={() => handleClickItem(item)} className={styles.item}>
                {item.icon}
              </li>
            ))
          ) : (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
          )}
        </ul>
      )}
    </article>
  );
};
