import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { AdditinalServicesProps } from './AdditionalServicest.type';
import { Button } from 'ui/Button';
import { additionalServecesColumns } from './data';
import { useForm } from '@mantine/form';

import styles from './AdditionalServices.module.scss';

import { Select, NumberInput, TextInput } from '@mantine/core';
import { BinIcon } from 'components/icons/BinIcon';
import { useAppSelector } from 'store/hooks';

export const AdditionalServices: FC<AdditinalServicesProps> = ({
  tour,
  selectedAdditionalServices,
  setSelectedAdditionalServices,
  setDirty,
  isCanEdit,
}) => {
  const { preSaveOrder } = useAppSelector((state) => state.orders);

  const servicesData =
    tour?.services_array && tour?.services_array.length
      ? selectedAdditionalServices.length > 0
        ? tour.services_array
            .map((item) => ({
              name: item.name,
              description: item.description,
              price: item.price,
            }))
            .concat(
              selectedAdditionalServices
                .map((item) => ({
                  name: item.service,
                  description: item.description,
                  price: item.price,
                }))
                .filter((el) => !tour.services_array.find((item) => item.name === el.name))
            )
        : tour.services_array.map((item) => ({
            name: item.name,
            description: item.description,
            price: item.price,
          }))
      : selectedAdditionalServices.map((item) => ({
          name: item.service,
          description: item.description,
          price: item.price,
        }));

  const initialPrice =
    tour?.services_array && tour?.services_array.length
      ? tour.services_array
          .map((item) => ({
            ind: item.name,
            value: item.price,
          }))
          .concat(
            selectedAdditionalServices.map((item) => ({
              ind: item.service,
              value: item.price,
            }))
            // .filter((el) => !tour.services_array.find((item) => Number(item.price) === Number(el.value)))
          )
      : [];
  const [service, setService] = useState<string[]>(servicesData.map((item) => item.name));
  const [price, setPrice] = useState<{ ind: string; value: string }[]>(initialPrice);

  // Задаем изначальные
  useEffect(() => {
    setService(servicesData.map((item) => item.name));
  }, [servicesData.length]);

  // Изменение выбора цен исходя из выбранной скидки
  useEffect(() => {
    if (price.length > 0) {
      return;
    }
    setPrice(
      servicesData
        .map((item) => ({
          ind: item.name,
          value: item.price,
        }))
        .concat(
          selectedAdditionalServices.map((item) => ({
            ind: item.service,
            value: item.price,
          }))
          // .filter((el) => !price.find((item) => Number(item.value) === Number(el.value)))
        )
    );
  }, [servicesData.length, selectedAdditionalServices]);

  const form = useForm({
    initialValues: {
      services: selectedAdditionalServices ||
        preSaveOrder.tourInfo?.extraService || [
          { number: 1, service: '', price: '', key: 1, quantity: 1, sum: 0, description: '' },
        ],
    },
  });

  useEffect(() => {
    if (!preSaveOrder.id) {
      setSelectedAdditionalServices(form.values.services);
    }
  }, [form.values.services]);

  const fields = form.values.services ? (
    form.values.services.map((item, index) => (
      <div key={index} className={styles.row}>
        <div className={clsx(styles.cell, styles.id)}>{index + 1}</div>
        <div className={clsx(styles.cell, styles.service)}>
          <Select
            data={service}
            placeholder="Выбрать"
            {...form.getInputProps(`services.${index}.service`)}
            searchable
            creatable
            defaultValue={form.values.services[index].service}
            getCreateLabel={(query) => `+ Создать ${query}`}
            onCreate={(query) => {
              setService((current) => [...current, query]);
              return query;
            }}
            disabled={!isCanEdit}
            //при смене скидки обнуляет стоимость
            onChange={(value: string) => {
              // form.setFieldValue(`services.${index}.price`, '');
              form.setFieldValue(`services.${index}.service`, value);
            }}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <div className={clsx(styles.cell, styles.price)}>
          <Select
            data={price
              .filter((item) => item.ind === form.values.services[index].service)
              .map((item) => item.value.toString())}
            placeholder="Выбрать"
            {...form.getInputProps(`services.${index}.price`)}
            searchable
            creatable
            getCreateLabel={(query) => `+ Создать ${query}`}
            onCreate={(query) => {
              setPrice((current) => [...current, { ind: item.service, value: query }]);

              return { ind: item.service, value: query };
            }}
            disabled={!isCanEdit}
            className={styles.select}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none', display: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
              item: {
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        <div className={clsx(styles.cell, styles.quantity)}>
          <NumberInput
            variant="unstyled"
            disabled={!isCanEdit}
            styles={{
              rightSection: { pointerEvents: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
            {...form.getInputProps(`services.${index}.quantity`)}
          />
        </div>
        <div className={styles.sum}>{+form.values.services[index].price * +form.values.services[index].quantity}</div>
        <div className={styles.description}>
          {' '}
          <TextInput
            disabled={!isCanEdit}
            variant="unstyled"
            styles={{
              rightSection: { pointerEvents: 'none' },
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
            {...form.getInputProps(`services.${index}.description`)}
          />
        </div>

        <span
          className={styles.delete}
          onClick={() => {
            if (!isCanEdit) {
              return;
            }
            const currentItem = price.find((el) => Number(el.value) === Number(item.price));
            setPrice(price.filter((el) => el.value != currentItem?.value));
            form.removeListItem('services', index);
            form.values.services.forEach((it, ind) => {
              if (ind < form.values.services.length - 1) {
                form.setFieldValue(`services.${ind}.number`, ind + 1);
              }
            });
            setDirty();
          }}
        >
          <BinIcon width={10} height={11} fill="#5B6572" />
        </span>
      </div>
    ))
  ) : (
    <></>
  );

  return (
    <div className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {additionalServecesColumns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div>{fields}</div>
        </div>
      </div>

      <div className={styles.btnRow}>
        {preSaveOrder.id && (
          <Button
            transparent={true}
            label="Сохранить все доп.услуги"
            className={styles.saveRow}
            disabled={!isCanEdit}
            onClick={() => {
              setSelectedAdditionalServices(form.values.services);
              setDirty();
            }}
          />
        )}
        <Button
          transparent={true}
          label="+ Добавить строку"
          className={styles.addRow}
          disabled={!isCanEdit}
          onClick={() =>
            form.insertListItem('services', {
              number: form.values.services.length + 1,
              service: '',
              price: '',
              quantity: 1,
              sum: 0,
              description: '',
              key: form.values.services.length + 1,
            })
          }
        />
      </div>
    </div>
  );
};
