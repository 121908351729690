import React, { FC } from 'react';
import clsx from 'clsx';

import styles from './Input.module.scss';
import { InputType } from 'ui/Input/Input.types';
import { getCurrencyIconToUI } from 'core/utils/getCurrency';

export const InputRate: FC<InputType> = ({ type, name, rate = 'RUB', label, placeholder, className, ...props }) => {
  return (
    <div className={clsx(styles.block, className)}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}

      <div
        //TODO write complete prop types
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={clsx(styles.inputWrapper, props.disabled ? styles.inputWrapperDisabled : undefined)}
      >
        <div className={styles.rateBlock}>{getCurrencyIconToUI(rate)}</div>
        <input id={name} type={type} className={styles.input} placeholder={placeholder} {...props} />
      </div>
    </div>
  );
};
