import React, { FC, useState } from 'react';
import styles from './AlbumTable.module.scss';
import clsx from 'clsx';
import { Album } from 'services/gallery/galleryApi.service';
import { getDate } from 'core/utils/getDate';
import { RemoveIcon } from 'components/icons';
import { ArrowDownIcon } from 'components/icons/ArrowDownIcon';
import { useAppDispatch } from 'store/hooks';
import { deleteAlbum, deleteAlbumWithPhoto } from 'store/action-creators/gallery';
import { ModalConfirm } from 'pages/Tours/components/TourForm/ModalConfirm';

type AlbumProps = {
  album: Album;
  handleClickRow: (id: number) => void;
};

export const AlbumItem: FC<AlbumProps> = ({ album, handleClickRow }) => {
  const [opened, setOpened] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  return (
    <>
      <ModalConfirm
        isOpen={isModalOpen}
        text={`Вы хотите удалить безвозвратно только альбом или альбом вместе с фотографиями?`}
        onClose={() => setIsModalOpen(false)}
        labelOne="Альбом"
        labelTwo="Альбом + фото"
        handleYes={() => {
          if (album?.id) {
            dispatch(deleteAlbum(Number(album?.id)));
            setIsModalOpen(false);
          }
        }}
        handleNo={() => {
          if (album?.id) {
            dispatch(deleteAlbumWithPhoto(Number(album?.id)));
            setIsModalOpen(false);
          }
        }}
      />
      <div key={album.id} className={styles.row} onClick={() => handleClickRow(Number(album.id))}>
        <div className={styles.header}>
          <div className={clsx(styles.cell, styles.id)}>{album.id}</div>
          <div className={clsx(styles.cell, styles.partner)}>{album.name}</div>
          <div className={clsx(styles.cell, styles.bookingDate)}>{album.photos_count}</div>
          <div className={clsx(styles.cell, styles.price)}>{getDate(new Date(album.created_at))}</div>
          <div className={clsx(styles.cell, styles.tourTitle)}>
            {album.album_tags.map((el) => `#${el.name}`).join(',')}
          </div>
          <div className={clsx(styles.cell, styles.iconGroup)}>
            <span
              className={styles.remove}
              onClick={(e) => {
                e.stopPropagation();
                console.log(album.id);
                setIsModalOpen(true);
              }}
            >
              <RemoveIcon width={22} height={22} fill="#F77B60" />
            </span>
            <span
              className={clsx(styles.remove, opened && styles.opened)}
              onClick={(e) => {
                e.stopPropagation();
                console.log(album.id);
                setOpened(!opened);
              }}
            >
              <ArrowDownIcon width={22} height={16} fill="#5D96C1" />
            </span>
          </div>
        </div>
        {opened && (
          <div className={clsx(styles.content)}>
            {album.photos.map((photo) => (
              <div key={photo.id} className={styles.photo}>
                <img
                  src={
                    photo.thumbnail_name
                      ? `${process.env.REACT_APP_BASE_API_URL}/uploads/thumbnails/${photo.thumbnail_name}`
                      : `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${photo.name}`
                  }
                  alt={photo.alt}
                />
                <p>{photo.title && photo.title.length > 0 ? `${photo.title.substring(0, 18)}...` : photo.title}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
