import React, { FC, useState } from 'react';
import styles from './AlbumList.module.scss';
import clsx from 'clsx';
import { Album } from 'services/gallery/galleryApi.service';
import { getDate } from 'core/utils/getDate';
import { ArrowDownIcon } from 'components/icons/ArrowDownIcon';

type AlbumProps = {
  album: Album;
  handleClickRow: (id: number) => void;
  selected: boolean;
};

export const AlbumListItem: FC<AlbumProps> = ({ album, handleClickRow, selected }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <div
        key={album.id}
        className={clsx(styles.row, selected && styles.selected)}
        onClick={() => handleClickRow(Number(album.id))}
      >
        <div className={styles.header}>
          <div className={clsx(styles.cell, styles.id)}>{album.id}</div>
          <div className={clsx(styles.cell, styles.partner)}>{album.name}</div>
          <div className={clsx(styles.cell, styles.bookingDate)}>{album.photos_count}</div>
          <div className={clsx(styles.cell, styles.price)}>{getDate(new Date(album.created_at))}</div>
          <div className={clsx(styles.cell, styles.tourTitle)}>
            {album.album_tags.map((el) => `#${el.name}`).join(',')}
          </div>
          <div className={clsx(styles.cell, styles.iconGroup)}>
            <span
              className={clsx(styles.remove, opened && styles.opened)}
              onClick={(e) => {
                e.stopPropagation();
                console.log(album.id);
                setOpened(!opened);
              }}
            >
              <ArrowDownIcon width={22} height={16} fill="#5D96C1" />
            </span>
          </div>
        </div>
        {opened && (
          <div className={clsx(styles.photoContent)}>
            {album.photos.map((photo) => (
              <div key={photo.id} className={styles.photo}>
                <img
                  src={
                    photo.thumbnail_name
                      ? `${process.env.REACT_APP_BASE_API_URL}/uploads/thumbnails/${photo.thumbnail_name}`
                      : `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${photo.name}`
                  }
                  alt={photo.alt}
                />
                <p>{photo.title && photo.title.length > 0 ? `${photo.title.substring(0, 18)}...` : photo.title}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
