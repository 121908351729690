import { TourTabs } from 'core/constants/enums';
import { ToursState } from './ToursTypes';

const initialState: ToursState = {
  isLoading: null,
  isSuccess: null,
  isError: null,
  toursForSearch: [],
  tours: [],
  realTours: [],
  countTours: 0,
  roomTypes: [],
  allTours: [],
  countAllTours: 0,
  searchedHotels: [],
  hotelsPlacements: [],
  tourDateList: [],
  tourDateNight: [],
  tourDatePlacements: [],
  currentTour: null,
  isEditedTour: false,
  isClonedTrip: false,

  tourOperators: [],
  countries: [],
  guides: [],
  cities: [],
  transports: [],
  tourTypes: [],
  tourPeriods: [],
  directions: [],
  tags: [],
  main_tag: null,

  params: {
    currentPage: 1,
    search: null,
    manager: null,
    tour_directions: null,
    status: TourTabs.ALL,
    dateFrom: null,
    dateTo: null,
    sortById: null,
    sortByName: null,
    itemsPerPage: 50,
    tour_type: 'tour',
  },

  preSaveTour: {
    id: '',
    name: '',
    template: '',
    dateCreate: null,
    title: '',
    dateFrom: null,
    dateTo: null,
    is_visible: false,
    // tourOperator: '',
    route: '',
    photo_album: {
      id: '',
      name: '',
      description: '',
      created_at: '',
      updated_at: '',
      photos: [],
      album_tags: [],
      used_count: 0,
      photos_count: 0,
      used_tours: [],
    },
    descriptionInfo: {
      booking_without_prepay: false,
      duration: 0,
      tourOperator: null,
      discount: '',
      shortDescription: '',
      fullDescription: '',
      tourProgram: null,
      priceIncludes: '',
      priceNoIncludes: '',
      system_name: '',
      additionalServices: [],
      additionalDiscounts: [],
    },

    tourParameters: {
      tourManager: null,
      guide: [],
      country: [],
      city: [],
      transport: [],
      tourType: [],
      travelTime: [],
      direction: [],
      tags: [],
    },

    tripsInfo: null,
    photos: [],
  },

  selectedTour: {
    booking_without_prepay: false,
    is_visible: false,
    '@context': '',
    '@id': '',
    '@type': '',
    id: 0,
    created_at: null,
    updated_at: null,
    currency: '',
    commission: '',
    name: '',
    template: '',
    partners_site: '',
    system_name: '',
    short_description: '',
    document_template_path: '',
    filter_price: '',
    additional_info: '',
    description: '',
    monday: '',
    monday_title: '',
    tuesday: '',
    tuesday_title: '',
    wednesday: '',
    wednesday_title: '',
    thursday: '',
    thursday_title: '',
    friday: '',
    friday_title: '',
    saturday: '',
    saturday_title: '',
    sunday: '',
    sunday_title: '',
    countries_array: [],
    cities_array: [],
    transports_array: [],
    hotels: [],
    hotel_rooms: [],
    labels_array: [],
    tags_array: [],
    main_tag: null,
    periods_array: [],
    tour_dates: [],
    tour_hotels: [],
    photos: [],
    settings: {
      tourGroup: Array.from({ length: 7 }, () => {
        return { name: '' };
      }),
      photoOrders: [],
    },
    photo_album: {
      id: '',
      name: '',
      description: '',
      created_at: '',
      updated_at: '',
      photos: [],
      album_tags: [],
      used_count: 0,
      photos_count: 0,
      used_tours: [],
    },
    status: {
      '@context': '',
      '@id': '',
      '@type': '',
    },
    priority: 1,
    managers: [
      {
        '@context': '',
        '@id': '',
        '@type': '',
        id: 0,
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
      },
    ],
    duration: 0,
    services: '',
    not_in_price: '',
    tour_days: [],
    services_array: [],
    sales_array: [],
    // tour_sales: [],
    tour_operator_contact: {
      '@context': '',
      '@id': '',
      '@type': '',
      id: 0,
      full_name: '',
      requisites: [],
    },
    guides_array: [],
    sale: '',
    sale_percent: '',
    price: '0',
    tour_group_title: '',
    tour_group_placements: [],
    eightday: '',
    eightday_title: '',
  },

  isResetPreSaveTour: false,

  errorsValidateTour: {
    title: false,
    operator: false,
    tripDate: false,
    tripStatus: false,
  },

  idNewTour: 0,
};

export { initialState };
