import React, { FC, useEffect, useRef, useState } from 'react';

import { Document } from 'components/Document';
import { listOfDocuments } from 'core/constants/lists';
import { useRefCloseOut } from 'hooks/useRefCloseOut';
import { DocumentItemProps, DocumentsTabsProps } from './DocumentsTab.types';
import { Documents } from 'core/constants/documents';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import styles from './DocumentsTab.module.scss';
import { setMainDocument } from 'store/entities/partners/partnersSlice';

export const DocumentsTab: FC<DocumentsTabsProps> = ({ documents, markDirty }) => {
  const { preSavePrivatePerson } = useAppSelector((state) => state.partners);
  const [listOpen, setListOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentItemProps>();
  const [documentNames, setDocumentNames] = useState(listOfDocuments);
  const [viewPassport, setViewPassport] = useState(false);
  const [viewInterPassport, setViewInterPassport] = useState(false);
  const [viewBirthCertificate, setViewBirthCertificate] = useState(false);
  const [viewForeignDocument, setViewForeignDocument] = useState(false);
  const listRef = useRef() as React.MutableRefObject<HTMLUListElement>;
  const btnOpenedListRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const dispatch = useAppDispatch();
  console.log(documents, preSavePrivatePerson.documents);
  const [isReset, setIsReset] = useState<boolean>(true);
  useEffect(() => {
    if (isReset) {
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (!isReset) return;
    if (preSavePrivatePerson.documents.passport || documents?.passport) {
      setViewPassport(true);
    }

    if (preSavePrivatePerson.documents.interPassport || documents?.interPassport) {
      setViewInterPassport(true);
    }

    if (preSavePrivatePerson.documents.birthCertificate || documents?.birthCertificate) {
      setViewBirthCertificate(true);
    }

    if (preSavePrivatePerson.documents.foreignDocument || documents?.foreignDocument) {
      setViewForeignDocument(true);
    }
  }, [isReset]);

  useEffect(() => {
    const viewDocuments: string[] = [];
    if (viewPassport) {
      viewDocuments.push(Documents.PASSPORT_RF);
    }
    if (viewInterPassport) {
      viewDocuments.push(Documents.INTERNATIONAL_PASSPORT_RF);
    }
    if (viewBirthCertificate) {
      viewDocuments.push(Documents.BIRTH_CERTIFICATE);
    }
    if (viewForeignDocument) {
      viewDocuments.push(Documents.FOREIGN_DOCUMENT);
    }

    setDocumentNames(documentNames.filter((doc) => !viewDocuments.includes(doc.name)));
  }, [viewPassport, viewInterPassport, viewBirthCertificate, viewForeignDocument]);

  const handleAddDoc = () => {
    setListOpen((prev) => !prev);
  };

  //добавление нового документа
  const setViewDocument = (document: DocumentItemProps) => {
    setSelectedDocument(document);
    setListOpen(false);
    if (document.name === Documents.PASSPORT_RF) {
      setViewPassport(true);
      if (!preSavePrivatePerson.documents.mainDocument) {
        dispatch(setMainDocument('contact_passport'));
      }
    }
    if (document.name === Documents.INTERNATIONAL_PASSPORT_RF) {
      setViewInterPassport(true);
      if (!preSavePrivatePerson.documents.mainDocument) {
        dispatch(setMainDocument('contact_foreign_passport'));
      }
    }
    if (document.name === Documents.BIRTH_CERTIFICATE) {
      setViewBirthCertificate(true);
      if (!preSavePrivatePerson.documents.mainDocument) {
        dispatch(setMainDocument('contact_birth_certificate'));
      }
    }
    if (document.name === Documents.FOREIGN_DOCUMENT) {
      setViewForeignDocument(true);
      if (!preSavePrivatePerson.documents.mainDocument) {
        dispatch(setMainDocument('contact_foreign_document'));
      }
    }
  };

  useRefCloseOut(listRef, setListOpen, btnOpenedListRef, false);

  useEffect(() => {
    if (documents?.mainDocument) {
      dispatch(
        setMainDocument(
          preSavePrivatePerson.documents.mainDocument
            ? preSavePrivatePerson.documents.mainDocument
            : documents.mainDocument
        )
      );
      return;
    } else if (viewPassport) {
      dispatch(setMainDocument('contact_passport'));
      return;
    } else if (viewInterPassport) {
      dispatch(setMainDocument('contact_foreign_passport'));
      return;
    } else if (viewBirthCertificate) {
      dispatch(setMainDocument('contact_birth_certificate'));
      return;
    } else if (viewForeignDocument) {
      dispatch(setMainDocument('contact_foreign_document'));
      return;
    }
  }, [
    preSavePrivatePerson.documents.mainDocument,
    documents?.mainDocument,
    viewPassport,
    viewBirthCertificate,
    viewForeignDocument,
    viewForeignDocument,
    viewInterPassport,
  ]);
  return (
    <section className={styles.documents}>
      <div className={styles.top}>
        {documentNames.length !== 0 && (
          <>
            <h2>Добавить документ</h2>
            <button className={styles.add} onClick={handleAddDoc} ref={btnOpenedListRef}>
              +
            </button>
          </>
        )}
      </div>
      {listOpen && (
        <ul className={styles.list} ref={listRef}>
          {documentNames.map((doc) => (
            <li key={doc.id} className={styles.item} onClick={() => setViewDocument(doc)}>
              {doc.name}
            </li>
          ))}
        </ul>
      )}

      <div className={styles.content}>
        {viewPassport && (
          <Document
            type={Documents.PASSPORT_RF}
            data={documents?.passport}
            markDirty={markDirty}
            onRemove={() => setViewPassport(false)}
            isOpened={selectedDocument?.name === Documents.PASSPORT_RF ? true : false}
          />
        )}
        {viewInterPassport && (
          <Document
            type={Documents.INTERNATIONAL_PASSPORT_RF}
            data={documents?.interPassport}
            markDirty={markDirty}
            onRemove={() => setViewInterPassport(false)}
            isOpened={selectedDocument?.name === Documents.INTERNATIONAL_PASSPORT_RF ? true : false}
          />
        )}
        {viewBirthCertificate && (
          <Document
            type={Documents.BIRTH_CERTIFICATE}
            data={documents?.birthCertificate}
            markDirty={markDirty}
            onRemove={() => setViewBirthCertificate(false)}
            isOpened={selectedDocument?.name === Documents.BIRTH_CERTIFICATE ? true : false}
          />
        )}
        {viewForeignDocument && (
          <Document
            type={Documents.FOREIGN_DOCUMENT}
            data={documents?.foreignDocument}
            markDirty={markDirty}
            onRemove={() => setViewForeignDocument(false)}
            isOpened={selectedDocument?.name === Documents.FOREIGN_DOCUMENT ? true : false}
          />
        )}
      </div>
    </section>
  );
};
