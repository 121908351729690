import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { ExtraNightType, ExtraNightProps } from './ExtraNight.type';
import { Button } from 'ui/Button';
import { extraNightColumns } from './data';
import { useForm } from '@mantine/form';
import { DatePickerInput, DatesProvider } from '@mantine/dates';
import { Select, NumberInput, TextInput, MultiSelect } from '@mantine/core';
import styles from './ExtraNight.module.scss';

import { BinIcon } from 'components/icons/BinIcon';

import { DateIcon } from 'components/icons';
import { useAppSelector } from 'store/hooks';
import { filterByProps } from 'core/utils/getUnique';
import { SelectItem } from '../Accomodation';
import { getTemplateToUI } from 'core/utils/getTemplate';
import { ToursTemplates } from 'core/constants/enums';
import { datediff } from 'core/utils/getDate';

export const ExtraNight: FC<ExtraNightProps> = ({
  tour,
  dateIndex,
  isCanEdit,
  selectedTourists,
  selectedExtraNight,
  setSelectedExtraNight,
  setDirty,
}) => {
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const isIndividual = useAppSelector((state) => state.orders.preSaveOrder.tourInfo?.individual);
  const [initState, setInitState] = useState<boolean>(true);
  const [dateIndexLocal, setDateIndexLocal] = useState<number>(dateIndex ? dateIndex : 0);
  const [individualTypes, setindividualTypes] = useState([
    '1взр',
    '2взр',
    '3взр',
    '1взр+1реб',
    '1взр+2реб',
    '2взр+1реб',
    '2взр+2реб',
  ]);
  const initialValues = {
    extraNights:
      preSaveOrder.tourInfo?.extraNights && preSaveOrder.tourInfo?.extraNights.length > 0
        ? preSaveOrder.tourInfo?.extraNights
        : selectedExtraNight || [
            {
              period: [null, null],
              hotel: '',
              type: '',
              category: '',
              accommodation: '',
              food_type: '',
              price: 0,
              comission: '',
              tourist: [],
            },
          ],
  };

  const formExtraNights = useForm({
    initialValues,
  });
  const tourById = useAppSelector((state) => state.tours.selectedTour);
  const placements = useAppSelector((state) => state.tours.tourDateNight);
  // список гостиниц в туре в конкретной поезде - dateIndex
  const hotelsData =
    placements.length > 0
      ? placements?.map((item, index) => ({
          id: item.id,
          name: item.hotel_string,
          type: item.room_type_string,
          category: item.room_category_string,
          food_type: item.food_type_string,
          accommodation: [
            { type: '1взр', price: parseInt(item.price1) },
            { type: '2взр', price: parseInt(item.price2) },
            { type: '3взр', price: parseInt(item.price3) },
            { type: '1взр+1реб', price: parseInt(item.price4) },
            { type: '1взр+2реб', price: parseInt(item.price5) },
            { type: '2взр+1реб', price: parseInt(item.price6) },
            { type: '2взр+2реб', price: parseInt(item.price7) },
          ],
        }))
      : [];

  // список типов комнат в зависимости от отеля
  const initialType =
    placements.length > 0
      ? placements?.map((item) => ({
          ind: item.hotel_string,
          value: item.room_type_string,
        }))
      : [];

  // список категорий комнат в зависимости от отеля и типа комнаты
  const initialCategory =
    placements.length > 0
      ? placements?.map((item) => ({
          ind: item.hotel_string,
          type: item.room_type_string,
          value: item.room_category_string,
        }))
      : [];

  const [hotels, setHotels] = useState<string[]>([]);
  const [categories, setCategories] = useState<{ ind: string; type: string; value: string }[]>(initialCategory);
  const [types, setTypes] = useState<{ ind: string; value: string }[]>(initialType);

  const [accommodations, setAccommodations] = useState<
    { ind: string; type: string; category: string; value: string }[]
  >([]);
  const [prices, setPrices] = useState<
    { ind: string; type: string; category: string; accommodation: string; value: string }[]
  >([]);

  useEffect(() => {
    if (!preSaveOrder.id) {
      setSelectedExtraNight(formExtraNights.values.extraNights);
    }
  }, [formExtraNights.values.extraNights]);

  useEffect(() => {
    console.log('>> OrderForm Extra Nights, SELECTED EXTRA NIGHTS CHANGE: ', selectedExtraNight);
  }, [selectedExtraNight]);

  useEffect(() => {
    setDateIndexLocal(dateIndex === undefined ? 0 : dateIndex);
  }, [dateIndex]);

  // Задаем изначальные отели
  useEffect(() => {
    const data = selectedExtraNight.map((el) => el.hotel);
    const hdata = hotelsData.map((item) => {
      return item.name;
    });
    const aggdata = data.concat(hdata);
    setHotels(aggdata.filter((el, index, self) => self.indexOf(el) === index));
  }, [hotelsData.length, selectedExtraNight]);

  // Задаем изначальные типы
  useEffect(() => {
    const newArr = hotelsData
      .map((item) => ({
        ind: item.name,
        value: item.type,
      }))
      .concat(selectedExtraNight.map((el) => ({ ind: el.hotel, value: el.type })));
    setTypes(filterByProps(newArr, ['ind', 'value']));
  }, [hotelsData.length, selectedExtraNight]);

  // Задаем изначальные категории
  useEffect(() => {
    const newArr = hotelsData
      .map((item) => ({
        ind: item.name,
        type: item.type,
        value: item.category,
      }))
      .concat(selectedExtraNight.map((el) => ({ ind: el.hotel, value: el.category, type: el.type })));
    setCategories(newArr);
  }, [hotelsData.length]);

  // Задаем изначальные виды размещений
  useEffect(() => {
    const accomms: any = [];
    hotelsData.forEach((item2) =>
      item2.accommodation
        .filter((item) => item.price > 0)
        .forEach((item) =>
          accomms.push({
            ind: item2.name,
            type: item2.type,
            category: item2.category,
            value: item.type,
          })
        )
    );
    const newAccomms = accomms.concat(
      selectedExtraNight.map((el) => ({
        ind: el.hotel,
        type: el.type,
        category: el.category,
        value: el.accommodation,
      }))
    );
    setAccommodations(newAccomms);
  }, [hotelsData.length]);

  //задаем начальные виды цен
  useEffect(() => {
    const prcs: any = [];
    hotelsData.forEach((item2) =>
      item2.accommodation.forEach((item) =>
        prcs.push({
          ind: item2.name,
          type: item2.type,
          category: item2.category,
          accommodation: item.type,
          value: item.price,
        })
      )
    );

    setPrices(prcs);
  }, [hotelsData.length]);

  const fields = formExtraNights.values.extraNights ? (
    formExtraNights.values.extraNights.map((item, index) => {
      const countOfNight =
        formExtraNights.values.extraNights[index].period[0] && formExtraNights.values.extraNights[index].period[1]
          ? datediff(
              new Date(String(formExtraNights.values.extraNights[index].period[0])),
              new Date(String(formExtraNights.values.extraNights[index].period[1]))
            )
          : '';
      return (
        <div key={index} className={styles.row}>
          <div className={clsx(styles.cell, styles.period)}>
            <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
              <DatePickerInput
                type="range"
                allowSingleDateInRange={true}
                {...formExtraNights.getInputProps(`extraNights.${index}.period`)}
                valueFormat="DD.MM.YYYY "
                placeholder="Выбрать"
                disabled={!isCanEdit}
                mx="auto"
                maw={400}
                icon={<DateIcon width={12} height={12} fill={'rgba(91, 101, 114, 0.75)'} className={'close'} />}
                className={styles.select}
                styles={{
                  rightSection: { pointerEvents: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            </DatesProvider>
          </div>
          <div className={clsx(styles.cell, styles.nightCount)}>
            <TextInput
              hideControls
              readOnly
              defaultValue={countOfNight}
              disabled={!isCanEdit}
              value={countOfNight}
              {...formExtraNights.getInputProps(`extraNights.${index}.nightCount`)}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
          <div className={clsx(styles.cell, styles.hotel)}>
            {isIndividual || (tourById?.name != '' && tour?.length === 0) ? (
              <TextInput
                {...formExtraNights.getInputProps(`extraNights.${index}.hotel`)}
                disabled={!isCanEdit}
                styles={{
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <Select
                data={hotels}
                placeholder="Выбрать"
                {...formExtraNights.getInputProps(`extraNights.${index}.hotel`)}
                searchable
                creatable
                disabled={!isCanEdit}
                getCreateLabel={(query) => `+ Создать ${query}`}
                onCreate={(query) => {
                  const item = { value: query, label: query };
                  setHotels((current) => [...current, query]);
                  return item;
                }}
                //при смене гостиницы обнуляет поля
                onChange={(value: string) => {
                  formExtraNights.setFieldValue(`extraNights.${index}.type`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.category`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.accommodation`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.price`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.hotel`, value);
                }}
                className={styles.select}
                rightSectionWidth={30}
                styles={{
                  rightSection: { pointerEvents: 'none', display: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                  item: {
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            )}
          </div>
          <div className={clsx(styles.cell, styles.type)}>
            {isIndividual || (tourById?.name != '' && tour?.length === 0) ? (
              <TextInput
                {...formExtraNights.getInputProps(`extraNights.${index}.type`)}
                disabled={!isCanEdit}
                styles={{
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <Select
                data={types
                  .filter((item) => item.ind === formExtraNights.values.extraNights[index].hotel)
                  .map((item) => item.value.toString())
                  .filter((el, index, self) => self.indexOf(el) === index)}
                placeholder="Выбрать"
                disabled={!isCanEdit}
                {...formExtraNights.getInputProps(`extraNights.${index}.type`)}
                searchable
                creatable
                getCreateLabel={(query) => `+ Создать ${query}`}
                onCreate={(query) => {
                  const item2 = { value: query, label: query };
                  setTypes((current) => [...current, { ind: item.hotel, value: query }]);
                  return item2;
                }}
                //при смене типа обнуляет поля
                onChange={(value: string) => {
                  formExtraNights.setFieldValue(`extraNights.${index}.category`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.accommodation`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.price`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.type`, value);
                }}
                className={styles.select}
                rightSectionWidth={30}
                styles={{
                  rightSection: { pointerEvents: 'none', display: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                    paddingRight: '0px',
                  },
                  item: {
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            )}
          </div>
          <div className={clsx(styles.cell, styles.category)}>
            {isIndividual || (tourById?.name != '' && tour?.length === 0) ? (
              <TextInput
                {...formExtraNights.getInputProps(`extraNights.${index}.category`)}
                disabled={!isCanEdit}
                styles={{
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <Select
                data={categories
                  .filter((item) => item.ind === formExtraNights.values.extraNights[index].hotel)
                  .filter((item) => item.type === formExtraNights.values.extraNights[index].type)
                  .map((item) => item.value.toString())
                  .filter((el, index, self) => self.indexOf(el) === index)}
                defaultValue={selectedExtraNight[index]?.category ? selectedExtraNight[index].category : ''}
                placeholder="Выбрать"
                {...formExtraNights.getInputProps(`extraNights.${index}.category`)}
                searchable
                disabled={!isCanEdit}
                creatable
                getCreateLabel={(query) => `+ Создать ${query}`}
                onCreate={(query) => {
                  const item2 = { value: query, label: query };
                  setCategories((current) => [...current, { ind: item.hotel, type: item.type, value: query }]);
                  return item2;
                }}
                //при смене категории обнуляет поля
                onChange={(value: string) => {
                  formExtraNights.setFieldValue(`extraNights.${index}.accommodation`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.price`, '');
                  formExtraNights.setFieldValue(`extraNights.${index}.category`, value);
                }}
                className={styles.select}
                rightSectionWidth={30}
                styles={{
                  rightSection: { pointerEvents: 'none', display: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    paddingRight: '0px',
                    color: '#263B53',
                  },
                  item: {
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            )}
          </div>
          <div className={clsx(styles.cell, styles.accomodationType)}>
            {isIndividual || (tourById?.name != '' && tour?.length === 0) ? (
              <Select
                data={individualTypes}
                defaultValue={selectedExtraNight[index]?.accommodation ? selectedExtraNight[index].accommodation : ''}
                value={selectedExtraNight[index]?.accommodation ? selectedExtraNight[index].accommodation : ''}
                placeholder="Выбрать"
                itemComponent={SelectItem}
                disabled={!isCanEdit}
                {...formExtraNights.getInputProps(`extraNights.${index}.accommodation`)}
                searchable
                creatable
                getCreateLabel={(query) => `+ Создать ${query}`}
                onChange={(value: string) => {
                  formExtraNights.setFieldValue(`extraNights.${index}.accommodation`, value);
                }}
                className={styles.select}
                rightSectionWidth={30}
                styles={{
                  rightSection: { pointerEvents: 'none', display: 'none' },
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                  item: {
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <>
                {accommodations.filter(
                  (item) =>
                    item.ind === formExtraNights.values.extraNights[index].hotel &&
                    item.category === formExtraNights.values.extraNights[index].category &&
                    item.type === formExtraNights.values.extraNights[index].type
                ).length > 0 ? (
                  <Select
                    data={accommodations
                      .filter(
                        (item) =>
                          item.ind === formExtraNights.values.extraNights[index].hotel &&
                          item.category === formExtraNights.values.extraNights[index].category &&
                          item.type === formExtraNights.values.extraNights[index].type
                      )
                      .map((item) => item.value?.toString() ?? '')}
                    disabled={!isCanEdit}
                    defaultValue={
                      selectedExtraNight[index]?.accommodation ? selectedExtraNight[index].accommodation : ''
                    }
                    value={selectedExtraNight[index]?.accommodation ? selectedExtraNight[index].accommodation : ''}
                    placeholder="Выбрать"
                    itemComponent={SelectItem}
                    {...formExtraNights.getInputProps(`extraNights.${index}.accommodation`)}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onChange={(value: string) => {
                      const price = prices
                        .filter(
                          (item) =>
                            item.ind === formExtraNights.values.extraNights[index].hotel &&
                            item.category === formExtraNights.values.extraNights[index].category &&
                            item.type === formExtraNights.values.extraNights[index].type &&
                            item.accommodation === value
                        )
                        .map((item) => item.value.toString());
                      const foodType = hotelsData.filter(
                        (el) =>
                          el.name === formExtraNights.values.extraNights[index].hotel &&
                          el.category === formExtraNights.values.extraNights[index].category &&
                          el.type === formExtraNights.values.extraNights[index].type
                      );
                      formExtraNights.setFieldValue(`extraNights.${index}.accommodation`, value);
                      formExtraNights.setFieldValue(
                        `extraNights.${index}.price`,
                        parseInt(price[0]) * Number(countOfNight)
                      );
                      formExtraNights.setFieldValue(`extraNights.${index}.food_type`, foodType[0].food_type);
                    }}
                    className={styles.select}
                    rightSectionWidth={30}
                    styles={{
                      rightSection: { pointerEvents: 'none', display: 'none' },
                      input: {
                        border: 'none',
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#263B53',
                        paddingRight: '0px',
                      },
                      item: {
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                    }}
                  />
                ) : (
                  <Select
                    data={individualTypes}
                    defaultValue={
                      selectedExtraNight[index]?.accommodation ? selectedExtraNight[index].accommodation : ''
                    }
                    value={selectedExtraNight[index]?.accommodation ? selectedExtraNight[index].accommodation : ''}
                    placeholder="Выбрать"
                    itemComponent={SelectItem}
                    disabled={!isCanEdit}
                    {...formExtraNights.getInputProps(`extraNights.${index}.accommodation`)}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onChange={(value: string) => {
                      formExtraNights.setFieldValue(`extraNights.${index}.accommodation`, value);
                    }}
                    className={styles.select}
                    rightSectionWidth={30}
                    styles={{
                      rightSection: { pointerEvents: 'none', display: 'none' },
                      input: {
                        border: 'none',
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                      item: {
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#263B53',
                      },
                    }}
                  />
                )}
              </>
            )}
          </div>
          <div className={clsx(styles.cell, styles.price)}>
            {isIndividual || (tourById?.name != '' && tour?.length === 0) ? (
              <NumberInput
                {...formExtraNights.getInputProps(`extraNights.${index}.price`)}
                disabled={!isCanEdit}
                styles={{
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            ) : (
              <NumberInput
                hideControls
                defaultValue={formExtraNights.values.extraNights[index].price}
                disabled={!isCanEdit}
                value={formExtraNights.values.extraNights[index].price}
                {...formExtraNights.getInputProps(`extraNights.${index}.price`)}
                styles={{
                  input: {
                    border: 'none',
                    fontFamily: 'Museo Sans Cyrl',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#263B53',
                  },
                }}
              />
            )}
          </div>
          <div className={clsx(styles.cell, styles.comission)}>
            <NumberInput
              variant="unstyled"
              disabled={!isCanEdit}
              defaultValue={Number(formExtraNights.values.extraNights[index].comission)}
              styles={{
                rightSection: { pointerEvents: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
              {...formExtraNights.getInputProps(`extraNights.${index}.comission`)}
            />
          </div>
          <div className={clsx(styles.cell, styles.tourist)}>
            <MultiSelect
              data={selectedTourists.map((item) => item.name)}
              disabled={!isCanEdit}
              placeholder="Выбрать"
              {...formExtraNights.getInputProps(`extraNights.${index}.tourist`)}
              searchable
              creatable
              getCreateLabel={(query) => `+ Создать ${query}`}
              className={styles.select}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none', display: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
                item: {
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
          <span
            className={styles.delete}
            onClick={() => {
              if (!isCanEdit) {
                return;
              }
              formExtraNights.removeListItem('extraNights', index);
            }}
          >
            <BinIcon width={10} height={11} fill="#5B6572" />
          </span>
        </div>
      );
    })
  ) : (
    <></>
  );

  return (
    <div className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {extraNightColumns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div>
            <div>{fields}</div>
          </div>
        </div>
      </div>
      <div className={styles.btnRow}>
        {preSaveOrder.id && (
          <Button
            transparent={true}
            label="Сохранить все доп.ночи"
            className={styles.saveRow}
            disabled={!isCanEdit}
            onClick={() => {
              setSelectedExtraNight(formExtraNights.values.extraNights);
              setDirty();
            }}
          />
        )}
        <Button
          transparent={true}
          label="+ Добавить строку"
          className={styles.addRow}
          disabled={!isCanEdit}
          onClick={() =>
            formExtraNights.insertListItem('extraNights', {
              period: [null, null],

              hotel: '',
              type: '',
              category: '',
              accommodationType: '',
              price: 0,
              tourist: [],
              comission: '',
            })
          }
        />
      </div>
    </div>
  );
};
