import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const ArrowDownIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.810547 1.08875L5.03021 5.91122L9.24988 1.08875"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
