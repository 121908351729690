import React, { FC } from 'react';
import styles from './OneFieldResult.module.scss';
import { OneFieldResultType} from './OneFieldResult.types';
import clsx from 'clsx';

export const OneFieldResult: FC<OneFieldResultType> = ({
  name,
  label,
  value='',
  iconLeft,
  iconRight,
  className,
  error,
  background = '',
}) => {
  return (
    <div className={clsx(styles.block, className, error && styles.error)}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <div
        className={styles.inputWrapper}
        style={background ? { backgroundColor: `${background}`, border: 'none' } : {}}
      >
        {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
        <span className={styles.input} style={{ backgroundColor: `${background}` }}>
          {value}
        </span>
        {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
      </div>
    </div>
  );
};
