import axiosInstance from 'services/axiosInstance';
import { OrderNotificationData, OrderNotificationResponse } from './OrderNotification.types';


export const addOrderNotificationAPI = async (data: OrderNotificationData) => {
  return await axiosInstance.post<OrderNotificationResponse>('/api/v1/order-notifications', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
};

export const getOrderNotificationAPI = async ({ id }: { id: string }) => {
  return await axiosInstance.get<OrderNotificationResponse>(`/api/v1/order-notifications/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
};

export const updateOrderNotificationAPI = async ({ id = '', ...data }: Partial<OrderNotificationData>) => {
  if (id === '') {
    throw new Error('Missing id while notification update');
  }
  return await axiosInstance.put<{ is_complete: boolean }>(`/api/v1/order-notifications/${id}`, data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
};

export const deleteOrderNotificationAPI = async ({ id = '' }: { id: string }) => {
  if (id === '') {
    throw new Error('Missing id while notification remove');
  }
  return await axiosInstance.delete(`/api/v1/order-notifications/${id}`);
};