import React, { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styles from './TextEditor.module.scss';
import { TextEditorProps } from './TextEditor.type';
import clsx from 'clsx';

export const TextEditor: FC<TextEditorProps> = ({
  data,
  setData,
  placeholder,
  className,
  isCanEdit,
  valueChanged,
  customModules,
  ...props
}) => {
  const [text, setText] = useState<string>(data || '');
  const modules = {
    toolbar: [['bold', 'italic', 'underline', { list: 'bullet' }]],
  };

  useEffect(() => {
    if (setData) {
      setData(text);
    }
  }, [text]);

  useEffect(() => {
    if (text !== '') return;
    if (data) {
      setText(data);
    }
  }, [data]);

  return (
    <ReactQuill
      theme="snow"
      value={text}
      onChange={(value) => {
        setText(value);
        setData && setData(value);
      }}
      className={clsx(isCanEdit ? styles.disabled : styles.editor, className)}
      modules={customModules ? customModules : modules}
      readOnly={isCanEdit}
      placeholder={data ? data : placeholder}
      onBlur={() => {
        valueChanged ? valueChanged(text) : void 1;
      }}
      {...props}
    />
  );
};
