import { ModalProvider } from 'core/context/ModalProvider';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from 'routes';

function App() {
  return (
    <BrowserRouter>
      <ModalProvider>
        <AppRouter />
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
