import React, { FC } from 'react';
import styles from './style.module.scss';
import { DragIcon } from 'components/icons';

type DragDropFile = {
  setState: (data: File[]) => void;
};

// drag drop file component
export const DragDropFile: FC<DragDropFile> = ({ setState }) => {
  const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles);
    setState(filesFromInput as File[]);
    e.target.value = '';
  };
  return (
    <form id="form-file-upload" className={styles.form}>
      <input
        type="file"
        id="input-file-upload"
        accept="image/*,image/jpg,image/png,image/gif"
        onChange={(e) => handleAddFiles(e)}
      />
      <label id="label-file-upload" htmlFor="input-file-upload">
        <DragIcon width={130} height={78} />
        <div>
          <p>
            Перетащите сюда файлы или
            <br />
            <span>нажмите для выбора файлов</span>
          </p>
        </div>
      </label>
    </form>
  );
};
