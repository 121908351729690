import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { AddServiceProps, ServiceProps } from './AddService.type';
import { Button } from 'ui/Button';
import { generateId } from 'core/utils/generateId';
import {
  BankIcon,
  BarIcon,
  CoffeeIcon,
  CruizeIcon,
  DollarIcon,
  FoodIcon,
  GymIcon,
  LiftIcon,
  MusicIcon,
  RemoveIcon,
  SpaIcon,
  SportIcon,
} from '../icons';

import styles from './AddService.module.scss';
import { SelectForElements } from 'ui/SelectForElements';
import { TrueIcon } from 'components/icons/TrueIcon';
import { FalseIcon } from 'components/icons/FalseIcon';

export const AddService: FC<AddServiceProps> = ({ columns, items = [], title, setAddService, valueChanged }) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<ServiceProps[]>(items);
  const [canDo, setCanDo] = useState<boolean>(false);
  useEffect(() => {
    if (canDo) {
      setCanDo(false);
    }
  }, [canDo]);
  const iconsList = [
    {
      value: 'default',
      icon: <DollarIcon />,
    },
    {
      value: 'sport',
      icon: <SportIcon />,
    },
    {
      value: 'bank',
      icon: <BankIcon />,
    },
    {
      value: 'bar',
      icon: <BarIcon />,
    },
    {
      value: 'coffee',
      icon: <CoffeeIcon />,
    },
    {
      value: 'cruize',
      icon: <CruizeIcon />,
    },
    {
      value: 'food',
      icon: <FoodIcon />,
    },
    {
      value: 'gym',
      icon: <GymIcon />,
    },
    {
      value: 'lift',
      icon: <LiftIcon />,
    },
    {
      value: 'music',
      icon: <MusicIcon />,
    },
    {
      value: 'spa',
      icon: <SpaIcon />,
    },
  ];
  const siteViewList = [
    {
      value: 'true',
      icon: <TrueIcon />,
    },
    {
      value: 'false',
      icon: <FalseIcon />,
    },
  ];
  const getIcon = (value: string) => {
    switch (value) {
      case 'default':
        return <DollarIcon />;
      case 'spa':
        return <SpaIcon />;
      case 'music':
        return <MusicIcon />;
      case 'lift':
        return <LiftIcon />;
      case 'gym':
        return <GymIcon />;
      case 'food':
        return <FoodIcon />;
      case 'cruize':
        return <CruizeIcon />;
      case 'coffee':
        return <CoffeeIcon />;
      case 'bar':
        return <BarIcon />;
      case 'sport':
        return <SportIcon />;
      case 'bank':
        return <BankIcon />;
      case 'true':
        return <TrueIcon />;
      case 'false':
        return <FalseIcon />;
      default:
        return <DollarIcon />;
    }
  };
  const addNewRow = () => {
    const newRow: ServiceProps = {} as ServiceProps;
    newRow.id = generateId();
    newRow.service = '';
    newRow.price = '';
    newRow.icon = 'default';
    newRow.siteView = false;
    setRows([...rows, newRow]);
    setCanDo(true);
  };

  const editExistRow = (key: number, value: Partial<ServiceProps>) => {
    const data = [...rows];
    if (value.price) {
      value.price = String(value.price).replace(/\D/g, '');
    }
    if (data[key]) {
      data[key] = { ...data[key], ...value };
    }
    setRows(data);
    setCanDo(true);
  };

  const removeExistRow = (key: number) => {
    const data = [...rows];
    if (data[key]) {
      delete data[key];
    }
    setRows([...data].filter((i) => i));
    setCanDo(true);
  };

  useEffect(() => {
    if (setAddService && rows) {
      setAddService(rows);
    }
  }, [rows]);

  const prevRows = useRef<ServiceProps[]>();
  useEffect(() => {
    if (
      valueChanged &&
      rows &&
      prevRows.current &&
      JSON.stringify(prevRows.current) !== JSON.stringify(rows) &&
      canDo
    ) {
      valueChanged();
    }
    prevRows.current = rows;
  }, [rows, canDo]);

  useEffect(() => {
    setRows(items);
  }, [items]);

  useEffect(() => {
    if (rows.length > 0) {
      setOpen(true);
    }
  }, [rows]);

  return (
    <article className={clsx(title != 'Скидка' ? styles.serviceContainer : styles.addService)}>
      <h3 className={clsx(styles.top, open && styles.topUp)} onClick={() => setOpen((prev) => !prev)}>
        {title}
      </h3>
      {open && (
        <>
          <div className={styles.tableWrapper}>
            <div className={styles.table}>
              <div className={styles.rows}>
                {columns.map((column) => (
                  <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
                    <span>{column.name}</span>
                  </div>
                ))}
              </div>
              <div>
                {rows.length > 0 &&
                  rows.map((item, key) => (
                    <Fragment key={key + item.service + item.price}>
                      <div className={styles.row}>
                        <SelectForElements
                          selected={{
                            value: item.icon,
                            icon: getIcon(item.icon),
                          }}
                          data={iconsList}
                          className={styles.icon}
                          onDirty={(a) => editExistRow(key, { icon: a })}
                          isWithReset={false}
                        />
                        <input
                          type="text"
                          defaultValue={item.service}
                          onBlur={(e) => editExistRow(key, { service: e.target.value })}
                          className={clsx(styles.service, styles.newItem)}
                        />
                        <input
                          type="text"
                          defaultValue={item.price}
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '');
                          }}
                          onBlur={(e) => editExistRow(key, { price: e.target.value })}
                          className={clsx(styles.price, styles.newItem)}
                        />
                        <SelectForElements
                          selected={{
                            value: item.siteView ? 'true' : 'false',
                            icon: getIcon(item.siteView ? 'true' : 'false'),
                          }}
                          data={siteViewList}
                          className={styles.viewOnSite}
                          onDirty={(a) => editExistRow(key, { siteView: a === 'true' ? true : false })}
                          isWithReset={false}
                        />
                      </div>
                      <div className={styles.removeWrapper}>
                        <button className={styles.remove} onClick={() => removeExistRow(key)}>
                          <RemoveIcon width={15} height={15} />
                        </button>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
          <Button transparent={true} label="+ Добавить строку" className={styles.button} onClick={addNewRow} />
        </>
      )}
    </article>
  );
};
