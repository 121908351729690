import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const Client2A2CIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 54 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_165_1367)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1769 12.4097C12.3174 12.4097 14.0525 10.6745 14.0525 8.53402C14.0525 6.39356 12.3174 4.65838 10.1769 4.65838C8.03644 4.65838 6.30126 6.39356 6.30126 8.53402C6.30126 10.6745 8.03644 12.4097 10.1769 12.4097ZM15.7109 8.53402C15.7109 10.6006 14.5781 12.4027 12.8996 13.353C14.3204 13.7702 15.6275 14.5115 16.6984 15.5289C17.621 16.4053 18.3279 17.4483 18.7868 18.5835H23.075C22.8204 17.5883 22.2807 16.6627 21.4942 15.9154C20.3638 14.8416 18.8168 14.2272 17.1904 14.2272C17.1111 14.2272 17.032 14.2286 16.9531 14.2315V12.449C17.0316 12.455 17.1108 12.458 17.1908 12.458C18.9032 12.458 20.2913 11.0699 20.2913 9.3575C20.2913 7.64514 18.9032 6.25699 17.1908 6.25699C17.1108 6.25699 17.0316 6.26002 16.9531 6.26597V4.99993H17.9773C20.0472 5.37104 21.618 7.18083 21.618 9.3575C21.618 11.0109 20.7117 12.4526 19.3687 13.2129C20.5055 13.5466 21.5512 14.1397 22.4079 14.9536C23.4579 15.951 24.1587 17.2181 24.4358 18.5835C24.5239 19.0176 24.5691 19.4617 24.5691 19.9102H23.2424H19.2C19.2115 19.9621 19.2226 20.0141 19.2332 20.0663C19.3433 20.6089 19.3999 21.164 19.3999 21.7247H17.7415H10.1765H2.61151H0.953125C0.953125 21.164 1.00968 20.6089 1.11979 20.0663C1.4661 18.3595 2.34216 16.7757 3.65459 15.5289C4.72559 14.5114 6.03287 13.77 7.45392 13.3529C5.77554 12.4025 4.64288 10.6005 4.64288 8.53402C4.64288 5.47767 7.12054 3 10.1769 3C13.2333 3 15.7109 5.47767 15.7109 8.53402ZM17.5322 20.0663C17.2139 18.8222 16.5394 17.6652 15.5562 16.7312C14.1432 15.3889 12.2095 14.6208 10.1765 14.6208C8.14347 14.6208 6.20975 15.3889 4.7968 16.7312C3.81359 17.6652 3.13904 18.8222 2.82074 20.0663L10.1765 20.0663H17.5322Z"
          fill={fill}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1108 8.41712C34.1108 9.5957 33.1553 10.5511 31.9768 10.5511C30.7982 10.5511 29.8427 9.5957 29.8427 8.41712C29.8427 7.23853 30.7982 6.28311 31.9768 6.28311C33.1553 6.28311 34.1108 7.23853 34.1108 8.41712ZM35.7108 8.41712C35.7108 10.4794 34.039 12.1511 31.9768 12.1511C29.9145 12.1511 28.2427 10.4794 28.2427 8.41712C28.2427 6.35488 29.9145 4.68311 31.9768 4.68311C34.039 4.68311 35.7108 6.35488 35.7108 8.41712ZM31.9765 12.6048C31.1308 12.6048 30.1748 12.6474 29.2628 12.8466C28.3518 13.0456 27.429 13.4126 26.7021 14.1031C25.9781 14.7909 25.547 15.7063 25.2947 16.6135C25.0411 17.5253 24.9531 18.4821 24.9531 19.317H26.5531C26.5531 18.5838 26.6316 17.7781 26.8362 17.0422C27.0422 16.3016 27.3611 15.684 27.8041 15.2631C28.2444 14.8449 28.858 14.5727 29.6042 14.4097C30.3495 14.247 31.1716 14.2048 31.9765 14.2048C32.7812 14.2048 33.6179 14.2469 34.378 14.4103C35.1411 14.5743 35.7648 14.8477 36.2021 15.2631C36.6399 15.679 36.9464 16.2903 37.1402 17.0305C37.333 17.7664 37.3998 18.5745 37.3998 19.317H38.9998C38.9998 18.4915 38.9269 17.5369 38.688 16.6251C38.4503 15.7176 38.0334 14.7959 37.3041 14.1031C36.5743 13.4098 35.635 13.044 34.7142 12.846C33.7904 12.6474 32.8223 12.6048 31.9765 12.6048Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1576 8.41712C48.1576 9.5957 47.2022 10.5511 46.0236 10.5511C44.8451 10.5511 43.8896 9.5957 43.8896 8.41712C43.8896 7.23853 44.8451 6.28311 46.0236 6.28311C47.2022 6.28311 48.1576 7.23853 48.1576 8.41712ZM49.7576 8.41712C49.7576 10.4794 48.0859 12.1511 46.0236 12.1511C43.9614 12.1511 42.2896 10.4794 42.2896 8.41712C42.2896 6.35488 43.9614 4.68311 46.0236 4.68311C48.0859 4.68311 49.7576 6.35488 49.7576 8.41712ZM46.0234 12.6048C45.1777 12.6048 44.2216 12.6474 43.3097 12.8466C42.3987 13.0456 41.4758 13.4126 40.749 14.1031C40.025 14.7909 39.5939 15.7063 39.3416 16.6135C39.088 17.5253 39 18.4821 39 19.317H40.6C40.6 18.5838 40.6784 17.7781 40.8831 17.0422C41.089 16.3016 41.4079 15.684 41.851 15.2631C42.2913 14.8449 42.9048 14.5727 43.6511 14.4097C44.3964 14.247 45.2185 14.2048 46.0234 14.2048C46.8281 14.2048 47.6648 14.2469 48.4248 14.4103C49.188 14.5743 49.8117 14.8477 50.249 15.2631C50.6868 15.679 50.9932 16.2903 51.1871 17.0305C51.3799 17.7664 51.4467 18.5745 51.4467 19.317H53.0467C53.0467 18.4915 52.9737 17.5369 52.7349 16.6251C52.4972 15.7176 52.0803 14.7959 51.351 14.1031C50.6212 13.4098 49.6819 13.044 48.7611 12.846C47.8373 12.6474 46.8691 12.6048 46.0234 12.6048Z"
        fill={fill}
      />
      <defs>
        <clipPath id="clip0_165_1367">
          <rect width="24" height="24" fill="white" transform="translate(0.953125)" />
        </clipPath>
      </defs>
    </svg>
  );
};
