import React, { FC, useEffect, useState } from 'react';

import { ImageFileType } from './ImageFile.type';
import { DownLoad } from 'components/icons/Download';
import file from '../../../assets/file.png';

import styles from './ImageFile.module.scss';

const ImageFile: FC<ImageFileType> = ({ name, preview, size, type }) => {
  const [imgCover, setImgCover] = useState<string>();

  useEffect(() => {
    if (type.includes('image')) {
      setImgCover(preview);
    } else {
      setImgCover(file);
    }
  }, []);

  return (
    <article className={styles.file}>
      <div className={styles.imgWrapper}>
        <img src={imgCover} alt={name} className={styles.img} />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        <div className={styles.params}>
          <div className={styles.size}>{`${(size / 1000).toFixed(2)} КБ`}</div>
          <div className={styles.type}>&nbsp;{type.length > 16 ? `${type.slice(0, 16)}...` : type}</div>
        </div>
      </div>
      <a href={preview} download={name} target="_blank" rel="noreferrer" className={styles.download}>
        <DownLoad />
      </a>
    </article>
  );
};

export default ImageFile;
