import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const CalendarIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40001 1.87995C5.40001 1.39394 5.794 0.999954 6.28001 0.999954H7.16001C7.64602 0.999954 8.04001 1.39394 8.04001 1.87995V3.55378H15.96V1.87995C15.96 1.39394 16.354 0.999954 16.84 0.999954H17.72C18.206 0.999954 18.6 1.39394 18.6 1.87995V3.55378H20.36C21.818 3.55378 23 4.73575 23 6.19378V19.7709C23 21.229 21.818 22.4109 20.36 22.4109H3.64C2.18197 22.4109 1 21.229 1 19.7709V6.19378C1 4.73575 2.18197 3.55378 3.64 3.55378H5.40001V1.87995ZM15.96 5.31378V5.39995C15.96 5.88597 16.354 6.27996 16.84 6.27996H17.72C18.206 6.27996 18.6 5.88597 18.6 5.39995V5.31378H20.36C20.846 5.31378 21.24 5.70777 21.24 6.19378V9.08747H2.76V6.19378C2.76 5.70777 3.15399 5.31378 3.64 5.31378H5.40001V5.39995C5.40001 5.88597 5.794 6.27996 6.28001 6.27996H7.16001C7.64602 6.27996 8.04001 5.88597 8.04001 5.39995V5.31378H15.96ZM2.76 10.8475V19.7709C2.76 20.2569 3.15399 20.6509 3.64 20.6509H20.36C20.846 20.6509 21.24 20.2569 21.24 19.7709V10.8475H2.76Z"
        fill={fill}
      />
    </svg>
  );
};
