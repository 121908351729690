import React, { FC, useEffect, useState } from 'react';

import clsx from 'clsx';

import { getPaymentStatus, toursColumns } from './data';
import { mockPaymentsForTable } from './mockData';

import styles from './PaymentLinkHistory.module.scss';
import { getDate } from 'core/utils/getDate';
import { getPaymentMethodForLink } from 'core/utils/getPaymentMethod';
import { Button } from 'ui/Button';
import { useAppDispatch } from 'store/hooks';
import { addOrderPaymentLinkCancel, addOrderPaymentLinkFinish } from 'store/action-creators/orderPaymentLink';
import { CopyIcon } from 'components/icons/CopyIcon';

export const PaymentLinkHistory: FC<{
  paymentLinks: {
    uuid: string;
    payment_id: string;
    payment_url: string;
    payment_type: string;
    payment_method: string;
    q_r_code_image_data: string;
    amount: string;
    created_at: string;
    active_before: string;
    last_check: string;
    status: string;
    status_code: string;
  }[];
}> = ({ paymentLinks }) => {
  const dispatch = useAppDispatch();
  const handleFinish = async (id: string) => {
    dispatch(addOrderPaymentLinkFinish(id));
  };
  const handleCancel = async (id: string) => {
    dispatch(addOrderPaymentLinkCancel(id));
  };
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.table}>
        <div className={styles.rows}>
          {toursColumns.map((column) => (
            <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
              <span>{column.name}</span>
            </div>
          ))}
        </div>
        <div>
          {paymentLinks.length > 0 ? (
            paymentLinks.map((payment, index) => (
              <div key={payment.uuid} className={styles.row}>
                <div className={clsx(styles.cell, styles.id)}>{index + 1}</div>
                <div className={clsx(styles.cell, styles.idInPaymentSystem)}>{payment.payment_id}</div>
                <div className={clsx(styles.cell, styles.paymentType)}>
                  {getPaymentMethodForLink(payment.payment_method)}
                </div>
                <div className={clsx(styles.cell, styles.sum)}>{payment.amount}</div>
                <div className={clsx(styles.cell, styles.date)}>{getDate(new Date(payment.created_at))}</div>
                <div className={clsx(styles.cell, styles.valid)}>{getDate(new Date(payment.active_before))}</div>
                <div className={clsx(styles.cell, styles.code)}>{payment.status_code}</div>
                <div className={clsx(styles.cell, styles.paymentStatus)}>{getPaymentStatus(payment.status)}</div>
                <div className={clsx(styles.cell, styles.buttons)}>
                  <Button
                    className={styles.copyButton}
                    onClick={() => navigator.clipboard.writeText(payment.payment_url)}
                  >
                    <CopyIcon />
                  </Button>
                  <Button onClick={() => handleFinish(payment.uuid)}>Списать</Button>
                  <Button onClick={() => handleCancel(payment.uuid)}>Вернуть средства</Button>
                </div>
              </div>
            ))
          ) : (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
        </div>
      </div>
    </div>
  );
};
