import React, { useEffect, useState } from 'react';

import { Search } from 'components/Search';
import { TabsPanel } from 'ui/TabsPanel/TabsPanel';
import { Filter } from 'ui/Filter/Filter';
import { Modal } from 'components/Modal';
import { PartnerCreate } from 'pages/Partners/PartnerCreate';
import { Button } from 'ui/Button';
import { PartnerTabs, SUBJECT } from 'core/constants/enums';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getContactTypes } from 'store/action-creators/partners';
import { setParamsPage, setParamsSearch, setParamsSubject, setParamsType } from 'store/entities/partners/partnersSlice';

import styles from './PartnersTop.module.scss';

export const PartnersTop = () => {
  const dispatch = useAppDispatch();
  const { types, params } = useAppSelector((state) => state.partners);
  const [newPartnerModal, setNewPartnerModal] = useState(false);

  //загружаем список типов контрагентов с сервера
  useEffect(() => {
    dispatch(getContactTypes({ page: 1 }));
  }, []);

  //из полученных типов создаем массив для фильтра
  const partnesTypes = types.map((type) => type.name);

  //обработка поиска - задаем фильтр по поиску.
  const handleSearchClick = (query: string) => {
    dispatch(setParamsSearch(query));
  };

  //обработка фильтра по типу контрагента
  const handleTypeClick = (type: string) => {
    let typeForBack;
    if (type !== '') {
      if (types.length > 0) {
        typeForBack = types.filter((item) => item.name === type)[0]['@id'];
      }
      dispatch(setParamsType(typeForBack as string));
    } else {
      dispatch(setParamsType(null));
    }
  };

  //обработка табов, фильтр для физ и юр лиц.
  const handleSubjectClick = (subject: string) => {
    dispatch(setParamsPage(1));
    switch (subject) {
      case PartnerTabs.PRIVATE_PERSONS:
        dispatch(setParamsSubject({ name: PartnerTabs.PRIVATE_PERSONS, nameForBack: SUBJECT.PRIVATE_PERSON }));

        break;
      case PartnerTabs.LEGAL_PERSONS:
        dispatch(setParamsSubject({ name: PartnerTabs.LEGAL_PERSONS, nameForBack: SUBJECT.LEGAL_PERSON }));
        break;
      case PartnerTabs.ALL:
        dispatch(setParamsSubject({ name: PartnerTabs.ALL, nameForBack: null }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.top}>
        <div className={styles.counteragents}>
          <h2 className={styles.title}>Контрагенты</h2>
          <Button onClick={() => setNewPartnerModal(true)}>+ Новый контрагент</Button>
        </div>
        <TabsPanel
          tabs={[PartnerTabs.ALL, PartnerTabs.PRIVATE_PERSONS, PartnerTabs.LEGAL_PERSONS]}
          setActive={handleSubjectClick}
          tab={params.subject.name}
        />
        <div className={styles.search}>
          <Search placeholder="Поиск" setQuery={handleSearchClick} query={params.search} />
          <Filter
            filter={{
              title: 'Тип',
              data: partnesTypes,
            }}
            setFilter={handleTypeClick}
          />
        </div>
      </div>
      {newPartnerModal && (
        <Modal opened={newPartnerModal} setOpened={setNewPartnerModal}>
          <PartnerCreate />
        </Modal>
      )}
    </>
  );
};
