import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './GalleryEdit.module.scss';
import { RemoveIcon } from 'components/icons';
import { GalleryForm } from '../components/GalleryForm';
import { Album, Photo, PhotoRes } from 'services/gallery/galleryApi.service';
import { ModalConfirm } from 'pages/Tours/components/TourForm/ModalConfirm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deletePhoto } from 'store/action-creators/gallery';
import { notifications } from '@mantine/notifications';
import ModalContext from 'core/context/ModalContext';
import { AlbumForm } from '../components/AlbumForm';
import { resetPreSaveAlbum } from 'store/entities/gallery/gallerySlice';

export const AlbumCreate: FC = () => {
  const dispatch = useAppDispatch();
  const { isSuccess, isError } = useAppSelector((state) => state.gallerySlice);
  const { setActive } = useContext(ModalContext);

  useEffect(() => {
    if (isSuccess === 'ADD_ALBUM') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Альбом создан', id: 'ADD_ALBUM' });
      dispatch(resetPreSaveAlbum());
      setActive(false);
    }
    if (isError === 'ADD_ALBUM') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'ADD_ALBUM_ERROR',
      });
    }
  }, [isSuccess, isError]);

  return (
    <section className={styles.edit}>
      <div className={styles.top}>
        <h2 className={styles.title}>Новый фотоальбом</h2>
      </div>
      <div className={styles.content}>
        <AlbumForm />
      </div>
    </section>
  );
};
