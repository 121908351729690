import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const DownContourIcon: FC<IconProps> = ({ width, height, stroke }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.56067 1.58878L5.78033 6.41125L10 1.58878"
        stroke={stroke}
        strokeOpacity="0.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
