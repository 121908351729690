import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const CruizeIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 16.5C2.66667 16.3333 4.1 15.7 4.5 14.5C4.5 15.1667 4.9 16.5 6.5 16.5C8.1 16.5 8.83333 15.1667 9 14.5C9.16667 15.1667 9.9 16.5 11.5 16.5C13.1 16.5 13.5 15.1667 13.5 14.5C13.5 15 14 16.1 16 16.5M4.5 12.5L3 9L4.34467 8.43972M4.34467 8.43972L8.23077 6.82051C8.72308 6.61538 9.27692 6.61538 9.76923 6.82051L13.6553 8.43972M4.34467 8.43972V4.5H6.67234M6.67234 4.5V2H11.3277V4.5M6.67234 4.5H11.3277M13.5 12.5L15 9L13.6553 8.43972M13.6553 8.43972V4.5H11.3277"
        stroke="#1886E9"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
