import React, { useState } from 'react';
import styles from './ButtonWithSelect.module.scss';
import { Item } from './ButtonWithSelect.types';

export const ItemComponent = ({
  item,
  handleClickItem,
  req,
}: {
  item: Item;
  handleClickItem: (item: string, req?: number) => void;
  req?: { contact?: any[]; operator?: any[] };
}) => {
  const [isHide, setIsHide] = useState(false);
  return (
    <li
      onMouseMove={() => {
        setIsHide(true);
      }}
      onMouseLeave={() => setIsHide(false)}
      key={item.title}
      onClick={() => !item.hasChild && handleClickItem(item.docType)}
      className={styles.item}
    >
      {item.title}
      {item.hasChild && (
        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.17773 9.21875L6.00021 4.99908L1.17773 0.779419"
            stroke="#5B6572"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {isHide && item.hasChild && (
        <>
          {req && item.docType === 'doc2' ? (
            <>
              {req?.operator && req?.operator?.length > 0 && (
                <div className={styles.secondSelect}>
                  {req?.operator?.map((el) => (
                    <span key={el.id} onClick={() => handleClickItem(item.docType, el.id)}>
                      {el.juri_name || el.legalName}
                    </span>
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              {req?.contact && req?.contact?.length > 0 && (
                <div className={styles.secondSelect}>
                  {req?.contact?.map((el) => (
                    <span key={el.id} onClick={() => handleClickItem(item.docType, el.id)}>
                      {el.juri_name}
                    </span>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </li>
  );
};
