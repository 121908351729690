import { OrderProps, OrderTourists, TourInfo } from 'pages/Orders/Order.types';
import {
  OrderExtraNights,
  OrderSale,
  OrderServices,
  OrderTransfer,
  PlannedPayment,
  RealPayment,
  orderNotification,
  orderNotsForCreate,
} from './orderApi.service';
import { ReminderType } from 'components/Reminders/Reminders.type';
import { getDate } from 'core/utils/getDate';
import { AdditinalServicesType } from 'pages/Orders/components/OrderForm/TourInfoTab/AdditionalServices/AdditionalServicest.type';
import { SalesType } from 'pages/Orders/components/OrderForm/TourInfoTab/Sales/Sales.type';
import { TransfersType } from 'pages/Orders/components/OrderForm/TourInfoTab/Transfers/Transfers.type';
import { getPaymentMethodRutoEn, getPaymentTypeRuToEn } from 'core/utils/getPaymentMethod';
import { ActualPaymentType } from 'pages/Orders/components/OrderForm/DocumentsAndPaymentTab/ActualPayment/ActualPayment.type';
import { PlannedPaymentsType } from 'pages/Orders/components/OrderForm/DocumentsAndPaymentTab/PlannedPayment/PlannedPayment.type';

// конвертация напоминаний из интерфейса на сервер
export const orderNotificationOut = (model: ReminderType): orderNotsForCreate => {
  return {
    text: model.label,
    notify_at: model.date ? model.date : null,
  };
};
// конвертация напоминаний из сервера в интерфейс
export const orderNotificationIn = (obj: orderNotification): ReminderType => {
  return {
    '@id': obj['@id'],
    id: obj.id,
    label: obj.text,
    date: obj.notify_at ? new Date(obj.notify_at) : null,
    status: obj.is_completed,
  };
};
// конвертация туристов из интерфейса на сервер
export const orderTouristsOut = (model: OrderTourists): string => {
  return `/api/v1/contacts/${model.id.toString()}`;
};
// // конвертация доп.ночей из интерфейса на сервер
// export const orderAddNightsOut = (model: ExtraNightType): OrderExtraNights => {
//   return {
//     date_start: getDate(new Date(model.period[0]!)),
//     date_end: getDate(new Date(model.period[1]!)),
//     note: model.accommodation,
//     price: model.sum != '' ? model.sum.toString() : '0',
//     comission: model.price != '' ? model.price.toString() : '0',
//   };
// };

// конвертация доп.услуг из интерфейса на сервер
export const orderAddServicesOut = (model: AdditinalServicesType): OrderServices => {
  return {
    name: model.service,
    price: model.price != '' ? model.price : '0',
    quantity: model.quantity,
    summ: (model.quantity * Number(model.price)).toString(),
    description: model.description,
  };
};

// конвертация скидок из интерфейса на сервер
export const orderSalesOut = (model: SalesType): OrderSale => {
  return {
    name: model.name,
    sale_value: model.sale != '' ? model.sale : '0',
    quantity: model.quantity != '' ? Number(model.quantity) : 0,
  };
};

// конвертация трансфера из интерфейса на сервер
export const orderTransferOut = (model: TransfersType): OrderTransfer => {
  return {
    start_place: model.departurePoint,
    plan: model.route,
    start_date: getDate(new Date(model.departureDate!)).toString(),
    start_time: model.departureTime ? model.departureTime!.toString() : undefined,
    transport_type: model.transportType,
    transport_form: model.transportCategory,
    places_count: Number(model.places_count),
    price: model.price,
  };
};

// конвертация плановой оплаты из интерфейса на сервер
export const orderPlannedPaymentOut = (model: PlannedPaymentsType): PlannedPayment => {
  return {
    id: model.id,
    type: getPaymentTypeRuToEn(model.type),
    summ: model.summ,
    plan_date: model.plan_date.toString(),
    exchange_rate: model.rate,
    summ_rub: (Number(model.summ) * Number(model.rate)).toString(),
  };
};

// конвертация фактической оплаты из интерфейса на сервер
export const orderRealPaymentOut = (model: ActualPaymentType): RealPayment => {
  return {
    id: model.id,
    method: getPaymentMethodRutoEn(model.method),
    date: model.date,
    summ: model.summ,
    exchange_rate: model.rate,
    summ_rub: (Number(model.summ) * Number(model.rate)).toString(),
  };
};

export const getTourDates = (preSaveOrder: OrderProps) => {
  return preSaveOrder.tourInfo && preSaveOrder.tourInfo?.isCruize && preSaveOrder.tourInfo.hotels.length > 0
    ? [
        getDate(new Date(preSaveOrder.tourInfo.hotels[0].period[0]!)),
        getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
      ]
    : preSaveOrder.tourInfo?.isHotelTransfer &&
      preSaveOrder.tourInfo.transfers.length > 0 &&
      preSaveOrder.tourInfo.hotels.length === 0
    ? [getDate(new Date(preSaveOrder.tourInfo.transfers[0].departureDate!)), undefined]
    : preSaveOrder.tourInfo?.isHotelTransfer &&
      preSaveOrder.tourInfo.transfers.length > 0 &&
      preSaveOrder.tourInfo.hotels.length > 0
    ? [
        getDate(new Date(preSaveOrder.tourInfo.transfers[0].departureDate!)),
        getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
      ]
    : preSaveOrder.tourInfo?.isHotelTransfer &&
      preSaveOrder.tourInfo.transfers.length === 0 &&
      preSaveOrder.tourInfo.hotels.length > 0
    ? [
        getDate(new Date(preSaveOrder.tourInfo.hotels[0].period[0]!)),
        getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
      ]
    : preSaveOrder.tourInfo?.date
    ? preSaveOrder.tourInfo.date.toString().split('-')
    : [];
};
