import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Logo } from 'ui/Logo';
import { User } from '../../../../components/User';
import { RouteNames } from 'core/constants/routeNames';
import { Button } from 'ui/Button';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLogout } from 'store/entities/auth/authSlice';
import { getCurrentUser } from '../../../../store/action-creators/users';

import styles from './LayoutHeader.module.scss';
import { setCurrentUser } from 'store/entities/users/usersSlice';
import { getNameSurname } from 'core/utils/getInitials';

export const LayoutHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const { currentUser = null } = useAppSelector((state) => state.users);

  useEffect(() => {
    if (!currentUser) {
      dispatch(getCurrentUser());
    }
  }, [currentUser]);

  const handleClickUser = (id: number) => {
    setOpenMenu((prev) => !prev);
  };

  const logout = () => {
    dispatch(setLogout());
    dispatch(setCurrentUser());
    setOpenMenu(false);
    navigate(RouteNames.HOME);
  };

  const cancel = () => {
    setOpenMenu(false);
  };

  return (
    <div className={styles.header}>
      <Link to={RouteNames.ORDERS}>
        <Logo />
      </Link>
      <div className={styles.user}>
        {currentUser && (
          <User
            name={`${currentUser.last_name || ''} ${currentUser.first_name || ''} ${
              currentUser.middle_name || ''
            }`.trim()}
            avatar={currentUser.avatar}
            id={currentUser.id}
            setUser={handleClickUser}
            getUserInitials={(name) => getNameSurname(name)}
          />
        )}
        {openMenu && (
          <div className={styles.menu}>
            <span>Хотите выйти из системы?</span>
            <div className={styles.buttons}>
              <Button onClick={logout} label="Выйти" />
              <Button onClick={cancel} label="Отмена" transparent={true} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
