import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';

export const accomodationColumns: TableColumn[] = [
  {
    id: 'number',
    name: '№',
  },
  {
    id: 'name',
    name: 'ФИО туриста',
  },
];
