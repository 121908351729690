import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './TabsPanel.module.scss';

interface TabsPanelProps {
  tabs: string[];
  tab?: string;
  color?: string;
  setActive?: (a: string) => void;
  className?: string;
  specialTab?: {
    tabName: string;
    count: number;
  };
}
export const TabsPanel: FC<TabsPanelProps> = ({ tabs, tab, color = '#70A4CB', setActive, className, specialTab }) => {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  useEffect(() => {
    if (tab && tab === activeTab) return;
    if (tab) {
      setActiveTab(tab);
    } else if (tabs.length > 0) {
      setActiveTab(tabs[0]);
    }
  }, [tabs, tab]);

  useEffect(() => {
    if (activeTab !== null && setActive) {
      setActive(activeTab);
    }
  }, [activeTab]);

  return (
    <div className={clsx(styles.panel, className)}>
      <ul className={styles.list}>
        {tabs.length !== 0 &&
          tabs.map((tabItem) => (
            <li key={tabItem} className={styles.item}>
              <button
                value={tabItem}
                onClick={() => setActiveTab(tabItem)}
                className={clsx(styles.button, tabItem === activeTab && styles.buttonActive)}
                style={
                  tabItem === activeTab
                    ? {
                        borderBottom: `4px solid ${color}`,
                      }
                    : {}
                }
              >
                {tabItem}
                {specialTab?.tabName === tabItem && specialTab.count > 0 ? <span>{specialTab.count}</span> : ''}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};
