import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const FalseIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 1.5L16 15.5" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" />
      <path d="M16 1.5L2 15.5" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" />
    </svg>
  );
};
