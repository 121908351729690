import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const SportIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM14.3803 12.053H12.0164L11.3653 11.1558L12.2534 8.56969L13.3081 8.22586L15.153 9.64125C15.0657 10.4901 14.8026 11.3114 14.3803 12.053ZM2.84907 9.64125L4.69055 8.22656L5.74524 8.57039L6.63328 11.1565L5.9836 12.053H3.61969C3.19693 11.3116 2.93329 10.4902 2.84555 9.64125H2.84907ZM3.55219 6.08063L3.93961 7.38844L2.86243 8.21039C2.95793 7.46461 3.18958 6.74272 3.54586 6.08063H3.55219ZM7.66407 10.6875L6.85828 8.34258L9 6.87023L11.1417 8.34258L10.3359 10.6875H7.66407ZM14.0674 7.38844L14.4548 6.08063C14.8111 6.74272 15.0428 7.46461 15.1383 8.21039L14.0674 7.38844ZM13.6315 4.90078L12.9628 7.15641L11.9032 7.50023L9.5625 5.89148V4.79602L11.6079 3.38977C12.3783 3.75004 13.0667 4.26428 13.6308 4.90078H13.6315ZM10.2706 2.94398L9 3.81727L7.72946 2.94398C8.56742 2.76867 9.43259 2.76867 10.2706 2.94398ZM6.39211 3.38977L8.4375 4.79602V5.89148L6.0975 7.50023L5.03789 7.15641L4.36922 4.90078C4.93328 4.26428 5.62172 3.75004 6.39211 3.38977ZM4.44024 13.178H5.8725L6.37664 14.6032C5.64457 14.2587 4.98679 13.7746 4.44024 13.178ZM7.73438 15.056L6.89907 12.7062L7.54805 11.8125H10.452L11.1009 12.7062L10.2691 15.056C9.43214 15.2314 8.56786 15.2314 7.73086 15.056H7.73438ZM11.6269 14.6032L12.131 13.178H13.5633C13.0157 13.7749 12.3567 14.2591 11.6234 14.6032H11.6269Z"
        fill="#1886E9"
      />
    </svg>
  );
};
