import React, { FC, useEffect, useState } from 'react';
import 'dayjs/locale/ru';

import { DatePickerInput, DatesProvider } from '@mantine/dates';
import { DateIcon } from 'components/icons';
import { InputDatePickerProps } from './InputDate.types';

export const InputDatePicker: FC<InputDatePickerProps> = ({
                                                            label,
                                                            className,
                                                            dateFrom,
                                                            dateTo,
                                                            placeholder,
                                                            setDates,
                                                            fill,
                                                            ...props
                                                          }) => {
  const [value, setValue] = useState<[Date | null, Date | null]>([dateFrom as Date, dateTo as Date]);

  //отдаем диапазон дат из календаря только когда выбраны и начало и окончание периода
  useEffect(() => {
    if (setDates && value[0] && value[1]) {
      setDates(value);
    } else if (setDates && value[0] === null && value[1] === null) {
      setDates([null, null]);
    }
  }, [value]);

  return (
    <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
      <DatePickerInput
        type='range'
        allowSingleDateInRange={true}
        clearable
        value={value}
        onChange={setValue}
        label={label}
        valueFormat='DD.MM.YYYY'
        placeholder={placeholder}
        maw={400}
        pt={11}
        icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
        className={className}
        styles={{
          root: {
            paddingTop: '0 !important',
          },
          input: {
            width: fill ? '100%' : '250px',
            height: '38px',
            border: `0.0625rem solid rgba(200, 205, 216, ${fill ? 0.6 : 0.3})`,
            borderRadius: '8px',

            paddingLeft: '46px !important',
            fontFamily: 'Museo Sans Cyrl',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#263B53',

            '&:focus': {
              border: '1px solid #C8CDD8',
            },

            '&::placeholder': {
              fontSize: '16px',
            },
          },
          icon: {
            marginLeft: '10px',
            zIndex: 1,
          },
        }}
        {...props}
      />
    </DatesProvider>
  );
};
