import React, { FC, useEffect, useRef, useState } from 'react';
import { Loader } from '@mantine/core';
import { SelectType } from './Select.type';
import styles from './Select.module.scss';
import clsx from 'clsx';
import { useRefCloseOut } from 'hooks/useRefCloseOut';

export const Select: FC<
  SelectType & {
    isWithReset?: boolean;
    onBlur?: () => void;
    disabled?: boolean;
    isShorted?: boolean;
    isFirstEl?: boolean;
  }
> = ({
  label,
  data,
  setSelect,
  onDirty,
  className,
  selected,
  onBlur = () => void 1,
  error,
  icon = null,
  isWithReset = true,
  uploaded = true,
  disabled = false,
  isShorted = false,
  isFirstEl = false,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<string | undefined>(selected);
  const selectRef = useRef() as React.MutableRefObject<HTMLUListElement>;
  const btnSelectRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [dirty, setDirty] = useState(false);

  const handleClickHeader = () => {
    !disabled && setOpened((prev) => !prev);
  };

  const handleClickItem = (item: string) => {
    const old_value = value;
    setValue(item);
    onBlur();

    if (old_value !== item) {
      setDirty(true); // помечаем измененным, если задано другое значение
    }
    setOpened(false);
  };

  useEffect(() => {
    if (dirty) {
      if (onDirty) onDirty();
      setDirty(false);
    }
  }, [dirty]);

  useEffect(() => {
    if (value && setSelect) {
      setSelect(value);
    }
  }, [value]);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  useRefCloseOut(selectRef, setOpened, btnSelectRef, isWithReset);

  return (
    <article className={clsx(styles.select, className)}>
      {label && <label className={styles.label}>{label}</label>}

      <div
        className={clsx(
          styles.header,
          opened && styles.headerOpened,
          error && styles.error,
          disabled && styles.disabled
        )}
        onClick={handleClickHeader}
        ref={btnSelectRef}
        {...props}
      >
        {icon ? <span className={styles.iconWrapper}>{icon}</span> : null}
        {isShorted ? (value && value?.length > 9 ? `${value?.substring(0, 9)}...` : value) : value}
      </div>
      {opened && (
        <ul className={clsx(styles.list, isFirstEl ? styles.first : styles.second)} ref={selectRef}>
          {uploaded ? (
            data.map((item, key) => (
              <li
                key={item + key}
                onClick={() => handleClickItem(item)}
                className={clsx(styles.item, selected && selected === item ? styles.selected : '')}
              >
                {item}
              </li>
            ))
          ) : (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
          )}
        </ul>
      )}
    </article>
  );
};
