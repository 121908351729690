import { BasicInfoTabs } from 'core/constants/enums';
import { OrderProps } from 'pages/Orders/Order.types';
// BasicInfoTabs.CHAT
export const getOrderTabs = (order: OrderProps) => {
  if (!order) return [BasicInfoTabs.HISTORY];
  if (order.id) {
    if (order.site_request) {
      return [BasicInfoTabs.HISTORY, BasicInfoTabs.CHAT, BasicInfoTabs.REQUEST];
    }
    return [BasicInfoTabs.HISTORY, BasicInfoTabs.CHAT];
  }
  return [];
};
