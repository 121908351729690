import React, { FC, useEffect, useState } from 'react';
import styles from './GalleryForm.module.scss';
import { AlertIcon } from 'components/icons/AlertIcon';
import { Input } from 'ui/Input';
import { useForm } from '@mantine/form';
import { MultiSelector } from 'ui/MultiSelector';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { Checkbox } from 'ui/Checkbox';
import { Photo, PhotoRes } from 'services/gallery/galleryApi.service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDate } from 'core/utils/getDate';
import { setPreSave } from 'store/entities/gallery/gallerySlice';
import { addPhotoTag, updatePhoto } from 'store/action-creators/gallery';
import { declOfNum } from 'core/utils/declOfNum';
import { notifications } from '@mantine/notifications';
import clsx from 'clsx';

type PhotoProps = {
  name: string;
  img: string;
  alt: string;
  tags: SelectMultiItemType[];
  is_license: boolean;
};

export const GalleryForm: FC<{ selectedPhoto: PhotoRes }> = ({ selectedPhoto }) => {
  const { preSave, tags, isSuccess, isError } = useAppSelector((state) => state.gallerySlice);
  const dispatch = useAppDispatch();
  const [isEdit, setisEdit] = useState(false);
  const [viewTourHelp, setViewTourHelp] = useState(false);
  const [tagList, setTagList] = useState(tags);

  useEffect(() => {
    if (tags) {
      setTagList(tags);
    }
  }, [tags]);

  const initialValues: PhotoProps = {
    name: preSave.title || selectedPhoto.title || '',
    img:
      `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${preSave.name}` ||
      `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${selectedPhoto.name}` ||
      '',
    alt: preSave.alt || selectedPhoto.alt || '',
    tags: preSave.photo_tags || selectedPhoto.photo_tags || [],
    is_license: preSave.is_license || selectedPhoto.is_license || false,
  };
  const [isBuyed, setisBuyed] = useState(preSave.is_license || false);
  const formPhoto = useForm({
    initialValues,
  });

  useEffect(() => {
    formPhoto.setFieldValue('name', preSave.title);
  }, [preSave.name]);

  useEffect(() => {
    formPhoto.setFieldValue('alt', preSave.alt);
  }, [preSave.alt]);

  useEffect(() => {
    formPhoto.setFieldValue('tags', preSave.photo_tags);
  }, [preSave.photo_tags]);

  useEffect(() => {
    dispatch(
      setPreSave({
        ...preSave,
        title: formPhoto.values.name,
        alt: formPhoto.values.alt,
        photo_tags: formPhoto.values.tags,
        is_license: isBuyed,
      })
    );
  }, [formPhoto.values]);
  useEffect(() => {
    if (isEdit) {
      const mappedData = {
        id: preSave.id,
        title: formPhoto.values.name || formPhoto.values.name,
        alt: preSave.alt || formPhoto.values.alt,
        photo_tags:
          formPhoto.values.tags.map((tag) => (tag['@id'] ? tag['@id'] : `/api/v1/photo-tags/${tag.id}`)) ||
          preSave.photo_tags.map((tag) => (tag['@id'] ? tag['@id'] : `/api/v1/photo-tags/${tag.id}`)),
        is_license: isBuyed,
      };
      dispatch(updatePhoto(mappedData));
      setisEdit(false);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isSuccess === 'UPDATE_PHOTO') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Данные изменены', id: 'UPDATE_PHOTO' });
    }
    setisEdit(false);
    if (isError === 'UPDATE_PHOTO') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'UPDATE_PHOTO_ERROR',
      });
    }
  }, [isSuccess, isError]);

  return (
    <div className={styles.container}>
      {preSave.name && (
        <img
          className={styles.image}
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/images/${preSave.name}`}
          alt={preSave.alt}
        />
      )}

      <div className={styles.useInfo}>
        <div className={styles.useInfoItem}>
          <p>
            Использовано:{' '}
            <span>{`${selectedPhoto.used_count} ${declOfNum(selectedPhoto.used_count, ['раз', 'раза', 'раз'])}`}</span>
          </p>
          <span
            className={styles.helper}
            onMouseMove={() => setViewTourHelp(true)}
            onMouseLeave={() => setViewTourHelp(false)}
          >
            <AlertIcon />
          </span>
          {viewTourHelp && preSave.used_tours.length > 0 && (
            <div className={clsx(styles.helpBlock, styles.durationHelp)}>
              {preSave.used_tours.map((el) => el).join(',')}
            </div>
          )}
        </div>
        <div className={styles.useInfoItem}>
          <p>
            Входит в{' '}
            <span>{`${selectedPhoto.albums_count} ${declOfNum(selectedPhoto.albums_count, [
              'альбом',
              'альбома',
              'альбомов',
            ])}`}</span>
          </p>
          <AlertIcon />
        </div>
      </div>
      <div className={styles.inputGroup}>
        <Input
          type="text"
          label="ALT фото"
          name="Название фото"
          {...formPhoto.getInputProps('alt')}
          onBlur={() => setisEdit(true)}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.subtitle}>Теги фото</div>
        <MultiSelector
          className={styles.select}
          allItems={tagList}
          defaultItems={formPhoto.values.tags}
          getItems={(value) => {
            formPhoto.setFieldValue('tags', value);
            setisEdit(true);
          }}
          creatable
          onCreate={(query) => {
            setTagList((prev) => [...prev, { name: query }]);
            dispatch(addPhotoTag(query));
            return query;
          }}
        />
      </div>
      <Checkbox
        label="Лицензионное фото"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onBlur={() => setisEdit(true)}
        isChecked={isBuyed}
        setIsChecked={setisBuyed}
      />
      <ul className={styles.listInfo}>
        <li>Название фото: {selectedPhoto.title}</li>
        <li>Дата загрузки фото: {getDate(new Date(selectedPhoto.created_at))}</li>
        <li>Дата последнего изменения: {getDate(new Date(selectedPhoto.updated_at))}</li>
        <li>Путь к файлу на сервере: {`/uploads/images/${preSave.name}`} </li>
        <li>URL фото: {`${process.env.REACT_APP_BASE_API_URL}/uploads/images/${preSave.name}`}</li>
      </ul>
    </div>
  );
};
