import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const PlusIcon: FC<IconProps> = ({ width, height, stroke }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 2L7.5 13" stroke={stroke} strokeWidth="2.3" strokeLinecap="round" />
      <path d="M13 7.5L2 7.5" stroke={stroke} strokeWidth="2.3" strokeLinecap="round" />
    </svg>
  );
};
