import React, { FC, useEffect, useState } from 'react';

import { OrderAction } from 'pages/Orders/Order.types';
import { User } from 'components/User';

import styles from './ActionItem.module.scss';
import { useAppSelector } from 'store/hooks';
import { srcToFile } from '../../../services/orders/orders.adapter';
import ImageFile from '../ImageFile';

export type ActionItemProps = {
  action: OrderAction;
};

export const ActionItem: FC<ActionItemProps> = ({ action }) => {
  const { user, changeManager, status, date, description, filePaths = [], files: inFiles } = action;

  const currentUser = useAppSelector((state) => state.users.currentUser);
  const currentUserName = currentUser?.first_name || '' + currentUser?.last_name || '';

  const outputDate = (date: Date) => {
    let textDate = '';
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();
    const dateFromAction = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();

    if (today === dateFromAction) {
      textDate = 'Сегодня' + ' ' + date.getHours() + '.' + date.getMinutes();
    } else {
      textDate = date.toLocaleString();
    }
    return textDate;
  };

  const [files, setFiles] = useState<File[]>();

  useEffect(() => {
    let active = true;
    if (filePaths.length > 0) {
      load();
    } else setFiles([]);

    return () => {
      active = false;
    };

    async function load() {
      setFiles(undefined); // this is optional
      const res = await Promise.all(
        filePaths.map(async (i) => {
          return await srcToFile(`${process.env.REACT_APP_BASE_API_URL}${i}`, i.substring(i.lastIndexOf('/') + 1));
        })
      );
      if (!active) {
        return;
      }
      setFiles(res);
    }
  }, [filePaths.length]);

  return (
    <article className={styles.action}>
      <div className={styles.avatar}>
        <User
          id={currentUser?.id || 321321}
          avatar={currentUser?.avatar}
          name={currentUserName}
          viewName={false}
          className={styles.user}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.manager}>{user}</div>
          <div className={styles.date}>{outputDate(date)}</div>
        </div>
        <div className={styles.bottom}>
          {description && (
            <div className={styles.description}>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
          )}

          {files && files.length > 0 && (
            <div className={styles.files}>
              {files.map((file, key) => (
                <ImageFile
                  key={file.name}
                  preview={filePaths[key]}
                  name={file.name}
                  size={file.size}
                  type={file.type}
                  deleteFile={() => console.log('hi')}
                />
              ))}
            </div>
          )}

          {inFiles && inFiles.length > 0 && (
            <div className={styles.files}>
              {inFiles.map((file, key) => (
                <ImageFile
                  key={file.name}
                  preview={URL.createObjectURL(file as Blob)}
                  name={file.name}
                  size={file.size}
                  type={file.type}
                  deleteFile={() => console.log('hi')}
                />
              ))}
            </div>
          )}

          {status && (
            <div className={styles.status}>
              Назначен статус <span>{status}</span>
            </div>
          )}
          {changeManager && (
            <div className={styles.status}>
              Назначен менеджер <span>{changeManager}</span>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};
