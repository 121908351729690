import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styles from './PhotoList.module.scss';
import ModalContext from 'core/context/ModalContext';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Button } from 'ui/Button';
import { getPhotos, getTags } from 'store/action-creators/gallery';
import { Loader } from '@mantine/core';
import { Photo, PhotoData } from 'services/gallery/galleryApi.service';
import {
  setIsOpenPhotoList,
  setParamsDateFrom,
  setParamsDateTo,
  setParamsPage,
  setParamsSearch,
  setParamsTags,
} from 'store/entities/gallery/gallerySlice';
import clsx from 'clsx';
import { Search } from 'components/Search';
import { InputDatePicker } from 'ui/InputDatePicker';
import { FilterNew } from 'ui/FilterNew/FilterNew';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { getTimezonelessDate } from 'core/utils/getDate';
import { RouteNames } from 'core/constants/routeNames';
import { useLocation } from 'react-router-dom';

export const PhotoList: FC<{ setPhotos: (a: Photo[]) => void; selectedPhotos: Photo[] }> = ({
  setPhotos,
  selectedPhotos,
}) => {
  const { photos, tags, photoCount, params, isLoading, isSuccess } = useAppSelector((state) => state.gallerySlice);
  const location = useLocation();
  const { setActive } = useContext(ModalContext);
  const [photoData, setPhotoData] = useState<PhotoData | null>(null);
  const [photoList, setPhotoList] = useState<Photo[]>([]);
  const dispatch = useAppDispatch();
  const [selectedIds, setSelectedIds] = useState<Photo[]>(selectedPhotos || []);
  const [countPages, setCountPages] = useState<number>(1);
  // при изменении параметров для запроса, отправляем запрос на получение заказов
  useEffect(() => {
    dispatch(getTags());
  }, []);
  useEffect(() => {
    if (photoData) {
      dispatch(getPhotos(photoData));
    }
  }, [photoData]);
  useEffect(() => {
    if (params.currentPage === 1) {
      setPhotoList(photos);
    } else {
      setPhotoList((pre: any) => {
        const combinedArray = pre.concat(photos);
        const uniqueArray = combinedArray.filter(
          (item: any, index: number, arr: any[]) => arr.findIndex((el) => el.id === item.id) === index
        );
        return uniqueArray;
      });
    }
  }, [photos]);
  useEffect(() => {
    setCountPages(Math.ceil(photoCount / params.itemsPerPage));
  }, [photoCount, params.itemsPerPage]);
  //при изменении значений фильтров, меняем параметры для запроса на сервер
  useEffect(() => {
    setPhotoData({
      ...photoData,
      page: params.currentPage,
      search: params.search === '' ? null : params.search,
      dateFrom: params['created_at[after]'] ? params['created_at[after]'] : null,
      dateTo: params['created_at[before]'] ? params['created_at[before]'] : null,
      itemsPerPage: params.itemsPerPage,
      photo_tags: params.photo_tags.length > 0 ? params.photo_tags : null,
    });
  }, [params]);

  const getNextPage = () => {
    dispatch(setParamsPage(params.currentPage + 1));
  };
  const myRef = useRef<any>(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  useEffect(() => {
    if (isSuccess === 'GET_GALLERY_PHOTOS' && params.currentPage > 1) {
      executeScroll();
    }
  }, [isSuccess]);

  const handleSave = () => {
    setPhotos(selectedIds);
    if (location.pathname === RouteNames.GALLERY) {
      dispatch(setIsOpenPhotoList(false));
      return;
    }
    setActive(false);
    dispatch(setParamsDateFrom(null));
    dispatch(setParamsDateTo(null));
    dispatch(setParamsPage(1));
    dispatch(setParamsTags([]));
    dispatch(setParamsSearch(''));
  };

  const toggleActiveItem = (photo: Photo) => {
    if (selectedIds.find((item) => Number(item.id) === Number(photo.id))) {
      setSelectedIds(selectedIds.filter((i) => i.id !== photo.id));
    } else {
      setSelectedIds([...selectedIds, photo]);
    }
  };

  const handleSearchClick = (query: string) => {
    dispatch(setParamsSearch(query));

    dispatch(setParamsPage(1));
  };
  const handleTags = (query: SelectMultiItemType) => {
    if (query) {
      dispatch(setParamsTags([query['@id']!]));

      dispatch(setParamsPage(1));
    }
    if (query === null) {
      dispatch(setParamsTags([]));

      dispatch(setParamsPage(1));
    }
  };
  const handleDataPicker = (value: [Date | null, Date | null]) => {
    console.log(value);
    if (value[0] != null) {
      dispatch(setParamsDateFrom(getTimezonelessDate(new Date(value[0]!))));
      dispatch(
        setParamsDateTo(
          new Date(value[0]).getTime() === new Date(value[1]!).getTime()
            ? null
            : getTimezonelessDate(new Date(value[1]!))
        )
      );
    } else {
      dispatch(setParamsDateFrom(null));
      dispatch(setParamsDateTo(null));
    }
    dispatch(setParamsPage(1));
  };
  return (
    <section className={styles.edit}>
      <div className={styles.top}>
        <h2 className={styles.title}>Выбор фото</h2>
        <Button onClick={handleSave}>Сохранить выбор</Button>
      </div>
      <div className={styles.content}>
        <div className={styles.filters}>
          <Search placeholder="Поиск" setQuery={handleSearchClick} />
          <InputDatePicker placeholder={'Дата загрузки'} setDates={handleDataPicker} />
          <FilterNew
            filter={{
              title: 'Теги',
              data: tags,
            }}
            setFilter={(a) => handleTags(a!)}
          />
        </div>
        <div className={styles.counts}>
          <span>Всего: {photoCount} фото</span>
          <span>Выбрано: {selectedIds.length} фото</span>
        </div>
        <div className={styles.table}>
          {isLoading && isLoading === 'GET_GALLERY_PHOTOS' && (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
          )}
          <div className={styles.list}>
            {isLoading !== 'GET_GALLERY_PHOTOS' &&
              photos &&
              photos.length > 0 &&
              photoList.map((el, idx) => (
                <article
                  className={clsx(
                    styles.article,
                    selectedIds.find((item) => Number(item.id) === Number(el.id)) && styles.selected
                  )}
                  key={idx}
                  onClick={() => toggleActiveItem(el)}
                >
                  <div className={styles.imgContainer}>
                    <img
                      src={
                        el.thumbnail_name
                          ? `${process.env.REACT_APP_BASE_API_URL}/uploads/thumbnails/${el.thumbnail_name}`
                          : `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${el.name}`
                      }
                      alt={el.alt}
                    />
                    {el.is_license && <span>Лицензия</span>}
                  </div>
                  <p>{el.title && el.title.length > 0 ? `${el.title.substring(0, 18)}...` : el.title}</p>
                </article>
              ))}
          </div>

          {isSuccess === 'GET_GALLERY_PHOTOS' && photoList.length === 0 && (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
          <Button onClick={() => getNextPage()}>Показать ещё</Button>
          <span ref={myRef} />
        </div>
      </div>
    </section>
  );
};
