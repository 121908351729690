import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './GalleryEdit.module.scss';
import { RemoveIcon } from 'components/icons';
import { GalleryForm } from '../components/GalleryForm';
import { Photo, PhotoRes } from 'services/gallery/galleryApi.service';
import { ModalConfirm } from 'pages/Tours/components/TourForm/ModalConfirm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deletePhoto } from 'store/action-creators/gallery';
import { notifications } from '@mantine/notifications';
import ModalContext from 'core/context/ModalContext';

export const GalleryEdit: FC<{ selectedPhoto?: PhotoRes }> = ({ selectedPhoto }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { isSuccess, isError } = useAppSelector((state) => state.gallerySlice);
  const { setActive } = useContext(ModalContext);

  useEffect(() => {
    if (isSuccess === 'DELETE_PHOTO') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Фото удалено', id: 'DELETE_PHOTO' });
      setActive(false);
    }
    if (isError === 'DELETE_PHOTO') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'DELETE_PHOTO_ERROR',
      });
    }
  }, [isSuccess, isError]);

  return (
    <section className={styles.edit}>
      <div className={styles.top}>
        <h2 className={styles.title}>Фото №{selectedPhoto?.id}</h2>
        <span className={styles.remove} onClick={() => setIsModalOpen(true)}>
          <RemoveIcon width={16} height={16} fill="#C8CDD8" />
        </span>
      </div>
      <div className={styles.content}>{selectedPhoto && <GalleryForm selectedPhoto={selectedPhoto} />}</div>
      <ModalConfirm
        isOpen={isModalOpen}
        text={`удалить фото №${selectedPhoto?.id}`}
        handleYes={() => {
          if (selectedPhoto?.id) {
            dispatch(deletePhoto(String(selectedPhoto?.id)));
            setIsModalOpen(false);
          }
        }}
        handleNo={() => {
          setIsModalOpen(false);
        }}
      />
    </section>
  );
};
