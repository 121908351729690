import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';

import { RequisitesTabProps } from './RequisitesTab.types';
import { Input } from 'ui/Input';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setPreSaveLegalPerson } from 'store/entities/partners/partnersSlice';

import styles from './styles.module.scss';
import { BinIcon } from 'components/icons/BinIcon';
import { ModalConfirm } from 'pages/Tours/components/TourForm/ModalConfirm';

export const RequisitesTab: FC<RequisitesTabProps> = ({ requisites, setRequisites, markDirty }) => {
  const { preSaveLegalPerson, isResetPreSaveLegalPerson } = useAppSelector((state) => state.partners);
  const dispatch = useAppDispatch();
  const [blur, setBlur] = useState(false);
  const [focus, setFocus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletedReq, setDeletedReq] = useState<{ id: number; name: string } | null>(null);

  const formRequisites = useForm({
    initialValues: {
      requisites:
        preSaveLegalPerson.requisites.length > 0
          ? preSaveLegalPerson.requisites
          : [
              {
                legalName: '',
                inn: '',
                kpp: '',
                ogrn: '',
                bik: '',
                correspondentAccount: '',
                settlementAccount: '',
                contract: '',
                address: {
                  legal: '',
                  real: '',
                },
                position: '',
                responsiblePerson: {
                  name: '',
                  permission: '',
                },
              },
            ],
    },
    // validate,
    validateInputOnChange: true,
  });

  useEffect(() => {
    setRequisites(formRequisites.values.requisites);
  }, [formRequisites.values]);

  //если форму начали заполнять и в ней нет ошибок, данные уходят в предсохранении и оттуда на сервер
  useEffect(() => {
    if (JSON.stringify(formRequisites.errors) === '{}') {
      dispatch(setPreSaveLegalPerson({ ...preSaveLegalPerson, requisites: formRequisites.values.requisites }));
    } else {
      console.log('Не заполнены обязательные поля формы');
    }
  }, [formRequisites.values, formRequisites.errors]);

  // useEffect(() => {
  //   if (focus && Object.values(formRequisites.errors).length === 0) {
  //     setFocus(false);
  //     markDirty();
  //   }
  // }, [formRequisites.errors, focus]);

  //если первое поле начали заполнять - то все остальные уже обязательны
  useEffect(() => {
    if (blur) {
      formRequisites.validate();
    }
  }, [blur]);
  console.log(formRequisites.values.requisites);

  const handleDelete = (id: number) => {
    setBlur(true);
    setFocus(true);
    formRequisites.removeListItem('requisites', id);
    setIsModalOpen(false);
    setDeletedReq(null);
    markDirty();
  };
  return (
    <div className={styles.requisitesTab}>
      <div className={styles.top}>
        <h2>Реквизиты</h2>
        <button
          className={styles.add}
          onClick={() =>
            formRequisites.insertListItem('requisites', {
              legalName: '',
              inn: '',
              kpp: '',
              ogrn: '',
              bik: '',
              correspondentAccount: '',
              settlementAccount: '',
              address: {
                legal: '',
                real: '',
              },
              responsiblePerson: {
                name: '',
                permission: '',
              },
            })
          }
        >
          +
        </button>
      </div>
      <ModalConfirm
        isOpen={isModalOpen}
        text={`удалить ${deletedReq?.name}`}
        handleYes={() => {
          deletedReq && handleDelete(deletedReq.id);
        }}
        handleNo={() => {
          setIsModalOpen(false);
          setDeletedReq(null);
        }}
      />
      {formRequisites.values.requisites?.map((item, idx) => (
        <form className={styles.requisitesForm} key={idx}>
          <button
            className={styles.deleteBtn}
            onClick={(e) => {
              e.preventDefault();
              setDeletedReq({ id: idx, name: String(formRequisites.values.requisites[idx].legalName) });
              setIsModalOpen(true);
            }}
          >
            <BinIcon width={14} height={14} fill="#5B6572" />
          </button>
          <div className={styles.group}>
            <Input
              label="Юридическое наименование"
              {...formRequisites.getInputProps(`requisites.${idx}.legalName`)}
              className={styles.input}
              onBlur={() => {
                setBlur(true);
                setFocus(true);
              }}
              error={!!formRequisites.errors.legalName}
            />
            <Input
              type="number"
              label="ОГРН/ОГРНИП"
              {...formRequisites.getInputProps(`requisites.${idx}.ogrn`)}
              className={clsx(styles.input, styles.number)}
              onBlur={setFocus}
              error={!!formRequisites.errors.ogrn}
              onWheel={(e: any) => e.target.blur()}
            />
            <Input
              type="number"
              label="ИНН"
              {...formRequisites.getInputProps(`requisites.${idx}.inn`)}
              className={clsx(styles.input, styles.number)}
              onBlur={setFocus}
              error={!!formRequisites.errors.inn}
              onWheel={(e: any) => e.target.blur()}
            />
            <Input
              type="number"
              label="КПП"
              {...formRequisites.getInputProps(`requisites.${idx}.kpp`)}
              className={clsx(styles.input, styles.number)}
              onBlur={setFocus}
              error={!!formRequisites.errors.kpp}
              onWheel={(e: any) => e.target.blur()}
            />
          </div>
          <div className={styles.dobbleGroup}>
            <div className={styles.textarea}>
              <label className={styles.textareaLabel}>Юридический адрес</label>
              <textarea
                {...formRequisites.getInputProps(`requisites.${idx}.address.legal`)}
                className={clsx(
                  styles.textareaText,
                  styles.address,
                  !!formRequisites.errors['address.legal'] && styles.textareaTextError
                )}
                onBlur={setFocus}
              />
            </div>
            <div className={styles.textarea}>
              <label className={styles.textareaLabel}>Фактический адрес</label>
              <textarea
                {...formRequisites.getInputProps(`requisites.${idx}.address.real`)}
                className={clsx(
                  styles.textareaText,
                  styles.address,
                  !!formRequisites.errors['address.real'] && styles.textareaTextError
                )}
                onBlur={setFocus}
              />
            </div>
          </div>
          <div className={styles.specialGroup}>
            <Input
              label={
                <>
                  ФИО ответственного
                  <br /> по договору (в род. падеже)
                </>
              }
              {...formRequisites.getInputProps(`requisites.${idx}.responsiblePerson.name`)}
              className={styles.input}
              onBlur={setFocus}
              error={!!formRequisites.errors['responsiblePerson.name']}
            />
            <Input
              label="Должность"
              {...formRequisites.getInputProps(`requisites.${idx}.position`)}
              className={styles.input}
              onBlur={setFocus}
            />
          </div>
          <div className={styles.dobbleGroup}>
            <Input
              label="Договор"
              {...formRequisites.getInputProps(`requisites.${idx}.contract`)}
              className={styles.input}
              onBlur={setFocus}
              error={!!formRequisites.errors['responsiblePerson.name']}
            />
            <Input
              label="Действует на основании"
              {...formRequisites.getInputProps(`requisites.${idx}.responsiblePerson.permission`)}
              className={styles.input}
              onBlur={setFocus}
              error={!!formRequisites.errors['responsiblePerson.permission']}
            />
          </div>
          <h3 className={styles.reqTitle}>Платежные реквизиты</h3>
          <div className={clsx(styles.group, styles.requisitesBottom)}>
            <Input
              type="number"
              label="БИК"
              {...formRequisites.getInputProps(`requisites.${idx}.bik`)}
              className={clsx(styles.input, styles.number)}
              onWheel={(e: any) => e.target.blur()}
            />
            <Input
              type="number"
              label="Корреспондентский счёт"
              {...formRequisites.getInputProps(`requisites.${idx}.correspondentAccount`)}
              className={clsx(styles.input, styles.number)}
              onBlur={setFocus}
              error={!!formRequisites.errors.correspondentAccount}
              onWheel={(e: any) => e.target.blur()}
            />
            <Input
              type="number"
              label="Расчетный счёт"
              {...formRequisites.getInputProps(`requisites.${idx}.settlementAccount`)}
              className={clsx(styles.input, styles.number)}
              onBlur={setFocus}
              error={!!formRequisites.errors.settlementAccount}
              onWheel={(e: any) => e.target.blur()}
            />
          </div>
        </form>
      ))}
    </div>
  );
};
