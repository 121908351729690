import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const PdfIcon: FC<IconProps> = ({ width = '15', height = '19', fill = '#5D96C1' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.87355 10.8232L7.87991 10.7935C8.0029 10.2867 8.1577 9.6548 8.03683 9.08225C7.95625 8.63058 7.62333 8.45458 7.33917 8.44185C7.00413 8.42701 6.70513 8.61786 6.63092 8.89565C6.49096 9.40458 6.61607 10.1001 6.84509 10.9865C6.5567 11.6735 6.09654 12.6723 5.75938 13.2661C5.1317 13.5905 4.28984 14.091 4.16473 14.7229C4.13929 14.8395 4.16897 14.9879 4.23895 15.1215C4.31741 15.27 4.44252 15.3845 4.58884 15.4396C4.65246 15.4629 4.7288 15.482 4.81786 15.482C5.19107 15.482 5.79542 15.1809 6.60123 13.7983C6.72422 13.758 6.85145 13.7156 6.97444 13.6732C7.55123 13.4781 8.14922 13.2746 8.68996 13.1834C9.28795 13.5036 9.96864 13.7093 10.4309 13.7093C10.889 13.7093 11.0692 13.4378 11.1371 13.2746C11.2558 12.9883 11.1985 12.6278 11.0056 12.4348C10.7257 12.1592 10.045 12.0871 8.98471 12.2185C8.46306 11.9004 8.12165 11.4679 7.87355 10.8232ZM5.54732 14.0443C5.25257 14.4727 5.02991 14.6868 4.90904 14.7801C5.05112 14.5193 5.32891 14.2436 5.54732 14.0443ZM7.40491 9.05045C7.51518 9.23917 7.50034 9.8096 7.41551 10.098C7.31161 9.676 7.29676 9.07801 7.35826 9.00803C7.37522 9.01016 7.39007 9.02288 7.40491 9.05045ZM7.37098 11.6057C7.59788 11.998 7.88415 12.3352 8.20011 12.5854C7.74208 12.6893 7.32433 12.861 6.95112 13.0137C6.86205 13.0498 6.77511 13.0858 6.69029 13.1198C6.97232 12.6087 7.2077 12.0298 7.37098 11.6057ZM10.6705 12.9946C10.6727 12.9989 10.6748 13.0052 10.6621 13.0137H10.6578L10.6536 13.0201C10.6366 13.0307 10.4627 13.1325 9.71417 12.8377C10.5751 12.7974 10.6684 12.9925 10.6705 12.9946ZM14.7292 4.76272L10.1658 0.19933C10.0386 0.0720982 9.86685 0 9.68661 0H0.678571C0.303237 0 0 0.303237 0 0.678571V18.3214C0 18.6968 0.303237 19 0.678571 19H14.25C14.6253 19 14.9286 18.6968 14.9286 18.3214V5.24408C14.9286 5.06384 14.8565 4.88996 14.7292 4.76272ZM13.3636 5.5558H9.37277V1.56496L13.3636 5.5558ZM13.4018 17.4732H1.52679V1.52679H7.9308V6.10714C7.9308 6.34335 8.02464 6.56989 8.19166 6.73691C8.35869 6.90393 8.58522 6.99777 8.82143 6.99777H13.4018V17.4732Z"
        fill={fill}
      />
    </svg>
  );
};
