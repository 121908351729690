import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  AddPartnerData,
  ContactTypesData,
  EditPartnerData,
  GetPartnerData,
  PartnersData,
  addPartnerAPI,
  contactTypesAPI,
  editPartnerAPI,
  getPartnerAPI,
  partnersAPI,
  RemoveDocumentData,
  removePartnerDocumentAPI,
  partnersAPIByQuery,
  addDocumentAPI,
} from 'services/partners/partnerApi.service';

// добавление контрагента
export const addPartner = createAsyncThunk('partners/addPartner', async (arg: AddPartnerData, thunkAPI) => {
  try {
    const response = await addPartnerAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка типов контрагентов
export const getContactTypes = createAsyncThunk('partners/getContactTypes', async (arg: ContactTypesData, thunkAPI) => {
  try {
    const response = await contactTypesAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка контрагентов
export const getPartners = createAsyncThunk('partners/getPartners', async (arg: PartnersData, thunkAPI) => {
  try {
    const response = await partnersAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка туристов
export const getTouristsForSearch = createAsyncThunk(
  'partners/getTouristsForSearch',
  async (arg: PartnersData, thunkAPI) => {
    try {
      const response = await partnersAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
// получение списка плательщиков
export const getPayersForSearch = createAsyncThunk(
  'partners/getPayersForSearch',
  async (arg: PartnersData, thunkAPI) => {
    try {
      const response = await partnersAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
// получение списка туристов
export const getTourOperatorsForSearch = createAsyncThunk(
  'partners/getTourOperatorsForSearch',
  async (arg: PartnersData, thunkAPI) => {
    try {
      const response = await partnersAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
// получение списка контрагентов
export const getPartnersNextPage = createAsyncThunk(
  'partners/getPartnersNextPage',
  async (pageQuery: string, thunkAPI) => {
    try {
      const response = await partnersAPIByQuery(pageQuery);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

// получение контрагента по id
export const getPartner = createAsyncThunk('partners/getPartner', async (arg: GetPartnerData, thunkAPI) => {
  try {
    const response = await getPartnerAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// редактирование контрагента по id
export const editPartner = createAsyncThunk('partners/editPartner', async (arg: EditPartnerData, thunkAPI) => {
  try {
    const response = await editPartnerAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// удаление документа контрагента по id
export const removePartnerDocument = createAsyncThunk(
  'partners/removeDocument',
  async (arg: RemoveDocumentData, thunkAPI) => {
    try {
      const response = await removePartnerDocumentAPI(arg);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
export const addDocument = createAsyncThunk('partners/addDocument', async (arg: { file: File[] }, thunkAPI) => {
  try {
    const response = await addDocumentAPI({ file: arg.file[0] });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const addAgrementDocument = createAsyncThunk(
  'partners/addAgrementDocument',
  async (arg: { file: File }, thunkAPI) => {
    try {
      const response = await addDocumentAPI({ file: arg.file });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
