import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TourHotelInfo, TourProps, TripInfo } from 'pages/Tours/Tours.types';
import {
  addTour,
  deleteHotel,
  deleteHotelPlacement,
  deletePlacement,
  deleteTrip,
  editTour,
  getAllTours,
  getAllToursForSearch,
  getCities,
  getCountries,
  getDirections,
  getGuides,
  getHotelRoomTypes,
  getRealTours,
  getTags,
  getTour,
  getTourDate,
  getTourDates,
  getTourDatesList,
  getTourDatesPlacements,
  getTourFactory,
  getTourHotel,
  getTourHotels,
  getTourHotelsMin,
  getTourHotelsPlacements,
  getTourNights,
  getTourOperators,
  getTourPeriods,
  getTourProgram,
  getTours,
  getTourTypes,
  getTransports,
} from 'store/action-creators/tours';
import {
  AllToursResponse,
  TourDocumentResponse,
  TourParams,
  TourResponse,
  ToursResponse,
  TripResponse,
} from 'services/tours/ToursResponse.model';
import {
  allTourShortFactoryIn,
  tourDatesFactoryIn,
  tourFactoryIn,
  tourHotelsFactoryIn,
  toursFactory,
  tourShortFactoryIn,
} from 'services/tours/tours.factory';
import { openInNewTab } from '../../../core/utils/openInNewTab';
import { initialState } from './toursData';

export const toursSlice = createSlice({
  name: 'tours',
  initialState: initialState,
  reducers: {
    setParamsPage(state, action: PayloadAction<number>) {
      state.params.currentPage = action.payload;
    },
    setParamsSearch(state, action: PayloadAction<string>) {
      state.params.search = action.payload;
    },
    setParamsManager(state, action: PayloadAction<string>) {
      state.params.manager = action.payload;
    },
    setParamsStatus(state, action: PayloadAction<string | null>) {
      state.params.status = action.payload;
    },
    setParamsDirection(state, action: PayloadAction<string | null>) {
      state.params.tour_directions = action.payload;
    },
    setParamsDateFrom(state, action: PayloadAction<Date | null>) {
      state.params.dateFrom = action.payload;
    },
    setParamsDateTo(state, action: PayloadAction<Date | null>) {
      state.params.dateTo = action.payload;
    },
    setParamsSortById(state, action: PayloadAction<'asc' | 'desc'>) {
      state.params.sortById = action.payload;
    },
    setParamsSortByName(state, action: PayloadAction<'asc' | 'desc'>) {
      state.params.sortByName = action.payload;
    },

    resetCurrentTour(state) {
      state.currentTour = null;
    },
    resetSelectedTour(state) {
      state.selectedTour = initialState.selectedTour;
      state.hotelsPlacements = initialState.hotelsPlacements;
      state.searchedHotels = initialState.searchedHotels;
      state.tourDateList = initialState.tourDateList;
      state.tourDateNight = initialState.tourDateNight;
      state.tourDatePlacements = initialState.tourDatePlacements;
    },
    setIsEditedTour(state, action: PayloadAction<boolean>) {
      state.isEditedTour = action.payload;
    },

    setIsClonedTrip(state, action: PayloadAction<boolean>) {
      state.isClonedTrip = action.payload;
    },

    setPreSaveTour(state, action: PayloadAction<TourProps>) {
      state.preSaveTour = action.payload;
    },
    resetPreSaveTour: (state) => {
      state.preSaveTour = initialState.preSaveTour;
      state.isResetPreSaveTour = true;
    },

    setErrorTourTitle(state, action: PayloadAction<boolean>) {
      state.errorsValidateTour.title = action.payload;
    },
    setErrorTourOperator(state, action: PayloadAction<boolean>) {
      state.errorsValidateTour.operator = action.payload;
    },
    setErrorTripDate(state, action: PayloadAction<boolean>) {
      state.errorsValidateTour.tripDate = action.payload;
    },
    setErrorTripStatus(state, action: PayloadAction<boolean>) {
      state.errorsValidateTour.tripStatus = action.payload;
    },
    setCurrentTourTripsInfo(state, action: PayloadAction<TripInfo[] | null | undefined>) {
      state.currentTour!.tripsInfo = action.payload;
    },
    setCurrentTourHotelInfo(state, action: PayloadAction<TourHotelInfo[] | undefined>) {
      state.currentTour!.tourHotels = action.payload;
    },
    setIdTour(state, action: PayloadAction<number>) {
      state.idNewTour = action.payload;
    },
  },

  extraReducers: (builder) => {
    //получение списка поездок
    builder.addCase(getTours.fulfilled, (state, action: PayloadAction<ToursResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOURS';
      state.tours = action.payload['hydra:member'].map((tour) => tourShortFactoryIn(tour));
      state.countTours = action.payload['hydra:totalItems'];
    });
    builder.addCase(getTours.pending, (state) => {
      state.isLoading = 'GET_TOURS';
    });
    builder.addCase(getTours.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOURS';
    });

    //получение списка туров
    builder.addCase(getRealTours.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOURS';
      state.realTours = action.payload['hydra:member'].map((tour: any) => toursFactory(tour));
      state.countTours = action.payload['hydra:totalItems'];
    });
    builder.addCase(getRealTours.pending, (state) => {
      state.isLoading = 'GET_TOURS';
    });
    builder.addCase(getRealTours.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOURS';
    });
    //получение списка контрагентов для поиска в заказах
    builder.addCase(getAllToursForSearch.fulfilled, (state, action: PayloadAction<any>) => {
      //TODO move that to another place for decompose
      state.toursForSearch = action.payload['hydra:member'];
    });
    //получение списка туров без поездок
    builder.addCase(getAllTours.fulfilled, (state, action: PayloadAction<AllToursResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_ALL_TOURS';
      state.allTours = action.payload['hydra:member'].map((tour) => allTourShortFactoryIn(tour));
      state.countAllTours = action.payload['hydra:totalItems'];
    });
    builder.addCase(getAllTours.pending, (state) => {
      state.isLoading = 'GET_ALL_TOURS';
    });
    builder.addCase(getAllTours.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_ALL_TOURS';
    });

    //получение тура по ip
    builder.addCase(getTour.fulfilled, (state, action: PayloadAction<TourResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOUR';
      state.isError = null;
      state.selectedTour = action.payload;
    });
    builder.addCase(getTour.pending, (state) => {
      state.isLoading = 'GET_TOUR';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTour.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR';
    });

    builder.addCase(
      getTourProgram.fulfilled,
      (
        state,
        action: PayloadAction<{
          result: TourDocumentResponse[];
        }>
      ) => {
        state.isLoading = null;
        state.isSuccess = 'GET_TOUR_DOCUMENT';
        state.isError = null;
        action.payload.result.map(({ link }) => {
          openInNewTab(process.env.REACT_APP_BASE_API_URL + link);
        });
      }
    );
    builder.addCase(getTourProgram.pending, (state) => {
      state.isLoading = 'GET_TOUR_DOCUMENT';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourProgram.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_DOCUMENT';
    });

    //добавление тура
    builder.addCase(addTour.fulfilled, (state, action: PayloadAction<TourResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_TOUR';
      state.isError = null;
      state.idNewTour = action.payload.id;
    });
    builder.addCase(addTour.pending, (state) => {
      state.isLoading = 'ADD_TOUR';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addTour.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_TOUR';
    });

    //получение списка туроператоров
    builder.addCase(getTourOperators.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOUR_OPERATORS';
      state.tourOperators = action.payload['hydra:member'];
    });
    builder.addCase(getTourOperators.pending, (state) => {
      state.isLoading = 'GET_TOUR_OPERATORS';
    });
    builder.addCase(getTourOperators.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_OPERATORS';
    });

    //получение списка стран
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_COUNTRIES';
      state.isError = null;
      state.countries = action.payload['hydra:member'];
    });
    builder.addCase(getCountries.pending, (state) => {
      state.isLoading = 'GET_COUNTRIES';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_COUNTRIES';
    });

    //получение списка гидов
    builder.addCase(getGuides.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_GUIDES';
      state.isError = null;
      state.guides = action.payload['hydra:member'];
    });
    builder.addCase(getGuides.pending, (state) => {
      state.isLoading = 'GET_GUIDES';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getGuides.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_GUIDES';
    });

    // получения списка городов
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_CITIES';
      state.isError = null;
      state.cities = action.payload['hydra:member'];
    });
    builder.addCase(getCities.pending, (state) => {
      state.isLoading = 'GET_CITIES';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getCities.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_CITIES';
    });

    //получение списка транспорта
    builder.addCase(getTransports.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TRANSPORTS';
      state.isError = null;
      state.transports = action.payload['hydra:member'];
    });
    builder.addCase(getTransports.pending, (state) => {
      state.isLoading = 'GET_TRANSPORTS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTransports.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TRANSPORTS';
    });

    //получение видов туров
    builder.addCase(getTourTypes.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOUR_TYPES';
      state.isError = null;
      state.tourTypes = action.payload['hydra:member'];
    });
    builder.addCase(getTourTypes.pending, (state) => {
      state.isLoading = 'GET_TOUR_TYPES';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourTypes.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_TYPES';
    });

    //получение списка времени тура
    builder.addCase(getTourPeriods.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOUR_PERIODS';
      state.isError = null;
      state.tourPeriods = action.payload['hydra:member'];
    });
    builder.addCase(getTourPeriods.pending, (state) => {
      state.isLoading = 'GET_TOUR_PERIODS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourPeriods.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_PERIODS';
    });
    //получение списка направлений тура
    builder.addCase(getDirections.fulfilled, (state, action) => {
      state.directions = action.payload['hydra:member'];
    });

    //получение списка типов номеров
    builder.addCase(getHotelRoomTypes.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOUR_ROOM_TYPE';
      state.isError = null;
      state.roomTypes = action.payload['hydra:member'];
    });
    builder.addCase(getHotelRoomTypes.pending, (state) => {
      state.isLoading = 'GET_TOUR_ROOM_TYPE';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getHotelRoomTypes.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_ROOM_TYPE';
    });

    //получение списка тегов тура
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOUR_TAGS';
      state.isError = null;
      state.tags = action.payload['hydra:member'];
    });
    builder.addCase(getTags.pending, (state) => {
      state.isLoading = 'GET_TOUR_TAGS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTags.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_TAGS';
    });

    //получение тура по id с конвертацией данных для интерфейса
    builder.addCase(getTourFactory.fulfilled, (state, action: PayloadAction<TourResponse & TourParams>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_TOUR_FACTORY';
      state.isError = null;
      state.currentTour = tourFactoryIn(action.payload);
    });
    builder.addCase(getTourFactory.pending, (state) => {
      state.isLoading = 'GET_TOUR_FACTORY';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourFactory.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_FACTORY';
    });

    //редактирование тура
    builder.addCase(editTour.fulfilled, (state, action: PayloadAction<TourResponse & TourParams>) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'EDIT_TOUR';
      state.currentTour = tourFactoryIn(action.payload);
    });
    builder.addCase(editTour.pending, (state) => {
      state.isLoading = 'EDIT_TOUR';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(editTour.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'EDIT_TOUR';
    });

    //удаление гостиницы
    builder.addCase(deletePlacement.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'DELETE_PLACEMENT';
    });
    builder.addCase(deletePlacement.pending, (state) => {
      state.isLoading = 'DELETE_PLACEMENT';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(deletePlacement.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'DELETE_PLACEMENT';
    });
    builder.addCase(deleteHotelPlacement.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'DELETE_PLACEMENT';
    });
    builder.addCase(deleteHotelPlacement.pending, (state) => {
      state.isLoading = 'DELETE_PLACEMENT';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(deleteHotelPlacement.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'DELETE_PLACEMENT';
    });
    //удаление поездки
    builder.addCase(deleteTrip.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'DELETE_TRIP';
    });
    builder.addCase(deleteTrip.pending, (state) => {
      state.isLoading = 'DELETE_TRIP';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(deleteTrip.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'DELETE_TRIP';
    });
    //удаление поездки
    builder.addCase(deleteHotel.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'DELETE_TRIP';
    });
    builder.addCase(deleteHotel.pending, (state) => {
      state.isLoading = 'DELETE_TRIP';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(deleteHotel.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'DELETE_TRIP';
    });

    //получение сокращенных дат туров
    builder.addCase(getTourDates.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'GET_TOUR_DATES';

      state.currentTour!.tripsInfo =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action.payload['hydra:member'].length > 0
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            action.payload['hydra:member'].map((el: TripResponse) => {
              return {
                id: el.id,
                '@id': el['@id'],
                dateStart: new Date(el.start),
                dateEnd: new Date(el.ending),
              };
            })
          : null;
    });
    builder.addCase(getTourDates.pending, (state) => {
      state.isLoading = 'GET_TOUR_DATES';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourDates.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_DATES';
    });

    builder.addCase(getTourDate.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'GET_TOUR_DATE';
      const filterArr = state.currentTour!.tripsInfo?.map((item) =>
        Number(item?.id) === Number(action.payload.id) ? action.payload : item
      );
      state.currentTour!.tripsInfo = [...filterArr!];
    });
    builder.addCase(getTourDate.pending, (state) => {
      state.isLoading = 'GET_TOUR_DATE';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourDate.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_DATE';
    });

    builder.addCase(getTourHotels.fulfilled, (state, action) => {
      state.searchedHotels = action.payload['hydra:member'];
    });
    builder.addCase(getTourHotelsPlacements.fulfilled, (state, action) => {
      state.hotelsPlacements = action.payload['hydra:member'];
    });
    builder.addCase(getTourDatesList.pending, (state, action) => {
      state.isLoading = 'GET_TOUR_DATELIST';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourDatesList.fulfilled, (state, action) => {
      state.tourDateList = action.payload['hydra:member'];
      state.isSuccess = 'GET_TOUR_DATELIST';
      state.isLoading = null;
      state.isError = null;
    });
    builder.addCase(getTourDatesPlacements.fulfilled, (state, action) => {
      state.tourDatePlacements = action.payload['hydra:member'];
      state.isLoading = null;
      state.isSuccess = 'GET_PLACEMENTS';
      state.isError = null;
    });
    builder.addCase(getTourNights.fulfilled, (state, action) => {
      state.tourDateNight = action.payload['hydra:member'];
      state.isLoading = null;
      state.isSuccess = 'GET_NIGHTS';
      state.isError = null;
    });
    builder.addCase(getTourDatesPlacements.pending, (state, action) => {
      state.isLoading = 'GET_PLACEMENTS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourNights.pending, (state, action) => {
      state.isLoading = 'GET_NIGHTS';
      state.isSuccess = null;
      state.isError = null;
    });
    //получение сокращенных дат туров
    builder.addCase(getTourHotelsMin.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'GET_TOUR_HOTELS';

      state.currentTour!.tourHotels =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        action.payload['hydra:member'].length > 0
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            action.payload['hydra:member'].map((el: TourHotelResponse) => {
              return {
                id: el.id,
                '@id': el['@id'],
                name: el.name,
              };
            })
          : null;
    });
    builder.addCase(getTourHotelsMin.pending, (state) => {
      state.isLoading = 'GET_TOUR_HOTELS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourHotelsMin.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_HOTELS';
    });

    builder.addCase(getTourHotel.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'GET_TOUR_HOTEL';
      const filterArr = state.currentTour!.tourHotels?.map((item) =>
        Number(item?.id) === Number(action.payload.id) ? action.payload : item
      );
      state.currentTour!.tourHotels = [...filterArr!];
    });
    builder.addCase(getTourHotel.pending, (state) => {
      state.isLoading = 'GET_TOUR_HOTEL';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getTourHotel.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_TOUR_HOTEL';
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setPreSaveTour,
  setParamsDirection,
  resetPreSaveTour,
  setCurrentTourTripsInfo,
  setCurrentTourHotelInfo,
  setParamsSearch,
  resetSelectedTour,
  setParamsStatus,
  setParamsManager,
  setParamsDateFrom,
  setParamsDateTo,
  setParamsSortById,
  setParamsSortByName,
  setParamsPage,
  resetCurrentTour,
  setIsEditedTour,
  setIsClonedTrip,
  setErrorTourTitle,
  setErrorTourOperator,
  setErrorTripDate,
  setErrorTripStatus,
  setIdTour,
} = toursSlice.actions;

export default toursSlice.reducer;
