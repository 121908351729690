import React, { FC } from 'react';
import clsx from 'clsx';
import { HistoryTabsProps } from './HistoryTab.types';
import { getDate } from 'core/utils/getDate';

import styles from './HistoryTab.module.scss';
import { getOrder } from 'store/action-creators/orders';
import { useAppDispatch } from 'store/hooks';
import { setIsOrderModal } from 'store/entities/orders/ordersSlice';

export const HistoryTab: FC<HistoryTabsProps> = ({ history }) => {
  const dispatch = useAppDispatch();
  const columns = [
    { id: 1, name: 'Дата заказа' },
    { id: 2, name: 'Тур' },
    { id: 3, name: 'Статус' },
  ];

  const handleGetOrder = (id: number) => {
    dispatch(setIsOrderModal(true));
    dispatch(getOrder({ id: id }));
  };

  return (
    <>
      <div className={styles.table}>
        <div className={styles.header}>
          {columns.map((column) => (
            <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
              <span>{column.name}</span>
            </div>
          ))}
        </div>
        <div>
          {history?.map((element) => (
            <div key={element.id} className={styles.row} onClick={() => handleGetOrder(element.id)}>
              <div className={clsx(styles.cell, styles.date)}>{getDate(element.date as Date)}</div>
              <div className={clsx(styles.cell, styles.tour)}>{element.tour}</div>
              <div className={clsx(styles.cell, styles.status)}>{element.status}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
