import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';

export const extraNightColumns: TableColumn[] = [
  {
    id: 'period',
    name: 'Период',
  },
  {
    id: 'night_count',
    name: 'Кол-во ночей',
  },
  {
    id: 'accommodation',
    name: 'Гостиница',
  },
  {
    id: 'type',
    name: 'Тип номера',
  },
  {
    id: 'category',
    name: 'Категория',
  },
  {
    id: 'accom',
    name: 'Вид',
  },
  {
    id: 'price',
    name: 'Цена',
  },
  {
    id: 'price_curant',
    name: 'Цена поставщика',
  },
  {
    id: 'tourists',
    name: 'ФИО туриста',
  },
];
