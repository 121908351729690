import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const Client1AIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8758 6.17172C13.8758 8.31217 12.1406 10.0474 10.0001 10.0474C7.85968 10.0474 6.1245 8.31217 6.1245 6.17172C6.1245 4.03126 7.85968 2.29608 10.0001 2.29608C12.1406 2.29608 13.8758 4.03126 13.8758 6.17172ZM12.7228 10.9907C14.4014 10.0403 15.5342 8.23832 15.5342 6.17172C15.5342 3.11536 13.0565 0.637695 10.0001 0.637695C6.94378 0.637695 4.46612 3.11536 4.46612 6.17172C4.46612 8.23821 5.59879 10.0401 7.27716 10.9906C5.85612 11.4077 4.54884 12.1491 3.47783 13.1665C2.1654 14.4134 1.28934 15.9972 0.943032 17.704C0.832923 18.2466 0.776367 18.8017 0.776367 19.3624H2.43475H9.99974H17.5647H19.2231C19.2231 18.8017 19.1665 18.2466 19.0564 17.704C18.7101 15.9972 17.8341 14.4134 16.5216 13.1665C15.4508 12.1492 14.1437 11.4079 12.7228 10.9907ZM15.3794 14.3689C16.3626 15.3029 17.0372 16.4599 17.3555 17.704H9.99974L2.64398 17.704C2.96228 16.4599 3.63683 15.3029 4.62004 14.3689C6.03299 13.0266 7.96671 12.2585 9.99974 12.2585C12.0328 12.2585 13.9665 13.0266 15.3794 14.3689Z"
        fill={fill}
      />
    </svg>
  );
};
