import { ContractStatus } from 'core/constants/enums';

export const documentsList = [
  {
    title: ContractStatus.CONTRACT_AQUARELLE_TOUR_OPERATOR,
    docType: 'doc1',
    hasChild: false,
  },
  {
    title: ContractStatus.PARTNER_TOUR_AQUARELLE_AGENT,
    docType: 'doc2',
    hasChild: true,
  },
  {
    title: ContractStatus.PARTNER_TOUR_AQUARELLE_TOUR_OPERATOR,
    docType: 'doc3',
    hasChild: false,
  },

  {
    title: ContractStatus.ABROAD_AQUARELLE_AGENT,
    docType: 'doc4',
    hasChild: false,
  },
  {
    title: ContractStatus.PAID_RENDERING_OF_SERVICES_BELARUS,
    docType: 'doc5',
    hasChild: false,
  },
  {
    title: ContractStatus.SCORE,
    docType: 'invoice',
    hasChild: true,
  },
  {
    title: ContractStatus.BOOKING_CONFIRMATION,
    docType: 'reserve',
    hasChild: false,
  },
];
