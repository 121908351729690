import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';

export const salesColumns: TableColumn[] = [

  {
    id: 'title',
    name: 'Название',
  },
  {
    id: 'price',
    name: 'Стоимость',
  },
  {
    id: 'quantity',
    name: 'Кол-во',
  },

];

export const titles = ['10%', '30%'];
export const prices = ['5000', '10000', '12000'];
export const quantities = ['1', '2', '3'];


