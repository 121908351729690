import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UsersState } from './UsersTypes';
import { getCurrentUser, getUsers } from 'store/action-creators/users';
import { userFactory } from 'services/users/users.factory';
import { UserResponse, UsersResponse } from 'services/users/UsersResponse.model';

const initialState: UsersState = {
  isLoading: null,
  isSuccess: null,
  isError: null,

  users: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    setCurrentUser: (state) => {
      state.currentUser = initialState.currentUser;
    },
  },

  extraReducers: (builder) => {
    // получения списка пользователей
    builder.addCase(getUsers.fulfilled, (state, action: PayloadAction<UsersResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_USERS';
      state.isError = null;
      state.users = action.payload['hydra:member'].map((user) => userFactory(user));
    });
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = 'GET_USERS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_USERS';
    });

    // получение текущего пользователя
    builder.addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<UserResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_CURRENT_USER';
      state.isError = null;
      state.currentUser = action.payload;
    });
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = 'GET_CURRENT_USER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_CURRENT_USER';
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentUser } = usersSlice.actions;

export default usersSlice.reducer;
