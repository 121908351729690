import React, { useEffect, useState } from 'react';

import { ToursTop } from '../ToursTop';
import { ToursWithTripsTable } from '../ToursWithTripsTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ToursData } from 'services/tours/toursApi.service';
import { getAllTours, getTours } from 'store/action-creators/tours';
import { TourTabs } from 'core/constants/enums';
import { ToursWithoutTripsTable } from './ToursWithoutTripsTable';
import { ToursAll } from './ToursAll';

export const ToursIndex = () => {
  const dispatch = useAppDispatch();
  const { params } = useAppSelector((state) => state.tours);
  const [toursData, setToursData] = useState<ToursData | null>(null);
  const getTourStatus = (status: string | null) => {
    switch (status) {
      case TourTabs.ACTIVE_TOURS:
        return 'active';
        break;
      case TourTabs.CLOSE_TOURS:
        return 'archive';
      case TourTabs.ALL:
        return null;
      case TourTabs.VISIBLE_TOUR:
        return null;
      case TourTabs.NO_VISIBLE_TOUR:
        return null;

      default:
        return null;
    }
  };
  //при изменении значений фильтров, меняем параметры для запроса на сервер
  useEffect(() => {
    setToursData({
      ...toursData,
      page: params.currentPage,
      search: params.search === '' ? null : params.search,
      manager: params.manager === '' ? null : params.manager,
      status: getTourStatus(params.status),
      tour_directions: params.tour_directions === '' ? null : params.tour_directions,
      dateFrom: params.dateFrom ? params.dateFrom : null,
      dateTo: params.dateTo ? params.dateTo : null,
      sortById: params.sortById ? params.sortById : null,
      sortByName: params.sortByName ? params.sortByName : null,
      itemsPerPage: params.itemsPerPage,
      tour_type: 'tour',
      is_visible:
        params.status !== TourTabs.VISIBLE_TOUR && params.status !== TourTabs.NO_VISIBLE_TOUR
          ? null
          : params.status === TourTabs.VISIBLE_TOUR
          ? true
          : false,
    });
  }, [params]);

  // при изменении параметров для запроса, отправляем запрос на получение поездок
  useEffect(() => {
    if (
      params.status !== TourTabs.ALL &&
      params.status !== TourTabs.VISIBLE_TOUR &&
      params.status !== TourTabs.NO_VISIBLE_TOUR &&
      toursData
    ) {
      dispatch(getTours(toursData));
    }
  }, [toursData]);

  // при изменении параметров для запроса, отправляем запрос на получение туров
  useEffect(() => {
    if (
      toursData &&
      (params.status === TourTabs.VISIBLE_TOUR ||
        params.status === TourTabs.NO_VISIBLE_TOUR ||
        params.status === TourTabs.ALL)
    ) {
      dispatch(getAllTours(toursData));
    }
  }, [toursData]);

  return (
    <section>
      <ToursTop />
      {params.status === TourTabs.VISIBLE_TOUR ||
      params.status === TourTabs.NO_VISIBLE_TOUR ||
      params.status === TourTabs.ALL ? (
        <ToursAll />
      ) : (
        <ToursWithTripsTable />
      )}
    </section>
  );
};
