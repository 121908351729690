import React, { FC } from 'react';
import { Modal } from '@mantine/core';

import { Button } from 'ui/Button';

import styles from './ModalWarning.module.scss';

interface ModalWarningProps {
  opened: boolean;
  close: () => void;
  handleClose: () => void;
  handleCancel: () => void;
}

export const ModalWarning: FC<ModalWarningProps> = ({ opened, close, handleClose, handleCancel }) => {
  return (
    <Modal opened={opened} onClose={close} withCloseButton={false} centered radius={'md'} padding={'xl'} zIndex={1002}>
      <p className={styles.textModal}>Вы уверены, что хотите уйти с этой страницы?</p>
      <div className={styles.buttons}>
        <Button onClick={handleClose} label="Да" />
        <Button onClick={handleCancel} label="Отмена" className={styles.btnCancel} />
      </div>
    </Modal>
  );
};
