import React, { FC, useEffect, useRef, useState } from 'react';
import { ButtonWithSelectType } from './ButtonWithSelect.types';
import styles from './ButtonWithSelect.module.scss';
import clsx from 'clsx';
import { useRefCloseOut } from 'hooks/useRefCloseOut';
import { DownContourIcon } from 'components/icons';
import { ItemComponent } from './item';

export const ButtonWithSelect: FC<ButtonWithSelectType> = ({ label, data, setSelect, className, req, ...props }) => {
  const [opened, setOpened] = useState(false);
  const btnSelectRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const selectRef = useRef() as React.MutableRefObject<HTMLUListElement>;

  const handleClickButton = () => {
    setOpened((prev) => !prev);
  };

  const handleClickItem = (item: string, req?: number) => {
    setOpened(false);
    if (item && setSelect) {
      req ? setSelect(item, req) : setSelect(item);
    }
  };

  useRefCloseOut(selectRef, setOpened, btnSelectRef);

  return (
    <article className={clsx(styles.select, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.header} onClick={handleClickButton} ref={btnSelectRef} {...props}>
        Создать
        <DownContourIcon stroke="white" width={8} height={5} />
      </div>
      {opened && (
        <ul className={styles.list} ref={selectRef}>
          {data.map((item) => (
            <ItemComponent req={req} item={item} handleClickItem={handleClickItem} key={item.title} />
          ))}
        </ul>
      )}
    </article>
  );
};
