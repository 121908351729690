import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const BinIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5044 1.91313H3.39253C3.45406 1.91313 3.5044 1.86279 3.5044 1.80125V1.91313H7.75568V1.80125C7.75568 1.86279 7.80602 1.91313 7.86755 1.91313H7.75568V2.92001H8.76256V1.80125C8.76256 1.3076 8.3612 0.90625 7.86755 0.90625H3.39253C2.89888 0.90625 2.49752 1.3076 2.49752 1.80125V2.92001H3.5044V1.91313ZM10.5526 2.92001H0.707512C0.459987 2.92001 0.26001 3.11999 0.26001 3.36751V3.81502C0.26001 3.87655 0.310354 3.92689 0.371885 3.92689H1.21655L1.56196 11.2408C1.58434 11.7176 1.9787 12.0938 2.45557 12.0938H8.80451C9.28278 12.0938 9.67574 11.719 9.69812 11.2408L10.0435 3.92689H10.8882C10.9497 3.92689 11.0001 3.87655 11.0001 3.81502V3.36751C11.0001 3.11999 10.8001 2.92001 10.5526 2.92001ZM8.69683 11.0869H2.56325L2.22483 3.92689H9.03525L8.69683 11.0869Z"
        fill={fill}
      />
    </svg>
  );
};
