import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';

import { ForeignDocumentType } from 'components/Document/Document.types';
import { Input } from 'ui/Input';
import { InputDate } from 'ui/InputDate';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { setPreSavePrivatePerson } from 'store/entities/partners/partnersSlice';
import { DateIcon } from 'components/icons';

import styles from './ForeignDocument.module.scss';

interface ForeignDocumentProps {
  passport?: ForeignDocumentType;
  setData: (a: ForeignDocumentType) => void;
}
export const ForeignDocument: FC<ForeignDocumentProps> = ({ passport, setData }) => {
  const { preSavePrivatePerson, isResetPreSavePrivatePerson } = useAppSelector((state) => state.partners);
  const dispatch = useAppDispatch();
  const [blur, setBlur] = useState(false);

  const initialValues: ForeignDocumentType = {
    lastName: preSavePrivatePerson?.documents?.foreignDocument?.lastName || passport?.lastName || '',
    firstName: preSavePrivatePerson?.documents?.foreignDocument?.firstName || passport?.firstName || '',
    citizenship: preSavePrivatePerson?.documents?.foreignDocument?.citizenship || passport?.citizenship || '',
    number: preSavePrivatePerson?.documents?.foreignDocument?.number || passport?.number || '',
    dateOfIssue: preSavePrivatePerson?.documents?.foreignDocument?.dateOfIssue || passport?.dateOfIssue || null,
    countryRegistration:
      preSavePrivatePerson?.documents?.foreignDocument?.countryRegistration || passport?.countryRegistration || '',
    placeOfStateRegistration:
      preSavePrivatePerson?.documents?.foreignDocument?.placeOfStateRegistration ||
      passport?.placeOfStateRegistration ||
      '',
  };

  const validate = {
    lastName: (value: string) => (value.length == 0 ? 'the field is required' : null),
    firstName: (value: string) => (value.length == 0 ? 'the field is required' : null),
    citizenship: (value: string) => (value.length == 0 ? 'the field is required' : null),
    number: (value: string) => (value.length == 0 ? 'the field is required' : null),
  };

  const formForeignDocument = useForm({
    initialValues,
    validate,
    validateInputOnChange: true,
  });

  useEffect(() => {
    setData(formForeignDocument.values);
  }, [formForeignDocument.values]);

  //если форму начали заполнять и в ней нет ошибок, данные уходят в предсохранении и оттуда на сервер
  useEffect(() => {
    if (formForeignDocument.values.lastName !== '' && JSON.stringify(formForeignDocument.errors) === '{}') {
      if (passport?.id) {
        dispatch(
          setPreSavePrivatePerson({
            ...preSavePrivatePerson,
            documents: {
              ...preSavePrivatePerson.documents,
              foreignDocument: { ...formForeignDocument.values, id: passport.id },
            },
          })
        );
      } else {
        dispatch(
          setPreSavePrivatePerson({
            ...preSavePrivatePerson,
            documents: { ...preSavePrivatePerson.documents, foreignDocument: formForeignDocument.values },
          })
        );
      }
    } else {
      console.log('Не заполнены обязательные поля формы');
    }
  }, [formForeignDocument.values, formForeignDocument.errors]);

  //если первое поле начали заполнять - то все остальные уже обязательны
  useEffect(() => {
    if (blur) {
      formForeignDocument.validate();
    }
  }, [blur]);

  // useEffect(() => {
  //   if (focus && Object.values(formForeignDocument.errors).length === 0) {
  //     setFocus(false);
  //     markDirty();
  //   }
  // }, [formForeignDocument.errors, focus]);

  useEffect(() => {
    if (isResetPreSavePrivatePerson) {
      formForeignDocument.reset();
      formForeignDocument.setFieldValue('citizenship', initialValues.citizenship);
      formForeignDocument.setFieldValue('number', initialValues.number);
      formForeignDocument.setFieldValue('dateOfIssue', initialValues.dateOfIssue);
      formForeignDocument.setFieldValue('placeOfStateRegistration', initialValues.placeOfStateRegistration);
    }
  }, [isResetPreSavePrivatePerson]);

  return (
    <form>
      <div className={styles.group}>
        <Input
          label="Фамилия (латиница)*"
          {...formForeignDocument.getInputProps('lastName')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
          }}
          error={!!formForeignDocument.errors.lastName}
        />
        <Input
          label="Имя (латиница)*"
          {...formForeignDocument.getInputProps('firstName')}
          className={styles.input}
          error={!!formForeignDocument.errors.firstName}
        />
      </div>
      <div className={styles.group}>
        <Input
          label="Гражданство*"
          {...formForeignDocument.getInputProps('citizenship')}
          className={styles.input}
          error={!!formForeignDocument.errors.citizenship}
        />
        <Input
          type="text"
          label="Номер документа*"
          {...formForeignDocument.getInputProps('number')}
          className={clsx(styles.input, styles.number)}
          error={!!formForeignDocument.errors.number}
        />
        <InputDate
          label="Дата выдачи"
          className={styles.input}
          placeholder="_ _._ _._ _ _ _"
          icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formForeignDocument.errors.dateOfIssue}
          {...formForeignDocument.getInputProps('dateOfIssue')}
        />
      </div>
      <Input
        label="Страна регистрации"
        {...formForeignDocument.getInputProps('countryRegistration')}
        className={styles.input}
        error={!!formForeignDocument.errors.countryRegistration}
      />
      <Input
        label="Место гос.регистрации"
        {...formForeignDocument.getInputProps('placeOfStateRegistration')}
        error={!!formForeignDocument.errors.placeOfStateRegistration}
        className={styles.input}
      />
    </form>
  );
};
