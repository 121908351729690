import React from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { RouteNames } from 'core/constants/routeNames';
import { OrdersIndex } from './OrdersIndex';

export const OrdersPage = () => {
  return (
    <Routes>
      <Route index element={<OrdersIndex />} />
      <Route path=":id" element={<OrdersIndex />} />
      <Route path="*" element={<Navigate to={RouteNames.ORDERS} replace />} />
    </Routes>
  );
};
