import { TableColumn } from './PaymentLinkHistory.types';

export const toursColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
  },
  {
    id: 'idInPaymentSystem',
    name: 'ID в платежной системе',
  },
  {
    id: 'paymentType',
    name: 'Способ платежа',
  },
  {
    id: 'sum',
    name: 'Сумма',
  },
  {
    id: 'date',
    name: 'Дата размещения',
  },
  {
    id: 'valid',
    name: 'Действует до',
  },
  {
    id: 'code',
    name: 'Код',
  },
  {
    id: 'paymentStatus',
    name: 'Статус платежа',
  },
  {
    id: 'buttons',
    name: '',
  },
];

export const getPaymentStatus = (status: string) => {
  switch (status) {
    case 'registered':
      return 'Зарегистрирована';
    case 'in-hold':
      return 'Средства заморожены';
    case 'authorized':
      return 'Средства списаны';
    case 'declined':
      return 'Платеж отозван';
    case 'returned':
      return 'Возврат платежа';
    case 'asc':
      return 'ASC';
    case 'cancelled':
      return 'Платеж отменен';
    case 'error':
      return 'Ошибка оплаты';
    default:
      'Зарегистрирована';
  }
};
