import React, { FC, useEffect, useRef, useState } from 'react';
import { SelectSearchType } from './SelectSearch.type';
import styles from './SelectSearch.module.scss';
import clsx from 'clsx';
import { useRefCloseOut } from 'hooks/useRefCloseOut';
import { Button } from 'ui/Button';
import { DownContourIcon } from 'components/icons';

export const SelectSearch: FC<SelectSearchType> = ({
  label,
  placeholder,
  value,
  setValue,
  getValueForModal,
  create,
  onBlur = () => void 1,
  data,
  className,
  setSearch,
  handleKey,
  isNeedSort,
  disabled = false,
  ...props
}) => {
  const [opened, setOpened] = useState(false);
  const [selectedValue, setSelectedValue] = useState<{ id: number; name: string } | null | undefined>(value);
  const selectRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const btnSelectRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [query, setQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState(data);
  const [error, setError] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    setIsDirty(false);
  }, [isDirty]);

  const handleClickHeader = () => {
    !disabled && !opened && setOpened((prev) => !prev);
  };

  const handleClickItem = (item: { id: number; name: string }) => {
    setSelectedValue(item);
    setOpened(false);
    setIsDirty(true);
    setQuery(item.name);
    setFilteredData(data);
  };

  const handleClickItemBtn = (item: number) => {
    if (getValueForModal) {
      getValueForModal(item);
    }
  };

  const handleClickCreate = () => {
    create && create();
  };

  useRefCloseOut(selectRef, setOpened, btnSelectRef);

  useEffect(() => {
    if (setValue && selectedValue) {
      setValue(selectedValue);
      if (isDirty) {
        onBlur();
      }
    }
  }, [selectedValue]);

  const valueRef = useRef<{ id: number; name: string } | null>();
  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
      setQuery('');
    }
    if (value && JSON.stringify(valueRef.current) !== JSON.stringify(value)) {
      console.log(selectedValue);
      const isQueryIdData = data?.filter((item) => item.id === selectedValue?.id);
      const findedValue = isQueryIdData.length !== 0 ? isQueryIdData[0] : value;
      setSelectedValue(findedValue);
      setQuery(findedValue.name);
    }
    valueRef.current = value;
  }, [value]);

  useEffect(() => {
    if (isNeedSort && isNeedSort) {
      setFilteredData(data);
      return;
    }
    const sortedByABC = data.sort((a, b) => a.name.localeCompare(b.name));
    if (query === '') {
      setFilteredData(sortedByABC);
    } else {
      const filterByQuery = sortedByABC?.filter(
        (item) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.subName?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filterByQuery);
      setError(false);

      const isQueryInData = sortedByABC?.filter(
        (item) =>
          item.name.toLowerCase() === query.toLowerCase() || item.subName?.toLowerCase().includes(query.toLowerCase())
      );

      if (isQueryInData.length === 0) {
        if (selectedValue?.id) {
          const isQueryIdData = sortedByABC?.filter((item) => item.id === selectedValue?.id);
          if (isQueryIdData.length === 0) {
            setError(true);
          }
        } else {
          setError(true);
        }
      }
    }

    if (query === selectedValue?.name) {
      setFilteredData(sortedByABC);
    }
  }, [query, selectedValue, data]);

  return (
    <article className={clsx(styles.select, className)}>
      <label className={styles.label}>{label}</label>
      <div
        onClick={handleClickHeader}
        ref={btnSelectRef}
        className={clsx(
          styles.header,
          opened && styles.headerOpened,
          error && styles.error,
          disabled && styles.disabled
        )}
        {...props}
      >
        <input
          value={query}
          onChange={(e) => {
            e.preventDefault();
            setQuery(e.target.value);
            setSearch && setSearch(e.target.value);
          }}
          placeholder={placeholder}
          className={clsx(styles.search)}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              e.preventDefault();
              handleKey && handleKey();
            }
          }}
        />
      </div>
      {opened && (
        <div className={styles.dropdown} ref={selectRef}>
          <ul className={styles.list}>
            {filteredData.filter((el) => el.name !== selectedValue?.name).length > 0 ? (
              filteredData
                .filter((el) => el.name !== selectedValue?.name)
                .map((item) => (
                  <>
                    {item.subName ? (
                      <li
                        key={item.id}
                        onClick={() => handleClickItem(item)}
                        className={clsx(
                          styles.itemWithSubName,
                          item.name === selectedValue?.name && styles.itemSelected
                        )}
                      >
                        {item.subName}
                        <span>{item.name}</span>

                        {getValueForModal && (
                          <button className={styles.btnItem} onClick={() => handleClickItemBtn(item.id)}>
                            <DownContourIcon width={8} height={4} stroke="#5B6572" />
                          </button>
                        )}
                      </li>
                    ) : (
                      <li
                        key={item.id}
                        onClick={() => handleClickItem(item)}
                        className={clsx(styles.item, item.name === selectedValue?.name && styles.itemSelected)}
                      >
                        {item.name}

                        {getValueForModal && (
                          <button className={styles.btnItem} onClick={() => handleClickItemBtn(item.id)}>
                            <DownContourIcon width={8} height={4} stroke="#5B6572" />
                          </button>
                        )}
                      </li>
                    )}
                  </>
                ))
            ) : (
              <li className={styles.item}>Введите текст для поиска</li>
            )}
          </ul>
          {create && (
            <Button className={styles.create} onClick={() => handleClickCreate()}>
              + Создать
            </Button>
          )}
        </div>
      )}
    </article>
  );
};
