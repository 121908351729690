import { ReportsState } from './reports.types';

const reportsInitialState: ReportsState = {
  isLoading: null,
  isSuccess: null,
  isError: null,

  reports: undefined,

  reportsPage: {
    page: 1,
    type: undefined,
  },

  reportId: { id: 0 },

  createReportForm: {
    period_from: null,
    period_to: null,
    manager: '',
    tour_operator_contact: '',
  },
};

export { reportsInitialState };
