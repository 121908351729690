import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './GalleryEdit.module.scss';
import { RemoveIcon } from 'components/icons';
import { Album } from 'services/gallery/galleryApi.service';
import { ModalConfirm } from 'pages/Tours/components/TourForm/ModalConfirm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteAlbum, deleteAlbumWithPhoto } from 'store/action-creators/gallery';
import { notifications } from '@mantine/notifications';
import ModalContext from 'core/context/ModalContext';
import { AlbumForm } from '../components/AlbumForm';

export const AlbumEdit: FC<{ album?: Album }> = ({ album }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { isSuccess, isError } = useAppSelector((state) => state.gallerySlice);
  const { setActive } = useContext(ModalContext);

  useEffect(() => {
    if (isSuccess === 'DELETE_ALBUM') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Альбом удалён', id: 'DELETE_ALBUM' });
      setActive(false);
    }
    if (isError === 'DELETE_ALBUM') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'DELETE_ALBUM_ERROR',
      });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isSuccess === 'UPDATE_ALBUM') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Альбом изменён', id: 'UPDATE_ALBUM' });
    }
    if (isError === 'UPDATE_ALBUM') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'UPDATE_ALBUM_ERROR',
      });
    }
  }, [isSuccess, isError]);

  return (
    <section className={styles.edit}>
      <div className={styles.top}>
        <h2 className={styles.title}>Фотоальбом №{album?.id}</h2>
        <span className={styles.remove} onClick={() => setIsModalOpen(true)}>
          <RemoveIcon width={16} height={16} fill="#C8CDD8" />
        </span>
      </div>
      <div className={styles.content}>{album && <AlbumForm album={album} />}</div>
      <ModalConfirm
        isOpen={isModalOpen}
        text={`Вы хотите удалить безвозвратно только альбом или альбом вместе с фотографиями?`}
        onClose={() => setIsModalOpen(false)}
        labelOne="Альбом"
        labelTwo="Альбом + фото"
        handleYes={() => {
          if (album?.id) {
            dispatch(deleteAlbum(Number(album?.id)));
            setIsModalOpen(false);
          }
        }}
        handleNo={() => {
          if (album?.id) {
            dispatch(deleteAlbumWithPhoto(Number(album?.id)));
            setIsModalOpen(false);
          }
        }}
      />
    </section>
  );
};
