import { createAsyncThunk } from '@reduxjs/toolkit';

import { UsersData, getCurrentUserAPI, getManagersAPI, getUsersAPI } from 'services/users/usersApi.service';

// получение списка пользователей
export const getUsers = createAsyncThunk('tours/getUsers', async (arg: UsersData, thunkAPI) => {
  try {
    const response = await getManagersAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение текущего пользователя
export const getCurrentUser = createAsyncThunk('users/getCurrentUser', async (_, thunkAPI) => {
  try {
    const response = await getCurrentUserAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
