import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import { useRefCloseOut } from 'hooks/useRefCloseOut';
import { ModalType } from './Modal.types';
import { CloseIcon } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetPreSavePrivatePerson,
  resetPreSaveLegalPerson,
  setIsPartnerModal,
  getBaseLeganPerson,
  getBasePrivatePerson,
} from 'store/entities/partners/partnersSlice';
import { resetPreSaveOrder, resetSelectedOrder, setIsOrderModal } from 'store/entities/orders/ordersSlice';
import ModalContext from '../../core/context/ModalContext';
import { ModalWarning } from 'pages/Tours/components/TourForm/ModalWarning';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from 'core/constants/routeNames';
import { resetOrderActions } from 'store/entities/orderActions/orderActionsSlice';
import {
  setAlbumParamsDateFrom,
  setAlbumParamsDateTo,
  setAlbumParamsPage,
  setAlbumParamsSearch,
  setAlbumParamsTags,
  setIsOpenPhotoList,
  setParamsDateFrom,
  setParamsDateTo,
  setParamsPage,
  setParamsSearch,
  setParamsTags,
} from 'store/entities/gallery/gallerySlice';
import { GalleryTab } from 'core/constants/enums';

export const Modal: FC<ModalType> = ({
  opened,
  setOpened,
  children,
  isWantClose,
  openedWarning,
  closeWarning,
  handleCancel,
  openModal,
  saveDataBeforeClose,
}) => {
  const [active, setActive] = useState(opened);
  const { active: isModalActive, setActive: setIsModalActive } = useContext(ModalContext);
  const { isPartnerModal } = useAppSelector((state) => state.partners);
  const { isOdrderModal } = useAppSelector((state) => state.orders);
  const { galleryTab } = useAppSelector((state) => state.gallerySlice);
  const refModal = useRef() as React.MutableRefObject<HTMLDivElement>;
  const refCloseBtn = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setActive(opened);
    setIsModalActive(opened);
  }, [opened]);

  useEffect(() => {
    setActive(isModalActive);
  }, [isModalActive]);
  useEffect(() => {
    setOpened(active);
    if (active) {
      document.body.style.overflow = 'hidden'; // disable background scrolling
    } else {
      document.body.style.overflow = 'auto'; // allow background scrolling
    }
  }, [active]);

  const close = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isWantClose && openModal) {
      openModal();
      return;
    }

    if (saveDataBeforeClose) {
      saveDataBeforeClose();
    }

    setActive(false);
    if (location.pathname === RouteNames.GALLERY && galleryTab === GalleryTab.ALBUMS) {
      dispatch(setParamsDateFrom(null));
      dispatch(setParamsDateTo(null));
      dispatch(setParamsPage(1));
      dispatch(setParamsTags([]));
      dispatch(setParamsSearch(''));
      dispatch(setIsOpenPhotoList(false));
    }
    if (location.pathname === RouteNames.TOURS) {
      // обнуление таблицы фото
      dispatch(setParamsDateFrom(null));
      dispatch(setParamsDateTo(null));
      dispatch(setParamsPage(1));
      dispatch(setParamsTags([]));
      dispatch(setParamsSearch(''));
      // обнуление таблицы фотоальбомов
      dispatch(setAlbumParamsTags([]));
      dispatch(setAlbumParamsDateFrom(null));
      dispatch(setAlbumParamsDateTo(null));
      dispatch(setAlbumParamsSearch(''));
      dispatch(setAlbumParamsPage(1));
    }
    if (location.pathname.includes(RouteNames.ORDERS) && id) {
      navigate(RouteNames.ORDERS);
    }
    if ((location.pathname === RouteNames.ORDERS || location.pathname === RouteNames.PARTNERS) && isPartnerModal) {
      dispatch(setIsPartnerModal(false));
      dispatch(resetPreSavePrivatePerson());
      dispatch(resetPreSaveLegalPerson());
    } else if (location.pathname === RouteNames.PARTNERS && isOdrderModal) {
      dispatch(setIsOrderModal(false));
      dispatch(resetPreSaveOrder());
      dispatch(resetSelectedOrder());
      dispatch(resetOrderActions());
      dispatch(getBaseLeganPerson());
      dispatch(getBasePrivatePerson());
    } else {
      dispatch(resetPreSavePrivatePerson());
      dispatch(resetPreSaveLegalPerson());
      dispatch(resetPreSaveOrder());
      dispatch(resetSelectedOrder());
      dispatch(resetOrderActions());
    }
  };

  // useRefCloseOut(refModal, setActive, refCloseBtn);
  return (
    <section className={active ? 'modal' : 'modal_close'} id="modal">
      {openedWarning && (
        <ModalWarning
          opened={openedWarning!}
          close={() => closeWarning && closeWarning()}
          handleCancel={() => handleCancel && handleCancel()}
          handleClose={() => {
            closeWarning && closeWarning();
            setActive(false);
            dispatch(resetPreSavePrivatePerson());
            dispatch(resetPreSaveLegalPerson());
            dispatch(resetPreSaveOrder());
            dispatch(resetSelectedOrder());
            dispatch(resetOrderActions());
          }}
        />
      )}

      <div className={'content'} ref={refModal}>
        {children}
        <button className={'close'} onClick={close} ref={refCloseBtn}>
          <CloseIcon width={12} height={12} fill={'rgba(91, 101, 114, 0.75)'} className={'close'} />
        </button>
      </div>
    </section>
  );
};
