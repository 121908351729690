import React, { FC, useEffect, useState } from 'react';
import styles from './OrdersTable.module.scss';
import { Modal } from 'components/Modal';
import { GalleryEdit } from '../GalleryEdit';
import { Photo, PhotoData, PhotoRes } from 'services/gallery/galleryApi.service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAlbumTags, getPhoto, getPhotos, getTags } from 'store/action-creators/gallery';
import { resetPreSave, setIsNeedReload, setParamsPage } from 'store/entities/gallery/gallerySlice';
import { Loader } from '@mantine/core';
import { Pagination } from '@mantine/core';

export const AllPhotoTable: FC = () => {
  const { photos, params, photoCount, isLoading, isSuccess, isNeedToReload, preSave } = useAppSelector(
    (state) => state.gallerySlice
  );
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  const [photoData, setPhotoData] = useState<PhotoData | null>(null);
  const [photoList, setPhotoList] = useState<Photo[]>([]);
  const [scroll, setScroll] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [countPages, setCountPages] = useState<number>(1);

  useEffect(() => {
    if (isNeedToReload) {
      dispatch(getPhotos(photoData!));
      dispatch(getTags());
      dispatch(getAlbumTags());
      dispatch(setIsNeedReload(false));
    }
  }, [photoData, isNeedToReload]);

  const handleClickRow = (item: number) => {
    setSelectedId(item);
    setModal(true);
  };

  useEffect(() => {
    // if (params.currentPage === 1) {
    setPhotoList(photos);
    // } else {
    //   setPhotoList((pre: any) => {
    //     const combinedArray = pre.concat(photos);
    //     const uniqueArray = combinedArray.filter(
    //       (item: any, index: number, arr: any[]) => arr.findIndex((el) => el.id === item.id) === index
    //     );
    //     return uniqueArray;
    //   });
    // }
  }, [photos]);

  //при изменении значений фильтров, меняем параметры для запроса на сервер
  useEffect(() => {
    setPhotoData({
      ...photoData,
      page: params.currentPage,
      search: params.search === '' ? null : params.search,
      dateFrom: params['created_at[after]'] ? params['created_at[after]'] : null,
      dateTo: params['created_at[before]'] ? params['created_at[before]'] : null,
      itemsPerPage: params.itemsPerPage,
      photo_tags: params.photo_tags.length > 0 ? params.photo_tags : null,
    });
  }, [params]);

  // при изменении параметров для запроса, отправляем запрос на получение заказов
  useEffect(() => {
    if (photoData) {
      dispatch(getPhotos(photoData));
    }
  }, [photoData]);

  useEffect(() => {
    if (selectedId) {
      dispatch(getPhoto(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    if (!modal) {
      setSelectedId(null);
      dispatch(resetPreSave());
    }
  }, [modal]);

  // высчитываем кол-во страниц в зависимости от кол-ва элементов и числа элементов на странице
  useEffect(() => {
    setCountPages(Math.ceil(photoCount / params.itemsPerPage));
  }, [photoCount, params.itemsPerPage]);

  // следим за скроллом. если достигаем конца страницы устанавливаем его в true
  // useEffect(() => {
  //   const handleScroll = (e: any) => {
  //     const scrollHeight = e.target.documentElement.scrollHeight;
  //     const currentHeight = e.target.documentElement.scrollTop + window.innerHeight;
  //     setScroll(false);
  //     if (currentHeight + 10 >= scrollHeight && params.currentPage <= countPages) {
  //       setScroll(true);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [params.currentPage]);

  // // когда достигли конца страницы, увеличиваем счетчик страниц на 1
  // useEffect(() => {
  //   if (scroll && params.currentPage < countPages) {
  //     dispatch(setParamsPage(params.currentPage + 1));
  //   }
  // }, [scroll]);

  return (
    <>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          {isLoading && isLoading === 'GET_GALLERY_PHOTOS' && (
            <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
          )}
          <div className={styles.list}>
            {isLoading !== 'GET_GALLERY_PHOTOS' &&
              photos &&
              photos.length > 0 &&
              photoList.map((el, idx) => (
                <article className={styles.article} key={idx} onClick={() => handleClickRow(Number(el.id))}>
                  <div className={styles.imgContainer}>
                    <img
                      src={
                        el.thumbnail_name
                          ? `${process.env.REACT_APP_BASE_API_URL}/uploads/thumbnails/${el.thumbnail_name}`
                          : `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${el.name}`
                      }
                      alt={el.alt}
                    />
                    {el.is_license && <span>Лицензия</span>}
                  </div>
                  <p>{el.title && el.title.length > 0 ? `${el.title.substring(0, 18)}...` : el.title}</p>
                </article>
              ))}
          </div>

          {isSuccess === 'GET_GALLERY_PHOTOS' && photoList.length === 0 && (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
        </div>
        {isLoading !== 'GET_GALLERY_PHOTOS' && photos && photos.length > 0 && (
          <div className={styles.tableFooter}>
            <span>Всего: {photoCount} фото</span>
            <Pagination
              total={countPages}
              value={params.currentPage}
              onChange={(val) => dispatch(setParamsPage(val))}
            />
          </div>
        )}
      </div>
      {modal && (
        <Modal opened={modal} setOpened={setModal}>
          {preSave.name != '' && <GalleryEdit selectedPhoto={preSave} />}
        </Modal>
      )}
    </>
  );
};
