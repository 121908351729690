import { configureStore } from '@reduxjs/toolkit';

import partnersReducer from './entities/partners/partnersSlice';
import ordersReducer from './entities/orders/ordersSlice';
import orderActionsReducer from './entities/orderActions/orderActionsSlice';
import authReducer from './entities/auth/authSlice';
import tourReducer from './entities/tours/toursSlice';
import preSaveTourReducer from './entities/tours/preSaveTourSlice';
import userReducer from './entities/users/usersSlice';
import reportsReducer from './entities/reports/reportsSlice';
import galleryReducer from './entities/gallery/gallerySlice';

export const store = configureStore({
  reducer: {
    partners: partnersReducer,
    orders: ordersReducer,
    orderActions: orderActionsReducer,
    auth: authReducer,
    tours: tourReducer,
    preSaveTour: preSaveTourReducer,
    users: userReducer,
    reports: reportsReducer,
    gallerySlice: galleryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
