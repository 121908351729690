import React, { FC, useState } from 'react';
import { ReminderProps } from '../Reminders.type';
import styles from './Reminder.module.scss';
import clsx from 'clsx';
import { CloseIcon } from 'components/icons';

export const Reminder: FC<ReminderProps> = ({ reminder, deleteId, deleteRemoteId, checkedId }) => {
  const { id, label, date, status } = reminder;
  const [checked, setChecked] = useState(status);

  const checkedItem = (e: React.MouseEvent, id: number) => {
    setChecked((prev) => !prev);
    checkedId(e, id);
  };

  return (
    <div className={styles.item}>
      <input
        type="checkbox"
        id={reminder.id.toString()}
        className={clsx(styles.checkbox, checked && styles.checkboxChecked)}
        onClick={(e: React.MouseEvent) => checkedItem(e, id)}
      />
      <div className={styles.info}>
        <label htmlFor={reminder.id.toString()} className={clsx(styles.label, checked && styles.labelChecked)}>
          {label}
        </label>
        {date ? (
          <div className={styles.date}>
            {date.toLocaleDateString()}
            {date?.getHours()
              ? ', ' +
                date?.getHours() +
                ':' +
                (date?.getMinutes() < 10 ? '0' + date?.getMinutes() : date?.getMinutes())
              : null}
          </div>
        ) : null}
      </div>
      <div className={styles.wrapperDelete}>
        <button
          className={styles.delete}
          onClick={(e: React.MouseEvent) => {
            deleteId(e, id);
            deleteRemoteId(reminder['@id'] ? String(reminder.id) : undefined);
          }}
        >
          <CloseIcon width={7} height={7} fill="#70A4CB" />
        </button>
      </div>
    </div>
  );
};
