import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { UserResponse } from './UsersResponse.model';

export const userFactory = (model: UserResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: `${model.last_name ? model.last_name : ''} ${model.first_name ? model.first_name : ''} ${
      model.middle_name ? model.middle_name : ''
    }`,
  };
};
