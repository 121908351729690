export const getTemplate = (template: string) => {
  switch (template) {
    case 'Многодневный тур':
      return 'moreday';
    case 'Однодневный тур':
      return 'oneday';
    case 'Тур без дат':
      return 'no_date';
    case 'Вертушка':
      return 'everyday';

    default:
      return 'moreday';
  }
};

export const getTemplateToUI = (template: string) => {
  switch (template) {
    case 'moreday':
      return 'Многодневный тур';
    case 'oneday':
      return 'Однодневный тур';
    case 'no_date':
      return 'Тур без дат';
    case 'everyday':
      return 'Вертушка';
  }
};
