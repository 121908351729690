import React, { FC, useEffect, useState } from 'react';
import styles from './ConsultationTab.module.scss';
import { DatePickerInput, DatesProvider } from '@mantine/dates';
import { DateIcon } from 'components/icons';
import { IOrderConsultation } from 'services/orders/orderApi.service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPreSaveOrder } from 'store/entities/orders/ordersSlice';
import { getDate } from 'core/utils/getDate';
import moment from 'moment';

export const ConsultationTab: FC<{ consultation?: IOrderConsultation | null; setDirty: () => void }> = ({
  setDirty,
}) => {
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const dispatch = useAppDispatch();
  const [dates, setDates] = useState<any>();
  const [allData, setAllData] = useState<IOrderConsultation>(
    preSaveOrder.order_consultation || {
      dates: '',
      place: '',
      tourists: '',
      hotel: '',
      room_category: '',
      transfer: '',
      transfer_service: '',
      documents_service: '',
      additional_service: '',
      price: '',
      variants_count: '',
      sum: '0',
    }
  );
  useEffect(() => {
    if (dates && dates[0] !== undefined && dates[1] !== null) {
      setAllData({ ...allData, dates: `${getDate(new Date(dates[0]))}-${getDate(new Date(dates[1]))}` });
    }
  }, [dates]);

  useEffect(() => {
    if (allData.dates) {
      const individualDates = allData.dates.split('-').map((item) => {
        return new Date(moment(item, 'DD.MM.YYYY').toDate());
      });
      setDates(individualDates);
    }
  }, []);

  //предсохранение всех данных в стейте и сбор данных всей вкладки для отправки в родительский компонент
  useEffect(() => {
    if (allData) {
      dispatch(setPreSaveOrder({ ...preSaveOrder, order_consultation: allData }));
    }
  }, [allData]);

  console.log(preSaveOrder.order_consultation);
  return (
    <>
      <section className={styles.form}>
        <h2 className={styles.title}>Пожелания заказчика</h2>
        <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
          <DatePickerInput
            type="range"
            allowSingleDateInRange={true}
            valueFormat="DD.MM.YYYY"
            label="Дата путешествия"
            maw={321}
            icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
            className={styles.select}
            value={dates}
            onChange={(date) => {
              setDates(date);
            }}
            onBlur={setDirty}
            clearable
            clearButtonProps={{
              onClick: () => {
                setDates(undefined);
                setAllData({ ...allData, dates: '' });
                setDirty();
              },
            }}
            styles={{
              label: {
                fontSize: '15px',
                color: '#737b87',
                marginBottom: '10px',
                fontFamily: 'Museo Sans Cyrl',
              },
              input: {
                width: '100%',
                height: '40px',
                marginRight: '16px',
                marginBottom: '10px',
                padding: ' 10px 16px',
                border: '1px solid #f5f6fa',
                borderRadius: '8px 8px 8px 8px',
                background: '#F5F6FA',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
              icon: {
                bottom: '4px',
              },
            }}
          />
        </DatesProvider>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Страна и место</div>
            <input
              className={styles.rowBody}
              value={allData.place}
              onChange={(e) => setAllData({ ...allData, place: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Кол-во туристов, возраст, пол</div>
            <input
              className={styles.rowBody}
              value={allData.tourists}
              onChange={(e) => setAllData({ ...allData, tourists: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Средство размещения</div>
            <input
              className={styles.rowBody}
              value={allData.hotel}
              onChange={(e) => setAllData({ ...allData, hotel: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Категория номера, кол-во номеров</div>
            <input
              className={styles.rowBody}
              value={allData.room_category}
              onChange={(e) => setAllData({ ...allData, room_category: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Трансфер (идив./групповой)</div>
            <input
              className={styles.rowBody}
              value={allData.transfer}
              onChange={(e) => setAllData({ ...allData, transfer: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Услуги перевозки</div>
            <input
              className={styles.rowBody}
              value={allData.transfer_service}
              onChange={(e) => setAllData({ ...allData, transfer_service: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Содействие для передачи документов</div>
            <input
              className={styles.rowBody}
              value={allData.documents_service}
              onChange={(e) => setAllData({ ...allData, documents_service: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Дополнительные услуги</div>
            <input
              className={styles.rowBody}
              value={allData.additional_service}
              onChange={(e) => setAllData({ ...allData, additional_service: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Примерная цена</div>
            <input
              className={styles.rowBody}
              value={allData.price}
              onChange={(e) => setAllData({ ...allData, price: e.target.value })}
              onBlur={setDirty}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Кол-во предложений</div>
            <input
              className={styles.rowBody}
              value={allData.variants_count}
              onChange={(e) => setAllData({ ...allData, variants_count: e.target.value })}
              onBlur={setDirty}
            />
          </div>
        </div>
        <div className={styles.sumContainer}>
          <div>Сумма</div>
          <input
            value={allData.sum === '0' || allData.sum === '0.00' ? '' : Number(allData.sum)}
            placeholder={allData.sum}
            onChange={(e) => {
              if (e.target.value.match(/^\d+$/)) {
                setAllData({ ...allData, sum: e.target.value });
              } else {
                setAllData({ ...allData, sum: e.target.value === '' ? '0' : allData.sum });
              }
            }}
            onBlur={setDirty}
          />
        </div>
      </section>
    </>
  );
};
