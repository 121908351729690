import React, { FC } from 'react';
import styles from './OrderCreate.module.scss';
import { OrderForm } from '../components/OrderForm';

export const OrderCreate: FC = () => {
  return (
    <section className={styles.create}>
      <div className={styles.top}>
        <h2 className={styles.title}>Новый заказ</h2>
      </div>
      <div className={styles.content}>
        <OrderForm />
      </div>
    </section>
  );
};
