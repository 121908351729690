import axiosInstance from '../axiosInstance';
import {
  ICreateReportProps,
  ICreateReportResponse,
  IReport,
  IReportsByIdProps,
  IReportsProps,
  IReportsResponse,
} from './reports.types';

// создание отчета
export const createReportAPI = async (data: ICreateReportProps) => {
  const response = await axiosInstance.post<ICreateReportResponse>('/api/v1/reports', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получение списка отчетов
export const getReportsAPI = async ({ page, type }: IReportsProps) => {
  const response = await axiosInstance.get<IReportsResponse>('/api/v1/reports', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      type: type,
    },
  });
  return response;
};

// получение списка отчетов по id отчета
export const getReportsByIdAPI = async ({ id }: IReportsByIdProps) => {
  const response = await axiosInstance.get<IReport>(`/api/v1/reports/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
