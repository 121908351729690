import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const PrevPageIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.5" cy="17" r="16.5" fill="white" />
      <circle cx="16.5" cy="17" r="16" stroke="#C8CDD8" strokeOpacity="0.3" />
      <path
        d="M9.46967 16.9697C9.17678 17.2626 9.17678 17.7374 9.46967 18.0303L14.2426 22.8033C14.5355 23.0962 15.0104 23.0962 15.3033 22.8033C15.5962 22.5104 15.5962 22.0355 15.3033 21.7426L11.0607 17.5L15.3033 13.2574C15.5962 12.9645 15.5962 12.4896 15.3033 12.1967C15.0104 11.9038 14.5355 11.9038 14.2426 12.1967L9.46967 16.9697ZM25 16.75L10 16.75V18.25L25 18.25V16.75Z"
        fill={fill}
        fillOpacity="0.75"
      />
    </svg>
  );
};
