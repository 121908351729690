import { createAsyncThunk } from '@reduxjs/toolkit';
import { createReportAPI, getReportsAPI, getReportsByIdAPI } from 'services/reports/reportsApi.service';
import { RootState } from 'store/store';

// создание отчета
export const createReport = createAsyncThunk('partners/createReport', async (_, thunkAPI) => {
  try {
    const {
      reports: { createReportForm },
    } = thunkAPI.getState() as RootState;
    const response = await createReportAPI(createReportForm);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка отчетов
export const getReports = createAsyncThunk('partners/getReports', async (_, thunkAPI) => {
  try {
    const {
      reports: { reportsPage },
    } = thunkAPI.getState() as RootState;
    const response = await getReportsAPI(reportsPage);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение отчета по id
export const getReportsById = createAsyncThunk('partners/getReportsById', async (_, thunkAPI) => {
  const {
    reports: { reportId },
  } = thunkAPI.getState() as RootState;
  try {
    const response = await getReportsByIdAPI(reportId);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
