import { notifications } from '@mantine/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import fileDownload from 'js-file-download';
import {
  AddOrderDoc,
  addOrderDocumentAPI,
  deleteDocumentAPI,
  sendOrderDocumentAPI,
  uploadDocAPI,
} from 'services/orderDocument/orderDocumentApi.service';

// создание документа
export const addOrderDocument = createAsyncThunk('orders/addOrderDocument', async (arg: AddOrderDoc, thunkAPI) => {
  try {
    const response = await addOrderDocumentAPI(arg);
    notifications.show({
      title: `Успех!`,
      color: 'green',
      message: `Документы созданы, вы также можете их скачать во вкладе "История созданных документов" `,
    });
    console.log(response.data);
    for (let index = 0; index < response.data.result.length; index++) {
      const element = response.data.result[index].link;
      axios
        .get(process.env.REACT_APP_BASE_API_URL + element, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, response.data.result[index].name);
        });
    }
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    notifications.show({
      title: 'Ошибка',
      color: 'red',
      message: error.response.data.detail ? error.response.data.detail : error.response.data['hydra:description'],
    });
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const deleteOrderDocument = createAsyncThunk('orders/deleteOrderDocument', async (arg: number, thunkAPI) => {
  try {
    const response = await deleteDocumentAPI(arg);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const sendOrderDocument = createAsyncThunk(
  'orders/sendOrderDocument',
  async (arg: { id: string; all?: boolean; document?: string }, thunkAPI) => {
    try {
      const response = await sendOrderDocumentAPI(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

export const uploadDocument = createAsyncThunk(
  'orders/uploadDocument',
  async (arg: { file: File; order_id: string }, thunkAPI) => {
    try {
      const response = await uploadDocAPI(arg);

      return response.data;
    } catch (error: any) {
      console.log(error.response);
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);
