import React, { FC } from 'react';
import { ImageFileType } from './ImageFile.type';

import styles from './ImageFile.module.scss';
import { CloseIcon } from 'components/icons';
import { DownLoad } from 'components/icons/Download';

const ImageFile: FC<ImageFileType> = ({ name, preview, size, type, path = '', deleteFile, canDownload = false }) => {
  const handleDelete = (e: React.MouseEvent, name: string) => {
    e.preventDefault();
    deleteFile && deleteFile(name);
  };
  return (
    <article className={styles.file}>
      {type.includes('image/') && (
        <div className={styles.imgWrapper}>
          <img src={preview} alt={name} className={styles.img} />
        </div>
      )}

      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        <div className={styles.params}>
          <div className={styles.size}>{size > 0 && `${(size / 1000).toFixed(2)} КБ`}</div>
          <div className={styles.type}>&nbsp;{type}</div>
        </div>
      </div>
      {deleteFile && (
        <button className={styles.delete} onClick={(e: React.MouseEvent) => handleDelete(e, name)}>
          <CloseIcon width={8} height={8} fill={'rgba(91, 101, 114, 0.75)'} />
        </button>
      )}
      {path !== '' && (
        <a
          href={`${process.env.REACT_APP_BASE_API_URL}${path}`}
          download={name}
          target="_blank"
          rel="noreferrer"
          className={styles.download}
        >
          <DownLoad />
        </a>
      )}
      {canDownload && (
        <a href={preview} download={name} target="_blank" rel="noreferrer" className={styles.download}>
          <DownLoad />
        </a>
      )}
    </article>
  );
};

export default ImageFile;
