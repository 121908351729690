import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateChat,
  IMessange,
  getChatMessagesAPI,
  getChatsAPI,
  getReadChatAPI,
  sendChatMessangeAPI,
  sendChatMessangeWithOutChatAPI,
} from 'services/chats/chats.service';

export const getChats = createAsyncThunk('chats/getChats', async (_, thunkAPI) => {
  try {
    const response = await getChatsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getChatMessages = createAsyncThunk(
  'chats/getChatMessages',
  async (
    {
      iri,
      is_read,
      to_user,
    }: {
      iri: string;
      is_read?: boolean;
      to_user?: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await getChatMessagesAPI({ iri, is_read, to_user });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

export const sendChatMessages = createAsyncThunk('chats/sendChatMessages', async (data: IMessange, thunkAPI) => {
  try {
    const response = await sendChatMessangeAPI(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const createChat = createAsyncThunk('chats/sendChatMessagesWithOutChat', async (data: ICreateChat, thunkAPI) => {
  try {
    return await Promise.all([sendChatMessangeWithOutChatAPI(data)]).then(async (res) => {
      const chatData = res[0].data;
      await sendChatMessangeAPI({ ...data.message, chat: String(chatData['@id']) });
      return chatData;
    });
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getReadChat = createAsyncThunk('chats/getChats', async (id: number, thunkAPI) => {
  try {
    const response = await getReadChatAPI(id);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
