import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const DownLoad: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70397 8.113L9.30697 6.503C9.37224 6.43773 9.44972 6.38596 9.535 6.35064C9.62027 6.31531 9.71167 6.29713 9.80397 6.29713C9.89627 6.29713 9.98767 6.31531 10.0729 6.35064C10.1582 6.38596 10.2357 6.43773 10.301 6.503C10.3662 6.56826 10.418 6.64575 10.4533 6.73102C10.4887 6.8163 10.5068 6.9077 10.5068 7C10.5068 7.0923 10.4887 7.1837 10.4533 7.26897C10.418 7.35425 10.3662 7.43173 10.301 7.497L7.50097 10.297C7.4344 10.3607 7.3559 10.4107 7.26997 10.444C7.09955 10.514 6.90839 10.514 6.73797 10.444C6.65205 10.4107 6.57354 10.3607 6.50697 10.297L3.70697 7.497C3.64136 7.43192 3.58929 7.3545 3.55375 7.2692C3.51821 7.1839 3.49991 7.0924 3.49991 7C3.49991 6.90759 3.51821 6.81609 3.55375 6.73079C3.58929 6.64549 3.64136 6.56807 3.70697 6.503C3.77205 6.43739 3.84947 6.38531 3.93477 6.34977C4.02007 6.31423 4.11156 6.29594 4.20397 6.29594C4.29638 6.29594 4.38787 6.31423 4.47318 6.34977C4.55848 6.38531 4.6359 6.43739 4.70097 6.503L6.30397 8.113L6.30397 1.4C6.30397 1.21435 6.37772 1.0363 6.509 0.905022C6.64027 0.773747 6.81832 0.699997 7.00397 0.699997C7.18962 0.699997 7.36767 0.773747 7.49895 0.905022C7.63022 1.0363 7.70397 1.21435 7.70397 1.4L7.70397 8.113ZM12.805 7.20504C12.9363 7.07376 13.1143 7.00001 13.3 7.00001C13.4857 7.00001 13.6637 7.07376 13.795 7.20504C13.9263 7.33631 14 7.51436 14 7.70001V11.9C14 12.457 13.7788 12.9911 13.3849 13.3849C12.9911 13.7788 12.457 14 11.9 14H2.1C1.54305 14 1.0089 13.7788 0.615076 13.3849C0.221249 12.9911 0 12.457 0 11.9V7.70001C0 7.51436 0.0737498 7.33631 0.205025 7.20504C0.336301 7.07376 0.514348 7.00001 0.7 7.00001C0.885652 7.00001 1.0637 7.07376 1.19497 7.20504C1.32625 7.33631 1.4 7.51436 1.4 7.70001V11.9C1.4 12.0857 1.47375 12.2637 1.60503 12.395C1.7363 12.5263 1.91435 12.6 2.1 12.6H11.9C12.0857 12.6 12.2637 12.5263 12.395 12.395C12.5263 12.2637 12.6 12.0857 12.6 11.9V7.70001C12.6 7.51436 12.6737 7.33631 12.805 7.20504Z"
        fill="#5B6572"
        fillOpacity="0.75"
      />
    </svg>
  );
};
