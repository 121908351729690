import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const CopyIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9045 5.97833C10.9995 5.74889 10.9995 5.45722 10.9995 4.875C10.9995 4.29278 10.9995 4.00111 10.9045 3.77167C10.8417 3.61996 10.7496 3.48212 10.6335 3.36602C10.5174 3.24991 10.3795 3.15782 10.2278 3.095C9.9984 3 9.70673 3 9.12451 3H5.49951C4.79951 3 4.44951 3 4.18229 3.13611C3.94688 3.25599 3.7555 3.44737 3.63562 3.68278C3.49951 3.94944 3.49951 4.29944 3.49951 5V8.625C3.49951 9.20722 3.49951 9.49889 3.59451 9.72833C3.72173 10.0344 3.96507 10.2783 4.27118 10.405C4.50062 10.5 4.79229 10.5 5.37451 10.5C5.95673 10.5 6.2484 10.5 6.47784 10.405M10.9045 5.97833C10.8417 6.13004 10.7496 6.26788 10.6335 6.38398C10.5174 6.50009 10.3795 6.59218 10.2278 6.655C9.9984 6.75 9.70673 6.75 9.12451 6.75C8.54229 6.75 8.25062 6.75 8.02118 6.845C7.86947 6.90782 7.73163 6.99991 7.61553 7.11602C7.49942 7.23212 7.40733 7.36996 7.34451 7.52167C7.24951 7.75111 7.24951 8.04278 7.24951 8.625C7.24951 9.20722 7.24951 9.49889 7.15451 9.72833C7.09169 9.88004 6.9996 10.0179 6.8835 10.134C6.76739 10.2501 6.62955 10.3422 6.47784 10.405M10.9045 5.97833C10.6057 7.00888 10.056 7.94923 9.30463 8.71518C8.55323 9.48112 7.62358 10.0488 6.59896 10.3672L6.47784 10.405M8.49951 3V2.5C8.49951 1.8 8.49951 1.45 8.3634 1.18278C8.24367 0.947452 8.05249 0.756083 7.81729 0.636111C7.54951 0.5 7.19951 0.5 6.49951 0.5H2.99951C2.29951 0.5 1.94951 0.5 1.68229 0.636111C1.44688 0.755989 1.2555 0.947367 1.13562 1.18278C0.999512 1.44944 0.999512 1.79944 0.999512 2.5V6C0.999512 6.7 0.999512 7.05 1.13562 7.31722C1.25562 7.55278 1.44673 7.74389 1.68229 7.86389C1.94896 8 2.29951 8 3.00007 8H3.49951"
        stroke="#5B6572"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
