import React, { FC } from 'react';
import { NavigationType } from '../../LayoutNavbar.type';
import { NavLink } from 'react-router-dom';
import styles from './NavbarItem.module.scss';
import clsx from 'clsx';

type NavbarItemProps = {
  item: NavigationType;
  opened: boolean;
};

const NavbarItem: FC<NavbarItemProps> = ({ item, opened }) => {
  const { title, href, icon } = item;
  return (
    <NavLink to={href} className={({ isActive }) => (isActive ? clsx(styles.link, styles.linkActive) : styles.link)}>
      {icon}
      {opened && <div className={styles.title}>{title}</div>}
    </NavLink>
  );
};

export default NavbarItem;
