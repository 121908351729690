import { useEffect } from 'react';
import { resetPreSaveLegalPerson, resetPreSavePrivatePerson } from 'store/entities/partners/partnersSlice';
import { useAppDispatch } from 'store/hooks';

export const useRefCloseOut = (
  openingBlock: React.MutableRefObject<HTMLElement>,
  setState: (a: boolean) => void,
  openingButton?: React.MutableRefObject<HTMLElement>,
  isWithReset = true,
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handler = (event: any) => {
      if (openingButton) {
        if (
          openingBlock.current &&
          !openingBlock.current.contains(event.target) &&
          !openingButton.current.contains(event.target)
        ) {
          setState(false);
          if (isWithReset) {
            dispatch(resetPreSavePrivatePerson());
            dispatch(resetPreSaveLegalPerson());
          }
        }
      } else {
        if (openingBlock.current && !openingBlock.current.contains(event.target)) {
          setState(false);
        }
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [openingBlock, openingButton, isWithReset]);
};
