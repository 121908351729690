import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const ClientChildIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1342 4.4176C10.1342 5.59619 9.17878 6.55162 8.0002 6.55162C6.82162 6.55162 5.86619 5.59619 5.86619 4.4176C5.86619 3.23902 6.82162 2.28359 8.0002 2.28359C9.17878 2.28359 10.1342 3.23902 10.1342 4.4176ZM11.7342 4.4176C11.7342 6.47984 10.0624 8.15162 8.0002 8.15162C5.93796 8.15162 4.26619 6.47984 4.26619 4.4176C4.26619 2.35537 5.93796 0.683594 8.0002 0.683594C10.0624 0.683594 11.7342 2.35537 11.7342 4.4176ZM7.99992 8.60527C7.15422 8.60527 6.1982 8.64789 5.28624 8.84708C4.37525 9.04605 3.4524 9.4131 2.72556 10.1036C2.00152 10.7914 1.57043 11.7068 1.31815 12.614C1.06458 13.5258 0.976562 14.4826 0.976562 15.3175H2.57656C2.57656 14.5843 2.65499 13.7786 2.85965 13.0426C3.06559 12.3021 3.3845 11.6845 3.82756 11.2636C4.26783 10.8453 4.88139 10.5732 5.62766 10.4102C6.37297 10.2474 7.19507 10.2053 7.99992 10.2053C8.80466 10.2053 9.64131 10.2474 10.4014 10.4108C11.1645 10.5748 11.7883 10.8482 12.2256 11.2636C12.6634 11.6795 12.9698 12.2907 13.1637 13.031C13.3564 13.7669 13.4233 14.575 13.4233 15.3175H15.0233C15.0233 14.492 14.9503 13.5374 14.7115 12.6256C14.4738 11.7181 14.0569 10.7964 13.3276 10.1036C12.5978 9.41029 11.6584 9.04445 10.7377 8.84652C9.81383 8.64793 8.84571 8.60527 7.99992 8.60527Z"
        fill={fill}
      />
    </svg>
  );
};
