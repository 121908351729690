import axiosInstance from '../axiosInstance';

export interface UsersData {
  page: number;
}

// получение списка пользователей
export const getUsersAPI = async ({ page }: UsersData) => {
  const response = await axiosInstance.get('/api/v1/users', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      //TODO сделать пагинацию
      itemsPerPage: 10000,
    },
  });
  return response;
};
export const getManagersAPI = async () => {
  const response = await axiosInstance.get('/api/v1/users/managers', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
// получение текущего пользователя
export const getCurrentUserAPI = async () => {
  return await axiosInstance.get('/api/v1/users/me', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
};
