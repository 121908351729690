import React, { FC } from 'react';
import { CheckboxType } from './Checkbox.type';
import styles from './Checkbox.module.scss';

export const Checkbox: FC<CheckboxType> = ({ label, checked, isChecked, setIsChecked, ...props }) => {
  const defaultChecked = checked ? checked : false;

  const handleChange = (e: boolean) => {
    setIsChecked(e);
  };

  return (
    <div className={styles.wrapper}>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleChange(e.currentTarget.checked)}
          {...props}
          className={isChecked ? styles.checked : ''}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};
