import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addOrderAPI,
  addOrderData,
  getBookingCancelAPI,
  getBookingStatusAPI,
  getOrderConsultations,
  getVoucherAPI,
  Order,
  orderAPI,
  OrderData,
  ordersAPI,
  updateOrderAPI,
} from 'services/orders/orderApi.service';
import { orderResponseAdapter } from '../../services/orders/orders.adapter';
import { getOrderActions } from './orderActions';
import { OrderActionResponse } from '../../services/orderActions/OrderActinResponse.model';
import {
  getAdditionalNight,
  getDocument,
  getNotifications,
  getOrderPaymentLinks,
  getPlacements,
  getPlannedPayment,
  getRealPayment,
  getTransfer,
} from './orderPaymentLink';
import { getTour, getTourDatesList } from './tours';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { getChatMessagesAPI, sendChatMessangeWithOutChatAPI } from 'services/chats/chats.service';

// добавление заказа
export const addOrder = createAsyncThunk('orders/addOrder', async (arg: addOrderData, thunkAPI) => {
  try {
    const response = await addOrderAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const updateOrder = createAsyncThunk('orders/updateOrder', async (arg: Partial<Order>, thunkAPI) => {
  try {
    const response = await updateOrderAPI(arg);
    console.log('SUCCESS UPDATE', response.data);
    return orderResponseAdapter(response.data);
  } catch (error) {
    console.log('ERROR UPDATE', error);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получение списка заказов

export const getOrders = createAsyncThunk('orders/getOrders', async (arg: OrderData, thunkAPI) => {
  try {
    const response = await ordersAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
// получение заказа

export const getOrder = createAsyncThunk('orders/getOrder', async (arg: { id: number }, thunkAPI) => {
  try {
    return await Promise.all([
      orderAPI(arg.id),
      thunkAPI.dispatch(getOrderActions({ tour_order: arg.id, is_system: false })),
      thunkAPI.dispatch(getRealPayment({ id: arg.id })),
      thunkAPI.dispatch(getPlannedPayment({ id: arg.id })),
      thunkAPI.dispatch(getAdditionalNight({ id: arg.id })),
      thunkAPI.dispatch(getNotifications({ id: arg.id })),
      thunkAPI.dispatch(getPlacements({ id: arg.id })),
      thunkAPI.dispatch(getTransfer({ id: arg.id })),
      thunkAPI.dispatch(getDocument({ id: arg.id })),
      thunkAPI.dispatch(getOrderPaymentLinks({ id: arg.id })),
    ]).then(async (data) => {
      const order = { ...data[0].data };
      let chat;
      let newChat;
      if (order.chat) {
        chat = await getChatMessagesAPI({ iri: order.chat });
      } else {
        if (order.contact.user && order.manager) {
          newChat = await sendChatMessangeWithOutChatAPI({
            order_entity: String(order['@id']),
            manager: `/api/v1/users/${order.manager.id}`,
            contact: String(order.contact.user?.['@id']),
          });
        }
      }
      let consultations;
      if (typeof order.order_consultation === 'string') {
        const id = order.order_consultation.replace('/api/v1/order-consultations/', '');
        consultations = await getOrderConsultations(Number(id));
      }
      if (order.tour?.id) {
        thunkAPI.dispatch(getTour({ id: String(order.tour?.id) }));
        thunkAPI.dispatch(getTourDatesList({ id: `/api/v1/tours/${order.tour?.id}` }));
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      order.order_actions = data[1].payload['hydra:member'] as OrderActionResponse[];
      order.real_payments = data[2].payload['hydra:member'];
      order.planned_payments = data[3].payload['hydra:member'];
      order.order_additional_nights = data[4].payload['hydra:member'];
      order.order_notifications = data[5].payload['hydra:member'];
      order.placements = data[6].payload['hydra:member'];
      order.order_transfers = data[7].payload['hydra:member'];
      order.order_documents = data[8].payload['hydra:member'];
      order.payment_links = data[9].payload['hydra:member'];
      if (consultations?.data) {
        order.order_consultation = consultations?.data;
      }
      if (newChat?.data) {
        order.chat = newChat.data['@id'];
      }
      if (chat?.data) {
        order.chat_message = chat?.data['hydra:member'];
        const count = chat?.data['hydra:member'].map((el: { author: any; is_read: any }) => {
          return { author: el.author, is_read: el.is_read };
        });

        order.countUnread = count.filter(
          (el: { author: string; is_read: boolean }) => el.author === order.contact.user?.['@id'] && !el.is_read
        ).length;
      }
      return orderResponseAdapter(order);
    });
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getBookingStatus = createAsyncThunk('orders/getBookingStatus', async (arg: number, thunkAPI) => {
  try {
    const response = await getBookingStatusAPI(arg);
    if (response.data.status === 'waiting') {
      notifications.show({
        title: `В процессе`,
        color: 'yellow',
        message: `Бронирование ожидается`,
      });
    }
    if (response.data.status === 'success') {
      notifications.show({
        title: `Успех!`,
        color: 'green',
        message: `Бронирование успешно`,
      });
      thunkAPI.dispatch(getOrder({ id: arg }));
    }
    if (response.data.status === 'error') {
      notifications.show({
        title: `Ошибка!`,
        color: 'red',
        message: `Произошла ошибка! Проверьте бронирование в личном кабинете!`,
      });
    }
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getVoucher = createAsyncThunk('orders/getVoucher', async (arg: number, thunkAPI) => {
  try {
    const response = await getVoucherAPI(arg);
    if (response.data.success) {
      axios
        .get(response.data.download_url, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, 'voucher');
        });
    }
    if (!response.data.success) {
      notifications.show({
        title: `Ошибка!`,
        color: 'red',
        message: `Произошла ошибка! Проверьте бронирование в личном кабинете!`,
      });
    }
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getBookingCancel = createAsyncThunk('orders/getBookingCancel', async (arg: number, thunkAPI) => {
  try {
    const response = await getBookingCancelAPI(arg);
    if (response.data.success) {
      notifications.show({
        title: `Успех!`,
        color: 'green',
        message: `Бронирование отменено`,
      });
    }
    if (!response.data.success) {
      notifications.show({
        title: `Ошибка!`,
        color: 'red',
        message: `Произошла ошибка! Проверьте бронирование в личном кабинете!`,
      });
    }
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
