import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const BarIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8275 6.5L8.75 8.8325L6.6725 6.5H10.8275ZM15.5 3C15.5 2.44772 15.0523 2 14.5 2H3C2.44772 2 2 2.44772 2 3V3.5L8 10.25V13C8 13.5523 7.55228 14 7 14H5C4.58579 14 4.25 14.3358 4.25 14.75C4.25 15.1642 4.58579 15.5 5 15.5H12.5C12.9142 15.5 13.25 15.1642 13.25 14.75C13.25 14.3358 12.9142 14 12.5 14H10.5C9.94772 14 9.5 13.5523 9.5 13V10.25L15.5 3.5V3ZM5.3225 5L3.995 3.5H13.5125L12.1775 5H5.3225Z"
        fill="#1886E9"
      />
    </svg>
  );
};
