import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Button } from 'ui/Button';
import { FilterIcon } from 'components/icons/FilterIcon';
import { FilterByActionItem, FilterByActionProps } from './FilterByAction.type';
import { useAppDispatch } from 'store/hooks';
import {
  resetParamsIsSystemActions,
  setParamsHiddenAllActions,
  setParamsIsSystemActions,
} from 'store/entities/orderActions/orderActionsSlice';

import styles from './FilterByAction.module.scss';

export const FilterByAction: FC<FilterByActionProps> = ({ filters, setFilters }) => {
  const [viewFilter, setViewFilter] = useState<boolean>(true);
  const [textSettings, setTextSettings] = useState('');
  const [filtersList, setFiltersList] = useState<FilterByActionItem[]>(filters);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const checkedFilters = filtersList.filter((item) => item.active === true);
    setFilters(checkedFilters);
  }, [filtersList]);

  const handleClickFilter = (id: string | number) => {
    const updateFilters = filtersList.map((item) => {
      if (item.id === id) {
        item = { ...item, active: !item.active };
      }
      return item;
    });
    setFiltersList(updateFilters);
  };

  useEffect(() => {
    const checkedFilter = filtersList.filter((filter) => filter.active === true);
    if (checkedFilter.length > 0) {
      setTextSettings('Сбросить');
    } else {
      setTextSettings('Включить все');
    }
  }, [filtersList]);

  const handleClickSetting = () => {
    if (textSettings === 'Сбросить') {
      const updateFilters = filtersList.map((item) => {
        item = { ...item, active: false };
        return item;
      });
      setFiltersList(updateFilters);
    } else if (textSettings === 'Включить все') {
      const updateFilters = filtersList.map((item) => {
        item = { ...item, active: true };
        return item;
      });
      setFiltersList(updateFilters);
    }
  };

  useEffect(() => {
    const updateFilters = filtersList.filter((item) => item.active == true);

    if (updateFilters.length === 0) {
      dispatch(setParamsHiddenAllActions(true));
    }
    if (updateFilters.length === 2) {
      dispatch(resetParamsIsSystemActions());
      dispatch(setParamsHiddenAllActions(false));
    } else if (updateFilters.length === 1) {
      if (updateFilters[0].id === 1) {
        dispatch(setParamsIsSystemActions(false));
        dispatch(setParamsHiddenAllActions(false));
      }
      if (updateFilters[0].id === 2) {
        dispatch(setParamsIsSystemActions(true));
        dispatch(setParamsHiddenAllActions(false));
      }
    }
  }, [filtersList]);

  return (
    <section className={styles.filter}>
      <div className={styles.top} onClick={() => setViewFilter((prev) => !prev)}>
        <FilterIcon width={16} height={13} stroke="#70A4CB" />
        <div className={clsx(styles.topUp, viewFilter && styles.topDown)}></div>
        <div className={styles.line}></div>
      </div>
      <div className={styles.buttons}>
        {viewFilter && (
          <>
            {filtersList.map((filter) => (
              <Button
                key={filter.id}
                className={clsx(styles.btn, filter.active && styles.btnActive)}
                onClick={() => handleClickFilter(filter.id)}
                label={filter.label}
              />
            ))}
            <Button className={clsx(styles.btnSettings)} onClick={() => handleClickSetting()} label={textSettings} />
          </>
        )}
      </div>
    </section>
  );
};
