import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OrderProps } from 'pages/Orders/Order.types';
import { OrdersDataRes } from 'services/orders/orderApi.service';
import { createChat, getChatMessages } from 'store/action-creators/chats';
import { addOrderDocument } from 'store/action-creators/orderDocument';
import {
  addOrderPaymentLink,
  addOrderPaymentLinkCancel,
  addOrderPaymentLinkFinish,
  addOrderPlannedPayment,
  addOrderRealPayment,
} from 'store/action-creators/orderPaymentLink';
import { addOrder, getBookingCancel, getOrder, getOrders, updateOrder } from 'store/action-creators/orders';
import { OrdersState } from 'store/entities/orders/OrdersTypes';

const initialState: OrdersState = {
  orderCount: 0,
  isLoading: null,
  isNeedToReload: false,
  isSuccess: null,
  isError: null,
  orders: [],
  isOdrderModal: false,
  selectedOrder: null,
  params: {
    currentPage: 1,
    search: null,
    manager: null,
    status: '',
    'created_at[before]': null,
    'created_at[after]': null,
    sortById: 'desc',
    sortByPartner: null,
    sortbyData: null,
    sortbyStatus: null,
    sortbyTotalSum: null,
    itemsPerPage: 50,
    tour_type: '',
    isConsultations: false,
  },
  preSaveOrder: {
    id: '',
    status: '',
    site_request: null,
    order_consultation: null,
    isShared: false,
    basicInfo: {
      partner: null,
      contactPerson: '',
      requisites: [],
      phone: '',
      email: '',
      manager: null,
      status: '',
      refundAmount: 0,
      newNote: { description: '', files: [] },
      reminders: undefined,
      orderActions: [],
    },
    tourInfo: {
      tour: null,
      date: null,
      adults: 0,
      tour_operator_contact: null,
      children: 0,
      operatorApplicationNo: '',
      reserve_system_name: '',
      individual: false,
      internationalPassport: false,
      isAccommodation: false,
      isExtraNight: false,
      show_akvarel: false,
      isHotelTransfer: false,
      isCruize: false,
      show_in_pass: false,
      tourists: [],
      hotels: [],
      hotelsTrans: [],
      extraNights: [],
      extraService: [],
      sales: [],
      transfers: [],
      fullDescription: '',
      base_price: '0',
      pass_placements_array: [],
      pass_tour_dates_array: [],
    },
    documentsAndPayment: {
      exchange_rate: '',
      total_amount: '0',
      agent_reward: '0',
      base_summ: '0',
      changed_baseSum: '0',
      services_summ: '0',
      additional_nights_summ: '0',
      sales_summ: '0',
      manager_sale: '0',
      manager_sale_percent: '0',
      supplierPrice: '0',
      total_summ: '0',
      real_payments: [],
      planned_payments: [],
      order_documents: [],
      payment_links: [],
      payer: undefined,
    },
    manager: {
      id: 0,
      avatar: undefined,
      name: '',
      viewName: undefined,
      setUser: undefined,
      className: undefined,
    },
    user: '',
  },

  isResetPreSaveOrder: false,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {
    setPreSaveOrder(state, action: PayloadAction<OrderProps>) {
      state.preSaveOrder = action.payload;
    },
    setPreSavePresentOrder(state, action: PayloadAction<OrderProps>) {
      state.preSaveOrder.presentation = action.payload;
    },
    resetPreSaveOrder: (state) => {
      state.preSaveOrder = initialState.preSaveOrder;
      state.isResetPreSaveOrder = true;
    },
    resetSelectedOrder: (state) => {
      state.selectedOrder = null;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.preSaveOrder.status = action.payload;
    },
    setParamsPage(state, action: PayloadAction<number>) {
      state.params.currentPage = action.payload;
    },
    setIsOrderModal: (state, action: PayloadAction<boolean>) => {
      state.isOdrderModal = action.payload;
    },
    setIsShared: (state, action: PayloadAction<boolean>) => {
      state.preSaveOrder.isShared = action.payload;
    },
    setIsNeedReload(state, action: PayloadAction<boolean>) {
      state.isNeedToReload = action.payload;
    },
    setParamsSearch(state, action: PayloadAction<string>) {
      state.params.search = action.payload;
    },
    setParamsManager(state, action: PayloadAction<string>) {
      state.params.manager = action.payload;
    },
    setParamsIsConsultation(state, action: PayloadAction<boolean>) {
      state.params.isConsultations = action.payload;
    },
    setParamsTourType(state, action: PayloadAction<string>) {
      state.params.tour_type = action.payload;
    },
    setParamsStatus(state, action: PayloadAction<string | null>) {
      state.params.status = action.payload;
    },
    setParamsDateFrom(state, action: PayloadAction<Date | null>) {
      state.params['created_at[before]'] = action.payload;
    },
    setParamsDateTo(state, action: PayloadAction<Date | null>) {
      state.params['created_at[after]'] = action.payload;
    },
    setParamsSortById(state, action: PayloadAction<'asc' | 'desc' | null>) {
      state.params.sortById = action.payload;
    },
    setParamsSortByPartner(state, action: PayloadAction<'asc' | 'desc' | null>) {
      state.params.sortByPartner = action.payload;
    },
    setParamsSortByStatus(state, action: PayloadAction<'asc' | 'desc' | null>) {
      state.params.sortbyStatus = action.payload;
    },
    setParamsSortByData(state, action: PayloadAction<'asc' | 'desc' | null>) {
      state.params.sortbyData = action.payload;
    },
    setParamsSortByTotalSum(state, action: PayloadAction<'asc' | 'desc' | null>) {
      state.params.sortbyTotalSum = action.payload;
    },
  },
  extraReducers: (builder) => {
    //добавление заказа
    builder.addCase(addOrder.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'ADD_ORDER';
      state.isNeedToReload = true;
    });
    builder.addCase(addOrder.pending, (state) => {
      state.isLoading = 'ADD_ORDER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addOrder.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_ORDER';
    });
    //получение списка контрагентов
    builder.addCase(getOrders.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_ORDERS';
      state.isError = null;
      state.orders = action.payload['hydra:member'];
      state.orderCount = action.payload['hydra:totalItems'];
    });
    builder.addCase(getOrders.pending, (state) => {
      state.isLoading = 'GET_ORDERS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getOrders.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_ORDERS';
    });
    //получение заказа для редактирования
    builder.addCase(getOrder.fulfilled, (state, action: PayloadAction<OrderProps>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_ORDER';
      state.isError = null;
      state.preSaveOrder = action.payload;
    });
    builder.addCase(getOrder.pending, (state) => {
      state.isLoading = 'GET_ORDER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getOrder.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_ORDER';
    });
    //получение заказа для редактирования
    builder.addCase(updateOrder.fulfilled, (state, action: PayloadAction<OrderProps>) => {
      state.isLoading = null;
      state.isSuccess = 'EDIT_ORDER';
      state.isError = null;
      state.isNeedToReload = true;
    });
    builder.addCase(updateOrder.pending, (state) => {
      state.isLoading = 'EDIT_ORDER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(updateOrder.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'EDIT_ORDER';
    });
    builder.addCase(addOrderRealPayment.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_REAL_PAYMENT';
      state.isError = null;
    });
    builder.addCase(addOrderPlannedPayment.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_PLANNED_PAYMENT';
      state.isError = null;
    });

    // добавление ссылки на оплату
    builder.addCase(addOrderPaymentLink.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_ORDER_PAYMENT_LINK';
      state.isError = null;
    });
    builder.addCase(addOrderPaymentLink.pending, (state) => {
      state.isLoading = 'ADD_ORDER_PAYMENT_LINK';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addOrderPaymentLink.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_ORDER_PAYMENT_LINK';
    });
    // добавление ссылки на оплату
    builder.addCase(addOrderPaymentLinkFinish.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'FINISH_PAYMENT_LINK';
      state.isError = null;
    });
    builder.addCase(addOrderPaymentLinkFinish.pending, (state) => {
      state.isLoading = 'FINISH_PAYMENT_LINK';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addOrderPaymentLinkFinish.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'FINISH_PAYMENT_LINK';
    });

    // добавление ссылки на оплату
    builder.addCase(addOrderPaymentLinkCancel.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'CANCEL_PAYMENT_LINK';
      state.isError = null;
    });
    builder.addCase(addOrderPaymentLinkCancel.pending, (state) => {
      state.isLoading = 'CANCEL_PAYMENT_LINK';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addOrderPaymentLinkCancel.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'CANCEL_PAYMENT_LINK';
    });

    // добавление документа
    builder.addCase(addOrderDocument.fulfilled, (state) => {
      state.isLoading = null;
      state.isSuccess = 'ADD_ORDER_DOCUMENT';
      state.isError = null;
    });
    builder.addCase(addOrderDocument.pending, (state) => {
      state.isLoading = 'ADD_ORDER_DOCUMENT';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addOrderDocument.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_ORDER_DOCUMENT';
    });
    builder.addCase(createChat.fulfilled, (state, action) => {
      state.preSaveOrder.chat = action.payload['@id'];
    });
    builder.addCase(getChatMessages.fulfilled, (state, action) => {
      state.preSaveOrder.chat_message = action.payload['hydra:member'];
      const arr = action.payload['hydra:member'].map((el: { author: any; is_read: any }) => {
        return { author: el.author, is_read: el.is_read };
      });
      state.preSaveOrder.countUnread = arr.filter(
        (el: { author: string; is_read: boolean }) => el.author === state.preSaveOrder.user && !el.is_read
      ).length;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setPreSaveOrder,
  setParamsTourType,
  resetPreSaveOrder,
  setParamsSortByStatus,
  setParamsSortByData,
  setParamsSortByTotalSum,
  resetSelectedOrder,
  setIsShared,
  setParamsPage,
  setParamsDateFrom,
  setParamsDateTo,
  setParamsManager,
  setIsOrderModal,
  setIsNeedReload,
  setParamsSearch,
  setParamsSortById,
  setParamsSortByPartner,
  setStatus,
  setParamsStatus,
  setParamsIsConsultation,
  setPreSavePresentOrder,
} = ordersSlice.actions;

export default ordersSlice.reducer;
