import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const LiftIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10007 12H13.3001V8.6H4.10007V12ZM2.70007 8.2C2.70007 7.64772 3.14779 7.2 3.70007 7.2H13.7001C14.2524 7.2 14.7001 7.64772 14.7001 8.2V15.2C14.7001 15.7523 14.2524 16.2 13.7001 16.2H3.70007C3.14779 16.2 2.70007 15.7523 2.70007 15.2V8.2ZM13.3001 13.4H4.10007V14.8H13.3001V13.4Z"
        fill="#1886E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70007 2C10.0867 2 10.4001 2.3134 10.4001 2.7V3.37368L15.585 2.50952C15.9663 2.44597 16.327 2.70358 16.3905 3.08492C16.4541 3.46626 16.1965 3.82692 15.8152 3.89048L10.4001 4.79299V6.2C10.4001 7.2456 9.84819 7.88752 9.22154 8.2277C8.64253 8.54202 8.01238 8.6 7.70007 8.6V7.2C7.88776 7.2 8.25762 7.15798 8.55361 6.9973C8.80196 6.86248 9.00007 6.6544 9.00007 6.2V4.99295L1.7869 5.89459C1.40328 5.94255 1.05343 5.67044 1.00548 5.28682C0.957527 4.90321 1.22964 4.55336 1.61325 4.50541L9.00007 3.58205V2.7C9.00007 2.3134 9.31347 2 9.70007 2Z"
        fill="#1886E9"
      />
    </svg>
  );
};
