import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { checkAuth, login } from '../../action-creators/auth';
import { AuthState } from './authTypes';

const initialState: AuthState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isAuth: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setLogout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      state.isAuth = false;
      state.isError = false;
    },
  },

  extraReducers: (builder) => {
    //получение токена
    builder.addCase(login.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    });

    //получение refresh токена
    builder.addCase(checkAuth.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(checkAuth.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
    });
    builder.addCase(checkAuth.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    });
  },
});

export const { setAuth, setLogout } = authSlice.actions;
export default authSlice.reducer;
