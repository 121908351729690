import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TourHotelInfo } from 'pages/Tours/Tours.types';
import { TripsTabProps } from './ToursHotelTab.types';
import { setPreSaveToursHotelInfo } from 'store/entities/tours/preSaveTourSlice';

import styles from './ToursHotelTab.module.scss';
import { HotelTrip } from './HotelTrip';
import { getHotelRoomTypes } from 'store/action-creators/tours';

export const ToursHotelTab: FC<TripsTabProps> = ({ trip }) => {
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const dispatch = useAppDispatch();
  const [tripData, setTripData] = useState<TourHotelInfo | null | undefined>(trip);
  useEffect(() => {
    dispatch(getHotelRoomTypes());
  }, []);
  useEffect(() => {
    setTripData(trip);
  }, [trip]);

  //при клике Сохранить в дочернем компоненте данные из формы сохраняются в предустановки
  const handleTripData = (data: TourHotelInfo) => {
    setTripData({
      ...data,
      sale_percent: data.sale_percent === null ? '0' : data.sale_percent,
      sale: data.sale === null ? '0' : data.sale,
    });
    // создаем новый массив, в данных которого меняем элемент на новый, а также следим за датой окончания поездки
    const updatePreSaveTrips = preSaveTour2?.tourHotels?.map((trip) => {
      if (trip.id === data.id) {
        return {
          ...data,
        };
      } else {
        return { ...trip };
      }
    });
    const dataInTrips = preSaveTour2.tourHotels?.find((item) => item.id === data.id);

    // если элемента в массиве из presave нет, то добавляем. если есть, то меняем весь массив на новый (с обновл.инфой)
    if (!dataInTrips) {
      dispatch(setPreSaveToursHotelInfo([...(updatePreSaveTrips || []), data]));
    } else {
      if (updatePreSaveTrips) {
        dispatch(setPreSaveToursHotelInfo(updatePreSaveTrips));
      }
    }
  };
  return (
    <section className={styles.trips}>
      <HotelTrip trip={tripData} setTrip={handleTripData} />
    </section>
  );
};
