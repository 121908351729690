import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';

import { SettingsProps } from './Settings.type';
import { SettingsIcon } from 'components/icons';
import { useRefCloseOut } from 'hooks/useRefCloseOut';

import styles from './Settings.module.scss';

const Settings: FC<SettingsProps> = ({ btnDeleteText, btnCopyText, bntDelete, bntCopy, className }) => {
  const [open, setOpen] = useState(false);
  const settingsRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const btnOpenSettingsRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  useRefCloseOut(settingsRef, setOpen, btnOpenSettingsRef);

  const handleBtnDelete = () => {
    bntDelete();
    setOpen(false);
  };

  const handleBtnCopy = () => {
    bntCopy();
    setOpen(false);
  };

  return (
    <article className={clsx(styles.settings, className)}>
      <button onClick={() => setOpen((prev) => !prev)} className={styles.button} ref={btnOpenSettingsRef}>
        <SettingsIcon width={4} height={14} fill="#C8CDD8" />
      </button>
      {open && (
        <div className={styles.dropdown} ref={settingsRef}>
          <button className={styles.action} onClick={handleBtnDelete}>
            {btnDeleteText}
          </button>
          <button className={styles.action} onClick={handleBtnCopy}>
            {btnCopyText}
          </button>
        </div>
      )}
    </article>
  );
};

export default Settings;
