import React, { FC, useEffect, useRef, useState } from 'react';
import 'dayjs/locale/ru';
import { DateInput, DatesProvider } from '@mantine/dates';
import moment from 'moment';
import { InputDateProps } from './InputDate.types';

export const InputDate: FC<InputDateProps> = ({
  label,
  className,
  date,
  setDate,
  placeholder,
  icon,
  error = false,
  onChange = (a) => void 1,
  clearButtonProps,
  clearable,
  ...props
}) => {
  const [value, setValue] = useState<Date | null>(date as Date);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (setDate) {
      setDate(value);
    }
    onChange(value === null ? '' : value);
  }, [value]);

  return (
    <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
      <DateInput
        allowDeselect
        clearButtonProps={clearButtonProps && clearButtonProps}
        clearable={clearable}
        ref={ref}
        value={value}
        dateParser={(input) => {
          if (input.length >= 8) {
            return moment(input, 'DD.MM.YYYY').toDate();
          }
          return new Date(input);
        }}
        onChange={(value) => {
          setValue(value);
        }}
        label={label}
        valueFormat="DD.MM.YYYY"
        placeholder={placeholder}
        maw={400}
        pt={11}
        icon={icon ? icon : null}
        className={className}
        {...props}
        styles={{
          label: {
            color: '#5B6572',
            marginBottom: '10px',
            fontSize: '15px',
            fontFamily: 'Museo Sans Cyrl',
            fontWeight: 400,
            lineHeight: '19px',
          },
          input: {
            paddingLeft: '46px !important',
            fontFamily: 'Museo Sans Cyrl',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#263B53',
            border: error ? '1px solid red !important' : '1px solid #C8CDD8',

            '&:focus': {
              border: error ? '1px solid red !important' : '1px solid #C8CDD8',
            },

            '&::placeholder': {
              fontSize: '16px',
            },
          },
          icon: {
            marginLeft: '10px',
            zIndex: 1,
          },
        }}
      />
    </DatesProvider>
  );
};
