import React from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { ToursIndex } from './ToursIndex';
import { TourCreate } from './TourCreate';
import { TourEdit } from './TourEdit';
import { RouteNames } from 'core/constants/routeNames';

export const ToursPage = () => {
  return (
    <Routes>
      <Route index element={<ToursIndex />} />
      <Route path="new" element={<TourCreate />} />
      <Route path=":id" element={<TourEdit />} />
      <Route path="*" element={<Navigate to={RouteNames.TOURS} replace />} />
    </Routes>
  );
};
