import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const GalleryIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4692 3.76796C2.55832 3.76796 1.81991 4.48529 1.81991 5.37017V18.6298C1.81991 19.5147 2.55832 20.232 3.4692 20.232H20.5308C21.4417 20.232 22.1801 19.5147 22.1801 18.6298V5.37017C22.1801 4.48529 21.4417 3.76796 20.5308 3.76796H3.4692ZM9.75733e-06 5.37017C9.75733e-06 3.50887 1.55322 2 3.4692 2H20.5308C22.4468 2 24 3.50887 24 5.37017V18.6298C24 20.4911 22.4468 22 20.5308 22H3.4692C1.55322 22 9.75733e-06 20.4911 9.75733e-06 18.6298V5.37017Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2654 7.08287C15.8257 7.08287 15.4692 7.42917 15.4692 7.85635C15.4692 8.28353 15.8257 8.62983 16.2654 8.62983C16.7051 8.62983 17.0616 8.28353 17.0616 7.85635C17.0616 7.42917 16.7051 7.08287 16.2654 7.08287ZM13.6493 7.85635C13.6493 6.45276 14.8206 5.31492 16.2654 5.31492C17.7102 5.31492 18.8815 6.45276 18.8815 7.85635C18.8815 9.25995 17.7102 10.3978 16.2654 10.3978C14.8206 10.3978 13.6493 9.25995 13.6493 7.85635Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78231 10.7174C7.28104 10.2873 7.93004 10.0584 8.59666 10.0776C9.26328 10.0968 9.8972 10.3626 10.3688 10.8207L14.9819 15.3117L16.2259 14.1031C16.6871 13.6542 17.3043 13.3891 17.9564 13.3597C18.6085 13.3303 19.2484 13.5388 19.7503 13.9443L19.7513 13.9451L23.6728 17.1222C24.0588 17.4349 24.1107 17.9923 23.7888 18.3673C23.467 18.7422 22.8932 18.7927 22.5072 18.48L18.5867 15.3037C18.4341 15.1806 18.239 15.1168 18.0408 15.1257C17.8423 15.1347 17.6544 15.2154 17.5141 15.352L15.6246 17.1875L10.9373 21.7411C10.5819 22.0863 10.0058 22.0863 9.6504 21.7411C9.29504 21.3959 9.29504 20.8362 9.6504 20.491L13.695 16.5618L9.08211 12.0709C8.9386 11.9317 8.7455 11.8506 8.54276 11.8448C8.33998 11.8389 8.14256 11.9085 7.99081 12.0393L1.51458 17.633C1.13901 17.9574 0.563853 17.9246 0.229929 17.5597C-0.103995 17.1949 -0.0702324 16.6361 0.305339 16.3118L6.78231 10.7174Z"
        fill={fill}
      />
    </svg>
  );
};
