import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createReport, getReports } from 'store/action-creators/reports';
import { ICreateReportProps, IReportsByIdProps, IReportsProps } from 'services/reports/reports.types';
import { reportsInitialState } from './reportsData';
import { reportsFactory } from 'services/reports/reports.factory';

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: reportsInitialState,
  reducers: {
    setReportsPage: (state, action: PayloadAction<IReportsProps>) => {
      state.reportsPage = { ...state.reportsPage, ...action.payload };
    },
    setReportId: (state, action: PayloadAction<IReportsByIdProps>) => {
      state.reportId = action.payload;
    },
    setCreateReportForm: (state, action: PayloadAction<ICreateReportProps>) => {
      state.createReportForm = action.payload;
    },
  },

  extraReducers: (builder) => {
    //создание отчета
    builder.addCase(createReport.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'CREATE_REPORT';
    });
    builder.addCase(createReport.pending, (state) => {
      state.isLoading = 'CREATE_REPORT';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(createReport.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'CREATE_REPORT';
    });
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'GET_REPORTS';
      state.reports = reportsFactory(action.payload['hydra:member']);
    });
    builder.addCase(getReports.pending, (state) => {
      state.isLoading = 'GET_REPORTS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getReports.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_REPORTS';
    });
  },
});

// Action creators are generated for each case reducer function
export const { setReportsPage, setReportId, setCreateReportForm } = reportsSlice.actions;

export default reportsSlice.reducer;
