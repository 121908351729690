import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TripInfo } from 'pages/Tours/Tours.types';
import { TripsTabProps } from './TripsTab.types';
import { Trip } from './Trip';
import { setPreSaveTripsInfo } from 'store/entities/tours/preSaveTourSlice';
import { addDaysToDate } from 'core/utils/getDate';

import styles from './TripsTab.module.scss';
import { getHotelRoomTypes } from 'store/action-creators/tours';

export const TripsTab: FC<TripsTabProps> = ({ trip, setCopy }) => {
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const { roomTypes } = useAppSelector((state) => state.tours);
  const dispatch = useAppDispatch();
  const [tripData, setTripData] = useState<TripInfo | null>(null);
  useEffect(() => {
    if (roomTypes.length > 0) return;
    dispatch(getHotelRoomTypes());
  }, []);

  useEffect(() => {
    if (trip) {
      setTripData(trip);
    } else {
      setTripData(null);
    }
  }, [trip]);

  //при клике Сохранить в дочернем компоненте данные из формы сохраняются в предустановки
  const handleTripData = (data: TripInfo) => {
    setTripData({
      ...data,
      priceOneDay: data.priceOneDay === null ? 0 : data.priceOneDay,
      freePlaces: data.freePlaces === null ? 0 : data.freePlaces,
      discount_percent: data.discount_percent === null ? 0 : data.discount_percent,
      discount: data.discount === null ? 0 : data.discount,
    });

    const dataInTrips = preSaveTour2.tripsInfo?.find((item) => item.id === data.id);

    // создаем новый массив, в данных которого меняем элемент на новый, а также следим за датой окончания поездки
    const updatePreSaveTrips = preSaveTour2?.tripsInfo?.map((trip) => {
      if (trip.dateStart && preSaveTour2.descriptionInfo.duration) {
        trip = { ...trip, dateEnd: addDaysToDate(trip.dateStart, preSaveTour2.descriptionInfo.duration) };
      }

      if (trip.id === data.id) {
        return {
          ...data,
        };
      } else {
        return { ...trip, dateEnd: trip.dateEnd };
      }
    });

    // если элемента в массиве из presave нет, то добавляем. если есть, то меняем весь массив на новый (с обновл.инфой)
    if (!dataInTrips) {
      dispatch(setPreSaveTripsInfo([...(updatePreSaveTrips || []), data]));
    } else {
      if (updatePreSaveTrips) {
        dispatch(setPreSaveTripsInfo(updatePreSaveTrips));
      }
    }
  };

  return (
    <section className={styles.trips}>
      <Trip trip={tripData} setTrip={handleTripData} setCopy={setCopy} />
    </section>
  );
};
