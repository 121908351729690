import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useForm } from '@mantine/form';
import { Loader } from '@mantine/core';

import { SignInType } from './SignIn.types';
import { Input } from 'ui/Input';
import { Button } from 'ui/Button';
import { Logo } from 'ui/Logo';
import { LoginData } from 'services/auth/authApi.service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLogout } from 'store/entities/auth/authSlice';
import { login } from 'store/action-creators/auth';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'core/constants/routeNames';
import { notifications } from '@mantine/notifications';

import styles from './SignIn.module.scss';

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuth, isLoading, isError } = useAppSelector((state) => state.auth);
  const [loginData, setLoginData] = useState<LoginData>({
    email: '',
    password: '',
  });

  const initialValues: SignInType = {
    email: '',
    password: '',
    remember: true,
  };

  const formSignIn = useForm({
    initialValues,
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.length < 1 ? 'Password is required' : null),
    },
  });

  const handleBtnSubmit = () => {
    formSignIn.validate();
    dispatch(setLogout());
    dispatch(login(loginData));
  };

  useEffect(() => {
    if (isAuth) {
      navigate(RouteNames.ORDERS);
    }
  }, [isAuth]);

  useEffect(() => {
    if (isError) {
      // console.log('Неверно указан пользователь или пароль.');
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Неверно указан пользователь или пароль' });
    }
  }, [isError]);

  useEffect(() => {
    setLoginData({ ...loginData, email: formSignIn.values.email, password: formSignIn.values.password });
  }, [formSignIn.values]);

  const handleClickInput = (e: React.KeyboardEvent) => {
    if (e.key == 'Enter') {
      e.preventDefault();
      handleBtnSubmit();
    }
  };

  return (
    <div className={clsx(styles.signin, 'container')}>
      <div className={styles.bg}>
        <Logo color="white" />
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
        ) : (
          <form className={styles.form}>
            <h2 className={styles.title}>Войти в систему</h2>
            <Input
              placeholder="Email"
              {...formSignIn.getInputProps('email')}
              className={clsx(styles.input, formSignIn.errors.email === 'Invalid email' && styles.inputError)}
              onKeyDown={handleClickInput}
              required
            />
            <Input
              placeholder="Пароль"
              {...formSignIn.getInputProps('password')}
              className={clsx(styles.input, formSignIn.errors.password === 'Password is required' && styles.inputError)}
              type="password"
              onKeyDown={handleClickInput}
              required
            />
            <div className={styles.buttons}>
              <Button onClick={handleBtnSubmit} label="Войти" className={styles.button} />
              {/* <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                {...formSignIn.getInputProps('remember', { type: 'checkbox' })}
                className={styles.checkbox}
              />
              Запомнить меня
            </label> */}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
