import axiosInstance from '../axiosInstance';

import { PartnerResponse, PartnersResponse } from './PartnerResponse.model';

export interface AddPartnerData {
  firstName?: string;
  middleName?: string;
  secondName?: string;
  fullName?: string;
  subject?: string;
  gender?: string;
  birthday?: Date | null;
  phone?: string;
  company?: string;
  contactFullName?: string;
  email?: string;
  note?: string;
  register_number?: string;
  register_agent_number?: string;
  document_template_path?: any;
  agreement_path?: any;
  company_email?: string;
  cooperation_format?: string;
  contacts_array?: {
    name: string;
    phone: string;
    email: string;
  }[];
  commission?: string | null;
  registration_status?: string;
  contactRequisite?: {
    id?: string;
    juriName?: string;
    inn?: string;
    kpp?: string;
    ogrn?: string;
    juriAddress?: string;
    address?: string;
    managerFullName?: string;
    registrationDocument?: string;
    bik?: string;
    corrAccount?: string;
    payAccount?: string;
  };
  requisites?: {
    id?: string;
    juriName?: string;
    inn?: string;
    kpp?: string;
    ogrn?: string;
    juriAddress?: string;
    contract?: string;
    address?: string;
    managerFullName?: string;
    registrationDocument?: string;
    bik?: string;
    corrAccount?: string;
    payAccount?: string;
  }[];
  main_document?: string | null;
  contactPassport?: {
    id?: string;
    code?: string;
    registeredDate?: Date | string | null;
    actualBefore?: Date | string | null;
    unitCode?: string;
    unitName?: string;
    birthPlace?: string;
    registrationPlace?: string;
    registration_country?: string;
  } | null;
  contactForeignPassport?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    code?: string;
    registeredDate?: Date | string | null;
    actualBefore?: Date | string | null;
    unitCode?: string;
    unitName?: string;
    birthPlace?: string;
    registrationPlace?: string;
    registration_country?: string;
  } | null;
  contactBirthCertificate?: {
    id?: string;
    code?: string;
    registeredDate?: Date | string | null;
    fatherFullName?: string;
    motherFullName?: string;
    registrationPlace?: string;
  } | null;
  contactForeignDocument?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    code?: string;
    nationality?: string;
    registeredDate?: Date | string | null;
    registrationPlace?: string;
    registration_country?: string;
  } | null;
  type?: (string | undefined)[];
}

export interface ContactTypesData {
  page?: number;
}

export interface PartnersDataSorting {
  id: boolean | null;
  full_name: boolean | null;
}

export interface PartnersData {
  page?: number;
  sort?: Partial<PartnersDataSorting>;
  subject?: string | null;
  search?: string | null;
  type?: string | null;
  itemsPerPage?: number;
  properties?: any[];
}

export interface GetPartnerData {
  id: string;
}

export interface EditPartnerData {
  id: string;
  data: AddPartnerData;
}

export interface RemoveDocumentData {
  id: string;
}

// добавление контрагента
export const addPartnerAPI = async (data?: AddPartnerData) => {
  const response = await axiosInstance.post<PartnerResponse[]>('/api/v1/contacts', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка типов контрагентов (формат hydra)
export const contactTypesAPI = async ({ page }: ContactTypesData) => {
  const response = await axiosInstance.get('/api/v1/contact-types', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
    },
  });
  return response;
};

//получение списка контрагентов
export const partnersAPI = async ({
  page,
  subject,
  search,
  type,
  sort = {},
  itemsPerPage,
  properties,
}: PartnersData) => {
  const params = {
    page: page,
    itemsPerPage: itemsPerPage,
    subject: subject,
    search: search,
    type: type,
    properties: properties,
    nocache: true,
  };
  Object.keys(sort).map((i) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    params[`sort[${i}]`] = sort[i] ? 'asc' : 'desc';
  });
  const response = await axiosInstance.get<PartnersResponse>('/api/v1/contacts', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params,
  });
  return response;
};

//получение списка контрагентов
export const partnersAPIByQuery = async (query: string) => {
  const response = await axiosInstance.get<PartnersResponse>(query, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

//получение контрагента по ID
export const getPartnerAPI = async ({ id }: GetPartnerData) => {
  const response = await axiosInstance.get<PartnerResponse>(`/api/v1/contacts/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// редактирование контрагента по ID
export const editPartnerAPI = async ({ id, data }: EditPartnerData) => {
  const response = await axiosInstance.put<PartnerResponse[]>(`/api/v1/contacts/${id}`, data);
  return response;
};

// удаление документа контрагента по ID
export const removePartnerDocumentAPI = async ({ id }: RemoveDocumentData) => {
  const response = await axiosInstance.delete<PartnerResponse[]>(`${id}`);
  console.log(response);
  return response;
};
// загрузка файла приложения
export const addDocumentAPI = async ({ file }: { file: File }) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return await axiosInstance.post<{ path: string }>('/api/v1/upload-document', bodyFormData);
};
