import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderNotificationData } from '../../services/orderNotification/OrderNotification.types';
import {
  addOrderNotificationAPI,
  deleteOrderNotificationAPI,
  getOrderNotificationAPI,
  updateOrderNotificationAPI,
} from '../../services/orderNotification/OrderNotificationApi.service';

export const addOrderNotification = createAsyncThunk('orderNotification/addOrderNotification', async (arg: OrderNotificationData, thunkAPI) => {
  try {
    const response = await addOrderNotificationAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const getOrderNotifications = createAsyncThunk('orderNotification/getOrderNotifications', async ({ id }: { id: string }, thunkAPI) => {
  try {
    const response = await getOrderNotificationAPI({ id });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const updateOrderNotification = createAsyncThunk('orderNotification/updateOrderNotification', async (arg: OrderNotificationData, thunkAPI) => {
  try {
    const response = await updateOrderNotificationAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const removeOrderNotification = createAsyncThunk('orderNotification/removeOrderNotification', async ({ id }: { id: string }, thunkAPI) => {
  try {
    const response = await deleteOrderNotificationAPI({ id });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

