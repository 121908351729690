import React, { FC } from 'react';
import { ButtonType } from './Button.type';
import clsx from 'clsx';
import styles from './Button.module.scss';

export const Button: FC<ButtonType> = ({
  label,
  onClick,
  className,
  bgColor,
  textColor,
  width,
  type = 'button',
  children,
  transparent,
  disabled,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        width: width,
      }}
      className={clsx(styles.button, className, transparent && styles.buttonTransparent)}
      disabled={disabled}
    >
      {label}
      {children}
    </button>
  );
};
