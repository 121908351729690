import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';

import { InterPassportType } from 'components/Document/Document.types';
import { Input } from 'ui/Input';
import { InputDate } from 'ui/InputDate';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPreSavePrivatePerson } from 'store/entities/partners/partnersSlice';
import { DateIcon } from 'components/icons';

import styles from './InterPassport.module.scss';

interface InterPassportProps {
  passport?: InterPassportType;
  setData: (a: InterPassportType) => void;
}
export const InterPassport: FC<InterPassportProps> = ({ passport, setData }) => {
  const { preSavePrivatePerson, isResetPreSavePrivatePerson } = useAppSelector((state) => state.partners);
  const dispatch = useAppDispatch();
  const [blur, setBlur] = useState(false);

  const initialValues: InterPassportType = {
    lastName: preSavePrivatePerson?.documents?.interPassport?.lastName || passport?.lastName || '',
    firstName: preSavePrivatePerson?.documents?.interPassport?.firstName || passport?.firstName || '',
    number: preSavePrivatePerson?.documents?.interPassport?.number || passport?.number || '',
    dateOfIssue: preSavePrivatePerson?.documents?.interPassport?.dateOfIssue || passport?.dateOfIssue || null,
    validUntil: preSavePrivatePerson?.documents?.interPassport?.validUntil || passport?.validUntil || null,
    divisionCode: preSavePrivatePerson?.documents?.interPassport?.divisionCode || passport?.divisionCode || '',
    issuedByWhom: preSavePrivatePerson?.documents?.interPassport?.issuedByWhom || passport?.issuedByWhom || '',
    placeOfBirth: preSavePrivatePerson?.documents?.interPassport?.placeOfBirth || passport?.placeOfBirth || '',
    countryRegistration:
      preSavePrivatePerson?.documents?.interPassport?.countryRegistration || passport?.countryRegistration || '',
    placeOfStateRegistration:
      preSavePrivatePerson?.documents?.interPassport?.placeOfStateRegistration ||
      passport?.placeOfStateRegistration ||
      '',
  };

  const validate = {
    lastName: (value: string) => (value.length == 0 ? 'the field is required' : null),
    firstName: (value: string) => (value.length == 0 ? 'the field is required' : null),
    number: (value: string) => (value.length == 0 ? 'the field is required' : null),
    dateOfIssue: (value: any) => (value == '' ? 'the field is required' : null),
    validUntil: (value: any) => (value == '' ? 'the field is required' : null),
  };

  const formInterPassport = useForm({
    initialValues,
    validate,
    validateInputOnChange: true,
  });

  useEffect(() => {
    setData(formInterPassport.values);
  }, [formInterPassport.values]);

  //если форму начали заполнять и в ней нет ошибок, данные уходят в предсохранении и оттуда на сервер
  //если первое поле заполнено, то данные уже готовы к отправке на сервер. если нет - нет.
  useEffect(() => {
    if (formInterPassport.values.lastName !== '' && JSON.stringify(formInterPassport.errors) === '{}') {
      if (passport?.id) {
        dispatch(
          setPreSavePrivatePerson({
            ...preSavePrivatePerson,
            documents: {
              ...preSavePrivatePerson.documents,
              interPassport: { ...formInterPassport.values, id: passport.id },
            },
          })
        );
      } else {
        dispatch(
          setPreSavePrivatePerson({
            ...preSavePrivatePerson,
            documents: { ...preSavePrivatePerson.documents, interPassport: formInterPassport.values },
          })
        );
      }
    } else {
      console.log('Не заполнены обязательные поля формы');
    }
  }, [formInterPassport.values, formInterPassport.errors]);

  //если первое поле начали заполнять - то все остальные уже обязательны
  useEffect(() => {
    if (blur) {
      formInterPassport.validate();
    }
  }, [blur]);

  // useEffect(() => {
  //   if (focus && Object.values(formInterPassport.errors).length === 0) {
  //     setFocus(false);
  //     markDirty();
  //   }
  // }, [formInterPassport.errors, focus]);

  useEffect(() => {
    if (isResetPreSavePrivatePerson) {
      formInterPassport.reset();
      formInterPassport.setFieldValue('lastName', initialValues.lastName);
      formInterPassport.setFieldValue('firstName', initialValues.firstName);
      formInterPassport.setFieldValue('number', initialValues.number);
      formInterPassport.setFieldValue('dateOfIssue', initialValues.dateOfIssue);
      formInterPassport.setFieldValue('validUntil', initialValues.validUntil);
      formInterPassport.setFieldValue('divisionCode', initialValues.divisionCode);
      formInterPassport.setFieldValue('issuedByWhom', initialValues.issuedByWhom);
      formInterPassport.setFieldValue('placeOfBirth', initialValues.placeOfBirth);
      formInterPassport.setFieldValue('placeOfStateRegistration', initialValues.placeOfStateRegistration);
    }
  }, [isResetPreSavePrivatePerson]);

  return (
    <form>
      <div className={styles.group}>
        <Input
          label="Фамилия (латиница)*"
          {...formInterPassport.getInputProps('lastName')}
          className={styles.input}
          onBlur={() => {
            setBlur(true);
          }}
          error={!!formInterPassport.errors.lastName}
        />
        <Input
          label="Имя (латиница)*"
          {...formInterPassport.getInputProps('firstName')}
          className={styles.input}
          error={!!formInterPassport.errors.firstName}
        />
      </div>
      <div className={styles.group}>
        <Input
          type="text"
          label="Номер документа*"
          {...formInterPassport.getInputProps('number')}
          className={clsx(styles.input, styles.number)}
          error={!!formInterPassport.errors.number}
        />
        <InputDate
          label="Дата выдачи*"
          className={styles.input}
          placeholder="_ _._ _._ _ _ _"
          icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formInterPassport.errors.dateOfIssue}
          {...formInterPassport.getInputProps('dateOfIssue')}
        />
        <InputDate
          label="Действует до*"
          className={styles.input}
          placeholder="_ _._ _._ _ _ _"
          icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formInterPassport.errors.validUntil}
          {...formInterPassport.getInputProps('validUntil')}
        />
        <Input
          type="text"
          label="Код подразделения"
          error={!!formInterPassport.errors.divisionCode}
          {...formInterPassport.getInputProps('divisionCode')}
          className={clsx(styles.input, styles.number)}
          onWheel={(e: any) => e.target.blur()}
        />
      </div>
      <Input
        label="Кем выдан"
        {...formInterPassport.getInputProps('issuedByWhom')}
        className={styles.input}
        error={!!formInterPassport.errors.issuedByWhom}
      />
      <Input
        label="Место рождения"
        {...formInterPassport.getInputProps('placeOfBirth')}
        className={styles.input}
        error={!!formInterPassport.errors.placeOfBirth}
      />
      <Input
        label="Страна регистрации"
        {...formInterPassport.getInputProps('countryRegistration')}
        className={styles.input}
        error={!!formInterPassport.errors.countryRegistration}
      />
      <Input
        label="Место гос.регистрации"
        error={!!formInterPassport.errors.placeOfStateRegistration}
        {...formInterPassport.getInputProps('placeOfStateRegistration')}
        className={styles.input}
      />
    </form>
  );
};
