import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';

import { TourForm } from '../components/TourForm';
import { TourProps, TripInfo } from '../Tours.types';
import { PrevPageIcon } from 'components/icons';
import { RouteNames } from 'core/constants/routeNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDate } from 'core/utils/getDate';
import { editTour, getTourDates, getTourFactory, getTourHotelsMin } from 'store/action-creators/tours';
import { resetCurrentTour, resetPreSaveTour, setIsClonedTrip, setIsEditedTour } from 'store/entities/tours/toursSlice';
import { EditTourData } from 'services/tours/toursApi.service';
import { Switch } from '@mantine/core';
import {
  tourDatesFactoryOut,
  tourDiscountsFactoryOut,
  tourProgramFactoryOut,
  tourServicesFactoryOut,
  tourHotelFactoryOut,
} from 'services/tours/tours.factory';
import {
  resetPreSaveTour2,
  setMainTag,
  setPreSaveIsVisible,
  setPreSaveTourAddInfo,
  setPreSaveTourAdditionalDiscounts,
  setPreSaveTourAdditionalServices,
  setPreSaveTourDiscount,
  setPreSaveTourDocument,
  setPreSaveTourDuration,
  setPreSaveTourFullDescription,
  setPreSaveTourId,
  setPreSaveTourName,
  setPreSaveTourOperator,
  setPreSaveTourPrice,
  setPreSaveTourPriceIncludes,
  setPreSaveTourPriceNoIncludes,
  setPreSaveTourShortDescription,
  setPreSaveTourShortSystemName,
  setPreSaveTourTemplate,
  setPreSaveToursHotelInfo,
  setPreSaveTripsInfo,
  setTourAlbum,
  setTourPhotos,
  setTourPhotosOrder,
} from 'store/entities/tours/preSaveTourSlice';
import { ModalWarning } from '../components/TourForm/ModalWarning';

import styles from './TourEdit.module.scss';
import { Select } from 'ui/Select';
import { listOfTourTemplates } from 'core/constants/lists';
import { ToursTemplates } from 'core/constants/enums';
import { getTemplate, getTemplateToUI } from 'core/utils/getTemplate';
import { getCurrencyToServer } from 'core/utils/getCurrency';

export const TourEdit: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { currentTour, isLoading, isSuccess, isError, isEditedTour, isClonedTrip, tags } = useAppSelector(
    (state) => state.tours
  );
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const [tour, setTour] = useState<TourProps | null>(null);
  const [title, setTitle] = useState('');
  const [tourData, setTourData] = useState<EditTourData | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [lastTrip, setLastTrip] = useState<TripInfo | null>(null);
  const [isSavePending, setSavePending] = useState(false);
  const [isCanDelete, setIsCanDelete] = useState(true);
  const templates: string[] = listOfTourTemplates;
  const [template, setTemplate] = useState<string>(ToursTemplates.MULTIDAY);
  const [isVisible, setisVisible] = useState(preSaveTour2.is_visible);

  const idTour = id?.split('-')[0];

  // настраиваем поля для presave (при первой загрузке берем данные из входящего тура).
  useEffect(() => {
    if (tour && id !== 'new') {
      if (tour.id) {
        dispatch(setPreSaveTourId(tour.id));
      }

      dispatch(setPreSaveTourName(tour.name || ''));

      if (tour.template) {
        dispatch(setPreSaveTourTemplate(getTemplate(tour.template)));
      }
      if (tour.is_visible) {
        dispatch(setPreSaveIsVisible(tour.is_visible));
      }
      if (tour.descriptionInfo?.duration) {
        dispatch(setPreSaveTourDuration(tour.descriptionInfo?.duration));
      }

      if (tour.descriptionInfo?.tourOperator) {
        dispatch(setPreSaveTourOperator(tour.descriptionInfo?.tourOperator));
      }

      if (tour.descriptionInfo?.discount) {
        dispatch(setPreSaveTourDiscount(tour.descriptionInfo?.discount));
      }
      if (tour.descriptionInfo?.system_name) {
        dispatch(setPreSaveTourShortSystemName(tour.descriptionInfo?.system_name));
      }
      if (tour.descriptionInfo?.shortDescription) {
        dispatch(setPreSaveTourShortDescription(tour.descriptionInfo?.shortDescription));
      }

      if (tour.descriptionInfo?.fullDescription) {
        dispatch(setPreSaveTourFullDescription(tour.descriptionInfo?.fullDescription));
      }

      if (tour.descriptionInfo?.priceIncludes) {
        dispatch(setPreSaveTourPriceIncludes(tour.descriptionInfo?.priceIncludes));
      }
      if (tour.descriptionInfo?.priceNoIncludes) {
        dispatch(setPreSaveTourPriceNoIncludes(tour.descriptionInfo?.priceNoIncludes));
      }

      if (tour.tripsInfo) {
        dispatch(setPreSaveTripsInfo(tour?.tripsInfo));
      }

      if (tour.tourHotels) {
        dispatch(setPreSaveToursHotelInfo(tour.tourHotels));
      }

      if (tour.descriptionInfo?.price) {
        dispatch(setPreSaveTourPrice(tour?.descriptionInfo.price));
      }

      if (tour.descriptionInfo?.additionalServices) {
        dispatch(setPreSaveTourAdditionalServices(tour.descriptionInfo?.additionalServices));
      }

      if (tour.descriptionInfo?.additionalDiscounts) {
        dispatch(setPreSaveTourAdditionalDiscounts(tour.descriptionInfo?.additionalDiscounts));
      }
      if (tour.descriptionInfo?.addInfo) {
        dispatch(setPreSaveTourAddInfo(tour.descriptionInfo?.addInfo));
      }
      if (tour.descriptionInfo?.document_template_path) {
        dispatch(setPreSaveTourDocument(tour.descriptionInfo?.document_template_path));
      }
      if (tour.photos) {
        dispatch(setTourPhotos(tour.photos));
      }
      if (tour.photo_album) {
        dispatch(setTourAlbum(tour.photo_album));
      }
      if (tour.descriptionInfo?.settings?.photoOrders) {
        dispatch(setTourPhotosOrder(tour.descriptionInfo?.settings?.photoOrders));
      }
      if (tour.tourParameters?.main_tag) {
        dispatch(setMainTag(tour.tourParameters?.main_tag));
      }
    }
  }, [tour, idTour]);

  useEffect(() => {
    if (isClonedTrip) {
      setSavePending(true);
    }
  }, [isClonedTrip]);

  // собираем данные для отправки на сервер
  useEffect(() => {
    if (isEditedTour && !isSavePending) {
      setSavePending(true);
    }
    console.log(preSaveTour2.descriptionInfo.additionalDiscounts);
    setTourData({
      ...tourData,

      //сбор данных для запроса из вкладки "описание тура"
      id: Number(preSaveTour2.id),
      data: {
        name: preSaveTour2.name,
        template: preSaveTour2.template,
        currency: getCurrencyToServer(preSaveTour2.descriptionInfo.currency!),
        commission: preSaveTour2.descriptionInfo.commission,
        duration: template === ToursTemplates.ONEDAY ? 0 : Number(preSaveTour2.descriptionInfo?.duration),
        tour_operator_contact:
          preSaveTour2.descriptionInfo?.tourOperator?.name != 'Акварель'
            ? `/api/v1/contacts/${preSaveTour2.descriptionInfo?.tourOperator?.id}`
            : null,
        sale:
          preSaveTour2.descriptionInfo?.discount === 0 || preSaveTour2.descriptionInfo?.discount === ''
            ? '0'
            : preSaveTour2.descriptionInfo?.discount?.toString(),
        sale_percent:
          preSaveTour2.descriptionInfo?.discount_percent === 0 || preSaveTour2.descriptionInfo?.discount_percent === ''
            ? '0'
            : preSaveTour2.descriptionInfo?.discount_percent?.toString(),
        short_description: preSaveTour2.descriptionInfo?.shortDescription,
        description: preSaveTour2.descriptionInfo?.fullDescription,
        document_template_path: preSaveTour2.descriptionInfo.document_template_path,
        additional_info: preSaveTour2.descriptionInfo.addInfo,
        services: preSaveTour2.descriptionInfo?.priceIncludes,
        not_in_price: preSaveTour2.descriptionInfo.priceNoIncludes,
        system_name: preSaveTour2.descriptionInfo.system_name,
        partners_site: preSaveTour2.descriptionInfo.partners_site,
        tour_days: preSaveTour2.descriptionInfo?.tourProgram?.map((day) => tourProgramFactoryOut(day)),
        filter_price: preSaveTour2.descriptionInfo.filter_price,
        services_array: preSaveTour2.descriptionInfo?.additionalServices
          ?.filter((i) => i)
          .map((service) => tourServicesFactoryOut(service)),
        sales_array: preSaveTour2.descriptionInfo?.additionalDiscounts?.map((discount) =>
          tourDiscountsFactoryOut(discount)
        ),
        is_visible: preSaveTour2.is_visible,
        price:
          preSaveTour2.descriptionInfo?.price?.toString() !== ''
            ? preSaveTour2.descriptionInfo?.price?.toString()
            : '0',
        booking_without_prepay: preSaveTour2.descriptionInfo.booking_without_prepay,
        monday: preSaveTour2.descriptionInfo.monday,
        tuesday: preSaveTour2.descriptionInfo.tuesday,
        wednesday: preSaveTour2.descriptionInfo.wednesday,
        thursday: preSaveTour2.descriptionInfo.thursday,
        friday: preSaveTour2.descriptionInfo.friday,
        saturday: preSaveTour2.descriptionInfo.saturday,
        sunday: preSaveTour2.descriptionInfo.sunday,
        monday_title: preSaveTour2.descriptionInfo.monday_title,
        tuesday_title: preSaveTour2.descriptionInfo.tuesday_title,
        wednesday_title: preSaveTour2.descriptionInfo.wednesday_title,
        thursday_title: preSaveTour2.descriptionInfo.thursday_title,
        friday_title: preSaveTour2.descriptionInfo.friday_title,
        saturday_title: preSaveTour2.descriptionInfo.saturday_title,
        sunday_title: preSaveTour2.descriptionInfo.sunday_title,
        eightday: preSaveTour2.descriptionInfo.eightday,
        eightday_title: preSaveTour2.descriptionInfo.eightday_title,
        //сбор данных для запроса из вкладки "параметры"
        managers: preSaveTour2.tourParameters?.tourManager?.map((manager) => manager['@id']),
        guides: preSaveTour2.tourParameters?.guide?.map((direction) => {
          return direction['@id']!;
        }),
        countries: preSaveTour2.tourParameters?.country?.map((direction) => {
          return direction['@id']!;
        }),
        cities: preSaveTour2.tourParameters?.city?.map((direction) => {
          return direction['@id']!;
        }),
        transports: preSaveTour2.tourParameters?.transport?.map((direction) => {
          return direction['@id']!;
        }),
        labels: preSaveTour2.tourParameters?.tourType?.map((direction) => {
          return direction['@id']!;
        }),
        tour_periods: preSaveTour2.tourParameters?.travelTime?.map((direction) => {
          return direction['@id']!;
        }),
        tour_directions: preSaveTour2.tourParameters?.direction?.map((direction) => {
          return direction['@id']!;
        }),
        tour_tags: preSaveTour2.tourParameters?.tags?.map((direction) => {
          return direction['@id']!;
        }),
        main_tag:
          tags.length > 0
            ? preSaveTour2.tourParameters.main_tag
              ? preSaveTour2.tourParameters?.tags?.find((el) => el.name === preSaveTour2.tourParameters.main_tag)?.[
                  '@id'
                ]
              : null
            : undefined,
        //сбор данных для запроса из вкладки "даты тура"
        tour_dates: preSaveTour2.tripsInfo?.map((trip) =>
          tourDatesFactoryOut(trip, preSaveTour2.descriptionInfo.duration)
        ),
        tour_hotels: preSaveTour2.tourHotels?.map((el) => tourHotelFactoryOut(el)),
        photos: preSaveTour2.photos?.map((el) => `/api/v1/photos/${el.id}`),
        photo_album: preSaveTour2.photo_album ? `/api/v1/photo-albums/${preSaveTour2.photo_album.id}` : null,
        tour_group_title: preSaveTour2.descriptionInfo.tour_group_title,
        priority: preSaveTour2.descriptionInfo.priority ? preSaveTour2.descriptionInfo.priority : 1,
        tour_group_placements: preSaveTour2.descriptionInfo?.tour_group_placements?.map((el) => {
          if (el.id) {
            return {
              id: `/api/v1/tour-group-placements/${el.id}`,
              hotel_string: el.hotel_string,
              description: el.description,
              price1: el.price1 === '' ? null : el.price1,
              price2: el.price2 === '' ? null : el.price2,
              price3: el.price3 === '' ? null : el.price3,
              price4: el.price4 === '' ? null : el.price4,
              price5: el.price5 === '' ? null : el.price5,
            };
          } else {
            return {
              hotel_string: el.hotel_string,
              description: el.description,
              price1: el.price1 === '' ? null : el.price1,
              price2: el.price2 === '' ? null : el.price2,
              price3: el.price3 === '' ? null : el.price3,
              price4: el.price4 === '' ? null : el.price4,
              price5: el.price5 === '' ? null : el.price5,
            };
          }
        }),
        settings: {
          tourGroup: preSaveTour2.descriptionInfo.settings?.tourGroup,
          photoOrders: preSaveTour2.descriptionInfo.settings?.photoOrders,
        },
      },
    });
  }, [preSaveTour2]);
  // при изменении названия тура (когда поле потеряло фокус),
  // записываем его в предсохраненные данные. оттуда позже уйдет запрос на сервер.
  useEffect(() => {
    if (title) {
      dispatch(setPreSaveTourName(title));
    }
  }, [title]);
  useEffect(() => {
    if (!isSavePending) return;
    if (tour === undefined || tour === null) return;
    if (!isEditedTour && !isClonedTrip) return;

    if (tourData && tourData.id != 0) {
      dispatch(editTour(tourData));

      setSavePending(false);
      dispatch(setIsEditedTour(false));
      dispatch(setIsClonedTrip(false));
    }
  }, [tourData, isEditedTour, isClonedTrip, tour]);

  // как только успешно прошло редактирование тура, меняем статус редактирования, чтобы отслеживать дальнейшие изменения
  useEffect(() => {
    if (isSuccess === 'EDIT_TOUR') {
      dispatch(setIsEditedTour(false));
    }
  }, [isSuccess]);

  // скролл новой страницы в самый верх, подтягиваем данные по туру
  useEffect(() => {
    window.scrollTo(0, 0);
    const abortController = new AbortController();
    if (!id) {
      return;
    }
    if (currentTour !== null) {
      return;
    }

    const idTour = id?.split('-')[0];
    dispatch(getTourFactory({ id: idTour, signal: abortController.signal }));

    return () => {
      abortController.abort();
    };
  }, [id]);

  useEffect(() => {
    if (isSuccess !== 'EDIT_TOUR' || !id) return;
  }, [isSuccess]);

  // как только данные с сервера загрузились, отображаем в интерфейсе
  useEffect(() => {
    const abortController = new AbortController();
    if (currentTour) {
      setTour(currentTour);
      //загружаем название тура в интерфейс и в предсохранные данные
      if (currentTour?.name) {
        setTitle(currentTour.name);
      }
      if (currentTour.template) {
        setTemplate(currentTour.template);
      }
      if (currentTour.is_visible) {
        setisVisible(currentTour.is_visible);
      }
      if (currentTour.template !== ToursTemplates.EVERYDAY) {
        if (currentTour.tripsInfo?.length && currentTour.tripsInfo?.length > 0) {
          return;
        }
        dispatch(getTourDates({ id: `/api/v1/tours/${currentTour.id}`, signal: abortController.signal }));
      } else {
        if (currentTour.tourHotels?.length && currentTour.tourHotels?.length > 0) {
          return;
        }
        dispatch(getTourHotelsMin({ id: `/api/v1/tours/${currentTour.id}`, signal: abortController.signal }));
      }
    }
    return () => {
      abortController.abort();
    };
  }, [currentTour]);

  // при клике на кнопку "Назад" открываем модалку для подтверждения
  const handleBackButton = () => {
    open();
  };

  useEffect(() => {
    if ((isEditedTour || isClonedTrip) && !isSavePending) {
      setSavePending(true);
      setIsEditedTour(true);
    }
  }, [tourData]);

  useEffect(() => {
    switch (isLoading) {
      case 'EDIT_TOUR':
        notifications.show({
          title: 'Идет сохранение',
          color: 'orange',
          message: 'Сохранение данных на сервер...',
          id: 'TOUR_SAVING',
          loading: true,
        });

        break;
      case 'DELETE_PLACEMENT':
        {
          notifications.show({
            title: 'Удаление места размещения',
            color: 'orange',
            message: 'Удаляется размещение...',
            id: 'PLACEMENT_DELETE',
            loading: true,
          });
        }
        break;
      case 'DELETE_TRIP': {
        notifications.show({
          title: 'Удаление поездки',
          color: 'orange',
          message: 'Поездка удаляется...',
          id: 'TRIP_DELETE',
          loading: true,
        });
      }
    }
    if (isLoading === null) {
      notifications.hide('TOUR_SAVING');
      notifications.hide('PLACEMENT_DELETE');
      notifications.hide('TRIP_DELETE');
    }
  }, [isLoading]);
  // нотификация
  useEffect(() => {
    if (isSuccess === 'EDIT_TOUR') {
      notifications.hide('EDIT_TOUR_ERROR');
      notifications.show({ title: 'Успешно', color: 'green', message: 'Тур успешно отредактирован', id: 'TOUR_SAVED' });
    }
    if (isError === 'EDIT_TOUR') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'EDIT_TOUR_ERROR',
      });
    }
  }, [isSuccess, isError]);

  // модалка по подтверждению действия
  // сбрасываем текущий тур и предсохранненые данные в стейте
  const handleClose = () => {
    dispatch(resetCurrentTour());
    dispatch(resetPreSaveTour());
    dispatch(resetPreSaveTour2());
    navigate(RouteNames.TOURS);
  };

  const handleCancel = () => {
    close();
  };

  const getLastTrip = (tour: TourProps) => {
    return tour.tripsInfo?.reduce((acc, curr) => {
      if (Number(acc.id) > Number(curr.id)) {
        return acc;
      } else {
        return curr;
      }
    }, {});
  };
  // если после редактирования тура меняется длина массива с поездками (т.е. при добавлении и дублировании),
  // то вычисляем последний элемент массива по id
  useEffect(() => {
    if (currentTour?.tripsInfo && isEditedTour && currentTour?.tripsInfo?.length > 0) {
      const lastTrip = getLastTrip(currentTour) || null;
      setLastTrip(lastTrip);
    }
  }, [currentTour?.tripsInfo?.length]);

  // если последний элемент массива изменился, переводим пользователя на новую страницу
  // это будет или новая поездка, или продублированная
  const lastTripRef = useRef<TripInfo | null>(currentTour ? getLastTrip(currentTour) || null : null);
  useEffect(() => {
    if (lastTrip && lastTrip.id && JSON.stringify(lastTripRef.current) !== JSON.stringify(lastTrip)) {
      navigate(`${RouteNames.TOURS}/${idTour}-${lastTrip.id}`);
    }
    lastTripRef.current = lastTrip;
  }, [lastTrip]);
  useEffect(() => {
    if (!isCanDelete) {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Название тура нельзя полностью удалить',
        id: 'EDIT_TOUR_ERROR',
      });
      setIsCanDelete(true);
    }
  }, [isCanDelete]);
  return (
    <section className={styles.edit}>
      {isLoading === 'GET_TOUR_FACTORY' || isLoading === 'GET_TOUR_DATES' ? (
        <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
      ) : (
        <>
          {tour && (
            <>
              <div className={styles.top}>
                <button className={styles.buttonBack} onClick={handleBackButton}>
                  <PrevPageIcon width={33} height={33} fill="#5B6572" />
                </button>
                <div className={styles.title}>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setIsCanDelete(false);
                        return;
                      }
                      setTitle(e.target.value);
                    }}
                    className={styles.tourName}
                  />
                  <p className={styles.tourDate}>
                    Дата создания: {tour?.dateCreate && getDate(tour?.dateCreate as Date)}
                  </p>
                </div>
                <div className={styles.options}>
                  <div className={styles.templates}>
                    <span>Шаблон</span>
                    <Select
                      selected={template}
                      data={templates}
                      setSelect={(a) => {
                        setTemplate(a);
                        dispatch(setPreSaveTourTemplate(getTemplate(a)));
                      }}
                      className={styles.select}
                      onBlur={() => {
                        dispatch(setIsEditedTour(true));
                      }}
                    />
                  </div>
                  <Switch
                    styles={{
                      body: {
                        alignItems: 'center',
                      },
                      labelWrapper: {
                        paddingBottom: 2.5,
                      },
                      label: {
                        fontFamily: 'Museo Sans Cyrl',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '22px',
                        color: '#263B53',
                      },
                    }}
                    labelPosition="left"
                    checked={isVisible}
                    onChange={(event) => {
                      setisVisible(event.currentTarget.checked);
                      dispatch(setPreSaveIsVisible(event.currentTarget.checked));
                      dispatch(setIsEditedTour(true));
                    }}
                    color="green"
                    label="Отображать тур на сайте"
                  />
                </div>
              </div>
              <ModalWarning opened={opened} close={close} handleClose={handleClose} handleCancel={handleCancel} />
              <div className={styles.content}>
                <TourForm tour={tour} template={template} />
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
};
