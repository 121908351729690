import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const PartnersIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.22377 12.4097C11.3642 12.4097 13.0994 10.6745 13.0994 8.53402C13.0994 6.39356 11.3642 4.65838 9.22377 4.65838C7.08331 4.65838 5.34813 6.39356 5.34813 8.53402C5.34813 10.6745 7.08331 12.4097 9.22377 12.4097ZM14.7578 8.53402C14.7578 10.6006 13.625 12.4027 11.9465 13.353C13.3673 13.7702 14.6744 14.5115 15.7453 15.5289C16.6679 16.4053 17.3748 17.4483 17.8337 18.5835H22.1219C21.8673 17.5883 21.3276 16.6627 20.5411 15.9154C19.4107 14.8416 17.8637 14.2272 16.2373 14.2272C16.158 14.2272 16.0789 14.2286 16 14.2315V12.449C16.0784 12.455 16.1577 12.458 16.2377 12.458C17.95 12.458 19.3382 11.0699 19.3382 9.3575C19.3382 7.64514 17.95 6.25699 16.2377 6.25699C16.1577 6.25699 16.0784 6.26002 16 6.26597V4.99993H17.0241C19.094 5.37104 20.6649 7.18083 20.6649 9.3575C20.6649 11.0109 19.7585 12.4526 18.4156 13.2129C19.5523 13.5466 20.5981 14.1397 21.4548 14.9536C22.5048 15.951 23.2056 17.2181 23.4827 18.5835C23.5707 19.0176 23.616 19.4617 23.616 19.9102H22.2893H18.2469C18.2584 19.9621 18.2695 20.0141 18.2801 20.0663C18.3902 20.6089 18.4467 21.164 18.4467 21.7247H16.7884H9.22337H1.65838H0C0 21.164 0.0565555 20.6089 0.166665 20.0663C0.512975 18.3595 1.38903 16.7757 2.70146 15.5289C3.77247 14.5114 5.07975 13.77 6.50079 13.3529C4.82242 12.4025 3.68975 10.6005 3.68975 8.53402C3.68975 5.47767 6.16742 3 9.22377 3C12.2801 3 14.7578 5.47767 14.7578 8.53402ZM16.5791 20.0663C16.2608 18.8222 15.5863 17.6652 14.6031 16.7312C13.1901 15.3889 11.2564 14.6208 9.22337 14.6208C7.19034 14.6208 5.25662 15.3889 3.84367 16.7312C2.86046 17.6652 2.18591 18.8222 1.86761 20.0663L9.22337 20.0663H16.5791Z"
        fill={fill}
      />
    </svg>
  );
};
