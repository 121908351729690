import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';
import { PaperClipChatIcon } from 'components/icons/PaperClipChat';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import clsx from 'clsx';
import { getDate, getTimeTzNoLess } from 'core/utils/getDate';
import { User } from 'components/User';
import { sendChatMessages, getChatMessages, getReadChat } from 'store/action-creators/chats';
import { addDocumentAPI } from 'services/partners/partnerApi.service';
import ChatFile from './ChatFile';

export const Chat: FC = () => {
  const dispatch = useAppDispatch();
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  // The value of the textarea
  const [value, setValue] = useState<string>('');
  const [chatArr, setChatArr] = useState<string[]>([]);
  const [isRead, setIsRead] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [messageList, setMessageList] = useState<
    {
      author: string;
      created_at: string;
      is_read: boolean;
      text: string;
      files: string[];
    }[]
  >([]);
  const [photosPreview, setPhotosPreview] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    const arr =
      preSaveOrder?.chat_message
        ?.map((el) => getDate(new Date(el.created_at)))
        .filter((item, i, ar) => ar.indexOf(item) === i) || [];
    setChatArr(arr);
  }, []);

  useEffect(() => {
    if (!preSaveOrder.chat) return;
    if (!isRead) {
      const id = preSaveOrder.chat?.replace('/api/v1/chats/', '');
      dispatch(getReadChat(Number(id)));
      setIsRead(true);
    }
  }, []);

  useEffect(() => {
    setMessageList(preSaveOrder.chat_message || []);
  }, [preSaveOrder.chat_message]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!preSaveOrder.chat) return;
      dispatch(getChatMessages({ iri: String(preSaveOrder.chat) }));
    }, 15000); // Poll every 1000 milliseconds (1 second)

    return () => {
      // Cleanup: Clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ens this effect runs only once

  const handleSend = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const currentDate = new Date().toISOString();
    if (files.length > 0) {
      const data = files.map(async (el) => {
        const res = await addDocumentAPI({ file: el }).then((res) => res.data.path);
        return res;
      });
      const arr = await Promise.all(data);
      if (preSaveOrder.chat) {
        dispatch(
          sendChatMessages({
            text: value,
            chat: preSaveOrder.chat,
            to_user: preSaveOrder.user,
            files: arr,
          })
        );
        const newData = {
          author: `/api/v1/users/${preSaveOrder.manager.id}`,
          text: String(value),
          files: arr,
          created_at: currentDate,
          is_read: false,
        };
        if (!chatArr.map((el) => el).includes(getDate(new Date(currentDate)))) {
          setChatArr([...chatArr, getDate(new Date(currentDate))]);
        }
        setMessageList((prev) => [...prev, newData]);
        setValue('');
        setPhotosPreview([]);
        setFiles([]);
      }
    }
    if (value === '') return;

    if (preSaveOrder.chat) {
      dispatch(
        sendChatMessages({
          text: value,
          chat: preSaveOrder.chat,
          to_user: preSaveOrder.user,
          files: [],
        })
      );
    }
    const newData = {
      author: `/api/v1/users/${preSaveOrder.manager.id}`,
      text: String(value),
      created_at: currentDate,
      files: [],
      is_read: false,
    };

    if (!chatArr.map((el) => el).includes(getDate(new Date(currentDate)))) {
      setChatArr([...chatArr, getDate(new Date(currentDate))]);
    }
    setMessageList((prev) => [...prev, newData]);
    setValue('');
  };

  const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles);
    setFiles(filesFromInput);
  };

  const handleAddPhotos = (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles);
    setFiles(filesFromInput);
    const updArr = filesFromInput.map((el) => {
      return URL.createObjectURL(el);
    });
    setPhotosPreview(updArr);
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.chatBlock}>
        {chatArr.length > 0 ? (
          chatArr.map((chat, idx) => (
            <div className={styles.chat} key={idx}>
              <span className={styles.date}>{chat === getDate(new Date()) ? 'Сегодня' : chat}</span>
              {messageList
                .filter((el) => chat === getDate(new Date(el.created_at)))
                .map((el, idx) => (
                  <div
                    className={clsx(styles.messageWrap, preSaveOrder.user === el.author ? styles.left : styles.right)}
                    key={idx}
                  >
                    <div className={styles.messageContainer}>
                      {el.files.length > 0 ? (
                        <div className={clsx(styles.messageFiles)}>
                          {el.files.map((file, index) => {
                            if (
                              file.includes('.png') ||
                              file.includes('.jpg') ||
                              file.includes('.jpeg') ||
                              file.includes('.webp')
                            ) {
                              return (
                                <a
                                  href={`${process.env.REACT_APP_BASE_API_URL}${file}`}
                                  download={String(file.split('/').at(-1))}
                                  target="_blank"
                                  rel="noreferrer"
                                  key={index}
                                >
                                  <img src={`${process.env.REACT_APP_BASE_API_URL}${file}`} alt="photo" />
                                </a>
                              );
                            } else {
                              return <ChatFile key={index} path={file} name={String(file.split('/').at(-1))} />;
                            }
                          })}
                        </div>
                      ) : (
                        <div className={clsx(styles.message)}>{el.text}</div>
                      )}

                      <span>{getTimeTzNoLess(new Date(el.created_at))}</span>
                    </div>
                    {preSaveOrder.user !== el.author && (
                      <User
                        id={Number(preSaveOrder.basicInfo?.manager?.id)}
                        name={String(preSaveOrder.basicInfo?.manager?.name)}
                        viewName={false}
                        className={styles.user}
                      />
                    )}
                  </div>
                ))}
              <div ref={messagesEndRef} />
            </div>
          ))
        ) : (
          <p className={styles.notFound}>
            У вас пока нет сообщений с клиентом по этому заказу. Напишите, чтобы начать общение.
          </p>
        )}
      </div>
      <form onSubmit={handleSend} className={styles.interface}>
        {files.length > 0 &&
          photosPreview.length === 0 &&
          files.map((file, idx) => (
            <ChatFile
              className={styles.previewFile}
              key={file.name}
              name={file.name}
              deleteFile={(a) => {
                const data = files.filter((item, index) => index !== idx);
                setFiles(data);
              }}
            />
          ))}
        {photosPreview.length > 0 && (
          <div className={styles.previews}>
            {photosPreview.map((el, idx) => (
              <div
                className={styles.photo}
                key={idx}
                style={{
                  backgroundImage: `url(${el})`,
                }}
              >
                <div
                  className={styles.icon}
                  onClick={() => {
                    const updatedPreview = photosPreview.filter((item, index) => index !== idx);
                    const data = files.filter((item, index) => index !== idx);
                    setFiles(data);
                    setPhotosPreview(updatedPreview);
                  }}
                >
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.00009 6.1099L8.14018 9.25L9.25 8.14018L6.1099 5.00009L9.25018 1.85982L8.14036 0.75L5.00009 3.89027L1.85982 0.75L0.75 1.85982L3.89027 5.00009L0.750179 8.14018L1.85999 9.25L5.00009 6.1099Z"
                      fill="#5B6572"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={styles.form}>
          {showMenu && (
            <ul className={styles.list}>
              <li>
                <label htmlFor="file" className={styles.addFileLabel}>
                  Файл
                </label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="application/pdf,application/msword,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.doc, .docx"
                  onChange={(e) => {
                    setShowMenu(false);
                    handleAddFiles(e);
                  }}
                  className={styles.addFile}
                  // multiple
                />
              </li>
              <li>
                <label htmlFor="photo" className={styles.addFileLabel}>
                  Фото
                </label>
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  accept="image/*,image/jpg,image/png,image/gif"
                  onChange={(e) => {
                    setShowMenu(false);
                    handleAddPhotos(e);
                  }}
                  className={styles.addFile}
                  multiple
                />
              </li>
            </ul>
          )}

          <button type="button" className={styles.optionsBtn} onClick={() => setShowMenu(!showMenu)}>
            <PaperClipChatIcon />
          </button>
          <input placeholder="Напишите текст" value={value} onChange={(e) => setValue(e.target.value)} />
          <button className={styles.sendBtn}>
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.02575 1.76775C4.28117 1.39566 3.457 2.10608 3.71492 2.89733L5.38742 8.02441C5.43472 8.16926 5.52083 8.29836 5.63639 8.39768C5.75195 8.49701 5.89252 8.56274 6.04283 8.58775L12.6512 9.689C12.9991 9.74733 12.9991 10.2473 12.6512 10.3057L6.04325 11.4069C5.89294 11.4319 5.75236 11.4977 5.63681 11.597C5.52125 11.6963 5.43514 11.8254 5.38783 11.9702L3.71492 17.1002C3.45658 17.8919 4.28117 18.6023 5.02575 18.2302L19.8149 10.8377C20.5062 10.4919 20.5062 9.50566 19.8149 9.16025L5.02575 1.76775Z" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};
