import { TableColumn } from 'pages/Orders/OrdersTable/OrderTable.types';

export const columns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
    sorted: 'desc',
  },
  {
    id: 'name',
    name: 'Название',
  },
  {
    id: 'count',
    name: 'Кол-во фото',
  },
  {
    id: 'edit',
    name: 'Дата создания',
    sorted: 'desc',
  },
  {
    id: 'tags',
    name: 'Теги',
  },
  {
    id: 'settings',
    name: '',
  },
];

export const albumData = [
  {
    id: 1,
    name: 'Тв 3дн/2н',
    count: 10,
    updated_at: '01.07.2023',
    tags: ['Осень', 'Школьники'],
    photos: [],
  },
];
