import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const CloseIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28033 7.06066L9.5 11.2803L10.5607 10.2197L6.34099 6L10.5607 1.78033L9.5 0.719666L5.28033 4.93934L1.06066 0.719666L0 1.78033L4.21967 6L0 10.2197L1.06066 11.2803L5.28033 7.06066Z"
        fill={fill}
      />
    </svg>
  );
};
