import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import axiosInstance from '../axiosInstance';

export interface SavePhoto {
  title: string;
  alt: string;
  file: File;
  photo_tags: SelectMultiItemType[];
  is_license: boolean;
}
export interface Photo {
  id: string | number;
  title: string;
  base_name: string;
  name: string;
  thumbnail_name: string;
  alt: string;
  photo_tags: string[];
  is_license: boolean;
  used_count: number;
  albums_count: number;
  used_tours: string[];
}

export interface Album {
  '@id'?: string;
  id: string | number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  photos: Photo[];
  album_tags: SelectMultiItemType[];
  used_count: number;
  photos_count: number;
  used_tours: string[];
}

export interface PhotoRes {
  '@id'?: string;
  id: string | number;
  base_name: string;
  name: string;
  title: string;
  thumbnail_name: string;
  alt: string;
  photo_tags: SelectMultiItemType[];
  is_license: boolean;
  used_count: number;
  albums_count: number;
  created_at: string;
  updated_at: string;
  used_tours: string[];
}

export interface PhotoData {
  page?: number;
  photo_tags?: string[] | null;
  itemsPerPage?: number;
  search?: string | null;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  sortById?: 'asc' | 'desc' | null;
  sortByDate?: 'asc' | 'desc' | null;
}

export interface AddAlbum {
  name: string;
  description: string;
  photos: string[];
  album_tags: string[];
}
export interface UpdatedAlbum {
  id: number | string;
  name: string;
  description: string;
  photos: string[];
  album_tags: string[];
}

export interface PhotosDataRes {
  'hydra:member': Photo[];
}
export interface AlbumsDataRes {
  'hydra:member': Album[];
}
export const addPhotoAPI = async (data: File) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data);
  return await axiosInstance.post<{
    '@id'?: string;
    id: string | number;
    base_name: string;
    name: string;
    thumbnail_name: string;
    alt: string;
    photo_tags: SelectMultiItemType[];
    is_license: boolean;
    used_count: number;
    albums_count: number;
    created_at: string;
    updated_at: string;
  }>('/api/v1/photos/upload', bodyFormData);
};

export const updatePhotoAPI = async (data: Partial<Photo>) => {
  const { id = 0, ...orders } = data;
  if (!id) throw new Error('Missing id while order update');
  const response = await axiosInstance.put<any>(`/api/v1/photos/${id}`, orders, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const updateAlbumAPI = async (data: Partial<UpdatedAlbum>) => {
  const { id = 0, ...orders } = data;
  if (!id) throw new Error('Missing id while order update');
  const response = await axiosInstance.put<any>(`/api/v1/photo-albums/${id}`, orders, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const photosAPI = async ({ page, itemsPerPage, search, dateFrom, dateTo, photo_tags }: PhotoData) => {
  const response = await axiosInstance.get<PhotosDataRes>('/api/v1/photos', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      'created_at[before]': dateFrom,
      'created_at[after]': dateTo,
      title: search,
      photo_tags: photo_tags,
      'sort[id]': 'desc',
    },
  });
  return response;
};

export const albumsAPI = async ({
  page,
  itemsPerPage,
  search,
  dateFrom,
  dateTo,
  photo_tags,
  sortByDate,
  sortById,
}: PhotoData) => {
  const response = await axiosInstance.get<AlbumsDataRes>('/api/v1/photo-albums', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      'created_at[before]': dateFrom,
      'created_at[after]': dateTo,
      name: search,
      album_tags: photo_tags,
      'sort[id]': sortById,
      'sort[created_at]': sortByDate,
    },
  });
  return response;
};

export const photoAPI = async (id: number) => {
  const response = await axiosInstance.get<Photo>(`/api/v1/photos/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const albumAPI = async (id: number) => {
  const response = await axiosInstance.get<Album>(`/api/v1/photo-albums/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const deleteAlbumAPI = async (id: number) => {
  const response = await axiosInstance.delete<any>(`/api/v1/photo-albums/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const deleteAlbumWithPhotoAPI = async (id: number) => {
  const response = await axiosInstance.post<any>(`/api/v1/photo-albums/${id}/recursive`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

// получения списка тегов галереи
export const getTagsAPI = async () => {
  const response = await axiosInstance.get('/api/v1/photo-tags', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      nocache: true,
      itemsPerPage: 150,
    },
  });
  return response;
};
export const createTagsAPI = async (name: string) => {
  const response = await axiosInstance.post('/api/v1/photo-tags', {
    name: name,
  });
  return response;
};
export const createAlbumTagsAPI = async (name: string) => {
  const response = await axiosInstance.post('/api/v1/album-tags', {
    name: name,
  });
  return response;
};
export const deletePhotoAPI = async (id: string) => {
  const response = await axiosInstance.delete<any>(`/api/v1/photos/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
// получения списка тегов альбома
export const getAlbumTagsAPI = async () => {
  const response = await axiosInstance.get('/api/v1/album-tags', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      nocache: true,
      itemsPerPage: 150,
    },
  });
  return response;
};
// создание фотоальбома
export const addAlbumAPI = async (data?: AddAlbum) => {
  const response = await axiosInstance.post<any>('/api/v1/photo-albums', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};
