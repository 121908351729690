import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const FilterIcon: FC<IconProps> = ({ width, height, stroke }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.02572 1.77875L7.12509 8.875V14.5L10.8751 12V8.875L16.9745 1.77875C17.322 1.3725 17.0301 0.75 16.4913 0.75H1.50884C0.970092 0.75 0.678217 1.3725 1.02572 1.77875Z"
        stroke={stroke}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
