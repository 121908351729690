// Контрагенты
export enum StatusPartner {
  PRIVATE_PERSON = 'Физическое лицо',
  LEGAL_PERSON = 'Юридическое лицо',
}

export enum PartnerTabs {
  ALL = 'Все',
  PRIVATE_PERSONS = 'Физ.лица',
  LEGAL_PERSONS = 'Юр.лица',
}

export enum PrivatePartnerTabs {
  COMMON = 'Общая информация',
  DOCUMENTS = 'Документы',
  HISTORY = 'История заказов',
}

export enum LegalPartnerTabs {
  COMMON = 'Общая информация',
  REQUISITES = 'Реквизиты',
  HISTORY = 'История заказов',
}

// Туры
export enum TourTabs {
  ALL = 'Все туры',
  VISIBLE_TOUR = 'Активные туры',
  NO_VISIBLE_TOUR = 'Архивные туры',
  ACTIVE_TOURS = 'Активные поездки',
  CLOSE_TOURS = 'Закрытые поездки',
}

// Статус тура на бэке
export enum TourStatus {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export enum TypeTour {
  ACTIVE_TOUR = 'Активный',
  CLOSE_TOUR = 'Закрытый',
}

// Заказы
export enum OrderStatus {
  NEW = 'В работе',
  AWAITING_PAYMENT = 'Ожидает оплаты',
  ADVANCE_RECEIVED = 'Аванс получен',
  PAID = 'Оплачен',
  REFUSAL = 'Отказ',
  FINISHED = 'Завершен',
  CANCELLED = 'Аннулирован',
  SITE_RESERVE = 'Бронь с сайта',
  CONSULTATION = 'Консультация',
  PAYMENT_ACCEPTANCE = 'Приём расчёт',
  RESERVATION_ACCEPTANCE = 'Приём брони',
}
export enum PaymentStatus {
  AWAITING_PAYMENT = 'Ожидается оплата',
  ADVANCE_RECEIVED = 'Аванс получен',
  NO_PAID = 'Заказ не оплачен',
  PAID = 'Заказ оплачен',
  FAILURE = 'Сбой при оплате с сайта',
  RESERVED = 'Разморозить деньги',
}
// Вид размещение
export enum AccomType {
  ONE = '1взр',
  TWO = '2взр',
  THREE = '3взр',
  FOUR = '1взр+1реб',
  FIVE = '1взр+2реб',
  SIX = '2взр+1реб',
  SEVEN = '2взр+2реб',
}

export enum OrderFormTabs {
  BASIC_INFO = 'Основное',
  CONSULTATION = 'Консультация',
  TOUR_INFO = 'Информация по заказу',
  DOCUMENTS_AND_PAYMENT = 'Документы и оплата',
}

export enum BasicInfoTabs {
  REQUEST = 'Заявка с сайта',
  HISTORY = 'История работы по заказу',
  CHAT = 'Чат поддержки',
}

export enum OrderActionsStatus {
  NOTE = 'Примечание',
  ACTION_HISTORY = 'История действий',
}

// Договора
export enum ContractStatus {
  CONTRACT_AQUARELLE_TOUR_OPERATOR = 'Договор (Акварель-туроператор)',
  PARTNER_TOUR_AQUARELLE_AGENT = 'Партнерский тур (Акварель-агент)',
  PARTNER_TOUR_AQUARELLE_TOUR_OPERATOR = 'Партнерский тур (Акварель-туроператор) ',
  ABROAD_AQUARELLE_AGENT = 'Заграница (Акварель-агент)',
  PAID_RENDERING_OF_SERVICES_BELARUS = 'Возмездного оказания услуг Беларусь',
  FOR_AGENCIES = 'Для агентств',
  SCORE = 'Счёт',
  BOOKING_CONFIRMATION = 'Подтверждение бронирования',
}

// Ответ сервера
export enum STATUS_RESPONSE {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

// Субъекты
export enum SUBJECT {
  LEGAL_PERSON = 'juridical',
  PRIVATE_PERSON = 'physical',
}

// Шаблоны Тура
export enum ToursTemplates {
  MULTIDAY = 'Многодневный тур',
  ONEDAY = 'Однодневный тур',
  NO_DATE = 'Тур без дат',
  EVERYDAY = 'Вертушка',
}
// табы галереи
export enum GalleryTab {
  ALL_PHOTO = 'Все фото',
  ALBUMS = 'Фотоальбомы',
}
