import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { TourProgramDay, TourProgramProps } from './TourProgram.types';
import { TextEditor } from 'ui/TextEditor';
import { Button } from 'ui/Button';
import { RemoveIcon } from '../../../../../components/icons';

import styles from './TourProgram.module.scss';
import { customModule } from '../DescriptionInfoTab/helper';

export const TourProgram: FC<TourProgramProps> = ({ days = [], setInfo, className, valueChanged }) => {
  const [daysList, setDaysList] = useState<TourProgramDay[]>(days || []);
  const [canDo, setCanDo] = useState<boolean>(false);

  useEffect(() => {
    if (canDo) {
      setCanDo(false);
    }
  }, [canDo]);

  useEffect(() => {
    days && setDaysList(days);
  }, [days]);

  const prevRows = useRef<TourProgramDay[]>(daysList);
  useEffect(() => {
    if (valueChanged && canDo && JSON.stringify(daysList) !== JSON.stringify(prevRows.current)) {
      valueChanged();
    }
    if (setInfo && daysList && JSON.stringify(daysList) !== JSON.stringify(prevRows.current)) {
      console.log(JSON.stringify(daysList) !== JSON.stringify(prevRows.current));
      setInfo(daysList);
    }
    prevRows.current = daysList;
  }, [daysList]);

  const update = (key: number, value: Partial<TourProgramDay>) => {
    const data = [...daysList];

    if (data[key]) {
      data[key] = { ...data[key], ...value };
    }
    setCanDo(true);
    setDaysList([...data].filter((i) => i));
  };

  const handleAddDay = (e: React.MouseEvent) => {
    e.preventDefault();
    setDaysList([
      ...daysList,
      {
        number: daysList.length,
        text: '',
        name: '',
      },
    ]);
  };

  const remove = (key: number) => {
    const data = [...daysList];
    if (data[key]) {
      delete data[key];
    }
    setCanDo(true);
    setDaysList([...data].filter((i) => i));
  };

  return (
    <section className={clsx(styles.program, className)}>
      <h3 className={styles.title}>Программа тура</h3>
      {daysList.map((day, id) => (
        <div className={styles.day} key={day.number}>
          <div className={styles.headerWrapper}>
            <h4 className={styles.subtitle}>День {id + 1}</h4>
            <input
              className={clsx(styles.titleProgram)}
              // defaultValue={item.price2A2C}
              value={day.name}
              onChange={(e) => {
                update(id, { name: e.target.value });
              }}
            />
            <button type="button" className={styles.remove} onClick={() => remove(id)}>
              <RemoveIcon width={15} height={15} />
            </button>
          </div>
          <TextEditor
            placeholder="Добавьте описание"
            className={styles.fullDesc}
            data={day.text}
            setData={(value) => update(id, { text: value.replace('<p><br></p>', '').trim() })}
            customModules={customModule}
          />
        </div>
      ))}
      <Button onClick={handleAddDay} transparent={true}>
        + Добавить день
      </Button>
    </section>
  );
};
