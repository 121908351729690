import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const Client2A1CIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_165_1366)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.70033 12.4097C11.8408 12.4097 13.576 10.6745 13.576 8.53402C13.576 6.39356 11.8408 4.65838 9.70033 4.65838C7.55988 4.65838 5.8247 6.39356 5.8247 8.53402C5.8247 10.6745 7.55988 12.4097 9.70033 12.4097ZM15.2344 8.53402C15.2344 10.6006 14.1016 12.4027 12.423 13.353C13.8439 13.7702 15.151 14.5115 16.2218 15.5289C17.1444 16.4053 17.8514 17.4483 18.3103 18.5835H22.5985C22.3438 17.5883 21.8042 16.6627 21.0176 15.9154C19.8873 14.8416 18.3403 14.2272 16.7139 14.2272C16.6346 14.2272 16.5554 14.2286 16.4766 14.2315V12.449C16.555 12.455 16.6343 12.458 16.7142 12.458C18.4266 12.458 19.8147 11.0699 19.8147 9.3575C19.8147 7.64514 18.4266 6.25699 16.7142 6.25699C16.6343 6.25699 16.555 6.26002 16.4766 6.26597V4.99993H17.5007C19.5706 5.37104 21.1414 7.18083 21.1414 9.3575C21.1414 11.0109 20.2351 12.4526 18.8921 13.2129C20.0289 13.5466 21.0746 14.1397 21.9314 14.9536C22.9813 15.951 23.6822 17.2181 23.9592 18.5835C24.0473 19.0176 24.0926 19.4617 24.0926 19.9102H22.7658H18.7234C18.735 19.9621 18.7461 20.0141 18.7566 20.0663C18.8667 20.6089 18.9233 21.164 18.9233 21.7247H17.2649H9.69993H2.13494H0.476562C0.476562 21.164 0.533118 20.6089 0.643228 20.0663C0.989537 18.3595 1.8656 16.7757 3.17803 15.5289C4.24903 14.5114 5.55631 13.77 6.97735 13.3529C5.29898 12.4025 4.16631 10.6005 4.16631 8.53402C4.16631 5.47767 6.64398 3 9.70033 3C12.7567 3 15.2344 5.47767 15.2344 8.53402ZM17.0557 20.0663C16.7374 18.8222 16.0628 17.6652 15.0796 16.7312C13.6667 15.3889 11.733 14.6208 9.69993 14.6208C7.66691 14.6208 5.73318 15.3889 4.32023 16.7312C3.33703 17.6652 2.66247 18.8222 2.34417 20.0663L9.69993 20.0663H17.0557Z"
          fill={fill}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6342 8.41712C34.6342 9.5957 33.6788 10.5511 32.5002 10.5511C31.3216 10.5511 30.3662 9.5957 30.3662 8.41712C30.3662 7.23853 31.3216 6.28311 32.5002 6.28311C33.6788 6.28311 34.6342 7.23853 34.6342 8.41712ZM36.2342 8.41712C36.2342 10.4794 34.5624 12.1511 32.5002 12.1511C30.438 12.1511 28.7662 10.4794 28.7662 8.41712C28.7662 6.35488 30.438 4.68311 32.5002 4.68311C34.5624 4.68311 36.2342 6.35488 36.2342 8.41712ZM32.4999 12.6048C31.6542 12.6048 30.6982 12.6474 29.7862 12.8466C28.8752 13.0456 27.9524 13.4126 27.2256 14.1031C26.5015 14.7909 26.0704 15.7063 25.8181 16.6135C25.5646 17.5253 25.4766 18.4821 25.4766 19.317H27.0766C27.0766 18.5838 27.155 17.7781 27.3596 17.0422C27.5656 16.3016 27.8845 15.684 28.3276 15.2631C28.7678 14.8449 29.3814 14.5727 30.1277 14.4097C30.873 14.247 31.6951 14.2048 32.4999 14.2048C33.3047 14.2048 34.1413 14.2469 34.9014 14.4103C35.6645 14.5743 36.2883 14.8477 36.7256 15.2631C37.1634 15.679 37.4698 16.2903 37.6637 17.0305C37.8564 17.7664 37.9233 18.5745 37.9233 19.317H39.5233C39.5233 18.4915 39.4503 17.5369 39.2115 16.6251C38.9738 15.7176 38.5569 14.7959 37.8276 14.1031C37.0978 13.4098 36.1584 13.044 35.2377 12.846C34.3138 12.6474 33.3457 12.6048 32.4999 12.6048Z"
        fill={fill}
      />
      <defs>
        <clipPath id="clip0_165_1366">
          <rect width="24" height="24" fill="white" transform="translate(0.476562)" />
        </clipPath>
      </defs>
    </svg>
  );
};
