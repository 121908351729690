import axiosInstance from 'services/axiosInstance';

export interface AddOrderDoc {
  document_type: string;
  order: string;
  contact_requisite?: string;
}

export const addOrderDocumentAPI = async (data?: AddOrderDoc) => {
  const response = await axiosInstance.post<any>('/api/v1/order-documents', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const sendOrderDocumentAPI = async (data?: { id: string; all?: boolean; document?: string }) => {
  const response = await axiosInstance.post<any>(
    `/api/v1/order/send-documents`,
    {
      all: data?.all,
      document: data?.document,
      order: data?.id,
    },
    {
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json;charset=UTF-8',
      },
    }
  );
  return response;
};

export const deleteDocumentAPI = async (id: number) => {
  const response = await axiosInstance.delete<any>(`/api/v1/order-documents/${id}`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const uploadDocAPI = async ({ file, order_id }: { file: File; order_id: string }) => {
  const id = order_id.split('/').at(-1);
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  bodyFormData.append('order_id', String(id));
  return await axiosInstance.post('/api/v1/orders/upload-document', bodyFormData);
};

export const updateOrderDocumentAPI = async (data: { id: number; transferred_to_user: boolean }) => {
  const response = await axiosInstance.put<any>(
    `/api/v1/order-documents/${data?.id}`,
    { transferred_to_user: data?.transferred_to_user },
    {
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json;charset=UTF-8',
      },
    }
  );
  return response;
};
