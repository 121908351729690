import React, { FC, useEffect, useState } from 'react';
import { Accordion, createStyles } from '@mantine/core';
import { AccordionType } from './Accordion.types';

import styles from './Accordion.module.scss';

export const AccordionFeature: FC<AccordionType> = ({ title, item, is_opened }) => {
  return (
    <Accordion
      variant="separated"
      defaultValue= {is_opened ? "reset-password" : ""}
      styles={{
        label: {
          fontWeight: 600,
          color: '#263B53',
          fontSize: '16px',
          lineHeight: '19px',
        },
        chevron: {
          marginLeft: '10px'
        }
      }}
    >
      <Accordion.Item className={styles.item} value="reset-password">
        <Accordion.Control className={styles.title}>{title}</Accordion.Control>
        <Accordion.Panel className={styles.panel}>{item}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
