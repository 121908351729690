import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const BankIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.674 7.99453C17.2947 7.99453 17.5539 7.19699 17.0496 6.82935L9.37752 1.24855C9.26796 1.16828 9.13567 1.125 8.99985 1.125C8.86402 1.125 8.73173 1.16828 8.62217 1.24855L0.950069 6.82935C0.445828 7.19498 0.704979 7.99453 1.32775 7.99453H2.57127V15.5079H1.12485C1.03645 15.5079 0.964131 15.5802 0.964131 15.6686V16.7133C0.964131 16.8017 1.03645 16.874 1.12485 16.874H16.8748C16.9632 16.874 17.0356 16.8017 17.0356 16.7133V15.6686C17.0356 15.5802 16.9632 15.5079 16.8748 15.5079H15.4284V7.99453H16.674ZM8.99985 2.66484L14.4461 6.62645H3.55364L8.99985 2.66484ZM4.0177 7.99453H6.36815V15.5079H4.0177V7.99453ZM7.81458 7.99453H10.165V15.5079H7.81458V7.99453ZM13.982 15.5079H11.6115V7.99453H13.982V15.5079Z"
        fill="#1886E9"
      />
    </svg>
  );
};
