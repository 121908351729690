import React, { FC } from 'react';
import { Button } from 'ui/Button';

import styles from './Controls.module.scss';

export type ControlsProps = {
  btnSaveLabel?: string;
  btnSaveHandler: () => void;
  btnCancelLabel?: string;
  btnCancelHandler: () => void;
  disabledSave?: boolean;
};

export const Controls: FC<ControlsProps> = ({
  btnSaveLabel = 'Создать',
  btnSaveHandler,
  btnCancelLabel = 'Отмена',
  btnCancelHandler,
  disabledSave,
}) => {
  return (
    <div className={styles.buttons}>
      <Button type="submit" className={styles.save} onClick={() => btnSaveHandler()} disabled={disabledSave}>
        {btnSaveLabel}
      </Button>
      <Button onClick={btnCancelHandler} className={styles.cancel}>
        {btnCancelLabel}
      </Button>
    </div>
  );
};
