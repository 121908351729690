import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const ToursIcon: FC<IconProps> = ({ width, height, fill, stroke }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_19)">
        <path
          d="M19.7606 7.35176C21.0371 7.00972 22.3492 7.76726 22.6912 9.04376V9.04376C23.0333 10.3203 22.2757 11.6324 20.9992 11.9744L15.2965 13.5024L15.3254 21.0194C15.3283 21.7656 14.8281 22.42 14.1074 22.6131L13.3408 22.8186C12.3427 23.086 11.3484 22.3769 11.276 21.3462L10.8098 14.7046L6.32311 15.9068L5.96558 19.6437L3.66259 20.2608L2.7523 16.8636L1.51367 12.2409L0.585543 8.7771L2.88533 8.16088L5.08446 11.2841L9.54485 10.0891L6.80212 4.57547C6.3395 3.64548 6.84489 2.5249 7.8482 2.25607L8.61763 2.0499C9.32957 1.85913 10.0814 2.16569 10.457 2.79987L14.0579 8.8798L19.7606 7.35176Z"
          stroke={stroke}
          strokeWidth="1.83052"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_19">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
