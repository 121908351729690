import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './GalleryForm.module.scss';
import { AlertIcon } from 'components/icons/AlertIcon';
import { Input } from 'ui/Input';
import { useForm } from '@mantine/form';
import { MultiSelector } from 'ui/MultiSelector';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { Album, Photo } from 'services/gallery/galleryApi.service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDate } from 'core/utils/getDate';
import { resetPreSaveAlbum, setIsOpenPhotoList, setPreSaveAlbum } from 'store/entities/gallery/gallerySlice';
import { addAlbum, addAlbumTag, getAlbumTags, getTags, updateAlbum, updatePhoto } from 'store/action-creators/gallery';
import { declOfNum } from 'core/utils/declOfNum';
import { Button } from 'ui/Button';
import { CloseIcon, RemoveIcon } from 'components/icons';
import { Controls } from 'components/Controls';
import ModalContext from 'core/context/ModalContext';
import { Modal } from 'components/Modal';
import { PhotoList } from 'pages/Gallery/PhotoList';
import clsx from 'clsx';
type AlbumProps = {
  name: string;
  description: string;
  photos: Photo[];
  album_tags: SelectMultiItemType[];
};

export const AlbumForm: FC<{ album?: Album }> = ({ album }) => {
  const { preSaveAlbum, album_tags, isLoading, isOpenPhotoList } = useAppSelector((state) => state.gallerySlice);
  const dispatch = useAppDispatch();
  const [isEdit, setisEdit] = useState(false);
  const [openPhotoList, setOpenPhotoList] = useState(false);
  const [allData, setAllData] = useState<AlbumProps | null>(null);
  const [viewTourHelp, setViewTourHelp] = useState(false);
  const [viewPhotoHelp, setViewPhotoHelp] = useState<null | number>(null);
  const [tagList, setTagList] = useState(album_tags);

  useEffect(() => {
    if (album_tags) {
      setTagList(album_tags);
    }
  }, [album_tags]);

  const initialValues: AlbumProps = {
    name: preSaveAlbum.name || album?.name || '',
    photos: preSaveAlbum.photos || album?.photos || [],
    album_tags: preSaveAlbum.album_tags || album?.album_tags || [],
    description: preSaveAlbum.description || album?.description || '',
  };
  useEffect(() => {
    if (album_tags) {
      setTagList(album_tags);
    }
  }, [album_tags]);

  const formAlbum = useForm({
    initialValues,
  });

  useEffect(() => {
    setAllData({ ...allData, ...formAlbum.values });
  }, [formAlbum.values]);
  useEffect(() => {
    formAlbum.setFieldValue('name', preSaveAlbum.name);
  }, [preSaveAlbum.name]);

  useEffect(() => {
    formAlbum.setFieldValue('description', preSaveAlbum.description);
  }, [preSaveAlbum.description]);

  useEffect(() => {
    formAlbum.setFieldValue('album_tags', preSaveAlbum.album_tags);
  }, [preSaveAlbum.album_tags]);

  useEffect(() => {
    if (allData) {
      dispatch(
        setPreSaveAlbum({
          ...preSaveAlbum,
          ...allData,
        })
      );
    }
  }, [allData]);

  useEffect(() => {
    if (isEdit && album != undefined) {
      const mappedData = {
        id: preSaveAlbum.id,
        name: formAlbum.values.name || preSaveAlbum.name,
        description: formAlbum.values.description || preSaveAlbum.description,
        album_tags:
          formAlbum.values.album_tags.map((el) => (el['@id'] ? el['@id'] : `/api/v1/album-tags/${el.id}`)) ||
          preSaveAlbum.album_tags.map((el) => (el['@id'] ? el['@id'] : `/api/v1/album-tags/${el.id}`)),
        photos:
          formAlbum.values.photos.map((el) => `/api/v1/photos/${el.id}`) ||
          preSaveAlbum.photos.map((el) => `/api/v1/photos/${el.id}`),
      };
      dispatch(updateAlbum(mappedData));
      setisEdit(false);
    }
  }, [isEdit]);

  const onSubmit = () => {
    console.log(preSaveAlbum);
    dispatch(
      addAlbum({
        name: preSaveAlbum.name,
        description: preSaveAlbum.description,
        photos: preSaveAlbum.photos.map((el) => `/api/v1/photos/${el.id}`),
        album_tags: preSaveAlbum.album_tags.map((el) => (el['@id'] ? el['@id'] : `/api/v1/album-tags/${el.id}`)),
      })
    );
  };
  const { setActive } = useContext(ModalContext);
  const closeForm = () => {
    setActive(false);
    dispatch(resetPreSaveAlbum());
  };
  return (
    <>
      <div className={styles.container}>
        {album != undefined && (
          <div className={styles.useInfoItem}>
            <p>
              Данный альбом использован:{' '}
              <span>{`${album?.used_count} ${album && declOfNum(album?.used_count, ['раз', 'раза', 'раз'])}`}</span>
            </p>
            <span
              className={styles.helper}
              onMouseMove={() => setViewTourHelp(true)}
              onMouseLeave={() => setViewTourHelp(false)}
            >
              <AlertIcon />
            </span>
            {viewTourHelp && album.used_tours.length > 0 && (
              <div className={clsx(styles.helpBlock, styles.durationHelp)}>
                {album.used_tours.map((el) => el).join(',')}
              </div>
            )}
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.inputGroup}>
            <Input
              type="text"
              label="Название альбома"
              name="Название альбома"
              {...formAlbum.getInputProps('name')}
              onBlur={() => setisEdit(true)}
            />
          </div>
          <div className={styles.inputGroup}>
            <Input
              type="text"
              label="Описание альбома"
              name="Описание альбома"
              {...formAlbum.getInputProps('description')}
              onBlur={() => setisEdit(true)}
            />
          </div>
        </div>
        <div className={styles.miltiSelect}>
          <div className={styles.subtitle}>Теги альбома</div>
          <MultiSelector
            className={styles.select}
            allItems={tagList}
            defaultItems={formAlbum.values.album_tags}
            getItems={(value) => {
              formAlbum.setFieldValue('album_tags', value);
              setisEdit(true);
            }}
            creatable
            onCreate={(query) => {
              setTagList((prev) => [...prev, { name: query }]);
              dispatch(addAlbumTag(query));

              return query;
            }}
          />
        </div>
        <div className={styles.photos}>
          <div className={styles.photosHeader}>
            <h2>Фото из галереи</h2>
            <Button
              className={styles.hideBtn}
              onClick={() => {
                setOpenPhotoList(true);
                dispatch(setIsOpenPhotoList(true));
              }}
              width="200px"
            >
              + Выбрать фото
            </Button>
          </div>
          <div className={styles.photoList}>
            {formAlbum.values.photos.map((el) => (
              <div className={styles.photo} key={el.id}>
                <div className={styles.imageContainer}>
                  <img
                    src={
                      el.thumbnail_name
                        ? `${process.env.REACT_APP_BASE_API_URL}/uploads/thumbnails/${el.thumbnail_name}`
                        : `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${el.name}`
                    }
                    alt={el.alt}
                  />
                  {el.is_license && <span>Лицензия</span>}
                </div>
                <div className={styles.photo_title}>
                  <h3>{el.title && el.title.length > 0 ? `${el.title.substring(0, 18)}...` : el.title}</h3>
                  <div className={styles.useInfoItem}>
                    <p>
                      <span>
                        Использовано: {`${el.used_count} ${declOfNum(el.used_count, ['раз', 'раза', 'раз'])}`}
                      </span>
                    </p>
                    <span
                      className={styles.helper}
                      onMouseMove={() => setViewPhotoHelp(Number(el.id))}
                      onMouseLeave={() => setViewPhotoHelp(null)}
                    >
                      <AlertIcon />
                    </span>
                    {viewPhotoHelp === Number(el.id) && el.used_tours.length > 0 && (
                      <div className={clsx(styles.helpBlock, styles.photoHelp)}>
                        {el.used_tours.map((el) => el).join(',')}
                      </div>
                    )}
                  </div>
                </div>
                <span
                  className={styles.remove}
                  onClick={() => {
                    const filtredPhotos = formAlbum.values.photos.filter((photo) => Number(photo.id) != Number(el.id));
                    formAlbum.setFieldValue('photos', filtredPhotos);
                    setisEdit(true);
                  }}
                >
                  <CloseIcon width={8} height={8} fill="#5B6572" />
                </span>
              </div>
            ))}
          </div>
        </div>
        {album != undefined && (
          <ul className={styles.listInfo}>
            <li>Дата создания альбома: {album && getDate(new Date(album.created_at))}</li>
            <li>Дата последнего изменения: {album && getDate(new Date(album.updated_at))}</li>
          </ul>
        )}
      </div>
      {album === undefined && (
        <Controls
          btnSaveHandler={onSubmit}
          btnCancelHandler={closeForm}
          btnSaveLabel="Создать"
          btnCancelLabel="Отмена"
          disabledSave={isLoading === 'ADD_ALBUM'}
        />
      )}
      {openPhotoList && isOpenPhotoList && (
        <Modal opened={isOpenPhotoList} setOpened={setOpenPhotoList}>
          <PhotoList
            selectedPhotos={formAlbum.values.photos.map((el) => el)}
            setPhotos={(a) => {
              formAlbum.setFieldValue('photos', a);
              setisEdit(true);
            }}
          />
        </Modal>
      )}
    </>
  );
};
