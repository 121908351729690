import React, { useEffect, useState } from 'react';

import { PartnersTable } from './PartnersTable/PartnersTable';
import { PartnersTop } from './PartnersTop/PartnersTop';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PartnersData } from 'services/partners/partnerApi.service';
import { getPartners } from 'store/action-creators/partners';

import styles from './Partners.module.scss';
import { clearNextQuery, setIsNeedToReloadTable } from '../../store/entities/partners/partnersSlice';

export const PartnersPage = () => {
  const dispatch = useAppDispatch();
  const { params, isNeedToReloadTable } = useAppSelector((state) => state.partners);
  const [partnersData, setPartnersData] = useState<PartnersData | null>(null);

  //при изменении значений фильтров, меняем параметры для запроса на сервер
  useEffect(() => {
    const setData = () => {
      dispatch(clearNextQuery());
      setPartnersData({
        ...partnersData,
        page: params.search === '' || params.search === null ? params.currentPage : 1,
        search: params.search === '' ? null : params.search,
        subject: params.subject.nameForBack,
        type: params.type,
        sort: params.sort,
        itemsPerPage: params.search === '' || params.search === null ? params.itemsPerPage : 100,
      });
    };

    if (isNeedToReloadTable) {
      dispatch(setIsNeedToReloadTable(false));

      const timeout = setTimeout(() => {
        setData();
      }, 700);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      setData();
    }
  }, [params, isNeedToReloadTable]);

  // при изменении параметров для запроса, отправляем запрос на получение контрагентов
  useEffect(() => {
    if (partnersData) {
      dispatch(getPartners(partnersData));
    }
  }, [partnersData]);

  //TODO move that to USEOffset or some else
  const [offset, setOffset] = useState<number>(window.scrollY);
  const [isDown, setIsDown] = useState<boolean>(false);
  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    setIsDown(window.innerHeight + window.scrollY >= document.body.offsetHeight);
  }, [offset]);

  // useEffect(() => {
  //   if (isDown) {
  //     const maxPage = Math.max(...Object.keys(partnersByPage).map((i) => Number(i)));
  //     const canDownload = maxPage <= lastPage;
  //     if (canDownload && nextPageQuery.length > 0) {
  //       dispatch(getPartnersNextPage(nextPageQuery));
  //     }
  //     setIsDown(false);
  //   }
  // }, [isDown]);

  return (
    <section className={styles.partners}>
      <PartnersTop />
      <PartnersTable />
    </section>
  );
};
