import { IReport } from './reports.types';

const reportTypes: Record<string, string> = {
  tours: 'Отчет по продаже туров сводный',
  tour_operators: 'Отчет по туроператорам',
  managers: 'Отчет по отдельным менеджерам',
  agents: 'Отчет по агентам',
  tours_started: 'Отчет по всем турам (начало тура в выбранный период)',
  pass: 'Отчет для электронной путевки',
};

export const reportsFactory = (reports: IReport[]): IReport[] => {
  const formattedReports = reports.map((report) => ({
    ...report,
    type: reportTypes[report.type],
    authorName: `${report.created_by.last_name} ${report.created_by.first_name[0]}.${
      report.created_by?.middle_name ? report.created_by?.middle_name[0] : report.created_by.first_name[0]
    }.`,
  }));

  return formattedReports.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
};
