import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TourParametersTabProps } from './TourParametersTab.types';
import { setIsEditedTour } from 'store/entities/tours/toursSlice';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import {
  getCities,
  getCountries,
  getDirections,
  getGuides,
  getTags,
  getTourPeriods,
  getTourTypes,
  getTransports,
} from 'store/action-creators/tours';
import { getUsers } from 'store/action-creators/users';
import {
  setMainTag,
  setPreSaveTourCities,
  setPreSaveTourCountries,
  setPreSaveTourDirection,
  setPreSaveTourGuides,
  setPreSaveTourManagers,
  setPreSaveTourTags,
  setPreSaveTourTransports,
  setPreSaveTourTravelTimes,
  setPreSaveTourTypes,
} from 'store/entities/tours/preSaveTourSlice';
import { MultiSelector } from 'ui/MultiSelector';

import styles from './TourParametersTab.module.scss';
import { HelperIcon } from 'components/icons/HelperIcon';
import { CloseButton, MultiSelectValueProps } from '@mantine/core';
import clsx from 'clsx';

function Value({ value, label, onRemove, classNames, ...others }: MultiSelectValueProps & { value: string }) {
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const { currentTour } = useAppSelector((state) => state.tours);
  const dispatch = useAppDispatch();

  return (
    <div {...others}>
      <div className={clsx(styles.item, value === preSaveTour2?.tourParameters?.main_tag && styles.itemChecked)}>
        <button
          className={clsx(styles.notChecked, value === preSaveTour2?.tourParameters?.main_tag && styles.checked)}
          onClick={(event: React.MouseEvent) => {
            if (value === preSaveTour2?.tourParameters?.main_tag) {
              dispatch(setMainTag(null));
            } else {
              dispatch(setMainTag(value));
            }
            console.log(value);
          }}
        ></button>
        <p>{label}</p>
        <CloseButton onMouseDown={onRemove} variant="transparent" size={22} iconSize={14} tabIndex={-1} />
      </div>
    </div>
  );
}

export const TourParametersTab: FC<TourParametersTabProps> = ({ tourParametersInfo }) => {
  const {
    countries: listOfCountriesTour,
    guides: listOfGuidesTour,
    cities: listOfCitiesTour,
    transports: listOfTransportsTour,
    tourTypes: listOfTypeTour,
    tourPeriods: listOfTimesTour,
    directions: listOfDirectionsTour,
    tags: listOfTagsTour,
  } = useAppSelector((state) => state.tours);
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  console.log(preSaveTour2.tourParameters.main_tag);
  const { users: listOfManagers } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const [managers, setManagers] = useState<SelectMultiItemType[]>([]);
  const [guids, setGuids] = useState<SelectMultiItemType[]>([]);
  const [countries, setCountries] = useState<SelectMultiItemType[]>([]);
  const [cities, setCities] = useState<SelectMultiItemType[]>([]);
  const [transport, setTransports] = useState<SelectMultiItemType[]>([]);
  const [tourTypes, setTourTypes] = useState<SelectMultiItemType[]>([]);
  const [times, setTimes] = useState<SelectMultiItemType[]>([]);
  const [directions, setDirections] = useState<SelectMultiItemType[]>([]);
  const [tags, setTags] = useState<SelectMultiItemType[]>([]);
  const [viewHelp, setViewHelp] = useState(false);
  //получение справочников с сервера
  useEffect(() => {
    if (listOfCountriesTour.length === 0) {
      dispatch(getCountries());
      dispatch(getGuides());
      dispatch(getUsers({ page: 1 }));
      dispatch(getTransports());
      dispatch(getTourTypes());
      dispatch(getTourPeriods());
      dispatch(getDirections());
      dispatch(getTags());
    }
  }, []);

  //получение списка городов (зависит от стран)
  useEffect(() => {
    if (countries !== undefined && countries.length > 0) {
      dispatch(getCities({ countries: countries.map((country) => country['@id']) }));
    }
  }, [countries]);

  // сбор направлений
  useEffect(() => {
    if (listOfDirectionsTour.length > 0 && directions.length === 0) {
      const dirList = listOfDirectionsTour.filter((el) =>
        tourParametersInfo?.direction?.includes(`/api/v1/tour-directions/${el.id}`)
      );
      setDirections(dirList || preSaveTour2.tourParameters?.direction || []);
    }
  }, [listOfDirectionsTour]);

  // сбор стран
  useEffect(() => {
    if (listOfCountriesTour.length > 0 && countries.length === 0) {
      const dirList = listOfCountriesTour.filter((el) =>
        tourParametersInfo?.country?.includes(`/api/v1/countries/${el.id}`)
      );
      setCountries(dirList || preSaveTour2.tourParameters?.country || []);
    }
  }, [listOfCountriesTour]);

  // сбор городов
  useEffect(() => {
    if (listOfCitiesTour.length > 0 && cities.length === 0) {
      const dirList = listOfCitiesTour.filter((el) => tourParametersInfo?.city?.includes(`/api/v1/cities/${el.id}`));
      setCities(dirList || preSaveTour2.tourParameters?.city || []);
    }
  }, [listOfCitiesTour]);

  // сбор транспорта
  useEffect(() => {
    if (listOfTransportsTour.length > 0 && transport.length === 0) {
      const dirList = listOfTransportsTour.filter((el) =>
        tourParametersInfo?.transport?.includes(`/api/v1/transports/${el.id}`)
      );
      setTransports(dirList || preSaveTour2.tourParameters?.transport || []);
    }
  }, [listOfTransportsTour]);

  // сбор гидов
  useEffect(() => {
    if (listOfGuidesTour.length > 0 && guids.length === 0) {
      const dirList = listOfGuidesTour.filter((el) => tourParametersInfo?.guide?.includes(`/api/v1/guides/${el.id}`));
      setGuids(dirList || preSaveTour2.tourParameters?.guide || []);
    }
  }, [listOfGuidesTour]);

  // сбор видов тура
  useEffect(() => {
    if (listOfTypeTour.length > 0 && tourTypes.length === 0) {
      const dirList = listOfTypeTour.filter((el) =>
        tourParametersInfo?.tourType?.includes(`/api/v1/tour-labels/${el.id}`)
      );
      setTourTypes(dirList || preSaveTour2.tourParameters?.tourType || []);
    }
  }, [listOfTypeTour]);

  // сбор времени путешествия
  useEffect(() => {
    if (listOfTimesTour.length > 0 && times.length === 0) {
      const dirList = listOfTimesTour.filter((el) =>
        tourParametersInfo?.travelTime?.includes(`/api/v1/tour-periods/${el.id}`)
      );
      setTimes(dirList || preSaveTour2.tourParameters?.travelTime || []);
    }
  }, [listOfTimesTour]);

  // сбор тегов
  useEffect(() => {
    if (listOfTagsTour.length > 0 && tags.length === 0) {
      const dirList = listOfTagsTour.filter((el) => tourParametersInfo?.tags?.includes(`/api/v1/tour-tags/${el.id}`));
      setTags(dirList || preSaveTour2.tourParameters?.tags || []);
      dispatch(setPreSaveTourTags(dirList));
    }
  }, [listOfTagsTour]);

  //установка данных в поля - из presave или из входящих пропсов
  useEffect(() => {
    setManagers(preSaveTour2.tourParameters?.tourManager || tourParametersInfo?.tourManager || []);
  }, []);
  // при изменении значений данные отправляются в presave, а оттуда на сервер
  const handleManagers = (managers: SelectMultiItemType[]) => {
    if (managers) {
      dispatch(setPreSaveTourManagers(managers));
    }
  };

  const handleGuids = (guids: SelectMultiItemType[]) => {
    if (guids) {
      dispatch(setPreSaveTourGuides(guids));
    }
  };

  const handleCountries = (countries: SelectMultiItemType[]) => {
    if (countries) {
      dispatch(setPreSaveTourCountries(countries));
      dispatch(getCities({ countries: countries.map((country) => country['@id']) }));
    }
  };

  const handleCities = (cities: SelectMultiItemType[]) => {
    if (cities) {
      dispatch(setPreSaveTourCities(cities));
    }
  };

  const handleTransports = (transports: SelectMultiItemType[]) => {
    if (transports) {
      dispatch(setPreSaveTourTransports(transports));
    }
  };

  const handleTourTypes = (types: SelectMultiItemType[]) => {
    if (types) {
      dispatch(setPreSaveTourTypes(types));
    }
  };

  const handleTimes = (times: SelectMultiItemType[]) => {
    if (times) {
      dispatch(setPreSaveTourTravelTimes(times));
    }
  };

  const handleDirections = (directions: SelectMultiItemType[]) => {
    if (directions) {
      dispatch(setPreSaveTourDirection(directions));
    }
  };
  const handleTags = (tags: SelectMultiItemType[]) => {
    if (tags) {
      console.log(tags);
      if (tags.length === 0) {
        dispatch(setMainTag(null));
      }
      dispatch(setPreSaveTourTags(tags));
    }
  };
  // const resArr: { id: number | undefined; name: string }[] = [];
  // listOfCitiesTour.filter(function (item) {
  //   const i = resArr.findIndex((x) => x.name == item.name);
  //   if (i <= -1) {
  //     resArr.push({ id: item.id, name: item.name });
  //   }
  //   return null;
  // });
  // console.log(resArr);
  return (
    <section className={styles.parameters}>
      <div className={styles.title}>Название параметра</div>
      <div className={styles.row}>
        <div className={styles.subtitle}>Менеджер тура</div>
        <MultiSelector allItems={listOfManagers} defaultItems={managers} getItems={handleManagers} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>Гид</div>
        <MultiSelector allItems={listOfGuidesTour} defaultItems={guids} getItems={handleGuids} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>Страна</div>
        <MultiSelector allItems={listOfCountriesTour} defaultItems={countries} getItems={handleCountries} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>Город</div>
        <MultiSelector allItems={listOfCitiesTour} defaultItems={cities} getItems={handleCities} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>Транспорт</div>
        <MultiSelector allItems={listOfTransportsTour} defaultItems={transport} getItems={handleTransports} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>Вид тура</div>
        <MultiSelector allItems={listOfTypeTour} defaultItems={tourTypes} getItems={handleTourTypes} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>Время путешествия</div>
        <MultiSelector allItems={listOfTimesTour} defaultItems={times} getItems={handleTimes} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>Направление</div>
        <MultiSelector allItems={listOfDirectionsTour} defaultItems={directions} getItems={handleDirections} />
      </div>

      <div className={styles.row}>
        <div className={styles.subtitle}>
          Теги
          <span className={styles.helper} onMouseMove={() => setViewHelp(true)} onMouseLeave={() => setViewHelp(false)}>
            <HelperIcon />
          </span>
          {viewHelp && (
            <div className={styles.helpBlock}>
              Вы можете установить один главный тег, который будет иметь приоритет при отображении на сайте
            </div>
          )}
        </div>
        <MultiSelector allItems={listOfTagsTour} defaultItems={tags} getItems={handleTags} customValue={Value} />
      </div>
    </section>
  );
};
