import { DocumentItemProps } from 'pages/Partners/components/PartnerFormPrivatePerson/DocumentsTab/DocumentsTab.types';
import { Documents } from './documents';
import { AccomType, ContractStatus, OrderStatus, PaymentStatus, ToursTemplates, TypeTour } from './enums';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';

export const listOfDocuments: DocumentItemProps[] = [
  { id: 1, name: Documents.PASSPORT_RF },
  { id: 2, name: Documents.INTERNATIONAL_PASSPORT_RF },
  { id: 3, name: Documents.BIRTH_CERTIFICATE },
  { id: 4, name: Documents.FOREIGN_DOCUMENT },
];

export const listOfOrderStatuses: string[] = [
  OrderStatus.NEW,
  OrderStatus.CONSULTATION,
  OrderStatus.SITE_RESERVE,
  OrderStatus.REFUSAL,
  OrderStatus.FINISHED,
  OrderStatus.CANCELLED,
  OrderStatus.PAYMENT_ACCEPTANCE,
  OrderStatus.RESERVATION_ACCEPTANCE,
];
export const listOfPaymentStatuses: string[] = [
  PaymentStatus.NO_PAID,
  PaymentStatus.AWAITING_PAYMENT,
  PaymentStatus.ADVANCE_RECEIVED,
  PaymentStatus.RESERVED,
  PaymentStatus.FAILURE,
  PaymentStatus.PAID,
];
export const listOfIdsOrderStatuses: { value: string; id: number }[] = [
  {
    id: 1,
    value: OrderStatus.NEW,
  },
  {
    id: 5,
    value: OrderStatus.REFUSAL,
  },
  {
    id: 6,
    value: OrderStatus.CANCELLED,
  },

  {
    id: 7,
    value: OrderStatus.SITE_RESERVE,
  },
  {
    id: 8,
    value: OrderStatus.CONSULTATION,
  },
  {
    id: 4,
    value: OrderStatus.FINISHED,
  },
  {
    id: 10,
    value: OrderStatus.PAYMENT_ACCEPTANCE,
  },
  {
    id: 11,
    value: OrderStatus.RESERVATION_ACCEPTANCE,
  },
];
export const listOfTypesPlacements: string[] = [
  AccomType.ONE,
  AccomType.TWO,
  AccomType.THREE,
  AccomType.FOUR,
  AccomType.FIVE,
  AccomType.SIX,
  AccomType.SEVEN,
];
export const listOfIdsTypePlacements: { value: string; id: number }[] = listOfTypesPlacements.map((i, key) => ({
  value: i,
  id: key + 1,
}));
export const listOfManagers: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Иванов И.И.' },
  { '@id': '2', id: 2, name: 'Петров СС' },
  { '@id': '3', id: 3, name: 'Сидоров' },
];

export const listOfManagersStr: string[] = ['Иванов И.И.', 'Петров СС', 'Сидоров'];

export const listOfGuidsTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Иванова Анна Сергеевна' },
  { '@id': '2', id: 2, name: 'Петрова Анна Сергеевна' },
];
export const listOfGuidsTourStr: string[] = ['Иванова Анна Сергеевна', 'Петрова Анна Сергеевна'];

export const listOfCountriesTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Россия' },
  { '@id': '2', id: 2, name: 'Беларусь' },
];
export const listOfCountriesTourStr: string[] = ['Россия', 'Беларусь'];

export const listOfCitiesTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Санкт-Петербург' },
  { '@id': '2', id: 2, name: 'Москва' },
];
export const listOfCitiesTourStr: string[] = ['Санкт-Петербург', 'Москва'];

export const listOfTransportsTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Ж/Д' },
  { '@id': '2', id: 2, name: 'Автобус' },
  { '@id': '3', id: 3, name: 'Авиа' },
];
export const listOfTransportsTourStr: string[] = ['Ж/Д', 'Автобус', 'Авиа'];

export const listOfTypeTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Активный отдых' },
  { '@id': '2', id: 2, name: 'Лечение и отдых' },
  { '@id': '3', id: 3, name: 'Экскурсионный' },
];
export const listOfTypeTourStr: string[] = ['Активный отдых', 'Лечение и отдых', 'Экскурсионный'];

export const listOfTimesTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: '8 марта' },
  { '@id': '2', id: 2, name: 'Весна' },
  { '@id': '3', id: 3, name: 'Март' },
  { '@id': '4', id: 4, name: 'Выходного дня' },
  { '@id': '5', id: 5, name: 'Новый год' },
  { '@id': '6', id: 6, name: 'Майские' },
];
export const listOfTimesTourStr: string[] = ['8 марта', 'Весна', 'Март', 'Выходного дня', 'Новый год', 'Майские'];

export const listOfDirectionsTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Народные промыслы России' },
  { '@id': '2', id: 2, name: 'Парки и дворцы' },
];
export const listOfDirectionsTourStr: string[] = ['Народные промыслы России', 'Парки и дворцы'];

export const listOfTagsTour: SelectMultiItemType[] = [
  { '@id': '1', id: 1, name: 'Туры акварели' },
  { '@id': '2', id: 2, name: 'Черная пятница' },
  { '@id': '3', id: 3, name: 'Золотая осень' },
  { '@id': '4', id: 4, name: 'Горящий тур' },
];
export const listOfTagsTourStr: string[] = ['Туры акварели', 'Черная пятница', 'Золотая осень', 'Горящий тур'];

export const listOfContracts: string[] = [
  ContractStatus.CONTRACT_AQUARELLE_TOUR_OPERATOR,
  ContractStatus.PARTNER_TOUR_AQUARELLE_AGENT,
  ContractStatus.PARTNER_TOUR_AQUARELLE_TOUR_OPERATOR,
  ContractStatus.ABROAD_AQUARELLE_AGENT,
  ContractStatus.PAID_RENDERING_OF_SERVICES_BELARUS,
  ContractStatus.SCORE,
  ContractStatus.BOOKING_CONFIRMATION,
  // ContractStatus.FOR_AGENCIES,
];

export const listOfPartners: string[] = [
  'Власова A.Е.',
  'ООО "Компания 1"',
  'Петренко Н.',
  'Власова П.Е.',
  'ООО "Компания 2"',
  'Петренко Н.E.',
  'Власова B.Е.',
  'ООО "Компания 3"',
  'Петренко Н.O.',
];

export const listOfDurationTour: string[] = ['2д/1н', '3д/2н', '7д/6н'];

export const listOfTourOperators: string[] = ['Акварель', 'ТариТур'];

export const listOfTourType: string[] = [TypeTour.ACTIVE_TOUR, TypeTour.CLOSE_TOUR];

export const listOfReportTypes: string[] = [
  'Отчет по продаже туров сводный',
  'Отчет по всем турам (начало тура в выбранный период)',
  'Отчет по отдельным менеджерам',
  'Отчет по туроператорам',
  'Отчет по агенту',
  'Отчет для электронной путевки',
];

export const listOfTourTemplates: string[] = [
  ToursTemplates.MULTIDAY,
  ToursTemplates.ONEDAY,
  ToursTemplates.NO_DATE,
  ToursTemplates.EVERYDAY,
];

export const nightCountsList = [
  { id: 1, value: '1 ночь' },
  { id: 2, value: '2 ночи' },
  { id: 3, value: '3 ночи' },
  { id: 4, value: '4 ночи' },
  { id: 5, value: '5 ночей' },
  { id: 6, value: '6 ночей' },
  { id: 7, value: '7 ночей' },
];

export const SalesCondition = [
  'не выбрано',
  'школьникам до 12 лет',
  'школьникам  7 лет - 13 лет',
  'школьникам до 14 лет на основном месте',
  'школьникам старше 14 лет - 18 лет',
  'школьникам до 16 лет',
  'детям старше 16 лет - 18 лет',
  'студентам',
  'мужчинам от 65 лет',
  'женщинам от 60 лет',
];
