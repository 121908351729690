import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';

import { HotelInfo, TourHotelInfo } from 'pages/Tours/Tours.types';
import { Input } from 'ui/Input';
import { Button } from 'ui/Button';
import { useAppDispatch } from 'store/hooks';
import { setIsEditedTour } from 'store/entities/tours/toursSlice';

import styles from './HotelTrip.module.scss';
import { HotelTable } from './HotelTable';

export type TripInfoProps = {
  trip?: TourHotelInfo | null;
  setTrip: (x: TourHotelInfo) => void;
};

export const HotelTrip: FC<TripInfoProps> = ({ trip, setTrip }) => {
  const dispatch = useAppDispatch();
  const [newTrip, setNewTrip] = useState<TourHotelInfo>();
  const [hotels, setHotels] = useState<HotelInfo[]>([]);
  //начальные значения для стандартных полей формы
  const initialValues: TourHotelInfo = {
    id: trip?.id || null,
    '@id': trip?.['@id'] || null,
    name: trip?.name || '',
    address: trip?.address || '',
    distance: trip?.distance || '',
    sale_percent: trip?.sale_percent === null ? '0' : trip?.sale_percent || '0',
    sale: trip?.sale === null ? '0' : trip?.sale || '0',
    agents_sale: trip?.agents_sale === null || trip?.agents_sale === undefined ? 0 : trip?.agents_sale,
    agents_sale_percent:
      trip?.agents_sale_percent === null || trip?.agents_sale_percent === undefined ? 0 : trip?.agents_sale_percent,
  };

  const formTrip = useForm({
    initialValues,
  });

  //если поездка меняется (изменяют извне), то переписываются заполненные значения в форме
  useEffect(() => {
    formTrip.setValues(trip || initialValues);
    setHotels(trip?.tour_hotel_placements || []);
  }, [trip]);

  //добавление/изменение отдельной поездки
  const handleAddTrip = (e: React.MouseEvent) => {
    e.preventDefault();

    dispatch(setIsEditedTour(true));

    const hotelsOut = hotels.map((hotel) => {
      if (hotel['@id']) {
        return hotel;
      } else {
        return {
          hotel: hotel.hotel,
          period: hotel.period,
          places_count: hotel.places_count,
          placement_type_string: hotel.placement_type_string,
          typeRoom: hotel.typeRoom,
          category: hotel.category,
          food_type_string: hotel.food_type_string,
          show_no_places: hotel.show_no_places,
          price1A: hotel.price1A,
          price2A: hotel.price2A,
          price3A: hotel.price3A,
          price1A1C: hotel.price1A1C,
          price1A2C: hotel.price1A2C,
          price2A1C: hotel.price2A1C,
          price2A2C: hotel.price2A2C,
        };
      }
    });

    if (formTrip.values.name !== null) {
      if (formTrip.values['@id'] !== '') {
        setNewTrip({
          ...formTrip.values,
          sale_percent: formTrip.values.sale_percent === null ? '0' : formTrip.values.sale_percent,
          sale: formTrip.values.sale === null ? '0' : formTrip.values.sale,
          agents_sale_percent: Number(formTrip.values.agents_sale_percent),
          agents_sale: Number(formTrip.values.agents_sale),
          tour_hotel_placements: hotelsOut,
        });
      } else {
        setNewTrip({
          name: formTrip.values.name,
          distance: formTrip.values.distance,
          address: formTrip.values.address,
          sale_percent: formTrip.values.sale_percent === null ? '0' : formTrip.values.sale_percent,
          sale: formTrip.values.sale === null ? '0' : formTrip.values.sale,
          agents_sale_percent: Number(formTrip.values.agents_sale_percent),
          agents_sale: Number(formTrip.values.agents_sale),
          tour_hotel_placements: hotelsOut,
        });
      }
    }
  };

  useEffect(() => {
    if (newTrip) {
      setTrip(newTrip);
    }
  }, [newTrip]);

  return (
    <>
      <form className={styles.form}>
        <div className={styles.groupParams}>
          <section className={styles.parameters}>
            <Input
              type="text"
              label="Гостиница"
              className={clsx(styles.input, styles.priceOneDay, styles.number)}
              {...formTrip.getInputProps('name')}
            />
            <Input
              type="text"
              label="Адрес"
              className={clsx(styles.input, styles.priceOneDay, styles.number)}
              {...formTrip.getInputProps('address')}
            />
            <Input
              type="text"
              label="Расстояние"
              className={clsx(styles.input, styles.priceOneDay, styles.number)}
              {...formTrip.getInputProps('distance')}
            />
            <Input
              type="text"
              label="Скидка %"
              {...formTrip.getInputProps('sale_percent')}
              onChange={(e: any) => {
                if (formTrip.values.sale != '0') {
                  formTrip.setFieldValue('sale', '');
                }
                if (e.target.value.match(/^\d+$/)) {
                  formTrip.setFieldValue('sale_percent', e.target.value);
                } else {
                  formTrip.setFieldValue(
                    'sale_percent',
                    e.target.value === '' ? e.target.value : formTrip.values.sale_percent
                  );
                }
              }}
              className={clsx(styles.input, styles.number, styles.sales)}
            />
            <Input
              type="text"
              label="Скидка руб."
              {...formTrip.getInputProps('sale')}
              onChange={(e: any) => {
                if (formTrip.values.sale_percent != '0') {
                  formTrip.setFieldValue('sale_percent', '');
                }
                if (e.target.value.match(/^\d+$/)) {
                  formTrip.setFieldValue('sale', e.target.value);
                } else {
                  formTrip.setFieldValue('sale', e.target.value === '' ? e.target.value : formTrip.values.sale);
                }
              }}
              className={clsx(styles.input, styles.number, styles.sales)}
            />
          </section>
          <section className={styles.parameters}>
            <Input
              type="text"
              label="Скидка % (для агентств)"
              {...formTrip.getInputProps('agents_sale_percent')}
              onChange={(e: any) => {
                if (formTrip.values.agents_sale != 0) {
                  formTrip.setFieldValue('agents_sale', 0);
                }
                if (e.target.value.match(/^\d+$/)) {
                  formTrip.setFieldValue('agents_sale_percent', e.target.value);
                } else {
                  formTrip.setFieldValue(
                    'agents_sale_percent',
                    e.target.value === '' ? e.target.value : formTrip.values.agents_sale_percent
                  );
                }
              }}
              className={clsx(styles.input, styles.number, styles.agent_sales)}
            />
            <Input
              type="text"
              label="Скидка руб.  (для агентств)"
              {...formTrip.getInputProps('agents_sale')}
              onChange={(e: any) => {
                if (formTrip.values.agents_sale_percent != 0) {
                  formTrip.setFieldValue('agents_sale_percent', 0);
                }
                if (e.target.value.match(/^\d+$/)) {
                  formTrip.setFieldValue('agents_sale', e.target.value);
                } else {
                  formTrip.setFieldValue(
                    'agents_sale',
                    e.target.value === '' ? e.target.value : formTrip.values.agents_sale
                  );
                }
              }}
              className={clsx(styles.input, styles.number, styles.agent_sales)}
            />
          </section>
        </div>
      </form>

      <>
        <HotelTable hotels={hotels} setHotels={setHotels} />
        <div className={styles.fixedContainer}>
          <Button onClick={handleAddTrip} className={styles.addTrip}>
            {trip?.id === undefined || trip?.id === null ? 'Добавить поездку' : 'Изменить поездку'}
          </Button>
        </div>
      </>
    </>
  );
};
