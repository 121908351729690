import React, { useEffect } from 'react';
import ReportsTop from './ReportsTop';
import ReportsTable from './ReportsTable';

import partnerStyles from '../Partners/Partners.module.scss';
import { useAppDispatch } from 'store/hooks';
import { getUsers } from 'store/action-creators/users';
import { getTourOperators } from 'store/action-creators/tours';
import { getReports } from 'store/action-creators/reports';
import { getPartners } from 'store/action-creators/partners';

export const ReportsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getReports());
    dispatch(getUsers({ page: 1 }));
    dispatch(getTourOperators());
    dispatch(
      getPartners({
        page: 1,
        itemsPerPage: 100,
        type: '/api/v1/contact-types/2',
      })
    );
  }, []);

  return (
    <section className={partnerStyles.partners}>
      <ReportsTop />
      <ReportsTable />
    </section>
  );
};
