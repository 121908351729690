import React, { FC, useEffect, useState } from 'react';
import ModalContext from './ModalContext';


export const ModalProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [active, setActive] = useState<boolean>(true);

  useEffect(()=>{
    if(!active){
      setActive(true);
    }
  },[active])

  return <ModalContext.Provider value={{ active, setActive }}>
    {children}
  </ModalContext.Provider>;
};

export default ModalProvider;