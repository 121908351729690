import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Loader } from '@mantine/core';
import moment from 'moment/moment';
import { Pagination } from '@mantine/core';
import { CellWithSorting } from '../../../components/CellWithSorting';
import { TourShortInfo } from '../Tours.types';
import { RouteNames } from 'core/constants/routeNames';
import { toursWithTripsColumns } from './data';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetCurrentTour,
  resetPreSaveTour,
  setParamsPage,
  setParamsSortById,
  setParamsSortByName,
} from 'store/entities/tours/toursSlice';
import { resetPreSaveTour2 } from 'store/entities/tours/preSaveTourSlice';

import styles from './ToursWithTripsTable.module.scss';

export const ToursWithTripsTable: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tours, countTours, isLoading, isSuccess, params } = useAppSelector((state) => state.tours);
  const [toursList, setToursList] = useState<TourShortInfo[]>([]);
  const [countPages, setCountPages] = useState<number>(1);
  const [scroll, setScroll] = useState(false);

  // для первой страницы устанавливаем первую загрузку данных,
  // для остальных подтягиваем дополнительные, полученные при скролле
  // небольшая имитация первой загрузки страницы
  useEffect(() => {
    // if (params.currentPage === 1) {
    setToursList(tours);
    // } else {
    //   setToursList((pre) => [...pre, ...tours]);
    // }
  }, [tours]);

  //когда загружаем страницу со списком туров, обнуляем данные в предсохраненном туре и туре с сервера.
  //тогда при клике на отдельный тур сразу подтягивается нужная информация, избегаем мелькания старой и новой.
  useEffect(() => {
    dispatch(resetCurrentTour());
    dispatch(resetPreSaveTour());
    dispatch(resetPreSaveTour2());
  }, []);

  // при клике на строку таблицы, переходим на страницу редактирования тура
  const handleClickRow = (item: TourShortInfo) => {
    navigate(`${RouteNames.TOURS}/${item.idTour}-${item.idTrip}`);
  };

  // обработка клика на сортировку по столбцам
  const handleSortByColumn = (sort: 'asc' | 'desc', column: string) => {
    if (column === 'id') {
      dispatch(setParamsSortById(sort));
      dispatch(setParamsPage(1));
    }
    if (column === 'title') {
      dispatch(setParamsSortByName(sort));
      dispatch(setParamsPage(1));
    }
  };

  // высчитываем кол-во страниц в зависимости от кол-ва элементов и числа элементов на странице
  useEffect(() => {
    setCountPages(Math.ceil(countTours / params.itemsPerPage));
  }, [countTours, params.itemsPerPage]);

  // // следим за скроллом. если достигаем конца страницы устанавливаем его в true
  // useEffect(() => {
  //   const handleScroll = (e: any) => {
  //     const scrollHeight = e.target.documentElement.scrollHeight;
  //     const currentHeight = e.target.documentElement.scrollTop + window.innerHeight;
  //     setScroll(false);
  //     if (currentHeight + 10 >= scrollHeight && params.currentPage <= countPages) {
  //       setScroll(true);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [params.currentPage]);

  // // когда достигли конца страницы, увеличиваем счетчик страниц на 1
  // useEffect(() => {
  //   if (scroll && params.currentPage < countPages) {
  //     dispatch(setParamsPage(params.currentPage + 1));
  //   }
  // }, [scroll]);

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.table}>
        <div className={styles.rows}>
          {toursWithTripsColumns.map((column) => (
            <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
              {column.sorted ? (
                <CellWithSorting column={column} setColumnSort={handleSortByColumn} sorting={column.sorted} />
              ) : (
                <span>{column.name}</span>
              )}
            </div>
          ))}
        </div>
        {isLoading === 'GET_TOURS' && (
          <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
        )}
        <div>
          {isLoading !== 'GET_TOURS' &&
            isSuccess === 'GET_TOURS' &&
            toursList.length > 0 &&
            toursList.map((tour) => (
              <div key={tour.idTrip} className={styles.row} onClick={() => handleClickRow(tour)}>
                <div className={clsx(styles.cell, styles.id)}>{tour.idTrip}</div>
                <div className={clsx(styles.cell, styles.title)}>
                  {tour.system_name ? tour.system_name : '-'}
                  <span>{tour.title}</span>
                </div>
                <div className={clsx(styles.cell, styles.bookingDate)}>{`${moment(tour.dateFrom).format(
                  'DD-MM-YYYY'
                )} - ${moment(tour.dateTo).format('DD-MM-YYYY')}`}</div>
                <div className={clsx(styles.cell, styles.tourOperator)}>{tour.tourOperator}</div>
                <div className={clsx(styles.cell, styles.route)}>{tour.route}</div>
              </div>
            ))}
          {isSuccess === 'GET_TOURS' && toursList.length === 0 && (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
        </div>
        {isLoading !== 'GET_TOURS' && isSuccess === 'GET_TOURS' && toursList.length > 0 && (
          <div className={styles.tableFooter}>
            <div className={styles.count}>Всего записей — {countTours}</div>
            <Pagination
              total={countPages}
              value={params.currentPage}
              onChange={(val) => dispatch(setParamsPage(val))}
            />
          </div>
        )}
      </div>
    </div>
  );
};
