import React, { FC } from 'react';

import clsx from 'clsx';

import { toursColumns } from './data';

import styles from './CreatedDocumentsHistory.module.scss';
import { getDate } from 'core/utils/getDate';
import { RemoveIcon } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteOrderDocument, sendOrderDocument, uploadDocument } from 'store/action-creators/orderDocument';
import { Button } from 'ui/Button';

export const CreatedDocumentsHistory: FC<{
  orderId?: string;
  documents: {
    id: number;
    title: string;
    type: string;
    created_at: Date | string;
    sent_by_email: string | null;
    link: string;
  }[];
  setDocuments: (
    x: {
      id: number;
      title: string;
      type: string;
      created_at: Date | string;
      link: string;
      sent_by_email: string | null;
    }[]
  ) => void;
}> = ({ documents, orderId, setDocuments }) => {
  const dispatch = useAppDispatch();
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const handleDelete = (id: number) => {
    const updatedArr = documents.filter((el) => el.id !== id);
    setDocuments(updatedArr);
    if (id) {
      dispatch(deleteOrderDocument(id));
    }
  };

  const handleAddFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles);
    const data = filesFromInput.map(async (el) => {
      const res = dispatch(
        uploadDocument({
          order_id: String(orderId),
          file: el,
        })
      ).then((res) => res.payload);
      return res;
    });
    const arr = await Promise.all(data);
    const updatedArr = arr.map((el) => {
      return {
        id: Number(el.result),
        title: el.link.split('/').at(-1),
        type: 'custom',
        created_at: new Date().toISOString(),
        link: el.link,
        sent_by_email: null,
      };
    });
    setDocuments([...documents, ...updatedArr]);

    e.target.value = '';
  };

  const handleSendDoc = (document: {
    id: number;
    title: string;
    type: string;
    created_at: Date | string;
    link: string;
    sent_by_email: string | null;
  }) => {
    dispatch(
      sendOrderDocument({
        id: String(orderId),
        document: `/api/v1/order-documents/${document.id}`,
      })
    );

    const filtredArr = documents.map((el) =>
      el.id === document.id ? { ...document, sent_by_email: new Date().toString() } : el
    );
    setDocuments(filtredArr);
  };

  const handleGetAllDocs = () => {
    dispatch(
      sendOrderDocument({
        id: String(orderId),
        all: true,
      })
    );
    const filtredArr = documents.map((el) => {
      return { ...el, sent_by_email: new Date().toString() };
    });
    setDocuments(filtredArr);
  };

  return (
    <div className={styles.tableWrapper}>
      {orderId && (
        <Button
          className={!preSaveOrder.basicInfo?.email ? styles.disabledBtn : ''}
          disabled={!preSaveOrder.basicInfo?.email}
          onClick={() => handleGetAllDocs()}
        >
          Отправить все документы
        </Button>
      )}
      <div className={styles.table}>
        <div className={styles.rows}>
          {toursColumns.map((column) => (
            <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
              <span>{column.name}</span>
            </div>
          ))}
        </div>
        <div>
          {documents.length > 0 ? (
            documents.map((document, idx) => (
              <div key={document.id} className={styles.row}>
                <div className={clsx(styles.cell, styles.id)}>{idx + 1}</div>
                <div className={clsx(styles.cell, styles.title)}>
                  <a
                    className={styles.link}
                    rel="noreferrer"
                    href={process.env.REACT_APP_BASE_API_URL + document.link}
                    target="_blank"
                  >
                    {document.title}
                  </a>
                </div>
                <div className={clsx(styles.cell, styles.documentType)}>{document.type}</div>
                <div className={clsx(styles.cell, styles.date)}>{getDate(new Date(document.created_at))}</div>
                <div className={clsx(styles.cell, styles.lk)}>
                  {document.sent_by_email ? (
                    <Button
                      className={styles.disabledBtn}
                      onClick={() => console.log(document)}
                      disabled
                    >{`Отправлено ${getDate(new Date(document.sent_by_email))}`}</Button>
                  ) : (
                    <Button
                      className={!preSaveOrder.basicInfo?.email ? styles.disabledBtn : ''}
                      disabled={!preSaveOrder.basicInfo?.email}
                      onClick={() => handleSendDoc(document)}
                    >
                      Отправить
                    </Button>
                  )}
                </div>
                <div className={clsx(styles.cell, styles.remove)}>
                  <span onClick={() => handleDelete(document.id)}>
                    <RemoveIcon fill="#131b21" width={16} height={16} />
                  </span>
                </div>
              </div>
            ))
          ) : (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
        </div>
      </div>
      {orderId && (
        <>
          <label htmlFor="file" className={styles.addFileLabel}>
            + Загрузить документы
          </label>
          <input
            type="file"
            id="file"
            name="file"
            accept="application/pdf,application/msword,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.doc, .docx"
            onChange={(e) => handleAddFiles(e)}
            className={styles.addFile}
            multiple
          />
        </>
      )}
    </div>
  );
};
