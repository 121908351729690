export const getPaymentMethodEnToRu = (method: string) => {
  switch (method) {
    case 'cash':
      return 'Наличные';
    case 'cashless':
      return 'Безналичная оплата (юр. лица)';
    case 'terminal':
      return 'Оплата по терминалу';
    case 'link':
      return 'Оплата по ссылке';
    case 'qr':
      return 'Оплата по QR-коду';
    default:
      return '';
  }
};
export const getPaymentMethodRutoEn = (method: string) => {
  switch (method) {
    case 'Наличные':
      return 'cash';
    case 'Безналичная оплата (юр. лица)':
      return 'cashless';
    case 'Оплата по терминалу':
      return 'terminal';
    case 'Оплата по ссылке':
      return 'link';
    case 'Оплата по QR-коду':
      return 'qr';
    default:
      return '';
  }
};
export const getPaymentTypeRuToEn = (type: string) => {
  switch (type) {
    case 'Аванс':
      return 'prepay';
    case 'Доплата':
      return 'postpay';
    case 'Полная оплата':
      return 'fullpay';

    default:
      return '';
  }
};
export const getPaymentTypeEnToRu = (type: string) => {
  switch (type) {
    case 'prepay':
      return 'Аванс';
    case 'postpay':
      return 'Доплата';
    case 'fullpay':
      return 'Полная оплата';

    default:
      return '';
  }
};
export const getPaymentMethodForLink = (type: string) => {
  switch (type) {
    case 'link':
      return 'Ссылка';
    case 'qr':
      return 'QR';
    case 'preAuthLink':
      return 'Ссылка для бронирования средств';
    default:
      return '';
  }
};
