import React, { FC } from 'react';
import styles from '../Request.module.scss';
import { ISiteRequest } from 'services/orders/orderApi.service';
import { getFormType } from 'core/utils/getFormType';
import { datediff, getDate, getTime, getTimeTzNoLess } from 'core/utils/getDate';
export const TourSelection: FC<{ request: ISiteRequest }> = ({ request }) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.requestType}>
          <div className={styles.requestType_title}>
            <p>Форма связи:</p>
            <span>{getFormType(request.form_type)}</span>
          </div>
          <div className={styles.dateTimeBlock}>
            <div className={styles.requestType_title}>
              <p>Дата оформления заявки:</p>
              <span>{getDate(new Date(request.created_at))}</span>
            </div>
            <div className={styles.requestType_title}>
              <p>Время оформления заявки:</p>
              <span>{getTimeTzNoLess(new Date(request.created_at))}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.requestInfo}>
          <h2>Время путешествия</h2>
          <div className={styles.dateTimeBlock}>
            <div className={styles.requestInfo_title}>
              <p>Период отправления:</p>
              <span>{`${getDate(new Date(request.date_from))} - ${getDate(new Date(request.date_to))}`}</span>
            </div>
            <div className={styles.requestInfo_title}>
              <p>Количество дней:</p>
              <span>{datediff(new Date(request.date_from), new Date(request.date_to))}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.requestInfo}>
          <h2>Пожелания к путешествию</h2>
          <div className={styles.columnBlock}>
            <div className={styles.column}>
              {request.country && <p>Страна:</p>}
              {request.location && <p>Локация:</p>}
              {request.resort_type && <p>Тип отдыха:</p>}
              {request.additional_info && <p>Дополнительные пожелания:</p>}
            </div>
            <div className={styles.column}>
              <span>{request.country}</span>
              <span>{request.location}</span>
              <span>{request.resort_type}</span>
              <span>{request.additional_info}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.requestInfo}>
          <h2>Туристы: {request.adults_count}</h2>
          {/* <div className={styles.dateTimeBlock}>
            <div className={styles.requestInfo_title}>
              <p>Взрослых:</p>
              <span>7</span>
            </div>
            <div className={styles.requestInfo_title}>
              <p>Детей:</p>
              <span>7</span>
            </div>
          </div> */}
        </div>
      </div>
      {request.call_time_from && (
        <div className={styles.row}>
          <div className={styles.requestInfo}>
            <h2>Период звонка</h2>
            <div className={styles.dateTimeBlock}>
              {/* <div className={styles.requestInfo_title}>
              <p>Дата звонка:</p>
              <span>21.09.2023</span>
            </div> */}

              <div className={styles.requestInfo_title}>
                <p>Время звонка:</p>
                <span>{`c ${getTimeTzNoLess(new Date(request.call_time_from))}`}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {request.comment && (
        <div className={styles.row}>
          <div className={styles.requestInfo}>
            <h2>Дополнительные комментарии к заявке</h2>
            <p>{request.comment}</p>
          </div>
        </div>
      )}
    </div>
  );
};
