import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import {
  BirthCertificateType,
  DocumentProps,
  ForeignDocumentType,
  InterPassportType,
  PassportRfType,
} from './Document.types';
import { Documents } from 'core/constants/documents';
import { PassportRf } from './components/PassportRF';
import { RemoveIcon } from 'components/icons';
import { InterPassport } from './components/InterPassport';
import { BirthCertificate } from './components/BirthCertificate';
import { ForeignDocument } from './components/ForeignDocument';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { removeDocument, setMainDocument } from 'store/entities/partners/partnersSlice';

import styles from './Document.module.scss';
import { removePartnerDocument } from '../../store/action-creators/partners';
import { ModalConfirm } from '../../pages/Tours/components/TourForm/ModalConfirm';

export const Document: FC<DocumentProps> = ({
  type,
  data,
  setData,
  isOpened,
  markDirty = () => void 1,
  onRemove = () => void 1,
}) => {
  const [opened, setOpened] = useState(isOpened);
  const refRemove = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { preSavePrivatePerson } = useAppSelector((state) => state.partners);
  const [typeDoc, setTypeDoc] = useState<string>();

  const remove = () => {
    switch (type) {
      case Documents.PASSPORT_RF:
        dispatch(removeDocument('passport'));
        break;
      case Documents.INTERNATIONAL_PASSPORT_RF:
        dispatch(removeDocument('interPassport'));
        break;
      case Documents.BIRTH_CERTIFICATE:
        dispatch(removeDocument('birthCertificate'));
        break;
      case Documents.FOREIGN_DOCUMENT:
        dispatch(removeDocument('foreignDocument'));
        break;
      default:
        throw new Error('Unreachable statement [remove]');
    }
    if (data?.id) dispatch(removePartnerDocument({ id: data.id.toString() as string }));
    dispatch(setMainDocument(null));
    onRemove();
    markDirty();
  };

  //устанавливаем тип документа для отправки на сервер
  const setDocActive = (e: React.MouseEvent) => {
    e.stopPropagation();

    switch (type) {
      case Documents.PASSPORT_RF:
        dispatch(setMainDocument('contact_passport'));
        break;
      case Documents.INTERNATIONAL_PASSPORT_RF:
        dispatch(setMainDocument('contact_foreign_passport'));
        break;
      case Documents.BIRTH_CERTIFICATE:
        dispatch(setMainDocument('contact_birth_certificate'));
        break;
      case Documents.FOREIGN_DOCUMENT:
        dispatch(setMainDocument('contact_foreign_document'));
        break;
      default:
        break;
    }
    // markDirty();
  };

  //при первой загрузке устанавливаем тип документа с которым будем сравнивать ответ с сервера
  useEffect(() => {
    switch (type) {
      case Documents.PASSPORT_RF:
        setTypeDoc('contact_passport');
        break;
      case Documents.INTERNATIONAL_PASSPORT_RF:
        setTypeDoc('contact_foreign_passport');
        break;
      case Documents.BIRTH_CERTIFICATE:
        setTypeDoc('contact_birth_certificate');
        break;
      case Documents.FOREIGN_DOCUMENT:
        setTypeDoc('contact_foreign_document');
        break;
      default:
        break;
    }
  }, []);

  //собираем данные из конкретного документа
  const setDocument = (info: PassportRfType | InterPassportType | BirthCertificateType | ForeignDocumentType) => {
    if (setData) {
      switch (type) {
        case Documents.PASSPORT_RF:
          setData(info as PassportRfType);
          break;
        case Documents.INTERNATIONAL_PASSPORT_RF:
          setData(info as InterPassportType);
          break;
        case Documents.BIRTH_CERTIFICATE:
          setData(info as BirthCertificateType);
          break;
        case Documents.FOREIGN_DOCUMENT:
          setData(info as ForeignDocumentType);
          break;
        default:
          break;
      }
    }
  };

  return (
    <article className={styles.document}>
      <div className={clsx(styles.top, opened && styles.top_opened)} onClick={() => setOpened((prev) => !prev)}>
        <h3 className={styles.title}>{type}</h3>
        <div className={styles.controls}>
          <button
            className={clsx(
              styles.notChecked,
              typeDoc === preSavePrivatePerson.documents.mainDocument && styles.checked
            )}
            onClick={(event: React.MouseEvent) => setDocActive(event)}
          ></button>
          <button
            ref={refRemove}
            className={styles.remove}
            onClick={() => {
              setIsModalOpen(true);
              setOpened((prev) => !prev);
            }}
          >
            <RemoveIcon width={15} height={15} />
          </button>
        </div>
      </div>
      <ModalConfirm
        isOpen={isModalOpen}
        text={`удалить ${type}`}
        handleYes={() => {
          remove();
        }}
        handleNo={() => {
          setIsModalOpen(false);
        }}
      />
      {opened && (
        <div className={styles.content}>
          {type === Documents.PASSPORT_RF && <PassportRf passport={data as PassportRfType} setData={setDocument} />}
          {type === Documents.INTERNATIONAL_PASSPORT_RF && (
            <InterPassport passport={data as InterPassportType} setData={setDocument} />
          )}
          {type === Documents.BIRTH_CERTIFICATE && (
            <BirthCertificate passport={data as BirthCertificateType} setData={setDocument} />
          )}
          {type === Documents.FOREIGN_DOCUMENT && (
            <ForeignDocument passport={data as ForeignDocumentType} setData={setDocument} />
          )}
        </div>
      )}
    </article>
  );
};
