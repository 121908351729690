import React, { FC, useEffect, useRef, useState } from 'react';

import { CellWithSortingType } from './CellWithSorting.types';

import styles from './CellWithSorting.module.scss';
import { clsx } from '@mantine/styles';

export const CellWithSorting: FC<CellWithSortingType> = ({ column, sorting: sortingProps, setColumnSort }) => {
  const [className, setClassName] = useState<string>(styles.cell);
  let sorting = 'asc';
  switch (typeof sortingProps) {
    case 'boolean':
      if (!sortingProps)
        sorting = 'desc';
      break;
    case 'string':
      sorting = sortingProps;
      break;
    default:
      throw new Error('Unreachable statement in CellWithSorting');
  }
  const [sorted, setSorted] = useState<'asc' | 'desc'>(sorting as 'asc' | 'desc');
  const [sortBy, setSortedBy] = useState<string>('');

  const handleCellClick = (cell: string) => {
    if (sorted === 'asc') {
      setSorted('desc');
    } else {
      setSorted('asc');
    }
    setSortedBy(cell);
  };

  const dataRef = useRef<string>();
  useEffect(() => {
    if (!dataRef.current) {
      dataRef.current = `-${sorted}${sortBy}`;
    }
    if (dataRef.current !== `-${sorted}${sortBy}` && setColumnSort) {
      setColumnSort(sorted, sortBy);
    }
    dataRef.current = `-${sorted}${sortBy}`;
  }, [sorted, sortBy]);

  useEffect(() => {
    setClassName(sorted === 'desc' ? styles.cell : clsx(styles.cell, styles.cellUp));
  }, [sorted]);

  return (
    <span onClick={() => handleCellClick(column.id)} className={className}>
      {column.name}
    </span>
  );
};
