import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddAlbum,
  Album,
  Photo,
  PhotoData,
  SavePhoto,
  UpdatedAlbum,
  addAlbumAPI,
  addPhotoAPI,
  albumAPI,
  albumsAPI,
  createAlbumTagsAPI,
  createTagsAPI,
  deleteAlbumAPI,
  deleteAlbumWithPhotoAPI,
  deletePhotoAPI,
  getAlbumTagsAPI,
  getTagsAPI,
  photoAPI,
  photosAPI,
  updateAlbumAPI,
  updatePhotoAPI,
} from 'services/gallery/galleryApi.service';

// получения списка тегов галереи
export const getTags = createAsyncThunk('gallery/getTagsAPI', async (_, thunkAPI) => {
  try {
    const response = await getTagsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
export const addPhotoTag = createAsyncThunk('gallery/addPhotoTag', async (arg: string, thunkAPI) => {
  try {
    const response = await createTagsAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
export const addAlbumTag = createAsyncThunk('gallery/addAlbumTag', async (arg: string, thunkAPI) => {
  try {
    const response = await createAlbumTagsAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
// получения списка тегов галереи
export const getAlbumTags = createAsyncThunk('gallery/getAlbumTags', async (_, thunkAPI) => {
  try {
    const response = await getAlbumTagsAPI();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка фото
export const getPhotos = createAsyncThunk('gallery/getPhotos', async (arg: PhotoData, thunkAPI) => {
  try {
    const response = await photosAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения списка фото
export const getAlbums = createAsyncThunk('gallery/getAlbums', async (arg: PhotoData, thunkAPI) => {
  try {
    const response = await albumsAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения  фото
export const getPhoto = createAsyncThunk('gallery/getPhoto', async (arg: number, thunkAPI) => {
  try {
    const response = await photoAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// получения  фото
export const getAlbum = createAsyncThunk('gallery/getAlbum', async (arg: number, thunkAPI) => {
  try {
    const response = await albumAPI(arg);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

// сохранение  фото
export const savePhoto = createAsyncThunk('gallery/savePhoto', async (arg: SavePhoto[], thunkAPI) => {
  try {
    const saveImages = arg.map((el) => {
      return addPhotoAPI(el.file);
    });
    return await Promise.all(saveImages).then(async (data) => {
      const res = arg.map((item) => {
        data.map((el) => {
          console.log(el.data);
          return updatePhotoAPI({
            id: Number(el.data.id),
            alt: item.alt,
            title: item.title,
            photo_tags: item.photo_tags.map((tag) => (tag['@id'] ? tag['@id'] : `/api/v1/photo-tags/${tag.id}`)),
            is_license: item.is_license,
          });
        });
      });

      return res;
    });
    // return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
export const deletePhoto = createAsyncThunk('gallery/deletePhoto', async (arg: string, thunkAPI) => {
  try {
    const response = await deletePhotoAPI(arg);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const updatePhoto = createAsyncThunk('gallery/updatePhoto', async (arg: Partial<Photo>, thunkAPI) => {
  try {
    const response = await updatePhotoAPI(arg);
    console.log('SUCCESS UPDATE', response.data);
    return response.data;
  } catch (error) {
    console.log('ERROR UPDATE', error);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const updateAlbum = createAsyncThunk('gallery/updateAlbum', async (arg: Partial<UpdatedAlbum>, thunkAPI) => {
  try {
    const response = await updateAlbumAPI(arg);
    console.log('SUCCESS UPDATE', response.data);
    return response.data;
  } catch (error) {
    console.log('ERROR UPDATE', error);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const deleteAlbum = createAsyncThunk('gallery/deleteAlbum', async (arg: number, thunkAPI) => {
  try {
    const response = await deleteAlbumAPI(arg);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const deleteAlbumWithPhoto = createAsyncThunk('gallery/deleteAlbumWithPhoto', async (arg: number, thunkAPI) => {
  try {
    const response = await deleteAlbumWithPhotoAPI(arg);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});

export const addAlbum = createAsyncThunk('gallery/addAlbum', async (arg: AddAlbum, thunkAPI) => {
  try {
    const response = await addAlbumAPI(arg);

    return response.data;
  } catch (error: any) {
    console.log(error.response);
    return thunkAPI.rejectWithValue((error as Error).message);
  }
});
