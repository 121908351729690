import React, { FC } from 'react';
import { ImageFileType } from './ChatFile.type';

import styles from './ChatFile.module.scss';
import { CloseIcon } from 'components/icons';
import { DownLoad } from 'components/icons/Download';
import clsx from 'clsx';

const ChatFile: FC<ImageFileType> = ({ name, className, path = '', deleteFile }) => {
  const handleDelete = (e: React.MouseEvent, name: string) => {
    e.preventDefault();
    deleteFile && deleteFile(name);
  };
  return (
    <article className={clsx(styles.file, className)}>
      <div className={styles.imgWrapper}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#70A4CB" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6665 28.3327H18.3332C15.1907 28.3327 13.619 28.3327 12.6432 27.356C11.6665 26.3802 11.6665 24.8085 11.6665 21.666V18.3327C11.6665 15.1902 11.6665 13.6185 12.6432 12.6427C13.619 11.666 15.199 11.666 18.3582 11.666C18.8632 11.666 19.2673 11.666 19.6082 11.6802C19.5973 11.7468 19.5915 11.8143 19.5915 11.8835L19.5832 14.2452C19.5832 15.1593 19.5832 15.9677 19.6707 16.6185C19.7657 17.3243 19.9832 18.0302 20.5598 18.6068C21.1348 19.1818 21.8415 19.4002 22.5473 19.4952C23.1982 19.5827 24.0065 19.5827 24.9207 19.5827H28.2973C28.3332 20.0277 28.3332 20.5743 28.3332 21.3018V21.666C28.3332 24.8085 28.3332 26.3802 27.3565 27.356C26.3807 28.3327 24.809 28.3327 21.6665 28.3327Z"
            fill="white"
          />
          <path
            d="M26.1268 16.3478L22.8268 13.3787C21.8877 12.5328 21.4185 12.1095 20.841 11.8887L20.8335 14.167C20.8335 16.1312 20.8335 17.1137 21.4435 17.7237C22.0535 18.3337 23.036 18.3337 25.0002 18.3337H27.9835C27.6818 17.747 27.1402 17.2603 26.1268 16.3478Z"
            fill="white"
          />
        </svg>
      </div>

      <div className={styles.name}>{name}</div>

      {path !== '' && (
        <a
          href={`${process.env.REACT_APP_BASE_API_URL}${path}`}
          download={name}
          target="_blank"
          rel="noreferrer"
          className={styles.icon}
        >
          <DownLoad />
        </a>
      )}
      {deleteFile && (
        <button className={styles.icon} onClick={(e: React.MouseEvent) => handleDelete(e, name)}>
          <CloseIcon width={14} height={14} fill={'rgba(91, 101, 114, 0.75)'} />
        </button>
      )}
    </article>
  );
};

export default ChatFile;
