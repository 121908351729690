import React, { FC, useEffect, useRef, useState } from 'react';
import { notifications } from '@mantine/notifications';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styles from './TextEditorOrder.module.scss';
import ImageFile from './ImageFile';
import { TextEditorOrderProps } from './TextEditorOrder.type';
import { Button } from 'ui/Button';
import { TextEditor } from 'ui/TextEditor';

export const TextEditorOrder: FC<TextEditorOrderProps> = ({
  data,
  setData,
  addNewNote,
  placeholder,
  isAddButtonActive = false,
  isCanEdit = false,
}) => {
  const [convertedText, setConvertedText] = useState(data?.description || '');
  const modules = {
    toolbar: [['bold', 'italic', 'underline', { list: 'bullet' }]],
  };
  const COUNT_FILES = 2;

  const [files, setFiles] = useState<File[]>(data?.files || []);

  const handleAddFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const addedFiles = e.target.files as FileList;
    const filesFromInput = Array.from(addedFiles).slice(0, COUNT_FILES);
    setFiles(filesFromInput as File[]);
    e.target.value = '';
  };

  const handleDeleteFile = (name: string) => {
    setFiles(files.filter((file) => file.name !== name));
  };

  const handleAddButton = () => {
    if (isAddButtonActive) {
      addNewNote({ description: convertedText, files: files });
      setFiles([]);
      setConvertedText(' ');
    } else {
      notifications.show({
        title: 'Внимание!',
        color: 'red',
        message: 'Перед добавлением примечаний нужно сохранить заказ',
      });
    }
  };

  useEffect(() => {
    setData({ description: convertedText, files: files });
  }, [convertedText, files]);

  const handleNewText = (text: string) => {
    setConvertedText(text);
  };
  // console.log(files);
  return (
    <div className={styles.wrapper}>
      <TextEditor isCanEdit={isCanEdit} data={convertedText} setData={handleNewText} placeholder={placeholder} />

      <label htmlFor="file" className={styles.addFileLabel}>
        + Прикрепить файл
      </label>
      <input
        type="file"
        id="file"
        name="file"
        accept="image/*,image/jpg,image/png,image/gif,application/pdf,application/msword,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.doc, .docx"
        onChange={(e) => handleAddFiles(e)}
        className={styles.addFile}
        multiple
        disabled={isCanEdit}
      />

      {files.length > 0 && (
        <div className={styles.files}>
          {files.map((file) => (
            <ImageFile
              key={file.name}
              preview={file.type.includes('image/') ? URL.createObjectURL(file as Blob) : ''}
              name={file.name}
              size={file.size}
              type={file.type}
              deleteFile={() => handleDeleteFile(file.name)}
            />
          ))}
        </div>
      )}

      <Button
        className={isCanEdit ? styles.disabledBtn : ''}
        label="Добавить"
        onClick={handleAddButton}
        width="138px"
        disabled={isCanEdit}
      />
    </div>
  );
};
