import axiosInstance from 'services/axiosInstance';
import {
  AddOrderActionData,
  OrderActionResponse,
  OrderActionsData,
  OrderActionsResponse,
} from './OrderActinResponse.model';


export const addOrderActionAPI = async (data?: AddOrderActionData) => {
  return await axiosInstance.post<OrderActionResponse[]>('/api/v1/order-actions', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
};

export const orderActionsAPI = async ({ tour_order, is_system }: OrderActionsData) => {
  return await axiosInstance.get<OrderActionsResponse>('/api/v1/order-actions', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      tour_order: tour_order,
      is_system: is_system
    },
  });
};

export const addAttachmentAPI = async ({ file }: { file: File }) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  return await axiosInstance.post<{ path: string }>('/api/v1/upload-attachment', bodyFormData);
};