import { BackIcon } from 'components/icons';
import React, { FC } from 'react';
import styles from './NavbarButton.module.scss';
import clsx from 'clsx';

type NavbarButtonProps = {
  opened: boolean;
  setOpened: () => void;
};
const NavbarButton: FC<NavbarButtonProps> = ({ opened, setOpened }) => {
  return (
    <button className={clsx(styles.button, !opened && styles.buttonBack)} onClick={setOpened}>
      <BackIcon width={22} height={22} />
    </button>
  );
};

export default NavbarButton;
