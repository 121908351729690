import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';
import {
  Client1A1CIcon,
  Client1A2CIcon,
  Client1AIcon,
  Client2A1CIcon,
  Client2A2CIcon,
  Client2AIcon,
  Client3AIcon,
} from 'components/icons';

export const hotelColumns: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Дата',
  },
  {
    id: 'typeRoom',
    name: 'Тип номера',
  },
  {
    id: 'category',
    name: 'Категория',
  },
  {
    id: 'food_type_string',
    name: 'Питание',
  },
  {
    id: 'places_count',
    name: 'Кол-во номеров',
  },
  {
    id: 'checkbox',
    name: '',
  },
  {
    id: 'saled',
    name: 'Куплено',
  },
  {
    id: 'placements_type',
    name: 'Вид размещения',
  },
  {
    id: 'price1A',
    name: '2дн/1н',
  },
  {
    id: 'price2A',
    name: '3дн/2н',
  },
  {
    id: 'price3A',
    name: '4дн/3н',
  },
  {
    id: 'price1A1C',
    name: '5дн/4н',
  },
  {
    id: 'price1A2C',
    name: '6дн/5н',
  },
  {
    id: 'price2A1C',
    name: '7дн/6н',
  },
  {
    id: 'price2A2C',
    name: '8дн/7н',
  },
];
