import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const PhoneIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3876 2.71697L11.5241 0.855206C11.2966 0.627734 10.9921 0.5 10.6702 0.5C10.3482 0.5 10.0437 0.625984 9.81627 0.855206L7.80927 2.85871C7.5818 3.08618 7.45407 3.39239 7.45407 3.71435C7.45407 4.03806 7.58005 4.34077 7.80927 4.56999L9.37708 6.13955C9.01992 6.97172 8.50675 7.72782 7.86527 8.36702C7.2231 9.01269 6.47244 9.52187 5.63955 9.88233L4.07174 8.31277C3.84427 8.0853 3.53981 7.95757 3.21785 7.95757C3.05904 7.95698 2.90172 7.9881 2.75509 8.04909C2.60847 8.11008 2.47549 8.19973 2.36395 8.31277L0.355206 10.3163C0.127734 10.5437 0 10.85 0 11.1719C0 11.4956 0.125984 11.7983 0.355206 12.0276L2.21697 13.8893C2.60542 14.2778 3.14086 14.5 3.69029 14.5C3.80402 14.5 3.91426 14.4913 4.02625 14.472C6.34296 14.0906 8.64217 12.857 10.4987 11.0022C12.3535 9.14392 13.5853 6.84471 13.9703 4.52625C14.0805 3.86833 13.86 3.19116 13.3876 2.71697V2.71697ZM12.7297 4.31802C12.3885 6.38102 11.2791 8.43876 9.60805 10.1098C7.93701 11.7808 5.88102 12.8902 3.81802 13.2314C3.55906 13.2752 3.29309 13.1877 3.10411 13.0004L1.27559 11.1719L3.21435 9.23141L5.31059 11.3311L5.32633 11.3469L5.70429 11.2069C6.85027 10.7856 7.89093 10.1201 8.75414 9.25657C9.61735 8.39306 10.2825 7.35217 10.7034 6.20604L10.8434 5.82808L8.72966 3.7161L10.6684 1.77559L12.4969 3.60411C12.6859 3.79309 12.7734 4.05906 12.7297 4.31802Z"
        fill={fill}
      />
    </svg>
  );
};
