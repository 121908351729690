import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { TransfersProps } from './Transfers.type';
import { Button } from 'ui/Button';
import { transfersColumns } from './data';
import { useForm } from '@mantine/form';
import { TextInput, Select } from '@mantine/core';

import { BinIcon } from 'components/icons/BinIcon';

import styles from './Transfers.module.scss';
import { useAppSelector } from 'store/hooks';
import { InputDate } from 'ui/InputDate';
import { TimeInput } from '@mantine/dates';

export const Transfers: FC<TransfersProps> = ({
  tour,
  isCanEdit,
  selectedTransfers,
  setSelectedTransfers,
  setDirty,
}) => {
  const { preSaveOrder } = useAppSelector((state) => state.orders);
  const isCruize = useAppSelector((state) => state.orders.preSaveOrder.tourInfo?.isCruize);
  const formTransfers = useForm({
    initialValues: {
      transfers:
        preSaveOrder.tourInfo?.transfers && preSaveOrder.tourInfo?.transfers.length > 0
          ? preSaveOrder.tourInfo?.transfers
          : selectedTransfers || [
              {
                departurePoint: '',
                route: '',
                departureDate: null,
                departureTime: null,
                transportType: '',
                transportCategory: '',
                place_count: '',
                price: '0',
              },
            ],
    },
  });

  useEffect(() => {
    if (!preSaveOrder.id) {
      setSelectedTransfers(formTransfers.values.transfers);
    }
  }, [formTransfers.values.transfers]);
  const fields = formTransfers.values.transfers ? (
    formTransfers.values.transfers.map((item, index) => (
      <div key={index} className={styles.row}>
        <div className={clsx(styles.cell, styles.departurePoint)}>
          <TextInput
            disabled={!isCanEdit}
            {...formTransfers.getInputProps(`transfers.${index}.departurePoint`)}
            styles={{
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>

        <div className={clsx(styles.cell, styles.route)}>
          <TextInput
            disabled={!isCanEdit}
            {...formTransfers.getInputProps(`transfers.${index}.route`)}
            styles={{
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>

        <div className={clsx(styles.cell, styles.departureDate)}>
          <InputDate
            disabled={!isCanEdit}
            date={formTransfers.values.transfers[index].departureDate}
            className={styles.calendar}
            {...formTransfers.getInputProps(`transfers.${index}.departureDate`)}
            styles={{
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>

        <div className={clsx(styles.cell, styles.departureTime)}>
          <TimeInput
            disabled={!isCanEdit}
            withAsterisk
            {...formTransfers.getInputProps(`transfers.${index}.departureTime`)}
            styles={{
              input: {
                border: 'none',
                fontFamily: 'Museo Sans Cyrl',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#263B53',
              },
            }}
          />
        </div>
        {!isCruize && (
          <div className={clsx(styles.cell, styles.transportCategory)}>
            <Select
              disabled={!isCanEdit}
              data={['Групповой', 'Индивидуальный']}
              {...formTransfers.getInputProps(`transfers.${index}.transportCategory`)}
              searchable
              creatable={false}
              className={styles.select}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none', display: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  paddingRight: '0px',
                  paddingLeft: '8px',
                  color: '#263B53',
                },
                item: {
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        {!isCruize && (
          <div className={clsx(styles.cell, styles.transportType)}>
            <Select
              disabled={!isCanEdit}
              data={['Автобус', 'Микроавтобус', 'Минивэн', 'Такси', 'Легковой автомобиль']}
              {...formTransfers.getInputProps(`transfers.${index}.transportType`)}
              searchable
              creatable={false}
              className={styles.select}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none', display: 'none' },
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  paddingRight: '0px',
                  paddingLeft: '8px',
                  color: '#263B53',
                },
                item: {
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}

        {!isCruize && (
          <div className={clsx(styles.cell, styles.places_count)}>
            <TextInput
              disabled={!isCanEdit}
              {...formTransfers.getInputProps(`transfers.${index}.places_count`)}
              onChange={(e) => {
                if (e.target.value.match(/^\d+$/)) {
                  formTransfers.setFieldValue(`transfers.${index}.places_count`, e.target.value);
                } else {
                  formTransfers.setFieldValue(
                    `transfers.${index}.places_count`,
                    e.target.value === '' ? e.target.value : formTransfers.values.transfers[index].places_count
                  );
                }
              }}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        {!isCruize && (
          <div className={clsx(styles.cell, styles.price)}>
            <TextInput
              disabled={!isCanEdit}
              {...formTransfers.getInputProps(`transfers.${index}.price`)}
              onChange={(e) => {
                if (e.target.value.match(/^\d+$/)) {
                  formTransfers.setFieldValue(`transfers.${index}.price`, e.target.value);
                } else {
                  formTransfers.setFieldValue(
                    `transfers.${index}.price`,
                    e.target.value === '' ? e.target.value : formTransfers.values.transfers[index].price
                  );
                }
              }}
              styles={{
                input: {
                  border: 'none',
                  fontFamily: 'Museo Sans Cyrl',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#263B53',
                },
              }}
            />
          </div>
        )}
        <span
          className={styles.delete}
          onClick={() => {
            if (!isCanEdit) {
              return;
            }
            formTransfers.removeListItem('transfers', index);
          }}
        >
          <BinIcon width={10} height={11} fill="#5B6572" />
        </span>
      </div>
    ))
  ) : (
    <></>
  );

  return (
    <div className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {transfersColumns.slice(0, isCruize ? 4 : 8).map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div></div>
          <div>{fields}</div>
        </div>
      </div>
      <div className={styles.btnRow}>
        {preSaveOrder.id && (
          <Button
            transparent={true}
            label="Сохранить трансфер"
            className={styles.saveRow}
            disabled={!isCanEdit}
            onClick={() => {
              setSelectedTransfers(formTransfers.values.transfers);
              setDirty();
            }}
          />
        )}
        <Button
          transparent={true}
          label="+ Добавить строку"
          className={styles.addRow}
          disabled={!isCanEdit}
          onClick={() =>
            formTransfers.insertListItem('transfers', {
              departurePoint: '',
              route: '',
              departureDate: '',
              departureTime: '',
              transportType: '',
              transportCategory: '',
              place_count: '',
              price: '0',
            })
          }
        />
      </div>
    </div>
  );
};
