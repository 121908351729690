import React, { FC, useEffect, useState } from 'react';
import { OrderActionsProps } from './OrderActions.type';
import { FilterByAction } from './FilterByAction';
import { OrderActionsStatus } from 'core/constants/enums';
import { FilterByActionItem } from './FilterByAction/FilterByAction.type';
import { ActionItem } from './ActionItem';
import { useAppSelector } from 'store/hooks';

import styles from './OrderActions.module.scss';

const filtersForActions = [
  {
    id: 1,
    label: OrderActionsStatus.NOTE,
    active: true,
  },
  {
    id: 2,
    label: OrderActionsStatus.ACTION_HISTORY,
    active: false,
  },
];

export const OrderActions: FC<OrderActionsProps> = ({ orderActions }) => {
  const [filteredData, setFilteredData] = useState(orderActions);
  const [filters, setFilters] = useState<FilterByActionItem[]>(filtersForActions);
  const { params } = useAppSelector((state) => state.orderActions);

  useEffect(() => {
    const activeFilters = filtersForActions.filter((item) => item.active === true);
    setFilters(activeFilters);
  }, []);

  // проверка на появление новых данных
  useEffect(() => {
    const labels: string[] = [];
    filtersForActions.forEach((item) => labels.push(item.label));
    setFilteredData(orderActions.filter((item) => labels.includes(item.type)));
  }, [orderActions]);

  useEffect(() => {
    if (params.hiddenAllActions) {
      setFilteredData([])};
  }, [params.hiddenAllActions]);

  return (
    <section className={styles.actions}>
      <FilterByAction filters={filters} setFilters={setFilters} />
      {filteredData.map((action, index) => (
        <ActionItem key={index} action={action} />
      ))}
    </section>
  );
};
