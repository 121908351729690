import React, { useEffect, useState } from 'react';

import { Modal } from 'components/Modal';
import { Button } from 'ui/Button';
import { Filter } from 'ui/Filter/Filter';
import { Search } from 'components/Search';
import { listOfOrderStatuses, listOfManagers, listOfManagersStr, listOfIdsOrderStatuses } from 'core/constants/lists';
import { InputDatePicker } from 'ui/InputDatePicker';
import { OrderCreate } from '../OrderCreate';

import styles from './Order.module.scss';
import { ModalWarning } from 'pages/Tours/components/TourForm/ModalWarning';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUsers } from 'store/action-creators/users';
import { FilterNew } from 'ui/FilterNew/FilterNew';
import {
  setParamsDateFrom,
  setParamsDateTo,
  setParamsIsConsultation,
  setParamsManager,
  setParamsPage,
  setParamsSearch,
  setParamsStatus,
  setParamsTourType,
} from 'store/entities/orders/ordersSlice';

export const OrdersTop = () => {
  const [newOrderModal, setNewOrderModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const dispatch = useAppDispatch();
  const [managers, setManagers] = useState<SelectMultiItemType[]>([]);
  const { users } = useAppSelector((state) => state.users);
  //получение справочника с менеджерами с сервера
  useEffect(() => {
    dispatch(getUsers({ page: 1 }));
  }, []);

  //загрузка менеджеров в фильтр
  useEffect(() => {
    if (users.length > 0) {
      setManagers(users);
    }
  }, [users]);
  const handleSearchClick = (query: string) => {
    dispatch(setParamsSearch(query));
    dispatch(setParamsPage(1));
  };

  const handleStatusClick = (status: string | null) => {
    // console.log(status);
    if (status) {
      dispatch(setParamsStatus('/api/v1/order-statuses/' + listOfIdsOrderStatuses.find((i) => i.value === status)?.id));
      dispatch(setParamsPage(1));
    }
    if (status === '') {
      dispatch(setParamsStatus(''));
      dispatch(setParamsPage(1));
    }
  };

  const handleServiceClick = (service: string | null) => {
    if (service === 'Круизы') {
      dispatch(setParamsIsConsultation(false));
      dispatch(setParamsTourType('cruise'));
    }
    if (service === 'Отель и трансфер') {
      dispatch(setParamsIsConsultation(false));
      dispatch(setParamsTourType('hotel'));
    }
    if (service === 'Туры') {
      dispatch(setParamsIsConsultation(false));
      dispatch(setParamsTourType('tour'));
    }
    if (service === 'Консультация') {
      dispatch(setParamsIsConsultation(true));
    }
    if (service === '') {
      dispatch(setParamsTourType(''));
      dispatch(setParamsIsConsultation(false));
    }
  };
  const handleManagersClick = (manager: SelectMultiItemType | null) => {
    if (manager) {
      dispatch(setParamsManager(manager['@id'] as string));
      dispatch(setParamsPage(1));
    }
    //если удалили всех менеджеров из фильтра
    if (manager === null) {
      dispatch(setParamsManager(''));
      dispatch(setParamsPage(1));
    }
  };
  const handleCancel = () => {
    setWarningModal(false);
  };
  const handleDataPicker = (value: [Date | null, Date | null]) => {
    console.log(value);
    dispatch(setParamsDateFrom(value[0]));
    dispatch(setParamsDateTo(value[1]));
    dispatch(setParamsPage(1));
  };
  return (
    <>
      <div className={styles.top}>
        <div className={styles.orders}>
          <h2 className={styles.title}>Заказы</h2>
          <Button onClick={() => setNewOrderModal(true)} width="200px">
            + Новый заказ
          </Button>
        </div>

        <div className={styles.search}>
          <Search placeholder="Поиск" setQuery={handleSearchClick} />
          <InputDatePicker placeholder="Дата брони" setDates={handleDataPicker} />
          <Filter
            filter={{
              title: 'Статус',
              data: listOfOrderStatuses,
            }}
            setFilter={handleStatusClick}
          />
          <FilterNew
            filter={{
              title: 'Менеджер',
              data: managers,
            }}
            setFilter={handleManagersClick}
          />
          <Filter
            filter={{
              title: 'Услуга',
              data: ['Туры', 'Отель и трансфер', 'Круизы', 'Консультация'],
            }}
            setFilter={handleServiceClick}
          />
        </div>
      </div>
      {newOrderModal && (
        <Modal
          opened={newOrderModal}
          setOpened={setNewOrderModal}
          isWantClose
          openedWarning={warningModal}
          closeWarning={() => setWarningModal(false)}
          openModal={() => setWarningModal(true)}
          handleCancel={handleCancel}
        >
          <OrderCreate />
        </Modal>
      )}
    </>
  );
};
