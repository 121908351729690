import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { SelectMultiItemType, SelectMultiType } from './SelectMulti.type';
import { useRefCloseOut } from 'hooks/useRefCloseOut';
import { CloseIcon } from 'components/icons';

import styles from './SelectMulti.module.scss';

export const SelectMulti: FC<SelectMultiType> = ({
  label,
  data,
  setSelect,
  className,
  selectedItems,
  valueChanged,
  isWithReset = true,
}) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<SelectMultiItemType[]>([]);
  const [dataList, setDataList] = useState<SelectMultiItemType[]>([]);

  const selectRef = useRef() as React.MutableRefObject<HTMLUListElement>;
  const btnSelectRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleClickHeader = () => {
    setOpened((prev) => !prev);
  };

  const handleClickItem = (item: SelectMultiItemType) => {
    setValue([...value, item]);
    setDataList(dataList.filter((element) => element !== item));
    setOpened(false);
    if (valueChanged) {
      valueChanged();
    }
  };

  const handleButtonRemove = (selectedItem: SelectMultiItemType, event: React.MouseEvent) => {
    setValue([...value].filter((item) => item !== selectedItem));
    setDataList([...dataList, selectedItem]);
    event.stopPropagation();
    if (valueChanged) {
      valueChanged();
    }
  };

  useEffect(() => {
    setSelect(value);
  }, [value]);

  useEffect(() => {
    if (selectedItems && selectedItems?.length > 0) {
      setValue(selectedItems);
    }
  }, [selectedItems]);

  //проверяем - если какие-то элементы из выпадающего списка уже выбраны, то сокращаем этот список.
  useEffect(() => {
    let restData: SelectMultiItemType[] = [];
    restData = data.filter((value) => selectedItems?.every((item) => item['@id'] !== value['@id']));
    setDataList(restData);
  }, [selectedItems, data]);

  useRefCloseOut(selectRef, setOpened, btnSelectRef, isWithReset);

  return (
    <article className={clsx(styles.select, className)}>
      {label && <label className={styles.label}>{label}</label>}

      <div
        className={clsx(styles.header, opened && styles.headerOpened)}
        onClick={handleClickHeader}
        ref={btnSelectRef}
      >
        {value.map((item) => (
          <span key={item.id} onClick={(event) => event.stopPropagation()} className={styles.badge}>
            {item.name}
            <button onClick={(event: React.MouseEvent) => handleButtonRemove(item, event)} className={styles.close}>
              <CloseIcon width={7} height={7} fill="#5B6572" />
            </button>
          </span>
        ))}
      </div>
      {opened && (
        <ul className={styles.list} ref={selectRef}>
          {dataList.map((item) => (
            <li key={item.id} onClick={() => handleClickItem(item)} className={styles.item}>
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </article>
  );
};
