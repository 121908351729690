import React, { FC, useRef, useState } from 'react';
import styles from './GalleryTab.module.scss';
import { CloseIcon, DragDoteIcon, PaperClipIcon } from 'components/icons';
import { Button } from 'ui/Button';
import { Modal } from 'components/Modal';
import { PhotoList } from 'pages/Gallery/PhotoList';
import { Album, Photo } from 'services/gallery/galleryApi.service';
import { AlertIcon } from 'components/icons/AlertIcon';
import { declOfNum } from 'core/utils/declOfNum';
import { useAppDispatch } from 'store/hooks';
import { setIsEditedTour } from 'store/entities/tours/toursSlice';
import { AlbumList } from 'pages/Gallery/AlbumList';
import clsx from 'clsx';

export const GalleryTab: FC<{
  photos?: Photo[];
  tourAlbum?: Album;
  setPhoto: (a: Photo[]) => void;
  orders?: number[];
  setPreSaveAlbum: (a: Album | null) => void;
}> = ({ photos, tourAlbum, orders, setPhoto, setPreSaveAlbum }) => {
  const dispatch = useAppDispatch();
  const [openedPhotoList, setOpenedPhotoList] = useState(false);
  const [openedAlbumList, setOpenedAlbumList] = useState(false);
  const [viewAlbumHelp, setViewAlbumHelp] = useState(false);
  const [viewPhotoHelp, setViewPhotoHelp] = useState<null | number>(null);
  const [photoList, setPhotoList] = useState<Photo[]>(photos || []);
  const [album, setAlbum] = useState<Album | null>(tourAlbum ? tourAlbum : null);
  const [ordersList, setOrdersList] = useState(orders || []);
  const handleAddAlbum = (a: Album) => {
    setAlbum(a);
    if (a != null && a.photos.length > 0) {
      setPhotoList((prev) => [...a.photos, ...prev]);
    }
  };

  const handleAddPhotos = (photos: Photo[]) => {
    setPhotoList(photos);
  };

  const handleDelete = (id: number) => {
    const filtredData = photoList.filter((el) => el.id != id);
    setPhotoList(filtredData);
  };

  const handleSave = () => {
    dispatch(setIsEditedTour(true));
    setPhoto(photoList);
    if (album != null) {
      setPreSaveAlbum(album);
      return;
    }
    setPreSaveAlbum(null);
  };

  const dragPerson = useRef<number>(0);
  const draggedOverPerson = useRef<number>(0);

  function handleSort() {
    const photoListClone = [...photoList];
    const temp = photoListClone[dragPerson.current];
    photoListClone[dragPerson.current] = photoListClone[draggedOverPerson.current];
    photoListClone[draggedOverPerson.current] = temp;
    setPhotoList(photoListClone);
    setOrdersList(photoListClone.map((el) => Number(el.id)));
  }

  const sorting = (a: Photo, b: Photo) => {
    const sort = ordersList.indexOf(Number(a.id)) - ordersList.indexOf(Number(b.id));
    return Number(sort);
  };
  const uniqueIds: (string | number)[] = [];
  return (
    <>
      <div className={styles.container}>
        <div className={styles.btnGroup}>
          <Button onClick={() => setOpenedPhotoList(true)}>Прикрепить фото</Button>
          <Button onClick={() => setOpenedAlbumList(true)}>Прикрепить альбом</Button>
        </div>
        {album && (
          <div className={styles.albumSection}>
            <div className={styles.album}>
              <PaperClipIcon />
              <div className={styles.album_title}>
                <h3>{album.name}</h3>
                <div className={styles.albumUsed}>
                  <p>
                    Использовано:
                    <span>{` ${album.used_count} ${declOfNum(album.used_count, ['раз', 'раза', 'раз'])}`}</span>
                  </p>
                  <span
                    className={styles.helper}
                    onMouseMove={() => setViewAlbumHelp(true)}
                    onMouseLeave={() => setViewAlbumHelp(false)}
                  >
                    <AlertIcon />
                  </span>
                  {viewAlbumHelp && album.used_tours.length > 0 && (
                    <div className={clsx(styles.helpBlock, styles.durationHelp)}>
                      {album.used_tours.map((el) => el).join(',')}
                    </div>
                  )}
                </div>
              </div>
              <span
                className={styles.remove}
                onClick={() => {
                  setAlbum(null);
                  const photos = album.photos.map((el) => el.id);
                  setPhotoList((prev) => prev.filter((el) => !photos.includes(el.id)));
                }}
              >
                <CloseIcon width={8} height={8} fill="#5B6572" />
              </span>
            </div>
          </div>
        )}

        {photoList.length > 0 && (
          <div className={styles.photoSection}>
            <h3>Фото тура</h3>
            <div className={styles.photoGrid}>
              {[...photoList]
                .concat(album ? album.photos : [])
                .filter((element) => {
                  const isDuplicate = uniqueIds.includes(element.id);

                  if (!isDuplicate) {
                    uniqueIds.push(element.id);

                    return true;
                  }

                  return false;
                })

                .sort((a, b) => sorting(a, b))
                // .filter((obj, index, arr) => arr.findIndex(index) === index)

                .map((el, index) => {
                  const canDelete = !album?.photos.find((item) => Number(item.id) === Number(el.id));
                  return (
                    <div
                      className={styles.photo}
                      key={el.id}
                      draggable
                      onDragStart={() => (dragPerson.current = index)}
                      onDragEnter={() => (draggedOverPerson.current = index)}
                      onDragEnd={handleSort}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <DragDoteIcon width={11} height={16} />
                      <div className={styles.imageContainer}>
                        <img
                          src={
                            el.thumbnail_name
                              ? `${process.env.REACT_APP_BASE_API_URL}/uploads/thumbnails/${el.thumbnail_name}`
                              : `${process.env.REACT_APP_BASE_API_URL}/uploads/images/${el.name}`
                          }
                          alt={el.alt}
                        />
                        {el.is_license && <span>Лицензия</span>}
                      </div>
                      <div className={styles.photo_title}>
                        <h3>{el.title && el.title.length > 16 ? `${el.title.substring(0, 16)}...` : el.title}</h3>
                        {album &&
                          album.photos.length > 0 &&
                          album.photos.find((item) => Number(item.id) === Number(el.id)) && <p>Альбомное фото</p>}
                        <div className={styles.albumUsed}>
                          <p>
                            Использовано:
                            <span>{` ${el.used_count ? el.used_count : 0} ${declOfNum(el.used_count, [
                              'раз',
                              'раза',
                              'раз',
                            ])}`}</span>
                          </p>
                          <span
                            className={styles.helper}
                            onMouseMove={() => setViewPhotoHelp(Number(el.id))}
                            onMouseLeave={() => setViewPhotoHelp(null)}
                          >
                            <AlertIcon />
                          </span>
                          {viewPhotoHelp === Number(el.id) && el.used_tours && el.used_tours.length > 0 && (
                            <div className={clsx(styles.helpBlock, styles.photoHelp)}>
                              {el.used_tours.map((el) => el).join(',')}
                            </div>
                          )}
                        </div>
                      </div>
                      {canDelete && (
                        <span className={styles.remove} onClick={() => handleDelete(Number(el.id))}>
                          <CloseIcon width={8} height={8} fill="#5B6572" />
                        </span>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <Button onClick={handleSave} className={styles.addTrip}>
          Сохранить фотогалерею
        </Button>
      </div>
      {openedPhotoList && (
        <Modal opened={openedPhotoList} setOpened={setOpenedPhotoList}>
          <PhotoList selectedPhotos={photoList} setPhotos={(a) => handleAddPhotos(a)} />
        </Modal>
      )}
      {openedAlbumList && (
        <Modal opened={openedAlbumList} setOpened={setOpenedAlbumList}>
          <AlbumList selectedAlbum={album ? Number(album?.id) : 0} setAlbum={(a) => handleAddAlbum(a)} />
        </Modal>
      )}
    </>
  );
};
