import axiosInstance from 'services/axiosInstance';

export interface IMessange {
  chat: string;
  text: string;
  to_user: string;
  files: string[];
}

export interface ICreateChat {
  order_entity: string;
  manager: string;
  contact: string;
  message: IMessange;
}

export const getChatsAPI = async () => {
  return await axiosInstance.get('/api/v1/chats', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
};
export const getChatMessagesAPI = async ({
  iri,
  is_read,
  to_user,
}: {
  iri: string;
  is_read?: boolean;
  to_user?: string;
}) => {
  return await axiosInstance.get('/api/v1/chat-messages', {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
    params: {
      itemsPerPage: 100,
      chat: iri,
      is_read,
      to_user,
      'sort[created_at]': 'asc',
      nocache: true,
    },
  });
};

export const sendChatMessangeAPI = async (data?: IMessange) => {
  const response = await axiosInstance.post<any>('/api/v1/chat-messages', data, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
  return response;
};

export const sendChatMessangeWithOutChatAPI = async ({
  order_entity,
  manager,
  contact,
}: {
  order_entity: string;
  manager: string;
  contact: string;
}) => {
  const response = await axiosInstance.post<any>(
    '/api/v1/chats',
    { order_entity, manager, contact },
    {
      headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json;charset=UTF-8',
      },
    }
  );
  return response;
};

export const getReadChatAPI = async (id: number) => {
  return await axiosInstance.get(`/api/v1/chats/${id}/read`, {
    headers: {
      Accept: 'application/ld+json',
      'Content-Type': 'application/ld+json;charset=UTF-8',
    },
  });
};
