import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TourHotelInfo, TripInfo } from 'pages/Tours/Tours.types';
import { PreSaveTourState } from './PreSaveTourTypes';
import { SelectItemType } from 'ui/SelectNew/SelectNew.type';
import { TourProgramDay } from 'pages/Tours/components/TourForm/TourProgram/TourProgram.types';
import { ServiceProps } from 'components/AddService/AddService.type';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { Album, Photo } from 'services/gallery/galleryApi.service';
import { DescProps, TableDescColumn } from 'components/AddDescription/AddService.type';
import { SalesProps } from 'components/AddSales/AddSales.type';
import { addDocument } from 'store/action-creators/partners';

const initialState: PreSaveTourState = {
  preSaveTour2: {
    id: '',
    name: '',
    template: '',
    is_visible: false,
    descriptionInfo: {
      booking_without_prepay: false,
      duration: 0,
      tourOperator: null,
      discount: '',
      shortDescription: '',
      fullDescription: '',
      tourProgram: null,
      priceIncludes: '',
      additionalServices: [],
      additionalDiscounts: [],
      price: 0,
      tour_group_title: '',
      tour_group_placements: [],
      settings: {
        tourGroup: Array.from({ length: 7 }, () => {
          return { name: '' };
        }),
        photoOrders: [],
      },
    },

    tourParameters: {
      tourManager: null,
      guide: null,
      country: null,
      city: null,
      transport: null,
      tourType: null,
      travelTime: null,
      direction: null,
      tags: null,
      main_tag: null,
    },

    tripsInfo: null,
    photos: [],
    photo_album: null,
  },
};

export const preSavetourSlice = createSlice({
  name: 'preSaveTours',
  initialState: initialState,
  reducers: {
    setPreSaveTourId(state, action: PayloadAction<number | string>) {
      state.preSaveTour2.id = action.payload;
    },
    setPreSaveTourName(state, action: PayloadAction<string>) {
      state.preSaveTour2.name = action.payload;
    },
    setPreSaveIsVisible(state, action: PayloadAction<boolean>) {
      state.preSaveTour2.is_visible = action.payload;
    },
    setPreSaveTourTemplate(state, action: PayloadAction<string>) {
      state.preSaveTour2.template = action.payload;
    },
    setPreSaveTourDuration(state, action: PayloadAction<number>) {
      state.preSaveTour2.descriptionInfo.duration = action.payload;
    },
    setPreSaveDescColumns(
      state,
      action: PayloadAction<{
        tourGroup?: TableDescColumn[];
        photoOrders?: number[];
      }>
    ) {
      state.preSaveTour2.descriptionInfo.settings = action.payload;
    },
    setPreSaveTourOperator(state, action: PayloadAction<SelectItemType | null>) {
      state.preSaveTour2.descriptionInfo.tourOperator = action.payload;
    },
    setPreSaveTourDiscount(state, action: PayloadAction<string | number>) {
      state.preSaveTour2.descriptionInfo.discount = action.payload;
    },
    setPreSaveTourDiscountPersent(state, action: PayloadAction<string | number>) {
      state.preSaveTour2.descriptionInfo.discount_percent = action.payload;
    },
    setPreSaveTourShortDescription(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.shortDescription = action.payload;
    },
    setPreSaveTourShortSystemName(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.system_name = action.payload;
    },
    setPreSaveTourPartnersSite(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.partners_site = action.payload;
    },
    setPreSaveTourAddInfo(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.addInfo = action.payload;
    },
    setPreSaveTourFullDescription(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.fullDescription = action.payload;
    },
    setPreSaveTourDocument(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.document_template_path = action.payload;
    },
    setPreSaveTourProgram(state, action: PayloadAction<TourProgramDay[] | null>) {
      state.preSaveTour2.descriptionInfo.tourProgram = action.payload;
    },
    setPreSaveTourPriceIncludes(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.priceIncludes = action.payload;
    },
    setPreSaveTourPriceNoIncludes(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.priceNoIncludes = action.payload;
    },
    setPreSaveTourFilterPrice(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.filter_price = action.payload;
    },
    setPreSaveTourComission(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.commission = action.payload;
    },
    setPreSaveTourCurrency(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.currency = action.payload;
    },
    setPreSaveMonday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.monday = action.payload;
    },
    setPreSaveEightday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.eightday = action.payload;
    },
    setPreSaveEightdayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.eightday_title = action.payload;
    },
    setPreSaveBookingPrepay(state, action: PayloadAction<boolean>) {
      state.preSaveTour2.descriptionInfo.booking_without_prepay = action.payload;
    },
    setPreSaveMondayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.monday_title = action.payload;
    },
    setPreSaveTuesday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.tuesday = action.payload;
    },
    setPreSaveTuesdayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.tuesday_title = action.payload;
    },
    setPreSaveWednesday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.wednesday = action.payload;
    },
    setPreSaveWednesdayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.wednesday_title = action.payload;
    },
    setPreSaveThursday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.thursday = action.payload;
    },
    setPreSaveThursdayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.thursday_title = action.payload;
    },
    setPreSaveFriday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.friday = action.payload;
    },
    setPreSaveFridayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.friday_title = action.payload;
    },
    setPreSaveSaturday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.saturday = action.payload;
    },
    setPreSaveSaturdayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.saturday_title = action.payload;
    },
    setPreSaveSunday(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.sunday = action.payload;
    },
    setPreSaveSundayTitle(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.sunday_title = action.payload;
    },
    setPreSavePriority(state, action: PayloadAction<number>) {
      state.preSaveTour2.descriptionInfo.priority = action.payload;
    },
    setPreSaveTourAdditionalServices(state, action: PayloadAction<ServiceProps[]>) {
      state.preSaveTour2.descriptionInfo.additionalServices = action.payload;
    },
    setPreSaveTourAddGroupDesc(state, action: PayloadAction<DescProps[]>) {
      state.preSaveTour2.descriptionInfo.tour_group_placements = action.payload;
    },
    setPreSaveTourAddTitleDesc(state, action: PayloadAction<string>) {
      state.preSaveTour2.descriptionInfo.tour_group_title = action.payload;
    },
    setPreSaveTourAdditionalDiscounts(state, action: PayloadAction<SalesProps[]>) {
      state.preSaveTour2.descriptionInfo.additionalDiscounts = action.payload;
    },
    setPreSaveTourPrice(state, action: PayloadAction<number | null>) {
      state.preSaveTour2.descriptionInfo.price = action.payload;
    },

    setPreSaveTourManagers(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.tourManager = action.payload;
    },
    setPreSaveTourGuides(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.guide = action.payload;
    },
    setPreSaveTourCountries(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.country = action.payload;
    },
    setPreSaveTourCities(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.city = action.payload;
    },
    setPreSaveTourTransports(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.transport = action.payload;
    },
    setPreSaveTourTypes(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.tourType = action.payload;
    },
    setPreSaveTourTravelTimes(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.travelTime = action.payload;
    },
    setPreSaveTourDirection(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.direction = action.payload;
    },
    setPreSaveTourTags(state, action: PayloadAction<SelectMultiItemType[] | null>) {
      state.preSaveTour2.tourParameters.tags = action.payload;
    },

    setPreSaveTripsInfo(state, action: PayloadAction<TripInfo[] | null | undefined>) {
      state.preSaveTour2.tripsInfo = action.payload;
    },
    setPreSaveToursHotelInfo(state, action: PayloadAction<TourHotelInfo[] | undefined>) {
      state.preSaveTour2.tourHotels = action.payload;
    },
    resetPreSaveTour2: (state) => {
      state.preSaveTour2 = initialState.preSaveTour2;
    },
    setMainTag(state, action: PayloadAction<string | null>) {
      state.preSaveTour2.tourParameters.main_tag = action.payload;
    },
    setTourPhotos(state, action: PayloadAction<Photo[]>) {
      state.preSaveTour2.photos = action.payload;
    },
    setTourAlbum(state, action: PayloadAction<Album | null>) {
      state.preSaveTour2.photo_album = action.payload;
    },
    setTourPhotosOrder(state, action: PayloadAction<number[]>) {
      state.preSaveTour2.descriptionInfo.settings!.photoOrders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addDocument.fulfilled, (state, action) => {
      state.preSaveTour2.descriptionInfo.document_template_path = action.payload.path;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setPreSaveTourId,
  setPreSaveMonday,
  setPreSaveTuesday,
  setPreSaveWednesday,
  setPreSaveThursday,
  setPreSaveFriday,
  setPreSaveSaturday,
  setPreSaveSunday,
  setPreSaveMondayTitle,
  setPreSaveTuesdayTitle,
  setPreSaveWednesdayTitle,
  setPreSaveThursdayTitle,
  setPreSaveFridayTitle,
  setPreSaveSaturdayTitle,
  setPreSaveSundayTitle,
  setPreSaveTourDocument,
  setPreSaveTourName,
  setPreSaveTourTemplate,
  setPreSaveTourDuration,
  setPreSaveTourCurrency,
  setPreSaveTourPartnersSite,
  setPreSaveTourFilterPrice,
  setMainTag,
  setPreSaveTourOperator,
  setPreSaveTourDiscount,
  setPreSaveTourComission,
  setPreSaveTourDiscountPersent,
  setPreSaveTourShortDescription,
  setPreSaveTourShortSystemName,
  setPreSaveTourAddInfo,
  setPreSaveTourFullDescription,
  setPreSaveTourProgram,
  setPreSaveTourPriceIncludes,
  setPreSaveTourPriceNoIncludes,
  setPreSaveTourAdditionalServices,
  setPreSaveTourAdditionalDiscounts,
  setPreSaveTourManagers,
  setPreSaveTourGuides,
  setPreSaveTourCountries,
  setPreSaveTourCities,
  setPreSaveTourTransports,
  setPreSaveTourTypes,
  setPreSaveTourTravelTimes,
  setPreSaveTourDirection,
  setPreSaveTourTags,
  setPreSaveTripsInfo,
  resetPreSaveTour2,
  setPreSaveTourPrice,
  setPreSaveToursHotelInfo,
  setPreSaveBookingPrepay,
  setTourPhotos,
  setTourAlbum,
  setPreSaveTourAddGroupDesc,
  setPreSaveTourAddTitleDesc,
  setPreSaveDescColumns,
  setPreSaveIsVisible,
  setTourPhotosOrder,
  setPreSavePriority,
  setPreSaveEightday,
  setPreSaveEightdayTitle,
} = preSavetourSlice.actions;

export default preSavetourSlice.reducer;
