import React, { FC, useEffect, useState } from 'react';
import logo from '../../assets/logo/logo.png';
import logoWhite from '../../assets/logo/logo-white.png';

type LogoProps = {
  color?: 'white' | 'blue';
};

export const Logo: FC<LogoProps> = ({ color = 'blue' }) => {
  const [logoImg, setLogoImg] = useState('');

  useEffect(() => {
    if (color === 'blue') {
      setLogoImg(logo);
    } else {
      setLogoImg(logoWhite);
    }
  }, []);
  return <img src={logoImg} width={105} height={48} />;
};
