import React, { FC } from 'react';
import { UserType } from './User.types';
import styles from './User.module.scss';
import { getInitialsFromName } from 'core/utils/getInitials';
import clsx from 'clsx';

export const User: FC<UserType> = ({ id, avatar, name, viewName = true, setUser, className, getUserInitials }) => {
  const handleUserClick = () => {
    if (setUser) {
      setUser(id);
    }
  };

  const userInitials = getUserInitials ? getUserInitials(name) : getInitialsFromName(name);

  return (
    <>
      {setUser ? (
        <article className={clsx(styles.userBasic, styles.userAdvanced, className)} onClick={() => handleUserClick()}>
          <div className={styles.name}>{name}</div>
          {avatar ? (
            <div className={styles.imgWrapper}>
              <img src={avatar} className={styles.img} />
            </div>
          ) : (
            <div className={styles.avatar}>{userInitials}</div>
          )}
        </article>
      ) : (
        <article className={clsx(styles.userBasic, className)}>
          {avatar ? (
            <div className={styles.imgWrapper}>
              <img src={avatar} className={styles.img} />
            </div>
          ) : (
            <div className={styles.avatar}>{userInitials}</div>
          )}
          {viewName && <div className={clsx(styles.name, styles.nameBasic)}>{name}</div>}
        </article>
      )}
    </>
  );
};
