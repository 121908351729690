import React, { FC, useEffect, useState, useContext } from 'react';
import { notifications } from '@mantine/notifications';

import { TabsPanel } from 'ui/TabsPanel/TabsPanel';
import { PartnerFormPrivatePersonType } from './PartnerFormPrivatePerson.types';
import { SelectMulti } from 'ui/SelectMulti';
import { DocumentsTab } from './DocumentsTab';
import { HistoryTab } from '../HistoryTab';
import { CommonTab } from './CommonTab';
import { PrivatePartnerTabs, SUBJECT } from 'core/constants/enums';
import { Controls } from 'components/Controls';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetPreSavePrivatePerson,
  setIsPartnerModal,
  setPreSavePrivatePerson,
} from 'store/entities/partners/partnersSlice';
import { addPartner, editPartner, getContactTypes } from 'store/action-creators/partners';
import { AddPartnerData } from 'services/partners/partnerApi.service';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import ModalContext from '../../../../core/context/ModalContext';

import styles from './PartnerFormPrivatePerson.module.scss';
import { getTimezonelessDate } from 'core/utils/getDate';
import { useLocation } from 'react-router-dom';
import { RouteNames } from 'core/constants/routeNames';
import { Button } from 'ui/Button';

export const PartnerFormPrivatePerson: FC<PartnerFormPrivatePersonType> = ({ partner }) => {
  const dispatch = useAppDispatch();
  const [tabsData, setTabsData] = useState<string[]>([]);
  const [tab, setTab] = useState<string>('');
  const [errorList, setErrorList] = useState<{ [index: string]: string }>({});
  const { types, preSavePrivatePerson, preSaveLegalPerson } = useAppSelector((state) => state.partners);
  const { isOdrderModal } = useAppSelector((state) => state.orders);
  const [selectedTypes, setSelectedTypes] = useState<SelectMultiItemType[] | undefined>([]);
  const [savePending, setSavePending] = useState(false);
  const [errorShown, setErrorShown] = useState(false);

  const [checkCommonTab, setCheckCommonTab] = useState<boolean>(false);
  const [errorsCommonTab, setErrorsCommonTab] = useState(null);
  const location = useLocation();

  const [partnerData, setPartnerData] = useState<AddPartnerData>({
    firstName: '',
    middleName: '',
    secondName: '',
    fullName: '',
    subject: SUBJECT.PRIVATE_PERSON,
    gender: '',
    phone: '',
    email: '',
    note: '',
    type: [
      /* '/api/v1/contact-types/1', '/api/v1/contact-types/2' */
    ],
  });

  useEffect(() => {
    if (partner === null || partner === undefined) {
      setTabsData([PrivatePartnerTabs.COMMON, PrivatePartnerTabs.DOCUMENTS]);
    } else {
      setTabsData([PrivatePartnerTabs.COMMON, PrivatePartnerTabs.DOCUMENTS, PrivatePartnerTabs.HISTORY]);
    }
  }, []);

  //загружаем список типов контрагентов с сервера
  useEffect(() => {
    dispatch(getContactTypes({ page: 1 }));
  }, []);

  useEffect(() => {
    //  console.log('TAB change detected! ' + tab);
  }, [tab]);
  useEffect(() => {
    if (Object.values(errorList).join('').length !== 0) {
      return;
    }
    if (!savePending) return;

    if (partner !== undefined) {
      // отредактировали данные - сохраняем
      dispatch(editPartner({ id: partner.id?.toString() as string, data: partnerData }));
    } else {
      // новый контрагент - сохраняем
      dispatch(addPartner(partnerData));
      if (!partner) closeForm();
      notifications.show({
        title: 'Сохранение',
        color: 'green',
        message: 'Сохранение данных...',
      });
    }

    setSavePending(false);
  }, [partnerData, errorList]);

  //устанавливаем тип контрагента при первой загрузке (если есть предсохраненные данные или существуют у контрагента)
  useEffect(() => {
    setSelectedTypes(
      (preSavePrivatePerson.type && preSavePrivatePerson.type?.length > 0 && preSavePrivatePerson.type) ||
        partner?.type ||
        []
    );
  }, []);

  // контрагент помечен как изменившийся
  const processSetDirty = () => {
    if (partner !== undefined) {
      storePartnerData();
      setCheckCommonTab(true); // проверим перед отправкой
    }
  };

  //при изменении выбранных типов, сохраняем в presave
  useEffect(() => {
    dispatch(setPreSavePrivatePerson({ ...preSavePrivatePerson, type: selectedTypes }));
  }, [selectedTypes]);

  useEffect(() => {
    if (savePending && partner !== undefined) {
      processSetDirty(); // обновляем данные
    }
  }, [preSavePrivatePerson.commonInfo.gender]);

  //при нажатии "Сохранить" запускаем проверку валидации в форме на общей вкладке (и потом добавить документы)
  const onSubmit = () => {
    switch (tab) {
      case PrivatePartnerTabs.COMMON:
        {
          if (Object.values(errorList).join('').length !== 0) {
            notifications.show({
              title: 'Ошибка',
              color: 'red',
              message: 'Заполните обязательные поля формы',
            });
            return;
          }
          setCheckCommonTab(true);
        }
        break;
      case PrivatePartnerTabs.DOCUMENTS:
        {
          setCheckCommonTab(true);
        }
        break;
    }
  };

  const storePartnerData = () => {
    let dataToStore: AddPartnerData = {
      ...partnerData,
      type: preSavePrivatePerson.type?.map((type) => type['@id']),
      firstName: preSavePrivatePerson.commonInfo.firstName,
      middleName: preSavePrivatePerson.commonInfo.patronymic,
      secondName: preSavePrivatePerson.commonInfo.lastName,
      fullName: `${preSavePrivatePerson.commonInfo.lastName} ${preSavePrivatePerson.commonInfo.firstName} ${preSavePrivatePerson.commonInfo.patronymic}`,
      gender: preSavePrivatePerson.commonInfo.gender,
      birthday: preSavePrivatePerson.commonInfo.birthDate
        ? getTimezonelessDate(preSavePrivatePerson.commonInfo.birthDate)
        : partner?.commonInfo.birthDate
        ? getTimezonelessDate(partner?.commonInfo.birthDate)
        : null,
      phone: preSavePrivatePerson.commonInfo.phone,
      email: preSavePrivatePerson.commonInfo.email,
      note: preSavePrivatePerson.commonInfo.comments,
    };
    if (preSavePrivatePerson.documents.passport) {
      dataToStore = {
        ...dataToStore,
        contactPassport: {
          id: preSavePrivatePerson.documents.passport?.id,
          code: preSavePrivatePerson.documents.passport?.number,
          registeredDate: preSavePrivatePerson.documents.passport?.dateOfIssue
            ? getTimezonelessDate(preSavePrivatePerson.documents.passport?.dateOfIssue)
            : null,
          actualBefore: preSavePrivatePerson.documents.passport?.validUntil
            ? getTimezonelessDate(preSavePrivatePerson.documents.passport?.validUntil)
            : null,
          unitCode: preSavePrivatePerson.documents.passport?.divisionCode,
          unitName: preSavePrivatePerson.documents.passport?.issuedByWhom,
          birthPlace: preSavePrivatePerson.documents.passport?.placeOfBirth,
          registrationPlace: preSavePrivatePerson.documents.passport?.placeOfStateRegistration,
          registration_country: preSavePrivatePerson.documents.passport?.countryRegistration,
        },
      };
    } else {
      dataToStore = {
        ...dataToStore,
        contactPassport: null,
      };
    }
    if (preSavePrivatePerson?.documents.interPassport) {
      dataToStore = {
        ...dataToStore,
        contactForeignPassport: {
          id: preSavePrivatePerson.documents.interPassport?.id,
          firstName: preSavePrivatePerson?.documents.interPassport?.firstName,
          lastName: preSavePrivatePerson?.documents.interPassport?.lastName,
          code: preSavePrivatePerson?.documents.interPassport?.number,
          registeredDate: preSavePrivatePerson?.documents.interPassport?.dateOfIssue
            ? getTimezonelessDate(preSavePrivatePerson?.documents.interPassport?.dateOfIssue)
            : null,
          actualBefore: preSavePrivatePerson?.documents.interPassport?.validUntil
            ? getTimezonelessDate(preSavePrivatePerson?.documents.interPassport?.validUntil)
            : undefined,
          unitCode: preSavePrivatePerson?.documents.interPassport?.divisionCode,
          unitName: preSavePrivatePerson?.documents.interPassport?.issuedByWhom,
          birthPlace: preSavePrivatePerson?.documents.interPassport?.placeOfBirth,
          registrationPlace: preSavePrivatePerson?.documents.interPassport?.placeOfStateRegistration,
          registration_country: preSavePrivatePerson.documents.interPassport?.countryRegistration,
        },
      };
    } else {
      dataToStore = {
        ...dataToStore,
        contactForeignPassport: null,
      };
    }

    if (preSavePrivatePerson?.documents.birthCertificate) {
      dataToStore = {
        ...dataToStore,
        contactBirthCertificate: {
          id: preSavePrivatePerson.documents.birthCertificate?.id,
          code: preSavePrivatePerson?.documents.birthCertificate?.number,
          registeredDate: preSavePrivatePerson?.documents.birthCertificate?.dateOfIssue
            ? getTimezonelessDate(preSavePrivatePerson?.documents.birthCertificate?.dateOfIssue)
            : undefined,
          fatherFullName: preSavePrivatePerson?.documents.birthCertificate?.father,
          motherFullName: preSavePrivatePerson?.documents.birthCertificate?.mother,
          registrationPlace: preSavePrivatePerson?.documents.birthCertificate?.placeOfStateRegistration,
        },
      };
    } else {
      dataToStore = {
        ...dataToStore,
        contactBirthCertificate: null,
      };
    }

    if (preSavePrivatePerson?.documents.foreignDocument) {
      dataToStore = {
        ...dataToStore,
        contactForeignDocument: {
          id: preSavePrivatePerson.documents.foreignDocument?.id,
          firstName: preSavePrivatePerson?.documents.foreignDocument?.firstName,
          lastName: preSavePrivatePerson?.documents.foreignDocument?.lastName,
          code: preSavePrivatePerson?.documents.foreignDocument?.number,
          nationality: preSavePrivatePerson?.documents.foreignDocument?.citizenship,
          registeredDate: preSavePrivatePerson?.documents.foreignDocument?.dateOfIssue
            ? getTimezonelessDate(preSavePrivatePerson?.documents.foreignDocument?.dateOfIssue)
            : undefined,
          registrationPlace: preSavePrivatePerson?.documents.foreignDocument?.placeOfStateRegistration,
          registration_country: preSavePrivatePerson.documents.foreignDocument?.countryRegistration,
        },
      };
    } else {
      dataToStore = {
        ...dataToStore,
        contactForeignDocument: null,
      };
    }
    if (preSavePrivatePerson.documents.mainDocument !== '') {
      dataToStore = {
        ...dataToStore,
        main_document: preSavePrivatePerson.documents.mainDocument,
      };
    } else {
      dataToStore = {
        ...dataToStore,
        main_document: null,
      };
    }
    setPartnerData({
      ...partnerData,
      ...dataToStore,
    });
  };
  //проверяем. если в форме с общей вкладки есть ошибки, то не отправлять данные на сервер и не закрывать форму.
  useEffect(() => {
    if (errorsCommonTab === null) return;
    if (JSON.stringify(errorsCommonTab) === '{}') {
      setErrorShown(false);
      if (partnerData) {
        if (checkCommonTab) {
          storePartnerData();
          setSavePending(true);
        }
      }
    } else {
      if (checkCommonTab || !errorShown) {
        setErrorShown(true);
      }
    }
    setCheckCommonTab(false);
  }, [errorsCommonTab, checkCommonTab]);

  const { setActive } = useContext(ModalContext);
  const closeForm = () => {
    if (location.pathname === RouteNames.ORDERS || (location.pathname === RouteNames.PARTNERS && isOdrderModal)) {
      dispatch(setIsPartnerModal(false));
    } else {
      setActive(false);
    }

    dispatch(resetPreSavePrivatePerson());
  };

  return (
    <>
      <SelectMulti
        data={types}
        setSelect={setSelectedTypes}
        label="Тип контрагента"
        className={styles.select}
        selectedItems={selectedTypes}
        isWithReset={false}
      />

      <TabsPanel tabs={tabsData} color={'#F7A860'} setActive={setTab} className={styles.tabs} />

      {tab === PrivatePartnerTabs.COMMON && (
        <>
          <CommonTab
            commonInfo={partner?.commonInfo}
            check={checkCommonTab}
            setErrorList={(errors) => {
              setErrorShown(false);
              setErrorList({ ...errorList, ...{ common: errors.join(' ') } });
            }}
            setErrors={setErrorsCommonTab}
            markDirty={processSetDirty}
          />
        </>
      )}

      {tab === PrivatePartnerTabs.DOCUMENTS && (
        <DocumentsTab documents={partner?.documents} markDirty={processSetDirty} />
      )}

      {tab === PrivatePartnerTabs.HISTORY && <HistoryTab history={partner?.history} />}

      {partner === undefined && (
        <div className={styles.mtAuto}>
          <Controls
            btnSaveHandler={onSubmit}
            btnCancelHandler={closeForm}
            btnSaveLabel="Создать"
            btnCancelLabel="Отмена"
          />
        </div>
      )}

      {partner != undefined && tab === PrivatePartnerTabs.DOCUMENTS && (
        <div className={styles.editBlock}>
          <Button onClick={() => processSetDirty()} className={styles.addTrip}>
            Сохранить изменения
          </Button>
        </div>
      )}
    </>
  );
};
