import React, { useEffect, useState } from 'react';
import { Search } from 'components/Search';
import { InputDatePicker } from 'ui/InputDatePicker';

import styles from './GalleryTop.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TabsPanel } from 'ui/TabsPanel/TabsPanel';
import { GalleryProps } from '..';
import { GalleryTab } from 'core/constants/enums';
import { Button } from 'ui/Button';
import clsx from 'clsx';
import { addPhotoTag, getAlbumTags, getTags, savePhoto } from 'store/action-creators/gallery';
import { FilterNew } from 'ui/FilterNew/FilterNew';
import {
  setAlbumParamsDateFrom,
  setAlbumParamsDateTo,
  setAlbumParamsSearch,
  setAlbumParamsTags,
  setGalleryTab,
  setIsNeedReload,
  setParamsDateFrom,
  setParamsDateTo,
  setParamsPage,
  setParamsSearch,
  setParamsTags,
} from 'store/entities/gallery/gallerySlice';
import { DragDropFile } from 'components/DragFile';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { SavePhoto } from 'services/gallery/galleryApi.service';
import { RemoveIcon } from 'components/icons';
import { MultiSelector } from 'ui/MultiSelector';
import { Checkbox } from 'ui/Checkbox';
import { notifications } from '@mantine/notifications';
import { getTimezonelessDate } from 'core/utils/getDate';
import { Modal } from 'components/Modal';
import { AlbumCreate } from '../AlbumCreate';

export const GalleryTop = ({ tab, setTab }: GalleryProps) => {
  const dispatch = useAppDispatch();
  const tabsData = [GalleryTab.ALL_PHOTO, GalleryTab.ALBUMS];
  const [formData, setFormData] = useState<SavePhoto[]>([]);
  const [openedForm, setOpenedForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { tags, album_tags, isSuccess, isError } = useAppSelector((state) => state.gallerySlice);
  const [tagList, setTagList] = useState(tags);

  const editExistHotelRow = (key: number, value: Partial<SavePhoto>) => {
    const data = [...formData];
    data[key] = { ...data[key], ...value };
    setFormData(data);
  };
  //получение справочников с сервера
  useEffect(() => {
    dispatch(getTags());
    dispatch(getAlbumTags());
  }, []);

  const handleSearchClick = (query: string) => {
    if (tab === GalleryTab.ALL_PHOTO) {
      dispatch(setParamsSearch(query));
    } else {
      dispatch(setAlbumParamsSearch(query));
    }
    dispatch(setParamsPage(1));
  };
  const handleTags = (query: SelectMultiItemType) => {
    if (query) {
      if (tab === GalleryTab.ALL_PHOTO) {
        dispatch(setParamsTags([query['@id']!]));
      } else {
        dispatch(setAlbumParamsTags([query['@id']!]));
      }

      dispatch(setParamsPage(1));
    }
    if (query === null) {
      if (tab === GalleryTab.ALL_PHOTO) {
        dispatch(setParamsTags([]));
      } else {
        dispatch(setAlbumParamsTags([]));
      }

      dispatch(setParamsPage(1));
    }
  };
  const handleDataPicker = (value: [Date | null, Date | null]) => {
    console.log(value);
    if (value[0] != null) {
      if (tab === GalleryTab.ALL_PHOTO) {
        dispatch(setParamsDateFrom(getTimezonelessDate(new Date(value[0]!))));
        dispatch(
          setParamsDateTo(
            new Date(value[0]).getTime() === new Date(value[1]!).getTime()
              ? null
              : getTimezonelessDate(new Date(value[1]!))
          )
        );
      } else {
        dispatch(setAlbumParamsDateFrom(getTimezonelessDate(new Date(value[0]!))));
        dispatch(
          setAlbumParamsDateTo(
            new Date(value[0]).getTime() === new Date(value[1]!).getTime()
              ? null
              : getTimezonelessDate(new Date(value[1]!))
          )
        );
      }
    } else {
      if (tab === GalleryTab.ALL_PHOTO) {
        dispatch(setParamsDateFrom(null));
        dispatch(setParamsDateTo(null));
      } else {
        dispatch(setAlbumParamsDateFrom(null));
        dispatch(setAlbumParamsDateTo(null));
      }
    }
    dispatch(setParamsPage(1));
  };

  const handleAddFormData = (data: File[]) => {
    const formattedData = data.map((el) => {
      const name = el.name.replace(/\.[^/.]+$/, '');
      return {
        title: name,
        alt: '',
        file: el,
        photo_tags: [],
        is_license: false,
      };
    });

    setFormData((prev) => [...prev, ...formattedData]);
  };
  const handleDownLoad = () => {
    dispatch(savePhoto(formData));
  };

  useEffect(() => {
    if (isSuccess === 'ADD_PHOTO') {
      notifications.show({
        title: 'Успех!',
        color: 'green',
        message: 'Фото успешно сохранено!',
      });
      setFormData([]);
      setOpenedForm(false);
      setTimeout(function () {
        dispatch(setIsNeedReload(true));
      }, 1500);
    } else if (isError === 'ADD_PHOTO') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка сохранения данных на сервер',
      });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (tab) {
      dispatch(setGalleryTab(tab));
    }
  }, [tab]);

  useEffect(() => {
    if (tags) {
      setTagList(tags);
    }
  }, [tags]);

  return (
    <>
      <div className={styles.top}>
        <div className={styles.orders}>
          <h2 className={styles.title}>Галерея</h2>
        </div>
        <TabsPanel
          tabs={tabsData}
          tab={tab}
          setActive={(a) => {
            setTab && setTab(a);
          }}
          className={styles.tabs}
        />
        {openedForm && (
          <div className={clsx(styles.form)}>
            <div className={styles.formTitle}>
              <h3>Загрузка фото</h3>
              <Button className={styles.hideBtn} onClick={() => setOpenedForm(false)} width="200px">
                Скрыть загрузку
              </Button>
            </div>
            <div className={styles.formContent}>
              <DragDropFile setState={(data) => handleAddFormData(data)} />
              <div className={styles.loadedPhotoList}>
                <div className={styles.formsPhoto}>
                  {formData.map((item, idx) => (
                    <article className={styles.photoForm} key={idx}>
                      <div className={styles.photoFormImage}>
                        <img src={URL.createObjectURL(item.file as Blob)} alt={`img-${idx}`} />
                        <h2>{item.file.name}</h2>
                        <span>{`${(item.file.size / 1000).toFixed(2)} КБ`}</span>
                      </div>
                      <div className={styles.photoFormInputs}>
                        <div className={styles.inputGroup}>
                          <label>Название фото</label>
                          <input
                            type="text"
                            value={item.title}
                            onChange={(e) => editExistHotelRow(idx, { title: e.target.value })}
                          />
                        </div>
                        <div className={styles.inputGroup}>
                          <label>ALT фото</label>
                          <input
                            type="text"
                            value={item.alt}
                            onChange={(e) => editExistHotelRow(idx, { alt: e.target.value })}
                          />
                        </div>
                        <div className={styles.inputGroup}>
                          <label>Теги фото</label>
                          <MultiSelector
                            className={styles.select}
                            allItems={tagList}
                            defaultItems={item.photo_tags}
                            getItems={(value) => {
                              editExistHotelRow(idx, { photo_tags: value });
                            }}
                            creatable
                            onCreate={(query) => {
                              setTagList((prev) => [...prev, { name: query }]);
                              dispatch(addPhotoTag(query));

                              return query;
                            }}
                          />
                        </div>

                        <Checkbox
                          label="Лицензионное фото"
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          isChecked={item.is_license}
                          setIsChecked={(arg) => editExistHotelRow(idx, { is_license: arg })}
                        />
                      </div>
                      <button
                        className={styles.remove}
                        onClick={() => setFormData((prev) => prev.filter((el, id) => id != idx))}
                      >
                        <RemoveIcon width={16} height={16} fill="#C8CDD8" />
                      </button>
                    </article>
                  ))}
                </div>
              </div>
            </div>
            <Button className={styles.btn} onClick={() => handleDownLoad()} width="200px">
              Загрузить фото
            </Button>
          </div>
        )}

        <div className={styles.search}>
          <Search placeholder="Поиск" setQuery={handleSearchClick} />
          <InputDatePicker
            placeholder={tab === GalleryTab.ALL_PHOTO ? 'Дата загрузки' : 'Дата создания'}
            setDates={handleDataPicker}
          />
          <FilterNew
            filter={{
              title: 'Теги',
              data: tab === GalleryTab.ALL_PHOTO ? tags : album_tags,
            }}
            setFilter={(a) => handleTags(a!)}
          />
          {!openedForm && (
            <Button
              className={styles.btn}
              onClick={() => {
                tab === GalleryTab.ALL_PHOTO ? setOpenedForm(true) : setOpenModal(true);
              }}
              width="200px"
            >
              + {tab === GalleryTab.ALL_PHOTO ? 'Добавить фото' : 'Новый альбом'}
            </Button>
          )}
        </div>
      </div>
      {openModal && (
        <Modal opened={openModal} setOpened={setOpenModal}>
          <AlbumCreate />
        </Modal>
      )}
    </>
  );
};
