import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import { TabsPanel } from 'ui/TabsPanel/TabsPanel';
import {
  CommonInfoLegalPerson,
  LegalPerson,
  PartnerFormLegalPersonType,
  RequisitesLegalPerson,
} from './PartnerFormLegalPerson.types';
import { SelectMulti } from 'ui/SelectMulti';
import { HistoryTab } from '../HistoryTab';
import { Controls } from 'components/Controls';
import { CommonTab } from './CommonTab';
import { StatusPartner, LegalPartnerTabs, SUBJECT } from 'core/constants/enums';
import { RequisitesTab } from './RequisitesTab';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetPreSaveLegalPerson,
  setIsPartnerModal,
  setPreSaveLegalPerson,
  setPreSavePrivatePerson,
} from 'store/entities/partners/partnersSlice';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { addPartner, editPartner, getContactTypes } from 'store/action-creators/partners';
import { AddPartnerData } from 'services/partners/partnerApi.service';
import ModalContext from '../../../../core/context/ModalContext';

import styles from './PartnerFormLegalPerson.module.scss';
import { notifications } from '@mantine/notifications';
import { useLocation } from 'react-router-dom';
import { RouteNames } from 'core/constants/routeNames';
import { Button } from 'ui/Button';
import { Select } from 'ui/Select';
import { getStatusLKToServer } from 'core/utils/getStatus';

export const PartnerFormLegalPerson: FC<PartnerFormLegalPersonType> = ({ partner }) => {
  const [tabsData, setTabsData] = useState<string[]>([]);
  const [tab, setTab] = useState<string>('');
  const [errorList, setErrorList] = useState<{ [index: string]: string }>({});
  const [selectedTypes, setSelectedTypes] = useState<SelectMultiItemType[]>([]);
  const [status, setStatus] = useState<undefined | string>('');
  const [allValues, setAllValues] = useState<LegalPerson | any>();
  const { types, preSaveLegalPerson, preSavePrivatePerson, isSuccess, isError } = useAppSelector(
    (state) => state.partners
  );
  const { isOdrderModal } = useAppSelector((state) => state.orders);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [partnerData, setPartnerData] = useState<AddPartnerData>({
    subject: SUBJECT.LEGAL_PERSON,
    type: [
      /* '/api/v1/contact-types/1', '/api/v1/contact-types/2' */
    ],
  });

  //добавление параметров для запроса на сервер при изменении информации в типах контрагентов
  console.log(status);
  useEffect(() => {
    setPartnerData({
      ...partnerData,
      type: preSaveLegalPerson.type?.map((type) => type['@id']),
      registration_status: getStatusLKToServer(String(preSaveLegalPerson.registration_status)),
      document_template_path: preSaveLegalPerson.commonInfo.document_template_path,
      agreement_path: preSaveLegalPerson.commonInfo.agreement_path,
    });
  }, [
    preSaveLegalPerson.type,
    preSaveLegalPerson.registration_status,
    preSaveLegalPerson.commonInfo.document_template_path,
    preSaveLegalPerson.commonInfo.agreement_path,
  ]);
  //добавление параметров для запроса на сервер при изменении информации в общей вкладке
  useEffect(() => {
    setPartnerData({
      ...partnerData,
      commission: preSaveLegalPerson.commonInfo.commission === '' ? '0' : preSaveLegalPerson.commonInfo.commission,
      company: preSaveLegalPerson.commonInfo.name,
      fullName: preSaveLegalPerson.commonInfo.name,
      contactFullName: preSaveLegalPerson.commonInfo.contactPerson.name,
      phone: preSaveLegalPerson.commonInfo.contactPerson.phone,
      email: preSaveLegalPerson.commonInfo.contactPerson.email,
      note: preSaveLegalPerson.commonInfo.comments,
      register_number: preSaveLegalPerson.commonInfo.register_number,
      register_agent_number: preSaveLegalPerson.commonInfo.register_agent_number,
      cooperation_format: preSaveLegalPerson.commonInfo.cooperation_format,
      company_email: preSaveLegalPerson.commonInfo.company_email,
      contacts_array: preSaveLegalPerson.commonInfo.contacts_array
        ? preSaveLegalPerson.commonInfo.contacts_array.filter(
            (el) => el.email !== '' && el.name !== '' && el.phone !== ''
          )
        : [],
    });
  }, [preSaveLegalPerson.commonInfo]);

  //добавление параметров для запроса на сервер при изменении информации во вкладке с реквизитами
  useEffect(() => {
    setPartnerData({
      ...partnerData,
      requisites: preSaveLegalPerson.requisites.map((el) => {
        return {
          id: el.id,
          juriName: el.legalName,
          inn: el.inn,
          kpp: el.kpp,
          ogrn: el.ogrn,
          juriAddress: el.address?.legal,
          address: el.address?.real,
          contract: el.contract,
          managerFullName: el.responsiblePerson.name || '',
          registrationDocument: el.responsiblePerson.permission,
          bik: el.bik,
          position: el.position,
          corrAccount: el.correspondentAccount,
          payAccount: el.settlementAccount,
        };
      }),
    });
  }, [preSaveLegalPerson.requisites]);

  useEffect(() => {
    if (partner === null || partner === undefined) {
      setTabsData([LegalPartnerTabs.COMMON, LegalPartnerTabs.REQUISITES]);
    } else {
      setTabsData([LegalPartnerTabs.COMMON, LegalPartnerTabs.REQUISITES, LegalPartnerTabs.HISTORY]);
    }
  }, []);

  //устанавливаем тип контрагента при первой загрузке (если есть предсохраненные данные или существуют у контрагента)
  useEffect(() => {
    setSelectedTypes(
      (preSaveLegalPerson.type && preSaveLegalPerson.type?.length > 0 && preSaveLegalPerson.type) || partner?.type || []
    );
    setStatus(preSaveLegalPerson?.registration_status || partner?.registration_status || undefined);
  }, []);

  // контрагент помечен как изменившийся
  const processSetDirty = () => {
    if (partner !== undefined) {
      dispatch(editPartner({ id: partner.id?.toString() as string, data: { ...partnerData } }));
    }
  };

  const prevRows = useRef<SelectMultiItemType[]>();
  useEffect(() => {
    const { type = false } = allValues || {};
    if (
      (partner !== undefined &&
        prevRows.current &&
        prevStatus.current &&
        JSON.stringify(prevRows.current) !== JSON.stringify(type)) ||
      JSON.stringify(prevStatus.current) !== JSON.stringify(status)
    ) {
      processSetDirty();
    }
    prevRows.current = type;
    prevStatus.current = status;
  }, [allValues]);
  const prevStatus = useRef<string>();

  //при изменении выбранных типов, сохраняем в presave
  useEffect(() => {
    dispatch(setPreSaveLegalPerson({ ...preSaveLegalPerson, type: selectedTypes }));
    dispatch(setPreSavePrivatePerson({ ...preSavePrivatePerson, type: selectedTypes }));
    setAllValues({ ...allValues, type: selectedTypes });
  }, [selectedTypes]);
  useEffect(() => {
    dispatch(setPreSaveLegalPerson({ ...preSaveLegalPerson, registration_status: String(status) }));
    setAllValues({ ...allValues, registration_status: String(status) });
  }, [status]);

  //собираем данные из вкладки "общая информация"
  const setCommonInfo = (info: CommonInfoLegalPerson) => {
    setAllValues({ ...allValues, commonInfo: info });
  };

  //собираем данные из вкладки "реквизиты"
  const setRequisites = (info: RequisitesLegalPerson[]) => {
    setAllValues({ ...allValues, requisites: info });
  };

  //проверка успешности отправки запроса. если ок, закрываем окно, если нет - выводим сообщение об ошибке
  useEffect(() => {
    if (isSuccess === 'ADD_PARTNER') {
      closeForm();
    } else if (isError === 'ADD_PARTNER') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка сохранения данных на сервер',
      });
    }
  }, [isSuccess, isError]);
  //при нажатии "Сохранить" отправляем данные на сервер
  const onSubmit = () => {
    if (Object.values(errorList).join('').length !== 0) {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Заполните обязательные поля формы',
      });
      return;
    }
    dispatch(addPartner(partnerData));
    if (!partner) closeForm();
  };

  const { setActive } = useContext(ModalContext);
  const closeForm = () => {
    if (location.pathname === RouteNames.ORDERS || (location.pathname === RouteNames.PARTNERS && isOdrderModal)) {
      dispatch(setIsPartnerModal(false));
    } else {
      setActive(false);
    }
    dispatch(resetPreSaveLegalPerson());
  };

  //устанавливаем субъект
  useEffect(() => {
    setAllValues({ ...allValues, status: StatusPartner.LEGAL_PERSON });
  }, []);

  // загружаем список типов контрагентов
  useEffect(() => {
    dispatch(getContactTypes({ page: 1 }));
  }, []);

  return (
    <>
      <div className={styles.group}>
        <SelectMulti
          data={types}
          setSelect={(a) => {
            setSelectedTypes(a);
            dispatch(setPreSaveLegalPerson({ ...preSaveLegalPerson, type: a }));
          }}
          label="Тип контрагента"
          className={styles.select}
          selectedItems={selectedTypes}
          isWithReset={false}
        />
        <Select
          className={styles.statusSelect}
          label="Статус"
          data={['Нет ЛК', 'Неполный ЛК', 'Отправлен договор с ЛК', 'Подтвержденный ЛК']}
          selected={status ? status : 'Нет ЛК'}
          isWithReset={false}
          setSelect={(a) => {
            setStatus(a);
            dispatch(setPreSaveLegalPerson({ ...preSaveLegalPerson, registration_status: a }));
          }}
        />
      </div>

      <TabsPanel tabs={tabsData} color={'#F7A860'} setActive={setTab} className={styles.tabs} />

      {tab === LegalPartnerTabs.COMMON && (
        <CommonTab
          commonInfo={partner?.commonInfo}
          setCommonInfo={setCommonInfo}
          setErrorList={(errors) => {
            setErrorList({ ...errorList, ...{ common: errors.join(' ') } });
          }}
          markDirty={processSetDirty}
        />
      )}

      {tab === LegalPartnerTabs.REQUISITES && (
        <RequisitesTab requisites={partner?.requisites} setRequisites={setRequisites} markDirty={processSetDirty} />
      )}

      {tab === LegalPartnerTabs.HISTORY && <HistoryTab history={partner?.history} />}

      {partner === undefined && (
        <div className={styles.mtAuto}>
          <Controls
            btnSaveHandler={onSubmit}
            btnCancelHandler={closeForm}
            btnSaveLabel="Создать"
            btnCancelLabel="Отмена"
          />
        </div>
      )}
      {partner != undefined && tab === LegalPartnerTabs.REQUISITES && (
        <div className={styles.editBlock}>
          <Button onClick={() => processSetDirty()} className={styles.addTrip}>
            Сохранить изменения
          </Button>
        </div>
      )}
    </>
  );
};
