import React, { FC, useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import clsx from 'clsx';

import { PassportRfType } from 'components/Document/Document.types';
import { Input } from 'ui/Input';
import { InputDate } from 'ui/InputDate';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPreSavePrivatePerson } from 'store/entities/partners/partnersSlice';
import { DateIcon } from 'components/icons';

import styles from './PassportRf.module.scss';

interface PassportRfProps {
  passport?: PassportRfType;
  setData: (a: PassportRfType) => void;
}

export const PassportRf: FC<PassportRfProps> = ({ passport, setData }) => {
  const { preSavePrivatePerson, isResetPreSavePrivatePerson } = useAppSelector((state) => state.partners);
  const dispatch = useAppDispatch();
  const [blur, setBlur] = useState(false);

  const initialValues: PassportRfType = {
    number: preSavePrivatePerson?.documents?.passport?.number || passport?.number || '',
    dateOfIssue: preSavePrivatePerson?.documents?.passport?.dateOfIssue || passport?.dateOfIssue || null,
    validUntil: preSavePrivatePerson?.documents?.passport?.validUntil || passport?.validUntil || null,
    divisionCode: preSavePrivatePerson?.documents?.passport?.divisionCode || passport?.divisionCode || '',
    issuedByWhom: preSavePrivatePerson?.documents?.passport?.issuedByWhom || passport?.issuedByWhom || '',
    placeOfBirth: preSavePrivatePerson?.documents?.passport?.placeOfBirth || passport?.placeOfBirth || '',
    countryRegistration:
      preSavePrivatePerson?.documents?.passport?.countryRegistration || passport?.countryRegistration || '',
    placeOfStateRegistration:
      preSavePrivatePerson?.documents?.passport?.placeOfStateRegistration || passport?.placeOfStateRegistration || '',
  };

  const validate = {
    number: (value: string) => (value.length == 0 ? 'the field is required' : null),
    placeOfStateRegistration: (value: string) => (value.length == 0 ? 'the field is required' : null),
  };

  const formPassportRf = useForm({
    initialValues,
    validate,
    validateInputOnChange: true,
  });

  useEffect(() => {
    setData(formPassportRf.values);
  }, [formPassportRf.values]);

  //если форму начали заполнять и в ней нет ошибок, данные уходят в предсохранении и оттуда на сервер
  useEffect(() => {
    if (formPassportRf.values.number !== '' && JSON.stringify(formPassportRf.errors) === '{}') {
      if (passport?.id) {
        dispatch(
          setPreSavePrivatePerson({
            ...preSavePrivatePerson,
            documents: { ...preSavePrivatePerson.documents, passport: { ...formPassportRf.values, id: passport.id } },
          })
        );
      } else {
        dispatch(
          setPreSavePrivatePerson({
            ...preSavePrivatePerson,
            documents: { ...preSavePrivatePerson.documents, passport: formPassportRf.values },
          })
        );
      }
    } else {
      console.log('Не заполнены обязательные поля формы');
    }
  }, [formPassportRf.values, formPassportRf.errors]);

  //если первое поле начали заполнять - то все остальные уже обязательны
  useEffect(() => {
    if (blur) {
      formPassportRf.validate();
    }
  }, [blur]);

  // useEffect(() => {
  //   if (focus && Object.values(formPassportRf.errors).length === 0) {
  //     setFocus(false);
  //     markDirty();
  //   }
  // }, [formPassportRf.errors, focus]);

  useEffect(() => {
    if (isResetPreSavePrivatePerson) {
      formPassportRf.reset();
      formPassportRf.setFieldValue('number', initialValues.number);
      formPassportRf.setFieldValue('dateOfIssue', initialValues.dateOfIssue);
      formPassportRf.setFieldValue('validUntil', initialValues.validUntil);
      formPassportRf.setFieldValue('divisionCode', initialValues.divisionCode);
      formPassportRf.setFieldValue('issuedByWhom', initialValues.issuedByWhom);
      formPassportRf.setFieldValue('placeOfBirth', initialValues.placeOfBirth);
      formPassportRf.setFieldValue('placeOfStateRegistration', initialValues.placeOfStateRegistration);
    }
  }, [isResetPreSavePrivatePerson]);

  console.log(formPassportRf.values.dateOfIssue);
  return (
    <form>
      <div className={styles.group}>
        <Input
          type="text"
          label="Номер документа*"
          {...formPassportRf.getInputProps('number')}
          className={clsx(styles.input, styles.number)}
          onBlur={() => {
            setBlur(true);
          }}
          error={!!formPassportRf.errors.number}
        />
        <InputDate
          label="Дата выдачи"
          className={clsx(styles.input, styles.date)}
          placeholder="_ _._ _._ _ _ _"
          icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formPassportRf.errors.dateOfIssue}
          {...formPassportRf.getInputProps('dateOfIssue')}
          clearable
          clearButtonProps={{
            onClick: () => {
              formPassportRf.setFieldValue('dateOfIssue', null);
            },
          }}
        />
        <InputDate
          label="Действует до"
          className={clsx(styles.input, styles.date)}
          placeholder="_ _._ _._ _ _ _"
          icon={<DateIcon fill={'#C8CDD8'} width={14} height={14} />}
          error={!!formPassportRf.errors.validUntil}
          clearable
          {...formPassportRf.getInputProps('validUntil')}
          clearButtonProps={{
            onClick: () => {
              formPassportRf.setFieldValue('validUntil', null);
            },
          }}
        />
        <Input
          type="text"
          label="Код подразделения"
          {...formPassportRf.getInputProps('divisionCode')}
          className={clsx(styles.input, styles.number)}
          error={!!formPassportRf.errors.divisionCode}
          onWheel={(e: any) => e.target.blur()}
        />
      </div>
      <Input
        label="Кем выдан"
        {...formPassportRf.getInputProps('issuedByWhom')}
        className={styles.input}
        error={!!formPassportRf.errors.issuedByWhom}
      />
      <Input
        label="Место рождения"
        {...formPassportRf.getInputProps('placeOfBirth')}
        className={styles.input}
        error={!!formPassportRf.errors.placeOfBirth}
      />
      <Input
        label="Страна регистрации"
        {...formPassportRf.getInputProps('countryRegistration')}
        className={styles.input}
        error={!!formPassportRf.errors.countryRegistration}
      />
      <Input
        label="Место гос.регистрации*"
        {...formPassportRf.getInputProps('placeOfStateRegistration')}
        className={styles.input}
        error={!!formPassportRf.errors.placeOfStateRegistration}
      />
    </form>
  );
};
