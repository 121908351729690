import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const SettingsIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.86667" cy="1.86667" r="1.86667" fill={fill} />
      <circle cx="1.86667" cy="7.00003" r="1.86667" fill={fill} />
      <circle cx="1.86667" cy="12.1333" r="1.86667" fill={fill} />
    </svg>
  );
};
