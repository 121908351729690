import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';

export const transfersColumns: TableColumn[] = [
  {
    id: 'departurePoint',
    name: 'Место отправления',
  },
  {
    id: 'route',
    name: 'Маршрут',
  },
  {
    id: 'departureDate',
    name: 'Дата отправления',
  },
  {
    id: 'departureTime',
    name: 'Время отправления',
  },

  {
    id: 'transportCategory',
    name: 'Вид транспорта',
  },
  {
    id: 'transportType',
    name: 'Тип трансфера',
  },
  {
    id: 'places_count',
    name: 'Кол-во мест',
  },
  {
    id: 'price',
    name: 'Цена',
  },
];

export const departurePoints = ['Ижевск', 'Москва'];
export const routes = ['один', 'два', 'три'];
export const departureDates = ['11.10.23', '12.10.23', '18.10.23'];
export const departureTimes = ['12.40', '12.40', '12.40'];
export const transportTypes = ['машина', 'автобус', 'самолет'];
export const transportCategories = ['автомобиль', 'поезд', 'самолет'];
