import React, { FC } from 'react';
import { Modal } from '@mantine/core';

import { Button } from 'ui/Button';

import styles from './ModalConfirm.module.scss';

interface ModalConfirmProps {
  isOpen: boolean;
  text: string;
  handleYes: () => void;
  handleNo: () => void;
  onClose?: () => void;
  labelOne?: string;
  labelTwo?: string;
}

export const ModalConfirm: FC<ModalConfirmProps> = ({
  isOpen,
  handleYes,
  handleNo,
  text,
  onClose,
  labelOne,
  labelTwo,
}) => {
  return (
    <Modal
      className={styles.modalWrapper}
      opened={isOpen}
      onClose={onClose ? onClose : handleNo}
      withCloseButton={false}
      centered
      radius={'md'}
      padding={'xl'}
    >
      <p className={styles.textModal}>Вы уверены, что хотите {text}?</p>
      <div className={styles.buttons}>
        <Button onClick={handleYes} label={labelOne || 'Да'} />
        <Button onClick={handleNo} label={labelTwo || 'Отмена'} className={styles.btnCancel} />
      </div>
    </Modal>
  );
};
