import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';
import {
  Client1A1CIcon,
  Client1A2CIcon,
  Client1AIcon,
  Client2A1CIcon,
  Client2A2CIcon,
  Client2AIcon,
  Client3AIcon,
  ClientChildIcon,
} from 'components/icons';

export const hotelColumns: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Гостиница',
  },
  {
    id: 'typeRoom',
    name: 'Тип номера',
  },
  {
    id: 'category',
    name: 'Категория',
  },
  {
    id: 'food_type_string',
    name: 'Питание',
  },
  {
    id: 'places_count',
    name: 'Кол-во мест',
  },
  {
    id: 'checkbox',
    name: '',
  },
  {
    id: 'saled',
    name: 'Куплено',
  },
  {
    id: 'price1A',
    name: '1A',
    icon: <Client1AIcon width={18} height={18} fill="#263B53" />,
  },
  {
    id: 'price2A',
    name: '2A',
    icon: <Client2AIcon width={23} height={18} fill="#263B53" />,
  },
  {
    id: 'price3A',
    name: '3A',
    icon: <Client3AIcon width={29} height={18} fill="#263B53" />,
  },
  {
    id: 'price1A1C',
    name: '1A1C',
    icon: <Client1A1CIcon width={33} height={18} fill="#263B53" />,
  },
  {
    id: 'price1A2C',
    name: '1A2C',
    icon: <Client1A2CIcon width={46} height={18} fill="#263B53" />,
  },
  {
    id: 'price2A1C',
    name: '2A1C',
    icon: <Client2A1CIcon width={39} height={24} fill="#263B53" />,
  },
  {
    id: 'price2A2C',
    name: '2A2C',
    icon: <Client2A2CIcon width={53} height={24} fill="#263B53" />,
  },
];

export const onedayHotelColumn: TableColumn[] = [
  {
    id: 'hotel',
    name: 'Гостиница',
  },
  {
    id: 'food_type_string',
    name: 'Питание',
  },
  {
    id: 'places_count',
    name: 'Кол-во мест',
  },
  {
    id: 'checkbox',
    name: '',
  },
  {
    id: 'saled',
    name: 'Куплено',
  },
  {
    id: 'price1A',
    name: '1A',
    icon: <Client1AIcon width={18} height={18} fill="#263B53" />,
  },
  {
    id: 'price2A',
    name: '2A',
    icon: <ClientChildIcon width={23} height={18} fill="#263B53" />,
  },
];
