import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerTabs, SUBJECT } from 'core/constants/enums';
import { LegalPerson } from 'pages/Partners/components/PartnerFormLegalPerson/PartnerFormLegalPerson.types';
import { PrivatePerson } from 'pages/Partners/components/PartnerFormPrivatePerson/PartnerFormPrivatePerson.types';
import { PartnerResponse, PartnersResponse } from 'services/partners/PartnerResponse.model';
import { partnerLegalFactory, partnerPrivateFactory, partnersFactory } from 'services/partners/partners.factory';
import {
  addAgrementDocument,
  addDocument,
  addPartner,
  editPartner,
  getContactTypes,
  getPartner,
  getPartners,
  getPartnersNextPage,
  getPayersForSearch,
  getTouristsForSearch,
  getTourOperatorsForSearch,
  removePartnerDocument,
} from 'store/action-creators/partners';
import { PartnersState } from 'store/entities/partners/PartnersTypes';
import { PartnersDataSorting } from '../../../services/partners/partnerApi.service';
import { getDate } from 'core/utils/getDate';

const initialState: PartnersState = {
  isLoading: null,
  isSuccess: null,
  isError: null,
  partnersForSearch: [],
  touristsForSearch: [],
  tourOperatorsForSearch: [],
  payersForSearch: [],
  partners: [],
  partnersByPage: [],
  nextPageQuery: '',
  lastPage: 0,
  countPartners: 0,
  types: [],

  currentPrivatePersone: null,
  currentLegalPersone: null,
  baseLeganPerson: null,
  basePrivatePerson: null,
  isNeedToReloadTable: false,

  params: {
    currentPage: 1,
    subject: {
      name: PartnerTabs.ALL,
      nameForBack: null,
    },
    search: null,
    type: null,
    sort: { id: false },
    itemsPerPage: 50,
  },

  preSavePrivatePerson: {
    type: [],
    commonInfo: {
      lastName: '',
      firstName: '',
      patronymic: '',
      gender: '',
      birthDate: null,
      phone: '',
      email: '',
      comments: '',
    },
    documents: {
      mainDocument: '',
      passport: {
        number: '',
        dateOfIssue: null,
        validUntil: null,
        divisionCode: '',
        issuedByWhom: '',
        placeOfBirth: '',
        placeOfStateRegistration: '',
      },
      interPassport: {
        lastName: '',
        firstName: '',
        number: '',
        dateOfIssue: null,
        validUntil: null,
        divisionCode: '',
        issuedByWhom: '',
        placeOfBirth: '',
        placeOfStateRegistration: '',
      },
      birthCertificate: {
        number: '',
        dateOfIssue: null,
        father: '',
        mother: '',
        placeOfStateRegistration: '',
      },
      foreignDocument: {
        lastName: '',
        firstName: '',
        citizenship: '',
        number: '',
        dateOfIssue: null,
        placeOfStateRegistration: '',
      },
    },
  },
  isResetPreSavePrivatePerson: false,
  preSaveLegalPerson: {
    registration_status: undefined,
    type: [],
    commonInfo: {
      name: '',
      cooperation_format: '',
      company_email: '',
      commission: null,
      contactPerson: {
        name: '',
        phone: '',
        email: '',
      },
      contacts_array: [],
      document_template_path: '',
    },
    requisites: [],
  },
  isResetPreSaveLegalPerson: false,

  isPartnerModal: false,
};

export const partnersSlice = createSlice({
  name: 'partners',
  initialState: initialState,
  reducers: {
    setParamsPage(state, action: PayloadAction<number>) {
      state.params.currentPage = action.payload;
    },
    setParamsSubject(state, action: PayloadAction<{ name: string; nameForBack: string | null }>) {
      state.params.subject = action.payload;
    },
    setParamsSearch(state, action: PayloadAction<string>) {
      state.params.search = action.payload;
    },
    clearNextQuery(state) {
      state.nextPageQuery = '' + '';
    },
    setParamsType(state, action: PayloadAction<string | null>) {
      state.params.type = action.payload;
    },
    setIsNeedToReloadTable(state, action: PayloadAction<boolean>) {
      state.isNeedToReloadTable = action.payload;
    },
    setParamsSortByName(state, action: PayloadAction<'desc' | 'asc'>) {
      state.params.sort = { ...state.params.sort, full_name: action.payload === 'asc' };
    },
    setParamsSort(state, action: PayloadAction<Partial<PartnersDataSorting>>) {
      state.params.sort = action.payload;
    },

    setMainDocument(state, action: PayloadAction<string | null>) {
      state.preSavePrivatePerson.documents.mainDocument = action.payload;
    },
    removeDocument(
      state,
      action: PayloadAction<'passport' | 'interPassport' | 'birthCertificate' | 'foreignDocument'>
    ) {
      const key = action.payload;
      switch (key) {
        case 'passport':
          state.preSavePrivatePerson.documents[key] = null;
          break;
        case 'interPassport':
          state.preSavePrivatePerson.documents[key] = null;
          break;
        case 'birthCertificate':
          state.preSavePrivatePerson.documents[key] = null;
          break;
        case 'foreignDocument':
          state.preSavePrivatePerson.documents[key] = null;
          break;
        default:
          throw new Error('Unreachable statement [removeDocument]');
      }
    },

    setPreSavePrivatePerson(state, action: PayloadAction<PrivatePerson>) {
      state.preSavePrivatePerson = action.payload;
    },
    resetPreSavePrivatePerson: (state) => {
      state.preSavePrivatePerson = initialState.preSavePrivatePerson;
      state.isResetPreSavePrivatePerson = true;
      state.currentPrivatePersone = null;
    },
    setPreSaveLegalPerson(state, action: PayloadAction<LegalPerson>) {
      state.preSaveLegalPerson = action.payload;
    },
    resetPreSaveLegalPerson: (state) => {
      state.preSaveLegalPerson = initialState.preSaveLegalPerson;
      state.isResetPreSaveLegalPerson = true;
      state.currentLegalPersone = null;
    },
    getBaseLeganPerson: (state) => {
      state.preSaveLegalPerson =
        state.baseLeganPerson != null && state.basePrivatePerson === null
          ? state.baseLeganPerson
          : initialState.preSaveLegalPerson;
      state.currentLegalPersone = state.basePrivatePerson === null ? state.baseLeganPerson : null;
      state.baseLeganPerson = null;
    },
    getBasePrivatePerson: (state) => {
      state.preSavePrivatePerson =
        state.basePrivatePerson != null && state.baseLeganPerson === null
          ? state.basePrivatePerson
          : initialState.preSavePrivatePerson;
      state.currentPrivatePersone = state.baseLeganPerson === null ? state.basePrivatePerson : null;
      state.basePrivatePerson = null;
    },
    setIsPartnerModal: (state, action: PayloadAction<boolean>) => {
      state.isPartnerModal = action.payload;
    },
    resetPartner: (state) => {
      state.partners = [];
    },
    resetTourists: (state) => {
      state.touristsForSearch = [];
    },
  },

  extraReducers: (builder) => {
    //добавление партнера
    builder.addCase(addPartner.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isNeedToReloadTable = true;
      state.isSuccess = 'ADD_PARTNER';
    });
    builder.addCase(addPartner.pending, (state) => {
      state.isLoading = 'ADD_PARTNER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(addPartner.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'ADD_PARTNER';
    });
    builder.addCase(addDocument.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'ADD_DOC';
      state.preSaveLegalPerson.commonInfo.document_template_path = action.payload.path;
    });
    builder.addCase(addAgrementDocument.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isError = null;
      state.preSaveLegalPerson.commonInfo.agreement_path = [
        ...state.preSaveLegalPerson.commonInfo.agreement_path,
        action.payload.path,
      ];
    });
    //получение списка типов для контрагента
    builder.addCase(getContactTypes.fulfilled, (state, action) => {
      state.isLoading = null;
      state.isSuccess = 'GET_CONTACT_TYPES';
      state.types = action.payload['hydra:member'];
    });
    builder.addCase(getContactTypes.pending, (state) => {
      state.isLoading = 'GET_CONTACT_TYPES';
    });
    builder.addCase(getContactTypes.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_CONTACT_TYPES';
    });

    //получение списка контрагентов
    builder.addCase(getPartners.fulfilled, (state, action: PayloadAction<PartnersResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_PARTNERS';
      state.isError = null;
      state.isNeedToReloadTable = false;

      const payloadPreparedData = action.payload['hydra:member'].map((partner) => partnersFactory(partner));
      //TODO move that to another place for decompose
      state.partnersByPage[1] = payloadPreparedData;
      state.lastPage = 1;

      state.partners = payloadPreparedData;
      state.countPartners = action.payload['hydra:totalItems'];
    });
    builder.addCase(getPartners.pending, (state) => {
      state.isLoading = 'GET_PARTNERS';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getPartners.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_PARTNERS';
    });
    //получение списка туристов для поиска в заказах
    builder.addCase(getTouristsForSearch.fulfilled, (state, action: PayloadAction<PartnersResponse>) => {
      //TODO move that to another place for decompose
      state.touristsForSearch = action.payload['hydra:member'].map((partner) => ({
        id: partner.id,
        name: partner.full_name,
        birthday: partner.birthday ? getDate(new Date(partner.birthday)) : null,
        phone: partner.phone ? partner.phone : null,
        code: partner.contact_passport && partner.contact_passport.code ? partner.contact_passport.code : null,
      }));
    });
    //получение списка туристов для поиска в заказах
    builder.addCase(getTourOperatorsForSearch.fulfilled, (state, action: PayloadAction<PartnersResponse>) => {
      //TODO move that to another place for decompose
      state.tourOperatorsForSearch = action.payload['hydra:member'].map((partner) => ({
        id: partner.id,
        name: partner.full_name,
        requisites: partner.requisites
          ? partner.requisites?.map((el) => {
              return { id: el['@id'], legalName: el.juri_name };
            })
          : [],
      }));
    });
    //получение списка плательщиков для поиска в заказах
    builder.addCase(getPayersForSearch.fulfilled, (state, action: PayloadAction<PartnersResponse>) => {
      //TODO move that to another place for decompose
      state.payersForSearch = action.payload['hydra:member'].map((partner) => ({
        id: partner.id,
        name: partner.full_name,
        contact: partner.company,
        phone: partner.phone,
        email: partner.email,
      }));
    });
    //получение списка контрагентов
    builder.addCase(getPartnersNextPage.fulfilled, (state, action: PayloadAction<PartnersResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_PARTNERS_NEXT';
      state.isError = null;

      const payloadPreparedData = action.payload['hydra:member'].map((partner) => partnersFactory(partner));
      //TODO move that to another place for decompose
      const pageRegexResult = /page=(\d)/gm.exec(action.payload['hydra:view']['@id']);
      const lastPageRegexResult = /page=(\d)/gm.exec(action.payload['hydra:view']['hydra:last']);
      const nextPageRegexResult = /page=(\d)/gm.exec(action.payload['hydra:view']['hydra:next'] || '') || [];
      if (pageRegexResult && pageRegexResult[1]) {
        state.partnersByPage[Number(pageRegexResult[1])] = payloadPreparedData;
        state.lastPage = Number(lastPageRegexResult ? lastPageRegexResult[1] : 0);
        const nextPage = Number(nextPageRegexResult ? nextPageRegexResult[1] : 0);
        if (nextPage && state.lastPage >= nextPage) {
          state.nextPageQuery = action.payload['hydra:view']['hydra:next'] || '';
        }
      } else {
        state.partnersByPage[1] = payloadPreparedData;
        state.lastPage = 1;
      }
      state.countPartners = action.payload['hydra:totalItems'];
    });
    builder.addCase(getPartnersNextPage.pending, (state) => {
      state.isLoading = 'GET_PARTNERS_NEXT';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getPartnersNextPage.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_PARTNERS_NEXT';
    });

    //получение контрагента по ip
    builder.addCase(getPartner.fulfilled, (state, action: PayloadAction<PartnerResponse>) => {
      state.isLoading = null;
      state.isSuccess = 'GET_PARTNER';
      state.isError = null;
      switch (action.payload.subject) {
        case SUBJECT.LEGAL_PERSON:
          if (state.baseLeganPerson === null) {
            state.baseLeganPerson = partnerLegalFactory(action.payload);
          }
          state.currentLegalPersone = partnerLegalFactory(action.payload);
          state.preSaveLegalPerson = partnerLegalFactory(action.payload);
          break;
        case SUBJECT.PRIVATE_PERSON:
          if (state.basePrivatePerson === null) {
            state.basePrivatePerson = partnerPrivateFactory(action.payload);
          }
          state.currentPrivatePersone = partnerPrivateFactory(action.payload);
          state.preSavePrivatePerson = partnerPrivateFactory(action.payload);
          break;
        default:
          break;
      }
    });
    builder.addCase(getPartner.pending, (state) => {
      state.isLoading = 'GET_PARTNER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(getPartner.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'GET_PARTNER';
    });

    //редактирование партнера
    builder.addCase(editPartner.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isNeedToReloadTable = true;
      state.isSuccess = 'EDIT_PARTNER';
    });
    builder.addCase(editPartner.pending, (state) => {
      state.isLoading = 'EDIT_PARTNER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(editPartner.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'EDIT_PARTNER';
    });

    //Удаление документа партнера
    builder.addCase(removePartnerDocument.fulfilled, (state) => {
      state.isLoading = null;
      state.isError = null;
      state.isSuccess = 'EDIT_PARTNER';
    });
    builder.addCase(removePartnerDocument.pending, (state) => {
      state.isLoading = 'EDIT_PARTNER';
      state.isSuccess = null;
      state.isError = null;
    });
    builder.addCase(removePartnerDocument.rejected, (state) => {
      state.isLoading = null;
      state.isSuccess = null;
      state.isError = 'EDIT_PARTNER';
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setParamsPage,
  setParamsSubject,
  getBaseLeganPerson,
  getBasePrivatePerson,
  setParamsSearch,
  setIsNeedToReloadTable,
  setParamsType,
  setParamsSort,
  setParamsSortByName,
  setPreSavePrivatePerson,
  resetPreSavePrivatePerson,
  setPreSaveLegalPerson,
  resetPreSaveLegalPerson,
  setMainDocument,
  clearNextQuery,
  resetTourists,
  removeDocument,
  setIsPartnerModal,
  resetPartner,
} = partnersSlice.actions;

export default partnersSlice.reducer;
