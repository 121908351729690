import React, { FC, useEffect, useState } from 'react';
import styles from './OrdersTable.module.scss';
import clsx from 'clsx';
import { Loader } from '@mantine/core';
import { Pagination } from '@mantine/core';
import { Modal } from 'components/Modal';
import { OrderProps } from '../Order.types';
import { User } from 'components/User';
import { OrderEdit } from '../OrderEdit';
import { ordersColumns } from './data';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getOrder, getOrders, updateOrder } from 'store/action-creators/orders';
import { getPartners } from '../../../store/action-creators/partners';
import { getUsers } from '../../../store/action-creators/users';
import {
  resetPreSaveOrder,
  setIsNeedReload,
  setParamsPage,
  setParamsSortByData,
  setParamsSortById,
  setParamsSortByPartner,
  setParamsSortByStatus,
  setParamsSortByTotalSum,
} from '../../../store/entities/orders/ordersSlice';
import { getDate } from 'core/utils/getDate';
import { getStatus, getStatusColor, getStatusToServer } from 'core/utils/getStatus';
import { getInitialsFromName, getNameSurname } from 'core/utils/getInitials';
import { Order, OrderData } from 'services/orders/orderApi.service';
import { CellWithSorting } from 'components/CellWithSorting';
import { listOfIdsOrderStatuses, listOfIdsTypePlacements, nightCountsList } from 'core/constants/lists';
import { OrderStatus } from 'core/constants/enums';
import { orderSalesOut, orderTransferOut } from 'services/orders/order.factory';
import { resetSelectedTour } from 'store/entities/tours/toursSlice';
import { getCurrencyIconToUI } from 'core/utils/getCurrency';
import { RouteNames } from 'core/constants/routeNames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const OrdersTable: FC = () => {
  const { orders, preSaveOrder, params, orderCount, isLoading, isSuccess, isNeedToReload } = useAppSelector(
    (state) => state.orders
  );
  const navigate = useNavigate();
  const paramsPartner = useAppSelector((state) => state.partners.params);
  const [orderToEdit, setOrderToEdit] = useState<OrderProps | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [ordersData, setOrdersData] = useState<OrderData | null>(null);
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [countPages, setCountPages] = useState<number>(1);
  const [modal, setModal] = useState(false);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const handleClickRow = (item: OrderProps) => {
    setSelectedId(Number(item.id));
    navigate(`${RouteNames.ORDERS}/${item.id}`);
    setModal(true);
  };

  useEffect(() => {
    if (id && !modal && !selectedId) {
      setSelectedId(Number(id));
      dispatch(getOrder({ id: Number(id) }));
      setModal(true);
    }
  }, [id, selectedId]);

  const tourDateList = useAppSelector((state) => state.tours.tourDateList);
  const tourIds = tourDateList.map((item) => {
    return {
      id: item.id,
      tour: getDate(new Date(item.start)) + '-' + getDate(new Date(item.ending)),
    };
  });

  // для первой страницы устанавливаем первую загрузку данных,
  // для остальных подтягиваем дополнительные, полученные при скролле
  // небольшая имитация первой загрузки страницы
  useEffect(() => {
    // if (params.currentPage === 1) {
    setOrderList(orders);
    // } else {
    // setOrderList((pre: any) => [...pre, ...orders]); // баг - заказы дублировались, а надо дубли отсекать.

    // setOrderList((pre: any) => {
    //   const combinedArray = pre.concat(orders);
    //   const uniqueArray = combinedArray.filter(
    //     (item: any, index: number, arr: any[]) => arr.findIndex((el) => el.id === item.id) === index
    //   );
    //   return uniqueArray;
    // });
    // }
  }, [orders]);

  //при изменении значений фильтров, меняем параметры для запроса на сервер
  useEffect(() => {
    setOrdersData({
      ...ordersData,
      page: params.currentPage,
      search: params.search === '' ? null : params.search,
      manager: params.manager === '' ? null : params.manager,
      status: params.status,
      dateFrom: params['created_at[after]'] ? params['created_at[after]'] : null,
      dateTo: params['created_at[before]'] ? params['created_at[before]'] : null,
      sortById: params.sortById ? params.sortById : null,
      sortByPartner: params.sortByPartner ? params.sortByPartner : null,
      itemsPerPage: params.itemsPerPage,
      sortbyData: params.sortbyData ? params.sortbyData : null,
      sortbyStatus: params.sortbyStatus ? params.sortbyStatus : null,
      sortbyTotalSum: params.sortbyTotalSum ? params.sortbyTotalSum : null,
      tour_type: params.tour_type === '' ? null : params.tour_type,
      isConsultations: params.isConsultations ? params.isConsultations : null,
    });
  }, [params]);

  // при изменении параметров для запроса, отправляем запрос на получение заказов
  useEffect(() => {
    if (ordersData) {
      dispatch(getOrders(ordersData));
    }
  }, [ordersData]);
  useEffect(() => {
    if (isNeedToReload) {
      dispatch(getOrders(ordersData!));
      dispatch(setIsNeedReload(false));
    }
  }, [ordersData, isNeedToReload]);
  useEffect(() => {
    if (selectedId) {
      dispatch(getOrder({ id: selectedId }));
    }
  }, [selectedId]);

  useEffect(() => {
    if (!modal) {
      setSelectedId(null);
      setOrderToEdit(null);
      dispatch(resetPreSaveOrder());
      dispatch(resetSelectedTour());
    }
  }, [modal]);

  useEffect(() => {
    if (preSaveOrder) {
      setOrderToEdit(preSaveOrder);
    }
  }, [preSaveOrder]);

  useEffect(() => {
    dispatch(
      getPartners({
        search: paramsPartner.search === '' ? null : paramsPartner.search,
        subject: paramsPartner.subject.nameForBack,
        type: paramsPartner.type,
        sort: paramsPartner.sort,
      })
    );
  }, []);

  const handleSortByColumn = (sort: 'asc' | 'desc', column: string) => {
    if (column === 'id') {
      dispatch(setParamsSortById(sort));
      dispatch(setParamsSortByPartner(null));
      dispatch(setParamsSortByData(null));
      dispatch(setParamsSortByStatus(null));
      dispatch(setParamsSortByTotalSum(null));
      dispatch(setParamsPage(1));
    }
    if (column === 'partner') {
      dispatch(setParamsSortById(null));
      dispatch(setParamsSortByPartner(sort));
      dispatch(setParamsSortByData(null));
      dispatch(setParamsSortByStatus(null));
      dispatch(setParamsSortByTotalSum(null));
      dispatch(setParamsPage(1));
    }
    if (column === 'bookingDate') {
      dispatch(setParamsSortById(null));
      dispatch(setParamsSortByPartner(null));
      dispatch(setParamsSortByData(sort));
      dispatch(setParamsSortByStatus(null));
      dispatch(setParamsSortByTotalSum(null));
      dispatch(setParamsPage(1));
    }
    if (column === 'status') {
      dispatch(setParamsSortById(null));
      dispatch(setParamsSortByPartner(null));
      dispatch(setParamsSortByData(null));
      dispatch(setParamsSortByStatus(sort));
      dispatch(setParamsSortByTotalSum(null));
      dispatch(setParamsPage(1));
    }
    if (column === 'price') {
      dispatch(setParamsSortById(null));
      dispatch(setParamsSortByPartner(null));
      dispatch(setParamsSortByData(null));
      dispatch(setParamsSortByStatus(null));
      dispatch(setParamsSortByTotalSum(sort));
      dispatch(setParamsPage(1));
    }
  };

  // высчитываем кол-во страниц в зависимости от кол-ва элементов и числа элементов на странице
  useEffect(() => {
    setCountPages(Math.ceil(orderCount / params.itemsPerPage));
  }, [orderCount, params.itemsPerPage]);

  // сохранение всех данных при закрытии окна
  const closeOrderModal = () => {
    if (
      preSaveOrder.basicInfo?.status === OrderStatus.FINISHED ||
      preSaveOrder.basicInfo?.status === OrderStatus.REFUSAL ||
      preSaveOrder.basicInfo?.status === OrderStatus.CANCELLED
    ) {
      return;
    }
    // сбор дат тура
    const tour_date =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.date
        ? tourIds.find((el) => el.tour.toString() === preSaveOrder!.tourInfo!.date!.toString())
        : undefined;

    // сбор дат начала и конца тура
    const tourDates =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo?.isCruize && preSaveOrder.tourInfo.hotels.length > 0
        ? [
            getDate(new Date(preSaveOrder.tourInfo.hotels[0].period[0]!)),
            getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
          ]
        : preSaveOrder.tourInfo?.isHotelTransfer &&
          preSaveOrder.tourInfo.transfers.length > 0 &&
          preSaveOrder.tourInfo.hotels.length === 0
        ? [getDate(new Date(preSaveOrder.tourInfo.transfers[0].departureDate!)), undefined]
        : preSaveOrder.tourInfo?.isHotelTransfer &&
          preSaveOrder.tourInfo.transfers.length > 0 &&
          preSaveOrder.tourInfo.hotels.length > 0
        ? [
            getDate(new Date(preSaveOrder.tourInfo.transfers[0].departureDate!)),
            getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
          ]
        : preSaveOrder.tourInfo?.isHotelTransfer &&
          preSaveOrder.tourInfo.transfers.length === 0 &&
          preSaveOrder.tourInfo.hotels.length > 0
        ? [
            getDate(new Date(preSaveOrder.tourInfo.hotels[0].period[0]!)),
            getDate(new Date(preSaveOrder.tourInfo.hotels[preSaveOrder.tourInfo.hotels.length - 1].period[1]!)),
          ]
        : preSaveOrder.tourInfo?.date
        ? preSaveOrder.tourInfo.date.toString().split('-')
        : null;

    // сбор туристов
    const newTourists =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.tourists.length > 0
        ? preSaveOrder.tourInfo.tourists.map((tourist) => {
            return `/api/v1/contacts/${tourist.id.toString()}`;
          })
        : [];

    // сбор размещений
    const newPlacements =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.hotels.length > 0
        ? preSaveOrder.tourInfo.hotels.map((hotel) => {
            const newArrTurists = hotel.tourist.map((el) => {
              const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
              return newId && `/api/v1/contacts/${newId.id.toString()}`;
            });
            const nightCount = hotel.nights_count
              ? Number(nightCountsList.find((el) => el.value === String(hotel.nights_count))?.id)
              : 0;
            const periodSplit =
              hotel.period_string && hotel.period_string != '' ? hotel.period_string.split('-') : null;
            const type = Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id);
            if (hotel.id) {
              return {
                '@id': `/api/v1/order-person-placements/${hotel.id}`,
                id: hotel.id,
                date_start:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[0] != null
                    ? getDate(new Date(hotel.period[0]!))
                    : periodSplit != null
                    ? periodSplit[0]
                    : null,
                date_end:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[1] != null
                    ? getDate(new Date(hotel.period[1]!))
                    : periodSplit != null
                    ? periodSplit[1]
                    : null,
                period: hotel.period_string,
                nights_count: nightCount,
                address: hotel.address,
                boat_string: hotel.boat_string,
                room_level_string: hotel.room_level_string,
                room_number: hotel.room_number,
                hotel_string: hotel.hotel,
                room_type_string: hotel.type,
                food_type: hotel.food_type,
                room_category_string: hotel.category,
                placement_type: Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id) || 0,
                price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
                tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
              };
            } else {
              return {
                date_start:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[0] != null
                    ? getDate(new Date(hotel.period[0]!))
                    : periodSplit != null
                    ? periodSplit[0]
                    : null,
                date_end:
                  (preSaveOrder.tourInfo?.isCruize || preSaveOrder.tourInfo?.isHotelTransfer) && hotel.period[1] != null
                    ? getDate(new Date(hotel.period[1]!))
                    : periodSplit != null
                    ? periodSplit[1]
                    : null,
                period: hotel.period_string,
                nights_count: nightCount,
                address: hotel.address,
                boat_string: hotel.boat_string,
                room_level_string: hotel.room_level_string,
                room_number: hotel.room_number,
                hotel_string: hotel.hotel,
                room_type_string: hotel.type,
                food_type: hotel.food_type,
                room_category_string: hotel.category,
                placement_type:
                  hotel.accommodation === 'Реб.'
                    ? 2
                    : Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id) || 0,
                price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
                tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
              };
            }
          })
        : [];

    // сбор доп.ночей
    const newExtraNights =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.extraNights.length > 0
        ? preSaveOrder.tourInfo.extraNights.map((hotel) => {
            const newArrTurists = hotel.tourist.map((el) => {
              const newId = preSaveOrder.tourInfo?.tourists.find((partner) => partner.name === el);
              return newId && `/api/v1/contacts/${newId.id.toString()}`;
            });
            const type = Number(listOfIdsTypePlacements.find((i) => i.value === hotel.accommodation)?.id);
            return {
              id: hotel.id ? `/api/v1/order-additional-nights/${hotel.id}` : null,
              date_start: hotel.period[0] != null ? getDate(new Date(hotel.period[0]!)) : undefined,
              date_end: hotel.period[1] != null ? getDate(new Date(hotel.period[1]!)) : undefined,
              hotel_string: hotel.hotel,
              room_type_string: hotel.type,
              room_category_string: hotel.category,
              food_type: hotel.food_type,
              placement_type: type ? type : 0,
              price: hotel.price.toString() != '' ? hotel.price.toString() : '0',
              tourists: newArrTurists.length > 0 ? newArrTurists.filter((el) => el != null) : [],
              comission: hotel.comission.toString(),
            };
          })
        : [];
    // сбор доп.услуг
    const newServices =
      preSaveOrder.tourInfo && preSaveOrder.tourInfo.extraService.length > 0
        ? preSaveOrder.tourInfo.extraService.map((service) => {
            return {
              name: service.service,
              price: service.price != '' ? service.price : '0',
              quantity: service.quantity,
              summ: (service.quantity * Number(service.price)).toString(),
              description: service.description,
            };
          })
        : [];

    const newBasePrice = preSaveOrder.tourInfo?.base_price ? preSaveOrder.tourInfo?.base_price : '0';

    const baseSum =
      preSaveOrder.documentsAndPayment && +preSaveOrder.documentsAndPayment.changed_baseSum > 0
        ? preSaveOrder.documentsAndPayment.changed_baseSum
        : Number(
            preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          ) +
            Number(
              preSaveOrder.tourInfo?.transfers?.reduce((accumulator, object) => {
                return accumulator + Number(object.price);
              }, 0)
            ) ===
          0
        ? Number(preSaveOrder.tourInfo?.base_price)
        : Number(
            preSaveOrder.tourInfo?.hotels?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          ) +
          Number(
            preSaveOrder.tourInfo?.transfers?.reduce((accumulator, object) => {
              return accumulator + Number(object.price);
            }, 0)
          );
    // Формирование суммы доп.ночей
    const extraNightSum =
      preSaveOrder.tourInfo?.extraNights.reduce((accumulator, object) => {
        return accumulator + Number(object.price);
      }, 0) || 0;

    // Формирование суммы доп.услуг
    const extraServiceSum =
      preSaveOrder.tourInfo?.extraService.reduce((accumulator, object) => {
        return accumulator + Number(object.price) * object.quantity;
      }, 0) || 0;

    // Формирование суммы скидок
    const extraSalesSum =
      preSaveOrder.tourInfo?.sales.reduce((accumulator, object) => {
        return accumulator + Number(object.sale) * Number(object.quantity);
      }, 0) || 0;
    const serviceSum = +baseSum + extraNightSum + extraServiceSum;
    const allPrice = serviceSum - extraSalesSum - Number(preSaveOrder.documentsAndPayment?.agent_reward);
    const persent = preSaveOrder.documentsAndPayment?.manager_sale_percent
      ? (allPrice * +preSaveOrder.documentsAndPayment?.manager_sale_percent) / 100
      : 0;
    const allSum =
      preSaveOrder?.documentsAndPayment && persent === 0
        ? allPrice - +preSaveOrder?.documentsAndPayment?.manager_sale
        : allPrice - persent;
    console.log(allSum);
    if (orderToEdit?.['@id']) {
      const data = {
        contact: `/api/v1/contacts/${
          orderToEdit.basicInfo && orderToEdit.basicInfo.partner && orderToEdit.basicInfo.partner.id
        }`,
        manager: `/api/v1/users/${
          preSaveOrder.basicInfo && preSaveOrder.basicInfo.manager && preSaveOrder.basicInfo.manager.id
        }`,
        status: `/api/v1/order-statuses/${
          listOfIdsOrderStatuses.find((i) => i.value === preSaveOrder.basicInfo!.status)?.id
        }`,
        payed_status: preSaveOrder.basicInfo?.payed_status && getStatusToServer(preSaveOrder.basicInfo?.payed_status),
        return_summ:
          preSaveOrder.basicInfo && preSaveOrder.basicInfo.refundAmount
            ? preSaveOrder.basicInfo.refundAmount.toString()
            : '0',
        tour:
          preSaveOrder.tourInfo && preSaveOrder.tourInfo.tour && preSaveOrder.tourInfo.tour.id != 0
            ? `/api/v1/tours/${preSaveOrder.tourInfo.tour!.id}`
            : undefined,
        tour_date: tour_date ? `/api/v1/tour-dates/${tour_date?.id}` : null,
        children_count: preSaveOrder.tourInfo && Number(preSaveOrder.tourInfo.children),
        adults_count: preSaveOrder.tourInfo && Number(preSaveOrder.tourInfo.adults),
        base_price: newBasePrice,
        base_code: preSaveOrder.tourInfo && preSaveOrder.tourInfo.operatorApplicationNo,
        is_individual: preSaveOrder.tourInfo && preSaveOrder.tourInfo.individual,
        is_foreign_passport: preSaveOrder.tourInfo && preSaveOrder.tourInfo.internationalPassport,
        is_hotel: preSaveOrder.tourInfo && preSaveOrder.tourInfo.isHotelTransfer,
        is_cruise: preSaveOrder.tourInfo && preSaveOrder.tourInfo.isCruize,
        tour_type: preSaveOrder.tourInfo?.isCruize
          ? 'cruise'
          : preSaveOrder.tourInfo?.isHotelTransfer
          ? 'hotel'
          : 'tour',
        tour_name: preSaveOrder.tourInfo?.isCruize
          ? preSaveOrder.tourInfo.tour_name
          : preSaveOrder.tourInfo?.isHotelTransfer && preSaveOrder.tourInfo.hotels.length > 0
          ? preSaveOrder.tourInfo.hotels[0].hotel
          : preSaveOrder.tourInfo?.tour?.title,
        tour_date_start: tourDates ? tourDates![0] : undefined,
        tour_date_end: tourDates ? tourDates![1] : undefined,
        reserve_system_name: preSaveOrder.tourInfo?.reserve_system_name,
        tourists: newTourists,
        placements: newPlacements,
        show_akvarel: preSaveOrder.tourInfo?.show_akvarel,
        order_additional_nights: newExtraNights,
        services_array: newServices,
        sales_array: preSaveOrder.tourInfo?.sales.map((sale) => orderSalesOut(sale)),
        order_transfers: preSaveOrder.tourInfo?.transfers.map((transfer) => orderTransferOut(transfer)),
        transportation: preSaveOrder.tourInfo && preSaveOrder.tourInfo.fullDescription,
        base_summ: baseSum.toString(),
        services_summ: extraServiceSum.toString(),
        additional_nights_summ: extraNightSum.toString(),
        sales_summ: extraSalesSum.toString(),
        total_amount: String(serviceSum),
        agent_reward: preSaveOrder.documentsAndPayment?.agent_reward,
        manager_sale: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.manager_sale,
        provider_price: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.supplierPrice,
        manager_sale_percent: preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.manager_sale_percent,
        total_summ: allSum.toString(),
        tour_operator_contact: preSaveOrder.tourInfo?.tour_operator_contact
          ? preSaveOrder.tourInfo?.tour_operator_contact.name === 'Акварель'
            ? null
            : `/api/v1/contacts/${preSaveOrder.tourInfo?.tour_operator_contact?.id}`
          : null,
        payer:
          preSaveOrder.documentsAndPayment && preSaveOrder.documentsAndPayment.payer?.id
            ? `/api/v1/contacts/${preSaveOrder.documentsAndPayment.payer?.id}`
            : undefined,
        exchange_rate: preSaveOrder.documentsAndPayment?.exchange_rate,
      };

      dispatch(updateOrder({ ...data, id: orderToEdit?.id } as unknown as Partial<Order>));
    }
  };
  return (
    <div className={styles.tableWrapper}>
      {/* {!(modal && orderToEdit) && ( */}
      <div className={styles.table}>
        <div className={styles.rows}>
          {ordersColumns.map((column) => (
            <div key={column.id} className={clsx(styles.column, styles[`column_${column.id}`])}>
              {column.id === 'id' ||
              column.id === 'partner' ||
              column.id === 'status' ||
              column.id === 'price' ||
              column.id === 'bookingDate' ? (
                <CellWithSorting column={column} setColumnSort={handleSortByColumn} sorting={column.sorted!} />
              ) : (
                <span>{column.name}</span>
              )}
            </div>
          ))}
        </div>
        {isLoading && isLoading === 'GET_ORDERS' && (
          <Loader color={'#70a4cb'} variant={'oval'} className={styles.loader} size={'lg'} />
        )}
        <div>
          {isLoading !== 'GET_ORDERS' &&
            orders &&
            orders.length > 0 &&
            orderList.map((order: any) => (
              <div
                key={order.id}
                className={clsx(
                  styles.row,
                  order.unread_messages_count > 0 && styles.hasUnread,
                  order.status.value === 'consultation' && order.payed_status === 'waiting' && styles.cons,
                  order.status.value === 'site_reserve' && order.payed_status === 'waiting' && styles.reserve,
                  order.status.value === 'site_reserve' && order.payed_status === 'payed' && styles.payed
                )}
                onClick={() => handleClickRow(order)}
              >
                <div className={clsx(styles.cell, styles.id)}>{order.id}</div>
                <div className={clsx(styles.cell, styles.partner)}>{order.contact.full_name}</div>
                <div className={clsx(styles.cell, styles.bookingDate)}>{getDate(new Date(order.created_at))}</div>
                <div className={clsx(styles.cell, styles.price)}>
                  {params.isConsultations
                    ? order.order_consultation && order.order_consultation.sum
                    : order.total_summ &&
                      `${order.total_summ} ${order.tour ? getCurrencyIconToUI(order.tour?.currency) : '₽'}`}
                </div>
                <div className={clsx(styles.cell, styles.tourTitle)}>
                  {params.isConsultations
                    ? 'Консультация'
                    : order.is_cruise || order.is_hotel
                    ? order.tour_name != ''
                      ? order.tour_name
                      : '-'
                    : order.tour?.system_name
                    ? order.tour?.system_name
                    : '-'}
                  <span>
                    {params.isConsultations
                      ? ''
                      : order.is_cruise
                      ? order.placements.length > 0 && order.placements[0].boat_string
                        ? order.placements[0].boat_string
                        : '-'
                      : order.is_hotel
                      ? 'Отель и Трансфер'
                      : order.tour_name
                      ? order.tour_name
                      : 'Тур не выбран'}
                  </span>
                </div>
                <div className={clsx(styles.cell, styles.tourDate)}>
                  {order.tour_date_start != null
                    ? order.tour_date_end
                      ? `${getDate(new Date(order.tour_date_start))} - ${getDate(new Date(order.tour_date_end))}`
                      : `${getDate(new Date(order.tour_date_start))}`
                    : 'Дата не указана'}
                </div>
                <div className={clsx(styles.cell, styles.status)}>
                  {getStatus(order.status.value)}
                  <span style={{ color: getStatusColor(getStatus(order.payed_status)) }}>
                    {getStatus(order.payed_status)}
                  </span>
                </div>
                <div className={clsx(styles.cell, styles.manager)}>
                  {order.manager && (
                    <User
                      name={`${order.manager.last_name && order.manager.last_name} ${
                        order.manager.first_name && getInitialsFromName(order.manager.first_name)
                      } ${order.manager.middle_name && getInitialsFromName(order.manager.middle_name)}`}
                      id={order.manager.id}
                      avatar={order.manager.avatar}
                      getUserInitials={(name) => getNameSurname(name)}
                    />
                  )}
                </div>
              </div>
            ))}

          {isSuccess === 'GET_ORDERS' && orderList.length === 0 && (
            <span className={styles.notFound}>Ничего не найдено</span>
          )}
        </div>
        {isLoading !== 'GET_ORDERS' && isSuccess === 'GET_ORDERS' && orders && orders.length > 0 && (
          <div className={styles.tableFooter}>
            <div className={styles.count}>Всего записей — {orderCount}</div>
            <Pagination
              total={countPages}
              value={params.currentPage}
              onChange={(val) => dispatch(setParamsPage(val))}
            />
          </div>
        )}
      </div>

      {/* )} */}

      {modal && (
        <Modal opened={modal} setOpened={setModal} saveDataBeforeClose={closeOrderModal}>
          {orderToEdit && <OrderEdit order={orderToEdit} />}
        </Modal>
      )}
    </div>
  );
};
