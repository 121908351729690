import { TableColumn } from './ReportsTable.types';

export const reportsColumns: TableColumn[] = [
  {
    id: 'name',
    name: 'Название',
  },
  {
    id: 'type',
    name: 'Тип отчета',
  },
  {
    id: 'date',
    name: 'Дата создания',
  },
  {
    id: 'author',
    name: 'Автор',
  },
];
