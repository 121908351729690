import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { notifications } from '@mantine/notifications';

import { HotelsType } from './HotelTable.type';
import { Button } from 'ui/Button';
import { HotelInfo } from 'pages/Tours/Tours.types';
import { hotelColumns } from './data';
import { useForm } from '@mantine/form';
import Settings from 'components/Settings';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteHotelPlacement, deletePlacement } from 'store/action-creators/tours';
import { DeletePlacementData } from 'services/tours/toursApi.service';

import styles from './HotelTable.module.scss';
import { Select } from 'ui/Select';
import { DatePickerInput, DatesProvider, DatesRangeValue } from '@mantine/dates';
import { Checkbox } from 'ui/Checkbox';

export const HotelTable: FC<HotelsType> = ({ hotels, setHotels }) => {
  console.log(hotels);
  const dispatch = useAppDispatch();
  const { isSuccess, isError, roomTypes } = useAppSelector((state) => state.tours);
  const [hotelRows, setHotelRows] = useState<HotelInfo[]>(hotels || []);
  const [newHotelRow, setNewHotelRow] = useState<HotelInfo | null>(null);
  const initialValues: HotelInfo = {
    id: '',
    hotel: '',
    period: [null, null],
    typeRoom: '',
    category: '',
    food_type_string: '',
    places_count: '',
    placement_type_string: '',
    price1A: '',
    price2A: '',
    price3A: '',
    price1A1C: '',
    price1A2C: '',
    price2A1C: '',
    price2A2C: '',
    show_no_places: false,
    saled_places_count: 0,
  };

  const formNewHotelRow = useForm({
    initialValues,
  });

  useEffect(() => {
    if (hotelRows) {
      setHotels(hotelRows);
    }
  }, [hotelRows]);

  // добавление нового размещения
  const addHotelNewRow = () => {
    if (newHotelRow) {
      newHotelRow;
      setHotelRows([...hotelRows, newHotelRow]);
      formNewHotelRow.setValues(initialValues);
    }
  };

  const editExistHotelRow = (key: number, value: Partial<HotelInfo>) => {
    const data = [...hotelRows];
    data[key] = { ...data[key], ...value };
    setHotelRows(data);
  };

  useEffect(() => {
    setNewHotelRow({
      id: hotelRows.length + 1,
      hotel: formNewHotelRow.values.hotel,
      period: formNewHotelRow.values.period,
      placement_type_string: formNewHotelRow.values.placement_type_string,
      typeRoom: formNewHotelRow.values.typeRoom,
      category: formNewHotelRow.values.category,
      food_type_string: formNewHotelRow.values.food_type_string,
      places_count: formNewHotelRow.values.places_count,
      show_no_places: formNewHotelRow.values.show_no_places,
      saled_places_count: formNewHotelRow.values.saled_places_count,
      price1A: formNewHotelRow.values.price1A,
      price2A: formNewHotelRow.values.price2A,
      price3A: formNewHotelRow.values.price3A,
      price1A1C: formNewHotelRow.values.price1A1C,
      price1A2C: formNewHotelRow.values.price1A2C,
      price2A1C: formNewHotelRow.values.price2A1C,
      price2A2C: formNewHotelRow.values.price2A2C,
    });
  }, [formNewHotelRow.values]);

  useEffect(() => {
    if (hotels && hotels[0] != hotelRows[0]) {
      setHotelRows(hotels);
    }
  }, [hotels]);

  // удаление размещения (гостиницы с номерами)
  const handleHotelSettingsDelete = (id: number | string | undefined, idText: string | undefined) => {
    const updateRows = hotelRows?.filter((item) => item.id !== id);

    if (updateRows) {
      setHotelRows(updateRows);
    }

    // если idText есть, значит размещение есть на сервере. удаляем с сервера.
    // если размещение было добавлено только на клиенте и еще не ушло на сервер, запрос на удаление не отправляем.
    // просто выводим сообщение, что объект удален и обновляем текущий массив.
    if (idText) {
      const idFactory = (id: number | string): DeletePlacementData => {
        return {
          id: id,
        };
      };

      if (id) {
        dispatch(deleteHotelPlacement(idFactory(id)));
      }
    } else {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Размещение удалено' });
    }
  };

  // дублирование поездки
  const handleHotelSettingsClone = (id: number | string | undefined) => {
    const dataInRows = hotelRows?.find((item) => item.id === id);

    if (dataInRows) {
      const newRow = {
        id: Number(hotelRows.length) + 1,
        hotel: dataInRows.hotel,
        period: dataInRows.period,
        placement_type_string: dataInRows.placement_type_string,
        typeRoom: dataInRows.typeRoom,
        category: dataInRows.category,
        food_type_string: dataInRows.food_type_string,
        places_count: dataInRows.places_count,
        show_no_places: dataInRows.show_no_places,
        saled_places_count: dataInRows.saled_places_count,
        price1A: dataInRows.price1A,
        price2A: dataInRows.price2A,
        price3A: dataInRows.price3A,
        price1A1C: dataInRows.price1A1C,
        price1A2C: dataInRows.price1A2C,
        price2A1C: dataInRows.price2A1C,
        price2A2C: dataInRows.price2A2C,
      };
      setHotelRows([...hotelRows, newRow]);
      formNewHotelRow.setValues(initialValues);
    }
  };

  // нотификация
  useEffect(() => {
    if (isSuccess === 'DELETE_PLACEMENT') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Размещение удалено' });
    }
    if (isError === 'DELETE_PLACEMENT') {
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Ошибка отправки данных на сервер' });
    }
  }, [isSuccess, isError]);

  return (
    <article className={styles.hotels}>
      <div className={styles.tableWrapper}>
        <div className={styles.table}>
          <div className={styles.headers}>
            {hotelColumns.map((column) => (
              <div
                key={column.id}
                className={clsx(
                  styles.column,
                  styles[`column_${column.id}`],
                  column.id.includes('price') && styles[`column_price`]
                )}
              >
                <span>{column.icon ? column.icon : column.name}</span>
              </div>
            ))}
          </div>
          <div>
            {hotelRows.length > 0 &&
              hotelRows.map((item, key) => (
                <div key={key} id={item.id + ''} className={styles.row}>
                  <div
                    className={clsx(styles.cell, styles.hotel, styles.newItem)}
                    // defaultValue={item.hotel}
                  >
                    <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 1, weekendDays: [0] }}>
                      <DatePickerInput
                        type="range"
                        allowSingleDateInRange={true}
                        value={item.period}
                        onChange={(value) => editExistHotelRow(key, { period: value })}
                        valueFormat="DD.MM.YYYY "
                        mx="auto"
                        maw={400}
                        className={styles.datePicker}
                        styles={{
                          rightSection: { pointerEvents: 'none' },
                          input: {
                            border: 'none',
                            fontFamily: 'Museo Sans Cyrl',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '19px',
                            color: '#263B53',
                          },
                        }}
                      />
                    </DatesProvider>
                  </div>
                  <Select
                    data={roomTypes.map((el) => el.name)}
                    className={clsx(styles.cell, styles.typeRoom, styles.newItem, styles.select)}
                    selected={item.typeRoom}
                    setSelect={(a) => editExistHotelRow(key, { typeRoom: a.toString() })}
                  />
                  <input
                    className={clsx(styles.cell, styles.category, styles.newItem)}
                    // defaultValue={item.category}
                    value={item.category}
                    onChange={(e) => {
                      editExistHotelRow(key, { category: e.target.value });
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.food_type_string, styles.newItem)}
                    // defaultValue={item.category}
                    value={item.food_type_string}
                    onChange={(e) => {
                      editExistHotelRow(key, { food_type_string: e.target.value });
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.places_count, styles.newItem)}
                    // defaultValue={item.category}
                    value={item.places_count}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { places_count: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          places_count: e.target.value === '' ? e.target.value : item.places_count,
                        });
                      }
                    }}
                  />
                  <span className={styles.checkbox}>
                    <Checkbox
                      isChecked={item.show_no_places || false}
                      setIsChecked={(a) => editExistHotelRow(key, { show_no_places: a })}
                    />
                  </span>
                  <input
                    className={clsx(styles.cell, styles.saled, styles.newItem)}
                    // defaultValue={item.category}
                    value={item.places_count ? `${item.saled_places_count || 0}/${item.places_count}` : ''}
                    disabled
                  />
                  <Select
                    data={['1взр', '2взр', '3взр', '1взр+1реб', '1взр+2реб', '2взр+1реб', '2взр+2реб']}
                    className={clsx(styles.cell, styles.placementType, styles.newItem, styles.select)}
                    selected={item.placement_type_string}
                    setSelect={(a) => editExistHotelRow(key, { placement_type_string: a.toString() })}
                  />
                  <input
                    className={clsx(styles.cell, styles.price, styles.newItem)}
                    // defaultValue={item.price1A}
                    value={item.price1A}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { price1A: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          price1A: e.target.value === '' ? e.target.value : item.price1A,
                        });
                      }
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.price, styles.newItem)}
                    // defaultValue={item.price2A}
                    value={item.price2A}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { price2A: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          price2A: e.target.value === '' ? e.target.value : item.price2A,
                        });
                      }
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.price, styles.newItem)}
                    // defaultValue={item.price3A}
                    value={item.price3A}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { price3A: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          price3A: e.target.value === '' ? e.target.value : item.price3A,
                        });
                      }
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.price, styles.newItem)}
                    // defaultValue={item.price1A1C}
                    value={item.price1A1C}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { price1A1C: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          price1A1C: e.target.value === '' ? e.target.value : item.price1A1C,
                        });
                      }
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.price, styles.newItem)}
                    // defaultValue={item.price1A2C}
                    value={item.price1A2C}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { price1A2C: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          price1A2C: e.target.value === '' ? e.target.value : item.price1A2C,
                        });
                      }
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.price, styles.newItem)}
                    // defaultValue={item.price2A1C}
                    value={item.price2A1C}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { price2A1C: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          price2A1C: e.target.value === '' ? e.target.value : item.price2A1C,
                        });
                      }
                    }}
                  />
                  <input
                    className={clsx(styles.cell, styles.price, styles.newItem)}
                    // defaultValue={item.price2A2C}
                    value={item.price2A2C}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d+$/)) {
                        editExistHotelRow(key, { price2A2C: e.target.value });
                      } else {
                        editExistHotelRow(key, {
                          price2A2C: e.target.value === '' ? e.target.value : item.price2A2C,
                        });
                      }
                    }}
                  />
                  <Settings
                    btnDeleteText={'Удалить'}
                    btnCopyText={'Дублировать'}
                    bntDelete={() => handleHotelSettingsDelete(item.id, item['@id'])}
                    bntCopy={() => handleHotelSettingsClone(item.id)}
                    className={styles.settings}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        <Button transparent={true} label="+ Добавить Размещение" className={styles.addRow} onClick={addHotelNewRow} />
      </div>
    </article>
  );
};
