export const getFormType = (type: string) => {
  switch (type) {
    case 'tour-selection':
      return 'Заявка на подбор тура';
    case 'question':
      return 'Форма вопроса';
    case 'individual-tour':
      return 'Индивидуальный тур';
    case 'consultation':
      return 'Консультация';
    case 'contact-us':
      return 'Связаться с нами';
    default:
      return 'Консультация';
  }
};
