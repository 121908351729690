import React, { useEffect, useMemo, useState } from 'react';

import { Button } from 'ui/Button';

import styles from './ReportsTop.module.scss';
import ModalCentred from 'components/Modal/ModalCentred';
import { InputDatePicker } from 'ui/InputDatePicker';
import { listOfReportTypes } from 'core/constants/lists';
import { Select } from 'ui/Select';
import { useForm } from '@mantine/form';
import { ICreateReportProps } from 'services/reports/reports.types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCreateReportForm } from 'store/entities/reports/reportsSlice';
import { createReport, getReports } from 'store/action-creators/reports';
import { notifications } from '@mantine/notifications';
import { Checkbox } from 'ui/Checkbox';
import { SelectItemType } from 'ui/SelectNew/SelectNew.type';
import { getTimezonelessDate } from 'core/utils/getDate';
import { getPartners } from 'store/action-creators/partners';
import { SelectSearch } from 'ui/SelectSearch';

const reportTypes = listOfReportTypes;

const ReportsTop = () => {
  const [newReportModal, setNewReportModal] = useState(false);
  const [isPrintAcvarel, setisPrintAcvarel] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [agent, setAgent] = useState<{ id: number; name: string } | null>(null);
  const {
    reports: { isSuccess, isLoading, isError },
    users: { users: listOfManagers },
  } = useAppSelector(({ reports, users, tours }) => ({ reports, users, tours }));
  const { partners } = useAppSelector((state) => state.partners);
  const [partnerList, setPartnerList] = useState<SelectItemType[]>([]);
  // при изменении списка туроператоров в стейте, устанавливаем его в выпадающий список
  useEffect(() => {
    const shortData = partners.map((el) => {
      return { name: el.name ? el.name : '', id: el.id };
    });
    setPartnerList([{ id: undefined, name: 'Акварель' }, ...shortData]);
  }, [partners]);

  // useEffect(() => {
  //   const shortData = partners.map((el) => {
  //     return el.requisites;
  //   });
  //   const reqList = shortData
  //     .filter((el) => el != undefined)
  //     .map((el) =>
  //       el!.map((item) => {
  //         return { '@id': item.id, name: item.legalName };
  //       })
  //     );
  //   const flattedArr = reqList.flat();
  //   setRequisitesList(flattedArr);
  // }, [partners]);
  const dispatch = useAppDispatch();
  const initialValues: ICreateReportProps = {
    period_from: null,
    period_to: null,
    type: '',
    manager: '',
    tour_operator_contact: '',
    requisite: '',
  };

  useEffect(() => {
    if (newReportModal) {
      document.body.style.overflow = 'hidden'; // disable background scrolling
    } else {
      document.body.style.overflow = 'auto'; // allow background scrolling
    }
  }, [newReportModal]);

  useEffect(() => {
    if (isError === 'CREATE_REPORT')
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Ошибка при создании отчета' });

    if (isLoading === 'CREATE_REPORT')
      notifications.show({ title: 'В процессе', color: 'yellow', message: 'Идет создание отчета' });

    if (isSuccess === 'CREATE_REPORT') {
      notifications.show({ title: 'Успех', color: 'green', message: 'Отчет успешно создан' });
      dispatch(getReports());
    }
  }, [isError, isLoading, isSuccess]);

  const managers = useMemo(() => listOfManagers.map((manager) => manager.name), [listOfManagers]);

  //TODO: не успел разобраться с useForm, заткнул any
  const validate: any = {
    period_from: (value: string) => (!value ? 'Поле Дата обязательно для заполнения' : null),
    period_to: (value: string) => (!value ? 'Поле Дата обязательно для заполнения' : null),
    type: (value: string) => (!value ? 'Поле Тип отчета обязательно для заполнения' : null),
  };

  const formCommonInfo = useForm({
    initialValues,
    validate,
  });
  const { isManageType, isOperatorType, isMixedType, isAgentType, isPassType } = useMemo(() => {
    const type = formCommonInfo.values.type;
    const isManageType = type === reportTypes[2];
    const isOperatorType = type === reportTypes[3];
    const isAgentType = type === reportTypes[4];
    const isPassType = type === reportTypes[5];
    const isMixedType = !isManageType && !isOperatorType && !isAgentType && !isPassType;
    return { isManageType, isOperatorType, isMixedType, isAgentType, isPassType };
  }, [formCommonInfo.values.type]);

  const handleDataPicker = ([dateFrom, dateTo]: [Date | null, Date | null]) => {
    formCommonInfo.setFieldValue('period_from', dateFrom);
    formCommonInfo.setFieldValue('period_to', dateTo);
  };

  const handleCreateOrder = () => {
    const hasManageError = isManageType ? !formCommonInfo.values.manager : false;
    const hasOperatorError = isOperatorType ? !formCommonInfo.values.tour_operator_contact : false;
    const { hasErrors, errors } = formCommonInfo.validate();

    if (hasErrors || hasManageError || hasOperatorError) {
      formCommonInfo.setErrors({
        ...errors,
        tour_operator: hasOperatorError ? 'Поле Туроператор обязательно для заполнения' : null,
        manager: hasManageError ? 'Поле Менеджер обязательно для заполнения' : null,
      });
      return;
    }
    const tourOperator = partnerList.find(
      (tourOperator) => tourOperator.name === formCommonInfo.values.tour_operator_contact
    );
    const formattedData: ICreateReportProps = {
      period_from: getTimezonelessDate(formCommonInfo.values.period_from!),
      period_to: getTimezonelessDate(formCommonInfo.values.period_to!),
      manager: isManageType
        ? listOfManagers.find((manager) => manager.name === formCommonInfo.values.manager)?.['@id']
        : undefined,
      type:
        formCommonInfo.values.type === reportTypes[1]
          ? 'tours_started'
          : isMixedType
          ? 'tours'
          : isManageType
          ? 'managers'
          : isAgentType
          ? 'agents'
          : isPassType
          ? 'pass'
          : 'tour_operators',
      tour_operator_contact:
        isOperatorType || isAgentType ? (tourOperator?.id ? `/api/v1/contacts/${tourOperator.id}` : null) : undefined,
      is_akvarel: isOperatorType ? isPrintAcvarel : undefined,
      // requisite: isAgentType
      //   ? requisitesList.find((el) => el.name === formCommonInfo.values.requisite)?.['@id']
      //   : undefined,
    };
    console.log(formattedData);
    dispatch(setCreateReportForm(formattedData));
    dispatch(createReport());
    setNewReportModal(false);
    setAgent(null);
    setSearchVal('');
    formCommonInfo.reset();
  };
  const handleKey = () => {
    if (searchVal != '') {
      dispatch(
        getPartners({
          page: 1,
          itemsPerPage: 100,
          subject: 'juridical',
          search: searchVal,
        })
      );
    }
  };
  return (
    <>
      <div className={styles.top}>
        <div className={styles.counteragents}>
          <h2 className={styles.title}>Отчеты</h2>
          <Button className={styles.topButton} onClick={() => setNewReportModal(true)}>
            Создать отчет
          </Button>
        </div>
        <h3 className={styles.subtitle}>История созданных отчетов</h3>
      </div>
      {newReportModal && (
        <ModalCentred opened={newReportModal} setOpened={setNewReportModal}>
          <form className={styles.form}>
            <div>
              <h2 className={styles.heading}>Мастер создания отчета</h2>
              <div className={styles.formContent}>
                <InputDatePicker
                  fill
                  className={styles.datepicker}
                  placeholder="__.__.___-__.__.___"
                  setDates={handleDataPicker}
                  label="Период"
                  error={!!formCommonInfo.errors.period_from || !!formCommonInfo.errors.period_to}
                />
                <Select
                  selected={formCommonInfo.values.type}
                  data={reportTypes}
                  setSelect={(value) => {
                    formCommonInfo.setFieldValue('type', value);
                    // if (value === reportTypes[3]) {
                    //   dispatch(
                    //     getPartners({
                    //       page: 1,
                    //       itemsPerPage: 100,
                    //       subject: 'juridical',
                    //     })
                    //   );
                    // }
                  }}
                  label="Тип отчета"
                  className={styles.reportType}
                  error={!!formCommonInfo.errors.type}
                  {...formCommonInfo.getInputProps('type')}
                />
                {isManageType && (
                  <Select
                    {...formCommonInfo.getInputProps('manager')}
                    selected={formCommonInfo.values.manager}
                    data={managers}
                    setSelect={(value) => formCommonInfo.setFieldValue('manager', value)}
                    label="Менеджер"
                    className={styles.manager}
                    error={!!formCommonInfo.errors.manager}
                  />
                )}
                {isAgentType && (
                  <SelectSearch
                    data={partnerList
                      .filter((el) => el.name != '')
                      .map((el) => {
                        return { id: Number(el.id), name: el.name };
                      })}
                    label="Агент"
                    value={agent}
                    setSearch={setSearchVal}
                    handleKey={handleKey}
                    setValue={(a) => {
                      setAgent(a);
                      formCommonInfo.setFieldValue('tour_operator_contact', a.name);
                    }}
                    placeholder="Не выбран"
                    className={styles.operator}
                    isNeedSort
                  />
                )}
                {isOperatorType && (
                  <>
                    <Select
                      selected={formCommonInfo.values.tour_operator_contact}
                      data={partnerList.map((el) => el.name)}
                      setSelect={(value) => formCommonInfo.setFieldValue('tour_operator_contact', value)}
                      label="Туроператор"
                      className={styles.operator}
                      error={!!formCommonInfo.errors.tour_operator_contact}
                      {...formCommonInfo.getInputProps('tour_operator_contact')}
                    />

                    <Checkbox
                      label="Выводить Акварель"
                      isChecked={isPrintAcvarel}
                      setIsChecked={(arg) => {
                        setisPrintAcvarel(arg);
                      }}
                      disabled={formCommonInfo.values.tour_operator_contact === 'Акварель'}
                    />
                  </>
                )}
              </div>
            </div>
            <Button className={styles.formButton} onClick={handleCreateOrder}>
              Создать отчет
            </Button>
          </form>
        </ModalCentred>
      )}
    </>
  );
};

export default ReportsTop;
