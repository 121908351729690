import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { privateRoutes, publicRoutes } from './routes';
import Layout from 'ui/Layout';
import { RouteNames } from 'core/constants/routeNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { checkAuth } from 'store/action-creators/auth';
import { NotFound } from 'pages/NotFound';
import { SignIn } from 'pages/SignIn';

export const AppRouter = () => {
  const { isAuth, isLoading, isError } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      //если пользователь нажмет на "выход", то токена не будет и функция не отработает
      dispatch(checkAuth());
    }
  }, []);

  return (
    <Routes>
      <Route path={RouteNames.HOME} element={<Navigate to={RouteNames.SIGN_IN} replace />} />

      <Route>
        {publicRoutes.map(({ path, Component }) => (
          <Route path={path} element={<Component />} key={path} />
        ))}
      </Route>

      {/* если пользователь авторизован, то ему доступны все страницы. для несуществующей будет NotFound */}
      {isAuth && (
        <Route path={RouteNames.HOME} element={<Layout />}>
          {privateRoutes.map(({ path, Component }) => (
            <Route path={path} element={<Component />} key={path} />
          ))}
          <Route path="*" element={<NotFound />} />
        </Route>
      )}
      {/* если пользователь не авторизован, но хочет зайти на конкретную страницу - открывается форма авторизации.
          после успешной авторизации пользователь остается на этой странице */}
      {!isAuth && !isError && !isLoading && <Route path="*" element={<SignIn />} />}
    </Routes>
  );
};
