import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const TrueIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8.03846L8.3 14.5L20 2.5" stroke="#1ADD16" strokeWidth="4" strokeLinecap="round" />
    </svg>
  );
};
