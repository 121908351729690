import axios, { AxiosRequestConfig, AxiosError } from 'axios';

import { checkAuthAPI } from './auth/authApi.service';
import { notifications } from '@mantine/notifications';

export const BASE_URL: string = process.env.REACT_APP_BASE_API_URL as string;

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers) {
      if (localStorage.getItem('token')) {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      } else {
        config.headers['Authorization'] = '';
      }

      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await checkAuthAPI();
        localStorage.setItem('token', response.data.token);
        return axiosInstance.request(originalRequest);
      } catch (error) {
        const _err = error as AxiosError;
        if (_err.code !== 'ERR_BAD_REQUEST')
          notifications.show({title:'Ошибка сервера:', color:'red', message: ('Код ошибки: ' + _err.code + ', ' + _err.message)});
      }
    }
    throw error;
  }
);

export default axiosInstance;
