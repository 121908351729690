import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const CoffeeIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.83398 4.93945C5.18379 4.93945 5.4668 4.65645 5.4668 4.30664V0.632812C5.4668 0.283008 5.18379 0 4.83398 0C4.48418 0 4.20117 0.283008 4.20117 0.632812V4.30664C4.20117 4.65645 4.48418 4.93945 4.83398 4.93945ZM15.6094 7.4707H13.5C13.5 6.77109 12.934 6.20508 12.2344 6.20508H3.51562C2.81602 6.20508 2.25 6.77109 2.25 7.4707V11.8301C2.25 11.8898 2.25352 11.9479 2.2623 12.0041C2.25352 12.1271 2.25 12.2502 2.25 12.375C2.25 15.4811 4.76895 18 7.875 18C10.6893 18 13.0201 15.9346 13.435 13.2363H15.6094C16.309 13.2363 16.875 12.6703 16.875 11.9707V8.73633C16.875 8.03672 16.309 7.4707 15.6094 7.4707ZM12.2344 11.9707H12.215C12.2273 12.1043 12.2344 12.2379 12.2344 12.375C12.2344 14.7832 10.2832 16.7344 7.875 16.7344C5.4668 16.7344 3.51562 14.7832 3.51562 12.375C3.51562 12.2379 3.52266 12.1043 3.53496 11.9707H3.51562V7.4707H12.2344V11.9707ZM15.6094 11.8301H13.6406V8.73633H15.6094V11.8301ZM10.7754 4.93945C11.1252 4.93945 11.4082 4.65645 11.4082 4.30664V0.632812C11.4082 0.283008 11.1252 0 10.7754 0C10.4256 0 10.1426 0.283008 10.1426 0.632812V4.30664C10.1426 4.65645 10.4256 4.93945 10.7754 4.93945ZM7.78711 4.93945C8.13691 4.93945 8.41992 4.65645 8.41992 4.30664V0.632812C8.41992 0.283008 8.13691 0 7.78711 0C7.4373 0 7.1543 0.283008 7.1543 0.632812V4.30664C7.1543 4.65645 7.4373 4.93945 7.78711 4.93945Z"
        fill="#1886E9"
      />
    </svg>
  );
};
