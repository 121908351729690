import React, { FC } from 'react';
import { ISiteRequest } from 'services/orders/orderApi.service';
import { TourSelection } from './types/TourSelection';
import { Question } from './types/Question';
import { IndividualTour } from './types/IndividualTour';
import { Consultation } from './types/Consultation';
import { ContactUs } from './types/ContactUs';
export const RequestFromSite: FC<{ request: ISiteRequest }> = ({ request }) => {
  const getRequest = (type: string) => {
    switch (type) {
      case 'tour-selection':
        return <TourSelection request={request} />;
      case 'question':
        return <Question request={request} />;
      case 'individual-tour':
        return <IndividualTour request={request} />;
      case 'consultation':
        return <Consultation request={request} />;
      case 'contact-us':
        return <ContactUs request={request} />;
      default:
        return <Consultation request={request} />;
    }
  };
  return <>{getRequest(request.form_type)}</>;
};
