import React, { FC } from 'react';

import { IconProps } from './icons.types';

export const DragDoteIcon: FC<IconProps> = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9999 2.59956C3.9999 3.7041 3.10449 4.59951 1.99995 4.59951C0.895407 4.59951 -1.72722e-07 3.7041 -1.1127e-07 2.59956C-4.98171e-08 1.49502 0.895407 0.599609 1.99995 0.599609C3.10449 0.59961 3.9999 1.49502 3.9999 2.59956ZM3.9999 8.99947C3.9999 10.104 3.10449 10.9994 1.99995 10.9994C0.895407 10.9994 -5.2879e-07 10.104 -4.67337e-07 8.99947C-4.05884e-07 7.89493 0.895407 6.99952 1.99995 6.99952C3.10449 6.99952 3.9999 7.89493 3.9999 8.99947ZM1.99995 17.3993C3.10449 17.3993 3.9999 16.5039 3.9999 15.3994C3.9999 14.2948 3.10449 13.3994 1.99995 13.3994C0.895407 13.3994 -7.61952e-07 14.2948 -8.23404e-07 15.3994C-8.84857e-07 16.5039 0.895407 17.3993 1.99995 17.3993Z"
        fill="#C8CDD8"
        fillOpacity="0.75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 2.59956C10.9999 3.7041 10.1045 4.59951 8.99995 4.59951C7.89541 4.59951 7 3.7041 7 2.59956C7 1.49502 7.89541 0.599609 8.99995 0.599609C10.1045 0.59961 10.9999 1.49502 10.9999 2.59956ZM10.9999 8.99947C10.9999 10.104 10.1045 10.9994 8.99995 10.9994C7.89541 10.9994 7 10.104 7 8.99947C7 7.89493 7.89541 6.99952 8.99995 6.99952C10.1045 6.99952 10.9999 7.89493 10.9999 8.99947ZM8.99995 17.3993C10.1045 17.3993 10.9999 16.5039 10.9999 15.3994C10.9999 14.2948 10.1045 13.3994 8.99995 13.3994C7.89541 13.3994 7 14.2948 7 15.3994C7 16.5039 7.89541 17.3993 8.99995 17.3993Z"
        fill="#C8CDD8"
        fillOpacity="0.75"
      />
    </svg>
  );
};
