import React from 'react';
import { TableColumn } from 'components/AddService/AddService.type';

export const PlannedPaymentColumns: TableColumn[] = [
  {
    id: 'id',
    name: '№',
  },
  {
    id: 'type',
    name: 'Вид',
  },
  {
    id: 'sum',
    name: 'Сумма',
  },
  {
    id: 'rate',
    name: 'Курс',
  },
  {
    id: 'rub',
    name: 'Руб.',
  },
  {
    id: 'date',
    name: 'Оплатить до',
  },
];

export const paymentTypes = ['Аванс', 'Доплата', 'Полная оплата'];
