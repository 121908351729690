import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { FilterType } from './FilterNew.types';
import { DropdownNew } from './components/Dropdown';
import { CloseIcon } from 'components/icons';
import { useRefCloseOut } from 'hooks/useRefCloseOut';

import styles from './FilterNew.module.scss';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';

interface FilterProps {
  filter: FilterType;
  setFilter?: (a: SelectMultiItemType | null) => void;
  isZeroing?: boolean;
  defaultVal?: string;
}

export const FilterNew: FC<FilterProps> = ({ filter, setFilter, isZeroing = true, defaultVal }) => {
  const [opened, setOpened] = useState(false);
  const [title, setTitle] = useState(defaultVal ? `${filter.title}: ${defaultVal}` : filter.title);
  const openedFilterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const btnOpenedFilterRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleFilter = (filterQuery: SelectMultiItemType) => {
    if (filterQuery.name !== '') {
      setTitle(`${filter.title}: ${filterQuery.name}`);
      if (setFilter) {
        setFilter(filterQuery);
      }
    }
  };

  const handleOpenedFilter = () => {
    setOpened((prev) => !prev);
  };

  const closeItem = (event: React.MouseEvent) => {
    event.stopPropagation();
    setTitle(filter.title);
    setFilter && setFilter(null);
  };
  useEffect(() => {
    if (!defaultVal) return;
    if (title !== `${filter.title}: ${defaultVal}`) {
      setTitle(`${filter.title}: ${defaultVal}`);
    }
  }, [defaultVal]);

  //если удалены все выбранные фильтры, то отправляется запрос на получение всех данных
  useEffect(() => {
    if (!isZeroing) return;
    if (title === filter.title && setFilter) {
      setFilter(null);
    }
  }, [title]);

  useRefCloseOut(openedFilterRef, setOpened, btnOpenedFilterRef);

  return (
    <div className={styles.filter}>
      <div
        className={clsx(styles.button, title !== filter.title && styles.buttonActive)}
        onClick={handleOpenedFilter}
        ref={btnOpenedFilterRef}
      >
        {title.length > 24 ? `${title.substring(0, 32)}...` : title}
        {title !== filter.title && (
          <button onClick={closeItem} className={styles.close}>
            <CloseIcon width={10} height={10} fill="#70A4CB" />
          </button>
        )}
      </div>
      {opened && (
        <div ref={openedFilterRef}>
          <DropdownNew
            title={filter.title}
            data={filter.data}
            setFiltersData={handleFilter}
            setOpenedFilter={setOpened}
          />
        </div>
      )}
    </div>
  );
};
