import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { SearchType } from './Search.types';
import { CloseIcon } from 'components/icons';

import styles from './Search.module.scss';

export const Search: FC<SearchType> = ({ placeholder, className, button, setQuery, query }) => {
  const [value, setValue] = useState<string>(query || '');
  const [onBlur, setOnBlur] = useState(false);

  //данные из инпута сохраняются при потере фокуса с этого поля
  useEffect(() => {
    if (onBlur) {
      setQuery(value);
      setOnBlur(false);
    }
  }, [value, onBlur]);

  //данные из инпута сохраняются при нажатии кнопки Enter
  const sendQuery = (e: React.KeyboardEvent) => {
    if (e.key == 'Enter') {
      e.preventDefault();
      setQuery(value);
    }
  };

  return (
    <article className={clsx(styles.search, className)}>
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={styles.input}
        onBlur={() => setOnBlur(true)}
        onKeyDown={sendQuery}
      />
      {value !== '' && (
        <button
          className={styles.close}
          onClick={() => {
            setValue('');
            setOnBlur(true);
          }}
        >
          <CloseIcon fill="#c8cdd8" width={12} height={12} />
        </button>
      )}

      {!!button && (
        <button onClick={() => button.onClick(value)} className={styles.button}>
          {button.label}
        </button>
      )}
    </article>
  );
};
