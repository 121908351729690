import React, { FC, useEffect, useState } from 'react';
import { Select } from 'ui/Select';
import styles from './PartnerCreate.module.scss';
import { PartnerFormPrivatePerson } from '../components/PartnerFormPrivatePerson';
import { PartnerFormLegalPerson } from '../components/PartnerFormLegalPerson';
import { StatusPartner } from 'core/constants/enums';
import { PartnerCreateType } from './PartnerCreate.type';

const mockSelectData: string[] = [StatusPartner.PRIVATE_PERSON, StatusPartner.LEGAL_PERSON];

export const PartnerCreate: FC<PartnerCreateType> = () => {
  const [selectSubject, setSelectSubject] = useState<string>();

  useEffect(() => {
    setSelectSubject(mockSelectData[0]);
  }, []);

  return (
    <section className={styles.create}>
      <div className={styles.top}>
        <h2>Создание контрагента</h2>
      </div>
      <div className={styles.content}>
        <>
          <Select selected={selectSubject} data={mockSelectData} setSelect={setSelectSubject} label="Субъект" />
        </>

        {selectSubject === StatusPartner.PRIVATE_PERSON ? <PartnerFormPrivatePerson /> : <PartnerFormLegalPerson />}
      </div>
    </section>
  );
};
